


  <div class="statsGrid" *ngIf="!spinnerProgress">
    <div class="statsOne ">
      <app-statsone 
      [getBookingStats]="getBookingStats" 
      [getPlacementStats]="getPlacementStats"
      [getRegistrationStats]="getRegistrationStats"
      ></app-statsone>
    </div>
    
    <div class="statsTwo removeOnMobile" >
      <app-statstwo
      [getAgencyOrClientLog]="getAgencyOrClientLog"
      ></app-statstwo>
    </div>
    <div class="statsThree removeOnMobile">
      <app-statsthree
      [getLastAction]="getLastAction"
      [getLastSubscribe]="getLastSubscribe"
      ></app-statsthree>
    </div>
  </div>



  <mat-spinner class="centred" *ngIf="spinnerProgress">
  </mat-spinner>



