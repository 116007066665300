import { Component, OnInit, Input } from '@angular/core';
import { HttpService } from '../http.service';
import { Router, ActivatedRoute } from "@angular/router";
import { CriteriaService } from '../criteria.service';
import {MatDialog } from '@angular/material/dialog';
import { ModelOverviewComponent } from '../pictures/model-overview/model-overview.component';

@Component({
  selector: 'app-suggested',
  templateUrl: './suggested.component.html',
  styleUrls: ['./suggested.component.css']
})
export class SuggestedComponent implements OnInit {

  @Input() type: string;
  spinnerProgress: boolean;
  models: any;
  hash: string;
  gender: string;

  constructor(
    private get: HttpService,
    private router: Router,
    private _Activatedroute:ActivatedRoute,
    private criteriaservice : CriteriaService,
    public dialog: MatDialog,
  ) {
    this.spinnerProgress = false;
    this.gender = 'female';
   }

  ngOnInit() {
    this._Activatedroute.params.subscribe( params => {
      console.log('params',params.id)  ;
      this.hash = params.id;
      this.getmodel(this.type, this.hash, this.gender);
    });
    this.criteriaservice.getUpdate().subscribe( res => {
      console.log('res updated', res);
      if(res = 'updated'){
        this.getmodel(this.type, this.hash, this.gender);
      }
     //

    });
    
  }

  getmodel(type, agence, gender){
    console.log('type, agence', this.type, this.hash);
    this.spinnerProgress = true;
    this.get.getSuggested(type, agence, gender).subscribe( res => {
      this.spinnerProgress = false;
      this.models = res;
      console.log( 'resultat', this.models);
    })
  } 

  getDetails($event,index) {
    let detail = 'details'.concat(index);
    console.log(detail);
    if ($event.type == 'mouseover'){
      document.getElementById(detail).classList.add('reveal');
    } else {
      document.getElementById(detail).classList.remove('reveal');
    }
   
  }
  
  /*goModel(elem: string){
    console.log('router', elem);
    this.router.navigate(['members/page2',elem] );
  }*/

  goModel(elem: string): void {
    const dialogRef = this.dialog.open(ModelOverviewComponent, {
      data: {modal: elem}
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }



}
