import {
  Component,
  OnInit,
  Inject,
  Input
} from '@angular/core';
import {
  HttpService
} from '../http.service';
import {
  Router,
  ActivatedRoute
} from '@angular/router';
import {
 MatDialog,
   MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormGroup
} from '@angular/forms';
import { MatSnackBar} from '@angular/material/snack-bar';

import {
  AngularEditorConfig
} from '@kolkov/angular-editor';
import {
  CriteriaService
} from '../criteria.service';
import {
  EditProfile
} from './editprofile.component';

import {
  GobookComponent
} from '../agenda/gobook/gobook.component';
import {
  PushlogComponent
} from '../push-manager/push/pushlog/pushlog.component';

import {
  sendEmailComponent
} from '../push-manager/push/push.component';

export interface DialogData {
  model: string;
}


@Component({
  selector: 'app-fastaction',
  templateUrl: './fastaction.component.html',
  styleUrls: ['./fastaction.component.css']
})
export class FastactionComponent implements OnInit {

  agency: string;
  model: string;
  request: any;
  resultat: any;
  visibility: any;
  favorite: any;
  activation: any;
  link: any;

  // activation: any;
  result: any;
  @Input() hash: string;

  constructor(
    private get: HttpService,
    public dialog: MatDialog,
    private _Activatedroute: ActivatedRoute,
    private router: Router,
    public snackBar: MatSnackBar,
    private criteriaservice: CriteriaService,
    public dialogRef: MatDialogRef<FastactionComponent>,
  ) {

  }

  ngOnInit() {

    /*    this._Activatedroute.params.subscribe( params => {
          console.log('paramsinter',params.id)  
          this.model = params.id;
          this.getVisibility(this.model);
        })*/
    this.model = this.hash;
    this.getVisibility(this.model);
  }

  SendPush(): void {
    const dialogRef = this.dialog.open(SendMess, {

      data: {
        modal: this.model
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
    });
  }

  SendEmail() {
    const dialogRef = this.dialog.open(SendEmail, {
         //width: '50vw',
      //    height: '50vh',
      data: {
        modal: this.model
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      ///   console.log('The dialog was closed');
    });
  }

  SendLike(): void {
    //console.log('Send Like', this.model);
    this.get.SendLike(this.model).subscribe(res => {
      this.openSnackBar('Model has received', 'Like');
    });
  }

  Delete() {
    // console.log('Delete');
    const dialogRef = this.dialog.open(DeleteModal, {
      //  width: '700px',
      //  height: '500px',
      data: {
        modal: this.model
      }
    });

    dialogRef.afterClosed().subscribe(result => {
         console.log('The dialog was closed', result);
     // this.router.navigate(['members/page2']);
     if(result == 'delete'){
      this.criteriaservice.updateProfileForm(result);
     }
     
    });
  }


  /* downloadPics(){
   //  console.log(this.model);
    this.get.download(this.model).subscribe(res => {
   //   console.log(res);
      return res;
    });
   }*/

  generatePDF() {
    // console.log(this.model);
    //  console.log('envoi');
    this.get.generatePDF(this.model).subscribe(res => {
      //    console.log(res);
      if (res) {
        this.link = res;
        window.open(this.link, '_blank');
      }
    });
  }

  toggleVisibilty() {
    this.get.toggleVisibilty(this.model, this.visibility).subscribe(res => {
      //  console.log(res);
      this.resultat = res;
      if (this.resultat == 'visible') {
        this.visibility = 0;
      } else {
        this.visibility = '-1';
      }
      this.openSnackBar('Model is now', this.resultat);
    })

  }

  toggleFavorites() {
    //console.log('favorite',this.model, this.favorite);
    this.get.toggleFavorites(this.model, this.favorite).subscribe(res => {
      //  console.log(res);
      this.resultat = res;
      if (this.resultat == 'ok') {
        this.favorite = !this.favorite;
      }
      this.openSnackBar('Model is now', this.resultat);
    })
  }

  getVisibility(elem) {
    this.get.getVisibility(elem).subscribe(res => {
      //  console.log('visibility', res);
      this.result = res;
      this.visibility = this.result.visibility;
      this.activation = this.result.activation;
      this.favorite = this.result.favorite;
      // console.log('favorite', this.favorite);
    })
  }

  editProfile() {
    const dialogRef = this.dialog.open(EditProfile, {
      data: {
        modal: this.model
      },
      panelClass: 'nopadding-box'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'success') {
        this.openSnackBar('Profile Updated ! ', 'Congrats !');
        this.criteriaservice.updateProfileForm('updated');
      }

    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }



  async goPublicProfile() {
    await this.get.getPublicLink(this.model).subscribe(res => {
      console.log('public link', res)
      this.link = res;
      window.open(res['publiclink']);
    })
  }


  setMeeting() {
    const dialogRef = this.dialog.open(GobookComponent, {
      data: {
        modal: this.model
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      //     console.log('The dialog was closed');
    });
  }

  goLog() {
    let dialogRef = this.dialog.open(PushlogComponent, {
      height: 'auto',
      width: 'auto',
      data: {
        model: this.model
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      //   console.log('The dialog was closed');
      //    console.log('result', result);
    });

  }

  goPush() {
    let dialogRef = this.dialog.open(sendEmailComponent, {
      height: 'auto',
      width: '80%',
      minWidth: '80%',
      data: {
        model: [this.model]
      },
      panelClass: 'nopadding-box'
    });

    dialogRef.afterClosed().subscribe(result => {
      //   console.log('The dialog was closed');
      //    console.log('result', result);
    });
  }

}

@Component({
  selector: 'send-mess',
  templateUrl: 'sendmess.html',
})

export class SendMess {
  spinnerProgress: boolean;
  chgStateGroup: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<SendMess>,
    private get: HttpService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: UntypedFormBuilder,
    public snackBar: MatSnackBar
  ) {
    this.createForm();
    this.spinnerProgress = false;
  }

  ngOnInit(): void {
    // console.log(this.data['modal']);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  createForm() {
    this.chgStateGroup = this.fb.group({
      message: ['', Validators.required],
      subject: ['', Validators.required],
    });
  }



  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  onOkClick() {

    this.spinnerProgress = true;

    let postData = new FormData();
    postData.append('model', this.data['modal']);
    postData.append('message', this.chgStateGroup.get('message').value);
    postData.append('subject', this.chgStateGroup.get('subject').value);

    postData.append('token', localStorage.getItem('token'));

    this.get.sendAppMess(postData).subscribe(
      res => {
        if (res) {
          //  console.log(res);
          this.openSnackBar('Like has been sent', '');

          this.spinnerProgress = false;
          this.dialogRef.close();
        }
      }, error => {
        this.openSnackBar('A mistake has occured !', '');
        this.spinnerProgress = false;
        this.dialogRef.close();
      }
    );
  }


}

@Component({
  selector: 'DeleteModal',
  templateUrl: 'delete.html',
})

export class DeleteModal {

  constructor(
    public dialogRef: MatDialogRef<DeleteModal>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private get: HttpService,
    public snackBar: MatSnackBar
  ) {


  }

  ngOnInit(): void {
    //console.log(this.data['modal']);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onOkClick(): void {
    this.deleteModal();

  }

  deleteModal() {
    this.get.deleteModal(this.data['modal']).subscribe(res => {
      console.log('res delete', res);
      this.openSnackBar('Modal have been delete', '');
      this.dialogRef.close(res);
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }


}

@Component({
  selector: 'SendEmail',
  templateUrl: 'send-email.html',
})

export class SendEmail {

  chgStateGroup: UntypedFormGroup;
  spinnerProgress: boolean;
  htmlContent: any;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '300px',
    minHeight: '300px',
    placeholder: 'Enter text here...',
    translate: 'no',
    uploadUrl: 'v1/images', // if needed
    customClasses: [ // optional
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };

  constructor(
    public dialogRef: MatDialogRef<DeleteModal>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private get: HttpService,
    public snackBar: MatSnackBar,
    private fb: UntypedFormBuilder,
  ) {
    this.createForm();

  }

  ngOnInit(): void {
    //console.log(this.data['modal']);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onOkClick(): void {
    this.spinnerProgress = true;
    // console.log('to', this.chgStateGroup.get('to').value);
    // console.log('htmlContent', this.chgStateGroup.get('htmlContent').value);
    // console.log('subject', this.chgStateGroup.get('subject').value);

    let postData = new FormData();
    postData.append('to', this.chgStateGroup.get('to').value);
    postData.append('htmlContent', this.chgStateGroup.get('htmlContent').value);
    postData.append('subject', this.chgStateGroup.get('subject').value);

    this.get.sendEmail(postData).subscribe(
      res => {
        if (res) {
          //    console.log(res);
          this.openSnackBar('E-Mail has been sent', '');

          this.spinnerProgress = false;
          this.dialogRef.close();
        }
      }, error => {
        //    console.log('error', error);
        this.openSnackBar('E-Mail has been sent !', '');
        this.spinnerProgress = false;
        this.dialogRef.close();
      }
    );
  }


  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  createForm() {
    this.chgStateGroup = this.fb.group({
      to: ['', Validators.required],
      htmlContent: ['', Validators.required],
      subject: ['', Validators.required],
    });
  }


}
