import { Injectable } from '@angular/core';

import { mergeMapTo } from 'rxjs/operators';
import { take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

import { NotifService } from './notif.service';
import { HttpService } from './http.service';

@Injectable()
export class MessagingService {

  currentMessage = new BehaviorSubject(null);

  constructor(
    private notif: NotifService,
    private http : HttpService
    ) {
   /*   this.angularFireMessaging.messaging.subscribe(
    (_messaging) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    )*/
  }


  updateToken(token) {
this.http.updateToken(token).subscribe(res=>{
 // console.log(res);
  if(res['status'] == 'ok'){
    this.notif.showNotif(  'Welcome ' + res['first'] );
  }
 
})
  }


}