<div class="grid-stats-ctn">
  <div class="grid-stats-ctn-title">Scouting</div>
  <div class="grid-stats-flex-center">
    <div class="grid-box" style="background-color: red">
      <div class="grid-box-value">3</div>
      <div class="grid-box-label">New models</div>
    </div>
  </div>
</div>

<div class="grid-stats-ctn">
  <div class="grid-stats-ctn-title">Placement</div>
  <div class="grid-stats-flex-row">


    <div class="grid-box-ctn" *ngFor="let x of stats">
        <div class="grid-box" [style.backgroundColor]="x.color">
            <div class="grid-box-value">{{x.chiffre}}</div>
            <div class="grid-box-label">{{x.label}}</div>
          </div>
          <div class="percentFlex">
            <div *ngIf="x.percent < 0" class="triangleRougeNegatif"></div>
            <div *ngIf="x.percent >0" class="triangleVertPositif"></div>
            <div class="grid-box-figure">{{x.percent}}<span>%</span></div>
          </div>
        
    </div>


 
  </div>
</div>
