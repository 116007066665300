

<form class="paddingTopOnMobile"  [formGroup]="chgStateGroup" style="width:90%;margin-top:15px;margin-left:50%; transform: translate(-50%, 0);">


    <mat-form-field>
      <mat-select placeholder="Gender" formControlName="gender" >
        <mat-option value="Mr">Mr</mat-option>
        <mat-option value="Mrs">Mrs</mat-option>
      </mat-select>
    </mat-form-field>
  
    <mat-form-field style="display:block">
      <input matInput placeholder="First Name" formControlName="firstname" >
    </mat-form-field>
  
  
        <mat-form-field style="display:block">
          <input matInput placeholder="Last Name" formControlName="lastname">
        </mat-form-field>

        
        <mat-form-field style="display:block">
          <input matInput placeholder="Fonction" formControlName="fonction">
        </mat-form-field>
  
        <mat-form-field style="display:block">
          <input matInput placeholder="Phone Number" formControlName="phone">
        </mat-form-field>
  
        <mat-form-field style="display:block">
            <input matInput placeholder="E-Mail" formControlName="email">
          </mat-form-field>
    

          
        </form>

                
        <div class="bTnPannelForMobile" >
          <button mat-button (click)="onNoClick()">Cancel</button>
          <button mat-button (click)="onOkClick()" cdkFocusInitial>Ok</button>
        </div>
        