
<form [formGroup]="myGroup" style="width:100%;"  class="paddingTopOnMobile">

  <mat-form-field class="matFormFiledCustom" >
    <input matInput placeholder="address" formControlName="address">
  </mat-form-field>

    <mat-form-field  class="matFormFiledCustom">
        <input matInput placeholder="email" formControlName="email">
      </mat-form-field>

      <mat-form-field  class="matFormFiledCustom">
          <input matInput placeholder="birthday" formControlName="birthday">
        </mat-form-field>

  <mat-form-field  class="matFormFiledCustom">
    <input matInput placeholder="Firstname" formControlName="first">
  </mat-form-field>

  <mat-form-field  class="matFormFiledCustom">
    <input matInput placeholder="Lastname" formControlName="last">
  </mat-form-field>

  <mat-form-field  class="matFormFiledCustom">
    <input matInput placeholder="Phone" formControlName="phone">
  </mat-form-field>

 <!-- <mat-form-field style="width:45%;margin: 10px;">
    <input matInput placeholder="E-Mail" formControlName="email">
  </mat-form-field>-->

  <mat-form-field  class="matFormFiledCustom">
    <input matInput placeholder="Height" formControlName="height">
  </mat-form-field>

  <mat-form-field  class="matFormFiledCustom">
    <input matInput placeholder="Chest" formControlName="chest">
  </mat-form-field>

  <mat-form-field  class="matFormFiledCustom">
    <input matInput placeholder="Hips" formControlName="hips">
  </mat-form-field>

  <mat-form-field  class="matFormFiledCustom">
      <input matInput placeholder="Waist" formControlName="waist">
    </mat-form-field>

  <mat-form-field  class="matFormFiledCustom">
    <input matInput placeholder="Shoe Size" formControlName="shoe">
  </mat-form-field>

  <mat-form-field  class="matFormFiledCustom">
    <input matInput placeholder="Hair Color" formControlName="hair">
  </mat-form-field>

  <mat-form-field  class="matFormFiledCustom">
    <input matInput placeholder="Eyes Color" formControlName="eye">
  </mat-form-field>

  <mat-form-field  class="matFormFiledCustom">
    <input matInput placeholder="Instagram" formControlName="instagram">
  </mat-form-field>

  <mat-form-field  class="matFormFiledCustom">
    <input matInput placeholder="Nationality" formControlName="nationality">
  </mat-form-field>


  <!-- <div style="margin:5px">
    <ng5-slider value="70" [(highValue)]="x.maxvalue"  (userChangeEnd)="changeSliderValue($event, x.part)" [options]="x.option"></ng5-slider>
  </div> -->



  <div class="bTnPannelForMobile">
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button (click)="onOkClick()" cdkFocusInitial>Ok</button>
  </div>

</form>
