import { Component, OnInit, Inject } from '@angular/core';
import { HttpService } from '../http.service';
import { Router,ActivatedRoute } from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { UntypedFormBuilder, Validators, UntypedFormGroup} from '@angular/forms';
import { MatSnackBar} from '@angular/material/snack-bar';
import { DialogData } from './fastaction.component';

@Component({
  selector: 'edit-profile',
  templateUrl: 'edit-profile.html',
  styleUrls: ['./edit-profile.component.css']
})

export class EditProfile {

  myGroup: UntypedFormGroup;
  result : any;

  constructor(
    public dialogRef: MatDialogRef<EditProfile>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private get: HttpService,
    public snackBar: MatSnackBar,
    private fb: UntypedFormBuilder,
  )
   {
  this.createForm();

  }

  ngOnInit(): void {
   console.log(this.data['modal']);
   this.prepareForm();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


  onOkClick() : void{
    var data = [
      { 'label' : 'user_first', 'value': this.myGroup.get('first').value },
      { 'label' : 'user_last', 'value': this.myGroup.get('last').value },

      { 'label' : 'user_phone', 'value': this.myGroup.get('phone').value },

      { 'label' : 'user_email', 'value': this.myGroup.get('email').value },
      { 'label' : 'user_birthday', 'value': this.myGroup.get('birthday').value },

      { 'label' : 'user_height', 'value': this.myGroup.get('height').value },
      { 'label' : 'user_chest', 'value': this.myGroup.get('chest').value },
      { 'label' : 'user_waist', 'value': this.myGroup.get('waist').value },
      { 'label' : 'user_hips', 'value': this.myGroup.get('hips').value },

      { 'label' : 'user_haircolor', 'value': this.myGroup.get('hair').value },
      { 'label' : 'user_eyecolor', 'value': this.myGroup.get('eye').value },
      { 'label' : 'user_shoe', 'value': this.myGroup.get('shoe').value },

      { 'label' : 'user_nationality', 'value': this.myGroup.get('nationality').value },
      { 'label' : 'user_igaccount', 'value': this.myGroup.get('instagram').value },

      { 'label' : 'user_address', 'value': this.myGroup.get('address').value },
   
    ];

    var criteria = [
      { 'label' : 'user_hash', 'value': this.data['modal'] },
    ];

    var postData = {  'table' : 'modalusers', 'action' : 'update', 'values': data , 'criteria' : criteria };
    this.get.goBackOffice(postData).subscribe(res=>{
      console.log('update', res);
      if(res == 'updated'){
        this.dialogRef.close('success');
      }
    });

  }



  prepareForm(){

    var criteria = [
      { 'label' : 'user_hash', 'value' : this.data['modal']}
    ];

    var postData = {  'table' : 'modalusers','action' : 'get', 'criteria' : criteria };

    this.get.goBackOffice(postData).subscribe(res=>{
      console.log('res', res);

      this.myGroup.get('first').setValue(res['user_first']);
      this.myGroup.get('last').setValue(res['user_last']);
     this.myGroup.get('phone').setValue(res['user_phone']);

     this.myGroup.get('height').setValue(res['user_height']);
     this.myGroup.get('chest').setValue(res['user_chest']);
     this.myGroup.get('waist').setValue(res['user_waist']);
     this.myGroup.get('hips').setValue(res['user_hips']);
     this.myGroup.get('shoe').setValue(res['user_shoe']);
     this.myGroup.get('hair').setValue(res['user_haircolor']);
     this.myGroup.get('eye').setValue(res['user_eyecolor']);
     this.myGroup.get('birthday').setValue(res['user_birthday']);
     this.myGroup.get('email').setValue(res['user_email']);
     this.myGroup.get('birthday').setValue(res['user_birthday']);
     this.myGroup.get('email').setValue(res['user_email']);
     this.myGroup.get('nationality').setValue(res['user_nationality']);
     this.myGroup.get('instagram').setValue(res['user_igaccount']);
     this.myGroup.get('address').setValue(res['user_address']);

    })
  }



  createForm(){
    this.myGroup = this.fb.group({
      first: ['', Validators.required],
      last: ['', Validators.required],
      phone: ['', Validators.required],
   //   email: ['', Validators.required],

      height: ['', Validators.required],
      chest: ['', Validators.required],
      waist: ['', Validators.required],
      hips: ['', Validators.required],
      shoe: ['', Validators.required],
      hair: ['', Validators.required],
      eye: ['', Validators.required],
      birthday: ['', Validators.required],
      email: ['', Validators.required],
      instagram: ['', Validators.required],
      nationality: ['', Validators.required],
      address: ['', Validators.required],
      });
  }



  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }


}
