    <div *ngIf="searchType == 'model' ">
        <app-approved [searchValue]="searchvalue" [type]=" 'search' "></app-approved>
    </div>

    <div *ngIf="searchType == 'client' ">
        <app-agency-card [searchValue]="searchvalue" [type]=" 'search' " ></app-agency-card>
    </div>

    <mat-dialog-actions align="end">
      
        <button 
         mat-button [mat-dialog-close]="true" cdkFocusInitial>Close</button>
      </mat-dialog-actions>

