import { Component, OnInit } from '@angular/core';



export interface Select {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})



export class SearchComponent implements OnInit {

  Hair: Select[] = [
    {value: 'Brown', viewValue: 'Brown'},
    {value: 'pizza-1', viewValue: 'Pizza'},
    {value: 'tacos-2', viewValue: 'Tacos'}
  ];

  Eyes: Select[] = [
    {value: 'Brown', viewValue: 'Brown'},
    {value: 'pizza-1', viewValue: 'Pizza'},
    {value: 'tacos-2', viewValue: 'Tacos'}
  ];

  Ethnicity: Select[] = [
    {value: 'Black', viewValue: 'Black'},
    {value: 'White', viewValue: 'White'},
    {value: 'tacos-2', viewValue: 'Tacos'}
  ];

  constructor() { }

  ngOnInit() {
  }

}
