import { Component, OnInit } from '@angular/core';
import { HttpService } from '../http.service';
import {  MatDialog } from '@angular/material/dialog';
import { AddusersComponent } from './addusers/addusers.component';

import * as moment from 'moment';

export interface Data {
  id: string;
  action: string;
}


@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  users : any;

  constructor(
    private http: HttpService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.getUsers();
  }

  async getUsers(){
    var funcE = { type: 'BDD', func: 'GetFullTable' };    


    var values = { 'table' : 'adminusers' };


    var Postdata = { func: funcE, data: values };
    console.log('values', Postdata);

    let res = await this.http.goAcess(Postdata);
    console.log('res', res);
  this.users = res;
  }

  async ToggleStatus(elem, user_login){
    //console.log(elem.checked);
    let status;
    if(elem.checked == true){
     status = 1;
    }
    if(elem.checked == false){
      status = 0;
     }

  
     var funcE = { 'type' : 'BDD', 'func' : 'updateValueinTable' };  
     var valuesE = [{ 'label' : 'account_state', 'value' : status }];  
     var criteriaE = [{ 'label': 'user_login',  'value' : user_login }];
     var values = { 'table' : 'adminusers', 'values' : valuesE, 'criteria' : criteriaE };

     var Postdata = { func: funcE, data: values };
     console.log('values', Postdata);
     let res = await this.http.goAcess(Postdata);
     console.log('res', res);
  }

  addUsers(action){
    const dialogRef = this.dialog.open(AddusersComponent, {  
      width: '350px',
     //  height: '500px',
     data: {action : action}
     });

     dialogRef.afterClosed().subscribe(result => {
       console.log(result);
       if(result){
        if(result['status'] == 'ok'){
          this.users.push(result['data']);
        }

       }

    
    })
  }

  updateUsers(action, hash){
    const dialogRef = this.dialog.open(AddusersComponent, {  
      width: '350px',
     //  height: '500px',
     data: {action : action, login : hash}
     });

     dialogRef.afterClosed().subscribe(result => {
   
       if(result){
        if(result['status'] == 'updated'){
          console.log('updated');
          this.getUsers();
        }
       }

    })
  }

  async delUsers(user_login){

    var funcE = { 'type' : 'BDD', 'func' : 'DeleteLineFromTable' };  
    var criteriaE = [{ 'label': 'user_login',  'value' : user_login }];
    var values = { 'table' : 'adminusers', 'criteria' : criteriaE };

    var Postdata = { func: funcE, data: values };
    console.log('values', Postdata);
    let res = await this.http.goAcess(Postdata);
    console.log('res', res);
    if(res == 'ok'){
      this.getUsers();
    }
  }


getRelativeTime(date){
  if(date){
    return moment(date, 'Y-m-d H:i:s').fromNow();
  }
}

getAvatarBackground(url){
  let res = 'url(' + url + ')';
  return res;
}

}
