import { Component, OnInit } from '@angular/core';
import { MatDialog,  MatDialogRef,  MAT_DIALOG_DATA} from '@angular/material/dialog';
import { UntypedFormBuilder, Validators, UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-joindocs',
  templateUrl: './joindocs.component.html',
  styleUrls: ['./joindocs.component.scss']
})
export class JoindocsComponent implements OnInit {


  myGroup: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<JoindocsComponent>,
    private fb: UntypedFormBuilder,
  ) { }

  ngOnInit(): void {
    this.createForm();
  }


  addDocs(){
    document.getElementById('inputID').click();
  }

  async readThis(e){
    console.log('e',e);
    let fileContent = await this.handleUpload(e);
    let fileName = e.target.files[0].name;
    console.log('content', fileContent, 'name', fileName);
  }



  handleUpload(event) {
    const file = event.target.files[0];
    const reader = new FileReader();
    let result;

    reader.readAsDataURL(file);
    reader.onload = () => {
      let fileContent = reader.result;
      let fileName = file.name;
      let res = {'fileContent' : fileContent, 'fileName' : this.myGroup.get('filename').value, 'realname' : fileName };
      let status = 'success';
      let resu = { 'status': status, 'data': res };
    this.dialogRef.close(resu);
    };
   
}


createForm(){
  this.myGroup = this.fb.group({
    filename: ['', Validators.required],
  });
}
  
  



}
