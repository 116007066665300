<div style="position: relative">
  <div
    style="
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 20px;
    "
  >
    <div>
      <input
        placeholder="Find a client"
        (keyup.enter)="goSearch($event.target.value)"
      />
    </div>
  </div>

  <div
    *ngIf="spinnerProgress2"
    style="
      margin-top: 100px;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    "
  >
    <mat-spinner> </mat-spinner>
  </div>

  <button class="fab" (click)="CreateAgency()" mat-fab color="primary">
    +
  </button>

  <div *ngFor="let x of agency">
    <div class="agency-card cardLetter">
      <div class="event-card-round-letter">
        <div class="bigLetter">
          {{ x.letter }}
        </div>
      </div>
    </div>

    <div
      class="agency-card"
      *ngFor="let y of x.data; let i = index"
      (mouseover)="getDetails($event, i)"
      (mouseout)="getDetails($event, i)"
      [style.backgroundImage]="'url(' + y.agency_logo + ')'"
      (click)="goAgency(y.agency_hash)"
    >
      <img
        src="../../assets/ig_star.png"
        style="
          position: absolute;
          top: 10px;
          left: calc(50% + 35px);
          height: 20px;
          z-index: 1000;
        "
        *ngIf="x.client_modelslink"
      />

      <div
        id="{{ 'details' + i }}"
        [ngClass]="{
          agencyInfos: y.agency_logo !== '',
          agencyInfos2: y.agency_logo == ''
        }"
      >
        <div
          *ngIf="y.agency_name"
          style="
            font-size: 14px;
            text-align: center;
            color: white;
            white-space: wrap;
            padding: 5px;
          "
        >
          {{ y.agency_name }}
        </div>
      </div>
    </div>
  </div>
</div>
