import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog,  MatDialogRef,  MAT_DIALOG_DATA} from '@angular/material/dialog';
import { HttpService } from '../http.service';
import { UntypedFormBuilder, Validators, UntypedFormGroup} from '@angular/forms';
import { SearchPannelComponent } from './search-pannel/search-pannel.component';

@Component({
  selector: 'app-banniere',
  templateUrl: './banniere.component.html',
  styleUrls: ['./banniere.component.css']
})
export class BanniereComponent implements OnInit {

  
  searchvalue: string;
  searchType: string;

  placeholder : string;

  chgStateGroup: UntypedFormGroup;

  constructor(
    public dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private http: HttpService,
  ) {
    this.placeholder = "Search Model by First or Last Name (at least 4 characters)";
    this.searchType = 'model';
   }

  ngOnInit() 
  {
    this.createForm();
  }

  createForm(){
    this.chgStateGroup = this.fb.group({
      searchValue: ['', Validators.required],
      searchType: ['', Validators.required],
      });
  }

  changeSearchType(e){
    console.log('change search type',e );
    if(e.checked == true){
      this.placeholder = "Searh Client by First or Last Name (at least 4 characters)";
      this.searchType = 'client';
    } else {
      this.placeholder = "Search Model by First or Last Name (at least 4 characters)";
      this.searchType = 'model';
    }
  }


  goSearch(): void {
   
    const dialogRef = this.dialog.open(SearchPannelComponent, {
      data: { 'searchValue': this.chgStateGroup.get('searchValue').value, 'searchType' : this.searchType }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      this.chgStateGroup.get('searchValue').setValue('');
    });
  }


}
