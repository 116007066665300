import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup} from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { HttpService } from '../../../http.service';

import { collabData2 } from '../modelrepresentation.component';

@Component({
  selector: 'app-setcollabend',
  templateUrl: './setcollabend.component.html',
  styleUrls: ['./setcollabend.component.scss']
})
export class SetcollabendComponent implements OnInit {

  myGroup: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<SetcollabendComponent>,
    @Inject(MAT_DIALOG_DATA) public data: collabData2,
   private http: HttpService,
   private fb: UntypedFormBuilder,
  ) {
    this.createForm();
   }

  ngOnInit() {
    console.log(this.data);
  }

  createForm(){
    this.myGroup = this.fb.group({
      enddate: ['', Validators.required],
    });
  }

  close(){
    this.dialogRef.close();
  }

  editEnd(){
    this.http.setcollabend(
      this.data['collabid'],
      this.myGroup.get('enddate').value.toISOString(),
    ).subscribe(res=>{
      if(res == 'ok'){
        this.dialogRef.close(res);
      }
    })
  }

}
