import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../http.service';

import { ActivatedRoute } from '@angular/router';
import {MatDialog } from '@angular/material/dialog';
import { EditFactureComponent } from '../allfactures/edit-facture/edit-facture.component';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit {

  displayedColumns: string[] = [ 'model', 'clients', 'montantht', 'topay', 'datepaiement', 'Facture', 'Actions' ];
  payments: any;
  paymentsNotPaidByClient: any;

  state: string;

  constructor(
    private http :HttpService,
    private route :ActivatedRoute,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe( state => {
      console.log('state',state);
      this.state = state['type'];
      this.getPayments(this.state);
    })
  }

  async getPayments(typeE){
    var funcE = { type: 'PAYMENTS', func: 'getAllModelPayments' };  
    
    var dataE = { type: typeE  };    

    var Postdata = { func: funcE, data : dataE };
    console.log('Postdata', Postdata);
    let res = await this.http.goAcess(Postdata);
    console.log('res', res);

    if(typeE == 'todo'){
      this.payments = res.alreadypaid;
      this.paymentsNotPaidByClient = res.notpaid;
    } else {
      this.payments = res;
    }
   
  }

  goFacture(url){
    window.open(url);
  }

  async setNotConcerned(facid, valueE){
    var funcE = { type: 'BDD', func: 'updateValueinTable' };  
    
    var valuesE = [ { 'label': 'paid_on', 'value': valueE } ];
    var criteriaE = [ { 'label': 'id', 'value': facid } ];

    var dataE = { table: 'facture_repart_model', values: valuesE, criteria: criteriaE };      

    var Postdata = { func: funcE, data : dataE };
    console.log('Postdata', Postdata);
    let res = await this.http.goAcess(Postdata);
    console.log('res', res);
    if(res == 'ok'){
      this.getPayments(this.state);
    }
  }


  setDate(facid){
    const dialogRef = this.dialog.open(EditFactureComponent, {  
      width: '350px',
     });

     dialogRef.afterClosed().subscribe(result => {
      if(result){
        if(result.status == 'set'){
         this.setNotConcerned(facid, result.date);
        }
      }

    }) 
  }

}
