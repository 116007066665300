import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup} from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { HttpService } from '../../../http.service';

import { factureData } from '../facturation.component';

@Component({
  selector: 'app-addfacture',
  templateUrl: './addfacture.component.html',
  styleUrls: ['./addfacture.component.css']
})
export class AddfactureComponent implements OnInit {

  myGroup: UntypedFormGroup;
  modal: any;
  agency: any;
  facture: any;

  constructor(
    public dialogRef: MatDialogRef<AddfactureComponent>,
    @Inject(MAT_DIALOG_DATA) public data: factureData,
   private http: HttpService,
   private fb: UntypedFormBuilder,
  ) { }

  ngOnInit() {
 
    this.createForm();
   
      console.log(this.data['id']);
  }

  close(){
    this.dialogRef.close();
  }

  createForm(){
    this.myGroup = this.fb.group({
      dateemission: ['', Validators.required],
     // datepaiement: ['', Validators.required],
      intitule: ['', Validators.required],
      montant: ['', Validators.required],
    });
  }



  addFacture(){
    this.http.addFacture(
      this.data['id'],
      this.myGroup.get('dateemission').value.toISOString(),
    //  this.myGroup.get('datepaiement').value.toISOString(),
      this.myGroup.get('intitule').value, 
      this.myGroup.get('montant').value, 
    ).subscribe(res=>{
      this.dialogRef.close(res);
    })
  }


}
