<div style="display: flex; flex-direction: column; justify-content: center; ">

    <div style = "display: flex; flex-direction: row; justify-content: center; ">
        Add a Feedback
    </div>

    <div style="display: flex; flex-direction: row; justify-content: center; ">

        <div [style.backgroundImage]=" 'url('+ data['agencyavatar'] +')' " class="agency-card">

        </div>

        <div>
                <i class="right"></i>
        </div>
    

        <div [style.backgroundImage]=" 'url('+ modalavatar +')' " class="agency-card">
 
        </div>

    </div>

    <div style="display: flex; flex-direction: row; justify-content: center; margin-top: 20px;">

        <div style="cursor:pointer;" class="logo"  (click)="accept()">
            <img src="../../../../../../assets/checked.svg" />
        </div>

        <div style="cursor:pointer;" class="logo" (click)="refuse()">
            <img src="../../../../../../assets/cancel.svg"  />
        </div>

    </div>

</div>