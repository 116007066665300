import { Component, OnInit } from '@angular/core';
import { MatDialog} from '@angular/material/dialog';
import { MatSnackBar} from '@angular/material/snack-bar';
import { HttpService } from '../http.service';
import { addFrais } from './addfrais.component';

import * as moment from 'moment';
import CommonFunc  from '../commonfunc';


export interface fraisData {
  action: string;
  id: string;
}


@Component({
  selector: 'app-frais',
  templateUrl: './frais.component.html',
  styleUrls: ['./frais.component.css']
})
export class FraisComponent implements OnInit {


  frais: any;
  total: string;
  displayedColumns: string[] = ['Date', 'Type', 'Observations', 'montantht', 'montantttc', 'Actions'];
  
  selecteddate : string;
  societe : string;
  spinnerProgress:boolean;

  constructor(
    private http: HttpService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private commonFunc : CommonFunc
  ) {
    this.selecteddate = '2020';
    this.societe = 'fr';
    this.spinnerProgress = false;
   }

  ngOnInit() {
    this.getFrais();
  }

  selectSociete(societeE){
    this.societe = societeE;
    this.getFrais();
  }


  addFrais(todo, elem): void {
   
      const dialogRef = this.dialog.open(addFrais, {  
         width: '350px',
        //  height: '500px',
        data: {id: elem, action : todo}
        });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
    if (result) {
      if (result.status == 'ok'){
        // snackbar
        this.frais.push(result);
        this.totalFunc();
        this.openSnackBar('Congrats ! ', 'Frais Added !');
      } else if(result == 'update'){
        this.getFrais();
        this.totalFunc();
        this.openSnackBar('Congrats ! ', 'Frais Updated !');
      }
       else {
        this.openSnackBar('Error ! ', 'Somewhere');
      }
    };
    })
  }

  delFrais(id, index){
    console.log('delfrais', id, index);
    this.http.delFrais(id).subscribe(res=>{
      console.log(res);
      if(res == 'ok'){
        this.frais.splice(index, 1);
        this.totalFunc();
        this.openSnackBar('Congrats ! ', 'Frais Deleted !');

      } else {
        this.openSnackBar('Error ! ', 'Somewhere');
      }
    })
  }



  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  getFrais(){
    this.frais = [];
    console.log('fraisA', this.societe, this.selecteddate);
    this.http.getFrais(this.societe, this.selecteddate).subscribe( res => {
      console.log('frais', res);
      this.frais = res;
    //  this.frais = res;
      this.totalFunc();
    })
  }


  totalFunc(){
    this.calculateTotal('ttc');
    this.calculateTotal('ht');
  }

  calculateTotal(elem){

    if(elem !== 'tva'){
      var number  = 0;
      for(var i = 0; i< this.frais.length ; i++)
      
      {
        var temp = this.frais[i];
     number = number + parseFloat(temp[elem]);
        // console.log( temp[elem]);
      }
     // this.total = number.toFixed(2) + ' €';
     this.total = number.toFixed(2);
      return this.total;
    }else {
      var temp : any;
      temp = this.calculateTotal('ttc') -  this.calculateTotal('ht');
      return temp.toFixed(2);
    }

  }

  SelecteDate(elem){
    this.selecteddate = elem;
    this.getFrais();
  }

  dateHaveBeenSet(e){
    console.log("dateHaveBeenSet", e);
    this._goFilter(e.startdate, e.enddate);
  }

  _goFilter(start, end){

    moment.locale('fr');
    var newDateSet = [];
    let startDate = new Date(moment(start).format('YYYY-MM-DD'));
    let endDate = new Date(moment(end).format('YYYY-MM-DD'));

    console.log('startDate', startDate,  'endDate', endDate );

    for(var i = 0; i < this.frais.length; i++) {
        let oneFrais = this.frais[i];
        
        if(oneFrais.date){

          let dateToCompare = new Date( moment(oneFrais.date, 'DD-MM-YYYY').format('YYYY-MM-DD') );
          console.log('dateToCompare', oneFrais.date,  'dateToCompare.moment.format()', dateToCompare );
  
          if(dateToCompare.getTime() < endDate.getTime() && dateToCompare.getTime() > startDate.getTime() ) {
            newDateSet.push(oneFrais);
          }
          
        }



    }
    console.log("filtered", newDateSet);
    this.frais = newDateSet;
  }

  get_url_extension( url ) {
    return url.split(/[#?]/)[0].split('.').pop().trim();
}


  async DownloadAsZip(){
    this.spinnerProgress = true;

    var fraisUrlList = [];
    for(var i = 0; i < this.frais.length; i++)
    {
      fraisUrlList[i] =  {url: this.frais[i].justif, name : this.frais[i].observation, ext : this.get_url_extension(this.frais[i].justif)};
    }

    await this.commonFunc.createZip(fraisUrlList, "frais.zip");
    this.spinnerProgress = false;
  }

  

}
