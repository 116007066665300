import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { DialogData } from '../contacttosend.component';

import { HttpService } from '../../../../../http.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {

  modalavatar: any;
  click: boolean;

  constructor(
    public dialogRef: MatDialogRef<FeedbackComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private http: HttpService
  ) {
    this.click = true;
  }

  ngOnInit() {
    console.log(this.data);
    this.getModelAvatar();
  }

  getModelAvatar() {
    this.http.getModelAvatar(this.data['model'][0]).subscribe(res => {
      console.log(res);
      this.modalavatar = res;
    })
  }

  accept() {

    if (this.click == true) {
      console.log('interest');
      this.addManualLog('interest');
    }



  }

  refuse() {

    if (this.click == true) {
      console.log('nointerest');
      this.addManualLog('nointerest');
    }

  }

  addManualLog(action) {
    this.click = false;
    this.http.addManualLog(this.data['agency'], this.data['model'][0], action).subscribe(res => {
      console.log(res);
      if(res == 'ok'){
        this.click = true;
        this.dialogRef.close('log');
      }

    })
  }

}
