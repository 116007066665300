<div style="height: 100%; position: relative" class="paddingTopOnMobile">
  <div class="modalCloseBtn" (click)="closeModal()">
    <div class="centred" style="color:white">X</div>
  </div>
  <div *ngIf="!spinner">
    <div style="text-align: center">
      <span
        *ngIf="publiclink"
        style="text-decoration: underline; cursor: pointer"
        (click)="goLink(publiclink)"
        >See Profil on Public Site</span
      >
      <span
        *ngIf="!publiclink"
        style="text-decoration: underline; color: red; font-weight: 700"
        >Not in Mainboard</span
      >
    </div>
    <div style="position: absolute;top:5px;right:5px; cursor: pointer; " (click)="createZIP()">
    <img src="../../../../assets/choice/zip.png" style="height: 40px;" />
    </div>
  </div>



  <div
    style="
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    "
  >
    <mat-spinner *ngIf="spinner"></mat-spinner>
  </div>

  <mat-tab-group class="marginTop20OnLargeDesktop">
    <mat-tab label="All" style="height: 90%; overflow: scroll">
      <ng-template matTabContent>
        <app-mgmt-gallery [modal]="data.modal"></app-mgmt-gallery>
      </ng-template>
    </mat-tab>

    <mat-tab label="Show Package">
      <ng-template matTabContent>
        <app-selected-gallery [modal]="data.modal"></app-selected-gallery>
      </ng-template>
    </mat-tab>

    <mat-tab label="Video">
      <ng-template matTabContent>
        <app-videomodel [hash]="data.modal"></app-videomodel>
      </ng-template>
    </mat-tab>

   
      <mat-tab label="Compcard"  class="removeOnMobile">
        <ng-template matTabContent>
          <app-compcard [hash]="data.modal"></app-compcard>
        </ng-template>
      </mat-tab>

  </mat-tab-group>
</div>
