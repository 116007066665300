<div class="sideBarSearch">

    <mat-form-field>
        <mat-select placeholder="Hair Color">
          <mat-option *ngFor="let x of Hair" [value]="x.value">
            {{x.viewValue}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
          <mat-select placeholder="Eyes Color">
            <mat-option *ngFor="let x of Eyes" [value]="x.value">
              {{x.viewValue}}
            </mat-option>
          </mat-select>
        </mat-form-field>


        <mat-form-field>
            <mat-select placeholder="Ethnicity">
              <mat-option *ngFor="let x of Ethnicity" [value]="x.value">
                {{x.viewValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>


</div>