<app-client-menu></app-client-menu>
<div style="width:100%; padding: 60px 0 190px 0;position:relative;">


  <div style="position: absolute; top:60px; left: 60px;width: calc(20% - 60px)">
    <div style="position:relative;height:100%;width:100%">
    <img src="assets\logoo_black.png" style="height: 150px; position:absolute;left:50%; transform: translate(-50%, 0)"/>
  </div>
  </div>

  <div>
    <div *ngFor="let x of models" class="vignette anim" (click)="seeDetails(x.hash)">



      <div class="surmask">
        <div class="name">{{x.first}}</div>

        <div class="mask" [style.backgroundImage]=" 'url(' + x.avatar + ') ' ">
          <div style="position:relative; width: 100%; height: 100%">
            <div class="namehover"> {{x.first}} </div>
            <div class="mensu">
              <div *ngFor="let y of x.mensurations" style="margin:5px;color: white;">{{y.name}} : {{y.valeur}}</div>
            </div>
          </div>
        </div>

      </div>


    </div>
  </div>

</div>
