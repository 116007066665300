import { Injectable, Output, EventEmitter } from '@angular/core';

import { NotificationsComponent } from './common/notifications/notifications.component';

@Injectable({
  providedIn: 'root'
})
export class NotifService {

  //@Output() show : EventEmitter<string> = new EventEmitter();

  constructor(
    private notif : NotificationsComponent
  ) {
   
   }


   ngOnInit() {
  }

showNotif(message){
 // console.log('show notif', message);
 // this.show.emit(elem);
 this.notif.launch_toast(message);
}

}
