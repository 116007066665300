import { Component, OnInit, ViewChild, ElementRef, AfterViewChecked  } from '@angular/core';
import { FormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import { HttpService } from '../http.service';
import { Router, ActivatedRoute } from "@angular/router";
import {  MatDialog } from '@angular/material/dialog';

import { ModelOverviewComponent } from '../pictures/model-overview/model-overview.component';
import { MesstemplateComponent } from './messtemplate/messtemplate.component';

import * as moment from 'moment';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit, AfterViewChecked {
  @ViewChild('scrollMe', { static: true }) private myScrollContainer: ElementRef;

  spinnerProgress: boolean;
  chat: any;
  mess: any;
  myGroup: UntypedFormGroup;
  from: string;
  name: string;
  conversation: string;
  newmess: any;
  subject: any;
  chatmess: string;

  constructor(
    private http: HttpService,
    private router: Router,
    public dialog: MatDialog,
  ) {
    this.myGroup = new UntypedFormGroup({
      mess: new UntypedFormControl()
   });
 //  this.from = '0266e33d3f546cb5436a10798e657d97'; 
   this.chatmess = 'send';
   }




  ngOnInit(){
    this.scrollToBottom();   
  }

  ngAfterViewChecked() {        
    this.scrollToBottom();        
} 

  getChat(convers){
    
    this.conversation = convers;
    this.spinnerProgress = true;

    let postData = new FormData();
    postData.append('nbconv', convers);

    this.http.getMailConv(postData).subscribe(
      res => {
      console.log(res);
        this.spinnerProgress = false;
       
       this.chat = res; 
       this.subject = res[0]['subject'];
       this.scrollToBottom();   
      }
    )
  }

  scrollToBottom(): void {
    try {
        this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    //    console.log('scroll to bottom');  
    } catch(err) {console.log('scroll to bottom ERROR');   }                 
}

sendMess(mess){

  console.log(mess);
  this.chatmess = 'sending ...';
  document.getElementById('messBtn').style.pointerEvents = 'none';

  if(this.checkMess(mess)){
    let input = new FormData();
    input.append('token',  localStorage.getItem('token'));
    input.append('convers', this.conversation);
    input.append('mess', mess);
   
    this.http.sendMess(input).subscribe(
     res => {
       if(res){
        this.chatmess = 'send';
        document.getElementById('messBtn').style.pointerEvents = 'auto';
   //      console.log('newmess', res);
         this.newmess = res; 
          this.chat.push(this.newmess);
         this.prepareForm();  
       }
     })
  }


}

prepareForm(){
  this.myGroup.reset({
    mess : '',
  });
}
 
checkMess(mess){
  if (mess.length > 1){
    return true;
  } else {
    return false;
  }
}

getCSSClasses(variable, element){

  let cssClasses;
  switch(element){

    case 'date':
    if(variable ==  this.from) {
 
       cssClasses = {
         'time-right': true
       }	
    } else {  
      
       cssClasses = {
         'time-left': true
       }	
    }
    break;

    case 'avatar':
    if(variable ==  this.from) {

       cssClasses = {
         'time-right': true
       }	
    } else {  

       cssClasses = {
         'time-left': true
       }	
    }
    break;

    case 'message':
    if(variable ==  this.from) {
    
       cssClasses = {
         'float-right': true
       }	
    } else {  
   
       cssClasses = {
         'float-left': true
       }	
    }
    break;

    default:
    cssClasses = {
      'time-left': true
    }

  }

  return cssClasses;
}
/*
goModel(elem: string){
  console.log('router', elem);
  this.router.navigate(['members/page2',elem] );
}*/

goModel(elem: string): void {
  const dialogRef = this.dialog.open(ModelOverviewComponent, {
    data: {modal: elem},
    panelClass: 'nopadding-box'
  });

  dialogRef.afterClosed().subscribe(result => {
   // console.log('The dialog was closed');
  });
}

remMess(mailid, index){
  console.log('mailid index', mailid, index);
  this.http.remMess(mailid).subscribe(
    res => {
      
  //    console.log('rem', res);
      if (res == 'ok') {
        this.chat.splice(index, 1);
      }

    })
}

goTemplates(){
  const dialogRef = this.dialog.open(MesstemplateComponent,{
    height : '80%',
    width: '80%'
  }

    
    
  );

  dialogRef.afterClosed().subscribe(result => {
    console.log('The dialog was closed', result);
    if(result !== 'close' || result !== undefined){
      this.myGroup.get('mess').setValue(result);
    }
  });
}

getRelativeTime(date){
  if(date){
    return moment(date, "DD/MM/YYYY").fromNow();
  }

}



}

