<div style="overflow-y: auto">

  <mat-spinner class="centred" *ngIf="spinnerProgress">
  </mat-spinner>

  <mat-tab-group *ngIf="!spinnerProgress">

    <mat-tab label="Pics">
      <app-modelsumary [hash]="hash"></app-modelsumary>
    </mat-tab>


    <mat-tab label="Push">
      <ng-template matTabContent>
        <app-modellog [hash]="hash"></app-modellog>
      </ng-template>
    </mat-tab>

    <mat-tab label="Representation">
      <ng-template matTabContent>
        <app-modelrepresentation [hash]="hash"></app-modelrepresentation>
      </ng-template>
    </mat-tab>

    <mat-tab label="Agenda">
      <ng-template matTabContent>
        <app-modelagenda [hash]="hash" [avatar]="avatar"></app-modelagenda>
      </ng-template>
    </mat-tab>



    <mat-tab label="Documents">
      <ng-template matTabContent>
        <app-modeldocs [hash]="hash"></app-modeldocs>
      </ng-template>
    </mat-tab>



    <mat-tab label="Notes">
      <ng-template matTabContent>
        <app-modelnotes [hash]="hash" [type]="'modal' "></app-modelnotes>
      </ng-template>
    </mat-tab>


    

    <mat-tab label="Facturations">
      <ng-template matTabContent>
        <app-modelfac [hash]="hash"></app-modelfac>
      </ng-template>
    </mat-tab>

    <mat-tab label="Payments">
      <ng-template matTabContent>
        <app-modelpayments [hash]="hash"></app-modelpayments>
      </ng-template>
    </mat-tab>

    <mat-tab label="F. de Paie">
      <ng-template matTabContent>
        <app-fichedepaie [hash]="hash"></app-fichedepaie>
      </ng-template>
    </mat-tab>



  </mat-tab-group>



  <mat-tab label="Statistics">


    <div style="display:flex; flex-direction: row" *ngIf="stats">
      <mat-card>Creation Date: {{ stats?.create_date }}</mat-card>
      <mat-card>Last Connect : {{ stats?.user_lastconnect }}</mat-card>
      <mat-card>Click on Profile : {{ stats?.user_click }} </mat-card>
      <mat-card>Visit on app : {{ stats?.user_visit_count }} </mat-card>
      <mat-card>Like on this model : {{ stats?.user_like }} </mat-card>
    </div>


  </mat-tab>



</div>
