import { Component, OnInit, Input, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { HttpService } from '../../../../http.service';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';

import {CdkDragDrop, CdkDragMove, CdkDropList, CdkDropListGroup } from '@angular/cdk/drag-drop';
import {ViewportRuler} from "@angular/cdk/overlay";
import { MatSnackBar} from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { EditFactureComponent } from '../../../../facturesystem/allfactures/edit-facture/edit-facture.component';


@Component({
  selector: 'app-selected-gallery',
  templateUrl: './selected-gallery.component.html',
  styleUrls: ['./selected-gallery.component.scss']
})
export class SelectedGalleryComponent implements OnInit {

  @Input() modal: string;


  pictures : any;
  items : any;
  packageSelected : boolean;

  @ViewChild(CdkDropListGroup, {static: true}) listGroup: CdkDropListGroup<CdkDropList>;
  @ViewChild(CdkDropList, {static: true}) placeholder: CdkDropList;

  @ViewChild('content') content: ElementRef;
  contentHeight: number;

  //public items: Array<number> = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  public target: CdkDropList;
  public targetIndex: number;
  public source: CdkDropList;
  public sourceIndex: number;
  public dragIndex: number;
  public activeContainer;
  nbreofshowPack : number;
  showPackageList: any;

  packageName  : string;
  packageId: string;

  packageImgSelected : any;
  packageImgNotSelected : any;

  selectedPicsToChangeIndexI: any;
  selectedPicsToChangeIndexJ: any;

  constructor(
    private http: HttpService,
    private sanitization: DomSanitizer,
    private viewportRuler: ViewportRuler,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
  ) {
    this.target = null;
    this.source = null;
    this.packageSelected = false;

    this.selectedPicsToChangeIndexI = -1;
    this.selectedPicsToChangeIndexJ = -1;

   }


  ngOnInit() {
    //this.getPublicProfile(this.modal, 'gallery');
    this.getShowPackageModel();
   
  
  }

  changeImageVisibility(id, visibility, index){
    this.http.changeImageVisibility(id, visibility).subscribe(res=>{
      console.log(res);
      if(res['visibility'] == 0){
        console.log('splice', index);
        this.items.splice(index, 1);
      }
    })
  }

  getPublicProfile(elem, type){
 
    this.http.getPublicProfile(elem, type).subscribe( res => {
    //  console.log('pp', res);
      this.items = res;
  
    });
  }

  switchSelected(elem){
    this.getPublicProfile(this.modal, elem);
  }

  
  getImage(data){
    if(data){
      return this.sanitization.bypassSecurityTrustStyle(`url(${data})`);
    } else {
      return this.sanitization.bypassSecurityTrustStyle(`url('../../../assets/placeholder.png')`);
    }
 
  }






  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  closePackage(){
    this.packageSelected = false; 
  }


    async getShowPackageModel()
    {
    var funcE = { type: 'PACKAGE', func: 'getAllPackageFromOneModel' };  
    var dataE = { modalhash: this.modal };    

    var Postdata = { func: funcE, data : dataE };
    let res = await this.http.goAcess(Postdata);
    console.log("res",res);
    this.showPackageList = res;
    }

    checkIndex(indexE){
     var status;
     if(this.packageImgSelected[indexE] !== null) {
      if(this.packageImgSelected[indexE].length < 2) {
        status = true;
      } else {
        status = false; 
      }
     
      return status;
     }

    }

    async getShowPackageDetails(packageId)
    {
      
      var funcE = { type: 'PACKAGE', func: 'getShowPackageDetails' };  
      var dataE = { packageId : packageId, modalhash : this.modal };    
  
      var Postdata = { func: funcE, data : dataE };
      let res = await this.http.goAcess(Postdata);
 
      this.packageName = res.packageName;
      this.packageId = res.packageId;

      this.packageImgSelected = this.filterImg(res.imgs, "selected");
      console.log("packageImgSelected",this.packageImgSelected);

      this.packageImgNotSelected = this.filterImg(res.imgs, "notselected");

      console.log("packageImgNotSelected",this.packageImgNotSelected);

      this.packageSelected = true; 
      
      setTimeout( () => {
        this.contentHeight = window.innerHeight-120;
      
        console.log("content height", this.contentHeight);
      }, 150)

     // this.showPackageList = res;
    }



  filterImg(data, typeToReturn){
    var temp = [];
    
    if(typeToReturn == "notselected") {
      for(var i = 0; i < data.length; i++){
        if(data[i].order < 0) {
          temp = data[i].imgs;
        }
      }
    }

    if(typeToReturn == "selected") {
      for(var i = 0; i < data.length; i++){
        if(data[i].order > -1) {
          temp[i] = data[i].imgs;
        }
      }
    }

    var filtered = temp.filter(function (el) {
      return el != null;
    });
    
    return filtered;

  }

  async selecteThisAsPublic(packageId){
    var funcE = { type: 'PACKAGE', func: 'makePackagePublic' };  
    var dataE = { packageId : packageId, modelHash : this.modal };    

    var Postdata = { func: funcE, data : dataE };
    let res = await this.http.goAcess(Postdata);

    if(res == 'ok') {
      this.getShowPackageModel();
    } else {
      this.openSnackBar('Error Happened !', ' :( ');
    }


  }

  async deletePackage(packageId){
    var funcE = { type: 'PACKAGE', func: 'deleteShowPackage' };  
    var dataE = { packageId : packageId };    

    var Postdata = { func: funcE, data : dataE };
    let res = await this.http.goAcess(Postdata);

    if(res == 'ok') {
      this.getShowPackageModel();
    } else {
      this.openSnackBar('Error Happened !', ' :( ');
    }
  }

  deletePannel(pannelIndex){
    this.packageImgSelected.splice(pannelIndex, 1);
  }

  async showSpecificPackage(publiclink){
    console.log("public", publiclink);
    window.open(publiclink);
  }

  removeFromSelection(indexI, indexJ){
    console.log("remove", indexI, indexJ);
    let temp = this.packageImgSelected[indexI][indexJ];

    this.packageImgSelected[indexI].splice(indexJ, 1);
    this.packageImgNotSelected.push(temp);
  }

  SelectPicsToChange(indexE, indexF, typeE = 'exist') {

console.log("get img from index", indexE, indexF);
this.selectedPicsToChangeIndexI = indexE;
if(typeE == 'empty'){

  this.selectedPicsToChangeIndexJ = this.packageImgSelected[this.selectedPicsToChangeIndexI].length + 1;
} else {
  this.selectedPicsToChangeIndexJ = indexF;
}

  }

  selectImgForReplace(indexE){

    if( (this.selectedPicsToChangeIndexI !== -1) && (this.selectedPicsToChangeIndexJ !== -1) ) {

      let newImg = this.packageImgNotSelected[indexE];
      let oldImg = this.packageImgSelected[this.selectedPicsToChangeIndexI][this.selectedPicsToChangeIndexJ];
    
  
      if( oldImg && (oldImg.resize !== null) ) {
        this.packageImgSelected[this.selectedPicsToChangeIndexI][this.selectedPicsToChangeIndexJ] = newImg;
        this.packageImgNotSelected.splice(indexE, 1);
        this.packageImgNotSelected.push(oldImg);
      } else {
        this.packageImgSelected[this.selectedPicsToChangeIndexI].push(newImg);
        this.packageImgNotSelected.splice(indexE, 1);
      }


      this.selectedPicsToChangeIndexI = -1;
      this.selectedPicsToChangeIndexJ = -1;


    }


  }

  getClassStatus(status){
    if(status == 1){
      return "showPackageItemCtn packageSelected";
    } else {
      return "showPackageItemCtn";
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    console.log("drop", event.previousIndex, event.currentIndex);
    var itemToReplace =   this.packageImgSelected[event.previousIndex];
    var itemNew = this.packageImgSelected[event.currentIndex];

    this.packageImgSelected[event.currentIndex] = itemToReplace;
    this.packageImgSelected[event.previousIndex] = itemNew;
  }

  _addNewPackage(){
    
    const dialogRef = this.dialog.open(EditFactureComponent, {  
      width: '350px',
     //  height: '500px',
     data: { 'type': 'number',  'text' : 'Package Name'}
     });

     dialogRef.afterClosed().subscribe(result => {
       console.log("new pack", result);
      if(result){
        if(result.status == 'set'){
          this.addNewPackage(result.date);
        }
      }

    }) 
  }

  async addNewPackage(packageName){
    var funcE = { type: 'PACKAGE', func: 'addNewPackage' };  

    var dataE = {  "packageName" : packageName, "modelHash": this.modal };    

    var Postdata = { func: funcE, data : dataE };
    let res = await this.http.goAcess(Postdata);

    if(res == 'ok') {
      this.getShowPackageModel();
    }
  }

  async savePackageToDB(){
    console.log("package to save", this.packageImgSelected);

    var funcE = { type: 'PACKAGE', func: 'saveNewPackage' };  
    var dataE = { modal : this.modal, "packageData" : this.packageImgSelected, packageId : this.packageId, 'packageNonSelected' : this.packageImgNotSelected };    

    var Postdata = { func: funcE, data : dataE };
    let res = await this.http.goAcess(Postdata);

    if(res == 'ok') {
      this.openSnackBar('Package Saved !', 'Congrats!');
    } else {
      this.openSnackBar('Error Happened !', ' :( ');
    }

  }

  removeFromUnselectedList(indexE) {
    this.packageImgNotSelected.splice(indexE, 1);
  }

  addNewPannel(){
    let temp = [];
    this.packageImgSelected.push(temp);
  }

  getClass(indexI, indexJ){

    let classtosend;

    if((indexI == this.selectedPicsToChangeIndexI) && (indexJ == this.selectedPicsToChangeIndexJ ) ) {
      classtosend = 'cardSelected';
    }



    return classtosend;

  }



  



 
}



