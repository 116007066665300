import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-albumphotos',
  templateUrl: './albumphotos.component.html',
  styleUrls: ['./albumphotos.component.scss']
})
export class AlbumphotosComponent implements OnInit {

  @Input() desc : string;
  @Input() date : string;
  @Input() total : string;
  @Input() visible : string;

  @Input() albumid : string;

  @Input() back : string;

  constructor() { }

  ngOnInit() {
   // console.log('back', this.back);
  }

}
