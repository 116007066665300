<app-date-filter (newItemEvent)='dateHaveBeenSet($event)' ></app-date-filter>

<div class="downloadBtn" (click)="DownloadAsZip()">
  <i style="font-size:30px; color: white" class="material-icons centred">download</i> 
</div> 


<mat-spinner class="centred"  *ngIf="spinnerProgress"> </mat-spinner>


<div style="margin-top: 30px; display: flex; flex-direction: row; justify-content: space-evenly; width: 80%; margin-left: auto; margin-right: auto;">
  <div  [ngClass]=" selecteddate == 'all' ? 'active': 'noactive'" (click)="SelecteDate('all')">All</div>
  <div  [ngClass]=" selecteddate == '2022' ? 'active': 'noactive'"(click)="SelecteDate('2022')">2022</div>
  <div  [ngClass]=" selecteddate == '2021' ? 'active': 'noactive'"(click)="SelecteDate('2021')">2021</div>
  <div  [ngClass]=" selecteddate == '2020' ? 'active': 'noactive'"(click)="SelecteDate('2020')">2020</div>
  <div  [ngClass]=" selecteddate == '2019' ? 'active': 'noactive'" (click)="SelecteDate('2019')">2019</div>
</div>

<div style="margin-top: 10px; display: flex; flex-direction: row; justify-content: space-evenly; width: 80%; margin-left: auto; margin-right: auto;">
  <div  [ngClass]=" societe == 'all' ? 'active': 'noactive'" (click)="selectSociete('all')">All</div>
  <div  [ngClass]=" societe == 'fr' ? 'active': 'noactive'"(click)="selectSociete('fr')">See Models SARL</div>
  <div  [ngClass]=" societe == 'uk' ? 'active': 'noactive'" (click)="selectSociete('uk')">SEEMO MGMT LTD</div>
</div>


<div style=" display: flex; flex-direction: column; justify-content: center; align-items: center; margin-bottom: 20px;">
  <h2 style="text-align: center;">Gestion des frais</h2>
  <p style="text-align: center;">Ajouter vos frais avec Justificatif (TVA).</p>

  <table *ngIf="frais && !spinnerProgress" mat-table [dataSource]="frais" class="mat-elevation-z3"
    style=" width: 80%;margin: auto;  margin-top: 20px;">
    <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

    <!-- Position Column -->
    <ng-container matColumnDef="Date">
      <th mat-header-cell *matHeaderCellDef>Date</th>
      <td mat-cell *matCellDef="let x">{{x.date}} </td>
      <td mat-footer-cell *matFooterCellDef> Total </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="Type">
      <th mat-header-cell *matHeaderCellDef>Type de Frais</th>
      <td mat-cell *matCellDef="let x">{{x.typefrais}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="Observations">
      <th mat-header-cell *matHeaderCellDef>Observations</th>
      <td mat-cell *matCellDef="let x">{{x.observation}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="montantht">
      <th mat-header-cell *matHeaderCellDef>Montant HT</th>
      <td mat-cell *matCellDef="let x"> {{x.montantht}} </td>
      <td mat-footer-cell *matFooterCellDef> {{ calculateTotal('ht') }} </td>
    </ng-container>

    <ng-container matColumnDef="montantttc">
      <th mat-header-cell *matHeaderCellDef>Montant TTC</th>
      <td mat-cell *matCellDef="let x"> {{x.montantttc}} </td>
      <td mat-footer-cell *matFooterCellDef> {{ calculateTotal('ttc') }} </td>
    </ng-container>

    <ng-container matColumnDef="Actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let x; let i = index;">
        <a [href]="x.justif" target="_blank">
          <i class="material-icons" style="cursor: pointer;margin:5px;">
            remove_red_eye
          </i>
        </a>
        <i (click)="addFrais('edit',x.id)" style="cursor: pointer;margin:5px;" class="material-icons">
          edit
        </i>

        <i (click)="delFrais(x.id, i)" style="cursor: pointer;margin:5px;" class="material-icons">
          delete_outline
        </i>
      </td>
      <td mat-footer-cell *matFooterCellDef>TVA Deductible : {{ calculateTotal('tva') }}</td>

    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
  </table>



</div>
<button class="fab" (click)="addFrais('add','')" mat-fab color="primary">+</button>