<div class="event-page">

  <mat-spinner class="centred"
 
  *ngIf="spinnerProgress">
  </mat-spinner>

  <div *ngIf="!spinnerProgress">

  
    <button class="fab" (click)="CreateNews()" mat-fab>+</button>

    <div style="margin-top:10px; display: flex; flex-direction: column; ">  <!-- START FLEXBOX -->
    
      <div>

    <mat-form-field style="position:relative; left:50%; transform: translate(-50%, 0);">
      <mat-select placeholder="Filter"  [(value)]="filter" (selectionChange)="doFilter(filter)">
        <mat-option *ngFor="let x of feed" [value]="x.value">
          {{x.viewValue}}
        </mat-option>
      </mat-select>
    </mat-form-field>


  </div>

  <div>
    <mat-card  *ngFor="let x of news; let i = index" [attr.data-index]="i">
        <mat-card-header style="padding:5px">
          <div mat-card-avatar class="example-header-image"></div>
          <mat-card-title>{{ x.category }}</mat-card-title>
          <mat-card-subtitle class="limitLinesTitle">{{ x.title }}</mat-card-subtitle>
        </mat-card-header>
        <div class="event-card1" [style.backgroundImage]="'url('+ x.urlToImage +')'">
          <div style="position:absolute;right:0;top:0;background-color: black; color:white; padding-left:5px;padding-right:5px;">
            {{x.views}} Views
          </div>
        </div>
        <mat-card-content style="padding:5px; height:auto;">
     
          <mat-slide-toggle
          style="margin-top:10px; margin-left: 50%; transform: translate(-50%, 0);"
  
          (change)="onChange($event, x.event_id)" 
          [checked]="x.publish == '1' " 
          >
        Publish !
      </mat-slide-toggle>

      <div [formGroup]="myGroup" style="margin-left:50%; transform: translate(-50%, 0)">
        <mat-form-field>
          <input matInput [matDatepicker]="picker" placeholder="date" [value]="x.publishedAt" (dateChange)="updateDate($event.value, x.event_id)">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>

   
         <div style="margin-top:20px" class="limitLines" [innerHTML]=" x.contenu | noSanitizePipe"></div>
         <mat-card-actions>
            <mat-grid-list cols="2" rowHeight="50px">
                <mat-grid-tile>
                
                    <button mat-stroked-button (click)="goNews(x.event_id)" color="primary">Edit</button>
                </mat-grid-tile>
                <mat-grid-tile>
  
                    <button mat-stroked-button (click)="deleteEvent(i,x.event_id)" color="primary">Delete</button>
                </mat-grid-tile>
              </mat-grid-list>

      
        </mat-card-actions>
        </mat-card-content>

    </mat-card>

  </div>
  </div> <!-- END FLEXBOX -->

  </div>
