import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../../http.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { UntypedFormBuilder, Validators, UntypedFormGroup} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-pushcustomizer',
  templateUrl: './pushcustomizer.component.html',
  styleUrls: ['./pushcustomizer.component.css']
})
export class PushcustomizerComponent implements OnInit {

  chgStateGroup: UntypedFormGroup;

  htmlContentFR: any;
  htmlContentENG : any;

  introENG: any;
  introFR: any;

  signatureFR:any;
  signatureENG: any;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    enableToolbar: true,
    showToolbar: true,
    toolbarPosition: 'top',
    height: '200px',
    minHeight: '200px',
    placeholder: 'Enter text here...',
    translate: 'no',
    uploadUrl: 'v1/images', // if needed
    customClasses: [ // optional
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };


  constructor(
    private http: HttpService,
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<PushcustomizerComponent>,
  ) { }


  ngOnInit() {
    this.createForm();
    this.getPushTemplate();

  }

  getPushTemplate(){
this.http.getPushTemplate().subscribe(res=>{
  //console.log('res',res);
  this.chgStateGroup.get('htmlContentFR').setValue(res['basefr']);
  this.chgStateGroup.get('htmlContentENG').setValue(res['baseeng']);

  this.chgStateGroup.get('introFR').setValue(res['introfr']);
  this.chgStateGroup.get('introENG').setValue(res['introeng']);

  this.chgStateGroup.get('signatureFR').setValue(res['signfr']);
  this.chgStateGroup.get('signatureENG').setValue(res['signeng']);
})
  }

  createForm(){
    this.chgStateGroup = this.fb.group({
      htmlContentFR: ['', Validators.required],
      htmlContentENG: ['', Validators.required],

      introFR: ['', Validators.required],
      introENG: ['', Validators.required],

      signatureFR: ['', Validators.required],
      signatureENG: ['', Validators.required],
      });
  }

  onNoClick(){
    this.dialogRef.close();
  }

  onOkClick(elem){
var temp1 = '';
var temp2 = '';

    if(elem == 'intro'){
       temp1 = this.chgStateGroup.get('introFR').value;
       temp2 = this.chgStateGroup.get('introENG').value;
    }

    if(elem == 'base'){
      temp1 = this.chgStateGroup.get('htmlContentFR').value;
      temp2 = this.chgStateGroup.get('htmlContentENG').value;
   }

   if(elem == 'signature'){
    temp1 = this.chgStateGroup.get('signatureFR').value;
    temp2 = this.chgStateGroup.get('signatureENG').value;
 }


this.http.updatePushTemplate(
temp1,
temp2,
elem
).subscribe(res=>{
  if(res == 'ok'){
    this.dialogRef.close(res);
  }
})
  }
 
}
