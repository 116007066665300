import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-client-menu',
  templateUrl: './client-menu.component.html',
  styleUrls: ['./client-menu.component.css']
})
export class ClientMenuComponent implements OnInit {


 

  constructor() { }

  ngOnInit() {
   
  }

  onScroll(event) {
    console.log(event);
  }

}
