<div style="position:relative;">


  <div>

 
    <mat-tab-group *ngIf="classic">

      <mat-tab *ngFor="let x of country" [label]="x.name" >
        <ng-template matTabContent>
          <app-agency-card [location]="x.name" [type]="type" [category]="classic"></app-agency-card>
        </ng-template>
      </mat-tab>

  
    </mat-tab-group>



    <mat-tab-group *ngIf="!classic">
    <mat-tab *ngFor="let x of country" [label]="x.label">
      <ng-template matTabContent>
        <app-agency-card [location]="x.label" [type]="type" [category]="classic"></app-agency-card>
      </ng-template>
    </mat-tab>
  </mat-tab-group>

  <div  class="removeOnMobile" style="position:absolute; top: 10px;right:50px;" *ngIf="type == 'clients' ">
    <mat-slide-toggle (change)="switchCategory($event)">Switch Category</mat-slide-toggle>
  </div>



</div>
