import { Injectable } from '@angular/core';
import { Observable, Subject } from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class CriteriaService {

  private subject = new Subject<any>();

  constructor(

  ) {


    }
    updateCriteria( message: string) {
      this.subject.next({ text : message});
   }

   goSearch(message: string){
    this.subject.next({ text : message});
   }

   updateProfileForm( message: string) {
    this.subject.next({ text : message});
 }

 getAgencyUpdate(): Observable<any>{
  return this.subject.asObservable();
 }

 updateAgencyForm(message: string){
  this.subject.next({ text : message});
 }

   getUpdate(): Observable<any> {
    return this.subject.asObservable();
}

getProfileUpdate(): Observable<any> {
  return this.subject.asObservable();
}

geSearch(): Observable<any> {
  return this.subject.asObservable();
}



}
