<div style="background-color:#F0F4F7">
    <div style="padding: 5px;" [formGroup]="chgStateGroup">

        <mat-form-field style="margin-top: 10px;width: 90%">

            <textarea matInput placeholder="Textarea"  formControlName="note" ></textarea>


        </mat-form-field>
    </div>


    <div style="display: flex; flex-direction: row; justify-content: flex-end;border: 1px solid #efefef; padding: 2px">
        <div>
            <button mat-stroked-button (click)="goCancel()">Cancel</button>
        </div>
        <div>
            <button mat-stroked-button (click)="saveNote()">Save</button>
        </div>
    </div>

</div>