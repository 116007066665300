<div style="min-height: calc((100vh - 50px)/2);max-height:  calc((100vh - 50px)/2); overflow-y: scroll;" *ngIf="!spinnerProgress">

    <table class="w3-table w3-striped w3-bordered">

      <tr class="w3-bar" *ngFor="let x of last" (click)="goModel(x.hash)">
        <td>
          <img [src]="x.avatar"  style="width:45px; border-radius: 50%;">
        </td>
        <td>
          <span >{{x.first}} {{x.last}}</span><br>
          <span>{{x.create}}</span>
        </td>
        

      </tr>

    </table>
  </div>