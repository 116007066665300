<div style="position:relative;height:580px;">
<img src="./assets/ihone_vide_good.png" height="100%" width="auto"/>
<div style="position:absolute; top: 67px;left:22px;height:40px;width:288px;background-color:white">
    <div class="title">{{category}}</div>
</div>
<div style="position:absolute; top: 107px;left:22px;width:288px;height:406px;">
  <div style="position:relative;width:100%;height:100%;overflow: scroll;overflow-x:hidden;">

    
        <div class="news-header" *ngIf="backImage"[style.backgroundImage]=" 'url('+ backImage + ') ' ">
            <div class="news-title" >{{title}}</div>
      </div>

          
      <div class="news-header" *ngIf="!backImage"[style.backgroundImage]=" 'url('+ bgImage + ') ' ">
          <div class="news-title" >{{title}}</div>
    </div>

        <div class="contenu">
            <div [innerHTML]="content | noSanitizePipe"></div>
        </div>

    


</div>
</div>