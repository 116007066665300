<mat-spinner class="centred"

*ngIf="spinnerProgress">
</mat-spinner>

<div style="display:flex; justify-content: center; overflow-y:  auto">

<form [formGroup]="myGroup" *ngIf="!spinnerProgress" style="width:50%;">
 
 
 <mat-form-field style="display:inline-block;width:50%;">
    <mat-select placeholder="Category" formControlName="category"  [(ngModel)]="cate">
      <mat-option *ngFor="let x of feed" [value]="x.value">
        {{x.viewValue}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field style="margin-left: 50px">
    <input matInput [matDatepicker]="picker" placeholder="date" formControlName="date" [(ngModel)]="date">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>


  <mat-form-field style="display:block;width:50%;">
      <input matInput placeholder="Title" value=""  formControlName="title" [(ngModel)]="title">
    </mat-form-field>


    <button mat-raised-button color="primary" (click)="cropFeed()">Feed Image</button>   
    <button mat-raised-button color="primary" style="margin-left:20px;" (click)="cropNews()">News Image</button>   

    <angular-editor style="margin-top:10px" formControlName="htmlContent" [config]="editorConfig" [(ngModel)]="htmlContent"></angular-editor>


  <button mat-raised-button color="primary" (click)="sendNews()">Save Events</button>


  </form>

  <app-seeevent [title]="title" [category]="cate" [content]="htmlContent" [backImage]="bgImage" style="float:left;width:40%"></app-seeevent>


</div>
