<div class="modalCloseBtn" (click)="onNoClick()">
  <div class="centred" style="color:white">X</div>
</div>

<form
class="paddingTopOnMobile"
  [formGroup]="chgStateGroup"
  style="
    width: 90%;
    margin-top: 15px;
    margin-left: 50%;
    transform: translate(-50%, 0);
  "
>
  <div
  class="flexRowResponsive"
  >
    <div>
      <div>
        <button class="BtnLongOnMobile"  mat-stroked-button mat-strokked (click)="selectCountry1()">
          Select Destinataire
        </button>
      </div>
      <div>
        <div

          *ngIf="tosend && tosend.length > 0"

          (click)="seeDestinataire()"
      
        >
        <div style="display: flex; flex-direction: column;">
          <div *ngFor="let y of tosend">{{y}}</div>
        </div>
       
      </div>
      </div>
    </div>

    <div style="display: flex; flex-direction: column">
      <div>
        <button class="BtnLongOnMobile" mat-stroked-button mat-strokked (click)="selectModel()">
          Select Model
        </button>
      </div>
      <div>
        <div
          *ngIf="selectedmodel && selectedmodel.length > 0"
          style="text-align: center;"
        >
         
          <div style="display: flex; flex-direction: row; justify-content: center; margin-top: 10px; margin-bottom: 10px;">
            <div *ngFor="let y of selectedAvatars">
              <img [src]="y" style="height: 70px; width: 70px;  border-radius: 50%;" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style="display: flex; flex-direction: column;">
      <div >
        <button class="BtnLongOnMobile" mat-stroked-button mat-strokked (click)="setDate()">
          Schedule Date
        </button>
      </div>
      <div>
        <div>{{date}}</div>
      </div>
    </div>


  </div>

  <div
  class="flexRowResponsive"
    style="

      margin-top: 20px;
    "
  >
    <div style="display: flex; flex-direction: column">
      <div>
        <mat-form-field style=" display: block; margin-top: 10px; min-width: 320px;">
          <textarea
           
            matInput
            placeholder="Subject"
            formControlName="subject"
          ></textarea>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field style="display:block;margin-top:10px;">
    
          <mat-select placeholder="Sender" formControlName="sender" >
            <mat-option value="contact@seemodels.co">contact@seemodels.co</mat-option>
            <mat-option value="pa@seemodels.co">pa@seemodels.co</mat-option>
            <mat-option value="jb@seemodels.co">jb@seemodels.co</mat-option>
            <mat-option value="admin@seemodels.co">admin@seemodels.co</mat-option>
            <mat-option value="jf@seemodels.co">jf@seemodels.co</mat-option>
          </mat-select>
      
      
        </mat-form-field>
      </div>




    </div>

    <div
      class="flexRowResponsive"
    >
      <div style="margin-right: 20px">
        <div class="toggleCtn">
          User can see Polaroids
          <mat-slide-toggle formControlName="polaroids"></mat-slide-toggle>
        </div>

        <div class="toggleCtn">
          User can see Videos
          <mat-slide-toggle formControlName="videos"></mat-slide-toggle>
        </div>

        <div class="toggleCtn">
          User can see/dl all album
          <mat-slide-toggle
            [checked]="!checked"
            formControlName="accesstoalbum"
          ></mat-slide-toggle>
        </div>

        <div class="toggleCtn">
          User can access ADMIN docs
          <mat-slide-toggle
            [checked]="!checked"
            formControlName="accesstodocs"
          ></mat-slide-toggle>
        </div>

        <div class="toggleCtn">
          Log User
          <mat-slide-toggle
            [checked]="checked"
            formControlName="log"
          ></mat-slide-toggle>
        </div>
      </div>

      <div>
        <mat-form-field style="display: block">
          <input
            matInput
            placeholder="Gallery Name (optional)"
            formControlName="galleryname"
          />
        </mat-form-field>

        <mat-form-field style="display: block">
          <mat-select placeholder="MenuType" formControlName="menuType">
            <mat-option value="0">No Menu</mat-option>
            <mat-option value="1"
              >Client Menu (1st Choice, 2nd Choice, No interest)</mat-option
            >
            <mat-option value="2"
              >Agency Menu (Want to see her, No Interest)</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>



  <angular-editor
    style="margin-top: 10px; min-width: 320px"
    id="editor1"
    formControlName="htmlContent"
    [config]="editorConfig"
    [(ngModel)]="htmlContentModel"
  ></angular-editor>
</form>



  <div style="margin-bottom: 50px; padding-bottom: 50px;">
  
    <div mat-dialog-actions *ngIf="!spinnerProgress2" >
      <div
      class="flexRowResponsive"
      >
        <div class="flexRowResponsive">
        
          <button class="BtnLongOnMobile"  mat-stroked-button mat-strokked (click)="onNoClick()" cdkFocusInitial>Close</button>
          <button class="BtnLongOnMobile" *ngIf="date"  mat-stroked-button mat-strokked (click)="saveForLaterPush()" >
            Save for later Push
          </button>
  
          <button class="BtnLongOnMobile" style="margin-left: 0px;"  mat-stroked-button mat-strokked (click)="sendEmailAll()" >
            Send Now
          </button>
  
   
        </div>
  
        <div sclass="flexRowResponsive">
          <div >
            <button class="BtnLongOnMobile" mat-stroked-button mat-strokked (click)="prepareForm('template1')" >
              Template 1
            </button>
          </div>
          <div>
            <button class="BtnLongOnMobile" mat-stroked-button mat-strokked (click)="prepareForm('template2')" >
              Template 2
            </button>
  
          </div>
        </div>
      </div>
    </div>


    <div *ngIf="logs.length > 0" style="width: 100%; margin-top: 20px">
      <div
        style="display: flex; flex-direction: row; justify-content: space-between; width: 320px; margin-left: auto; margin-right: auto"
        *ngFor="let x of logs"
      >
        <div>
          <img
            src="../../assets/email.png"
            class="icon"
            *ngIf="x.type == 'email' "
            style="height: 20px"
          />
          <img
            src="../../assets/phone.png"
            class="icon"
            *ngIf="x.type == 'sms' "
            style="height: 20px"
          />
          <img
            src="../../assets/calendar.png"
            class="icon"
            *ngIf="x.type == 'scheduleemail' "
            style="height: 20px"
          />
        </div>
        <div>{{ x.destinataire }}</div>
        <div>
          <img
            src="../../assets/checked.svg"
            class="icon"
            *ngIf="x.status == 'OK' "
            style="height: 20px"
          />
          <img
            src="../../assets/nochecked.svg"
            class="icon"
            *ngIf="x.status !== 'OK' "
            style="height: 20px"
          />
        </div>
      </div>
    </div>
    
    
  
  </div>





