import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../http.service';

import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router  } from '@angular/router';


@Component({
  selector: 'app-facturerappel',
  templateUrl: './facturerappel.component.html',
  styleUrls: ['./facturerappel.component.scss']
})
export class FacturerappelComponent implements OnInit {

  rappel: any;
  rappelBool : boolean;

  displayedColumns: string[] = [ 'Client', 'Job Date', 'Amount', 'Actions' ];


  constructor(
    private http : HttpService,
    public dialog: MatDialog,
    private router : Router,
    private r: ActivatedRoute,
  ) { 
    this.rappelBool = false;
  }

  ngOnInit(): void {
    this.getRappel();
  }

  async getRappel(){
    var funcE = { type: 'RAPPEL', func: 'getFactureRappel' };    
    var Postdata = { func: funcE };

    let res = await this.http.goAcess(Postdata);
    console.log('res', res);
    if(res){
      this.rappelBool = true;
      this.rappel = res;
    }


  }

  getDate(date){
    return date;
  }

  async doNotShow(agencyhash, index){
    console.log('do not show', index);
    var funcE = { type: 'BDD', func: 'updateValueinTable' };  
    
    var valuesE = [ { 'label': 'facture', 'value': 'donotshow' } ];
    var criteriaE = [ { 'label': 'event_hash', 'value': agencyhash } ];

    var dataE = { table: 'booking', values: valuesE, criteria: criteriaE };    

    var Postdata = { func: funcE, data : dataE };

    let res = await this.http.goAcess(Postdata);
    console.log('res', res);

    if(res == 'ok'){
      this.getRappel();
    }
  }

  generateFac(eventHash){
      this.router.navigate(['members/facturesystem/generatefac', eventHash ]  );
  }




}
