import { Component, OnInit, Inject } from '@angular/core';
import { Router } from "@angular/router";
import { MatDialog, MatDialogRef,  MAT_DIALOG_DATA} from '@angular/material/dialog';

import { UntypedFormBuilder, Validators, UntypedFormGroup} from '@angular/forms';
import { AuthService } from '../auth.service';

import {Md5} from 'ts-md5/dist/md5';
declare var Fingerprint2: any;
import * as JsEncryptModule from 'jsencrypt';
import { HttpService } from '../http.service';

export interface DialogData {
  name: string;
  animal: string;
}


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {

  agency: boolean;
  loginGroup: UntypedFormGroup;
  resultat: any;
  animal: string;
  name: string;
  test: any;

  browfp : string;
  loginBtnStatus: string;

  chgStateGroup: UntypedFormGroup;

  working : boolean;

  constructor(
    private route: Router ,
    public dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private auth: AuthService,
    private http: HttpService,

    
  ) 
  {
    this.agency = true;
    this.createForm();
    this.createForm2();
    this.loginBtnStatus = "Log In";
    this.working = false;
   }

   ngOnInit() {
     if(localStorage.getItem('rememberme') !== 'true'){
      localStorage.clear();
     } else {
 
       this.startSession(true);
     }
   

    new Fingerprint2().get((result, components) => {
      this.browfp = result;
      console.log(result); //a hash, representing your device fingerprint
      console.log(components); // an array of FP components
    });
  }

  createForm2(){
    this.chgStateGroup = this.fb.group({
      rememberme: [false, Validators.required],
    });

  }

  async startSession(rememberme = false){
   

    if(!this.working){
      this.working = true;
      this.loginBtnStatus = "Start Session";
      var Postdata = { 'type' : 'LOGIN', 'func' : 'startSession', 'browserfp' :  this.browfp };
      console.log('Postdata', Postdata);
      let res = await this.http.goAcess(Postdata, 'newlogin.php');
      console.log('res', res);
      localStorage.setItem('backPublicKey', res.publicKey);
      localStorage.setItem('sessionToken', res.sessionToken);
      this.sendCredentials(rememberme);
    }

  }

  async sendCredentials(rememberme = false){
    this.loginBtnStatus = "Login en cours...";

      var encrypt = new JsEncryptModule.JSEncrypt();
    //  console.log('encrpy', encrypt);
      var myPrivateKey = encrypt.getPrivateKey();
      var myPublicKey = encrypt.getPublicKey();

      localStorage.setItem('myPrivateKey', myPrivateKey);

      var credentials;

      if(!rememberme){
        this.test = Md5.hashStr(this.loginGroup.get('password').value);
         credentials = { 'login' : this.loginGroup.get('email').value, 'password' : this.test };
      } else {
        let pwd = localStorage.getItem('cryptedPass');
        let login = localStorage.getItem('login');
         credentials = { 'login' : login, 'password' : pwd };
      }

      var Postdata = { 'credentials' : credentials, 'myPublicKey' : myPublicKey, 'browserfp' : this.browfp };

      console.log('Postdata2', Postdata);
      let res1 = await this.http.goAcess(Postdata, 'newlogin.php');
      this.mgmtRes(res1);
  }

  mgmtRes(elem){
    console.log("this.chgStateGroup.get('rememberme').value", this.chgStateGroup.get('rememberme').value);
    if(elem.status == 'ok'){
      if(this.chgStateGroup.get('rememberme').value == true){
        localStorage.setItem('login', this.loginGroup.get('email').value);
        localStorage.setItem('cryptedPass', Md5.hashStr(this.loginGroup.get('password').value).toString() );
        localStorage.setItem('rememberme', 'true' );
      }

      localStorage.setItem('token', elem.token);
      localStorage.setItem('avatar', elem.avatar);
      localStorage.setItem('role', elem.role);
      this.route.navigate(['members/stats'])
    }else {
      this.loginBtnStatus = "Login";
      localStorage.clear();
      this.working = false;
      this.wrongPass();
    }
  }




openDialog(): void {
  const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
    width: '250px',
    data: {name: this.name, animal: this.animal}
  });

  dialogRef.afterClosed().subscribe(result => {

    this.animal = result;

  });
}

wrongPass(): void {
  const dialogRef = this.dialog.open(WrongPassword, {
    width: '250px',
  });

  dialogRef.afterClosed().subscribe(result => {
 
  });
}

createForm(){
  this.loginGroup = this.fb.group({
    email: ['', Validators.required],
    password: ['', Validators.required],
  });
}




   }


@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: 'dialog-overview-example-dialog.html',
})
export class DialogOverviewExampleDialog {

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'wrong-password',
  templateUrl: 'wrong-password.html',
})
export class WrongPassword {

  constructor(
    public dialogRef: MatDialogRef<WrongPassword>,
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}
