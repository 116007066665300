import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpService } from '../../http.service';

import * as moment from 'moment';


@Component({
  selector: 'app-donelist',
  templateUrl: './donelist.component.html',
  styleUrls: ['./donelist.component.scss']
})
export class DonelistComponent implements OnInit {

  done : any;
  @ViewChild('donelist', {static : false}) donelist: ElementRef;

  top: number;
  innerHeight: number;
  heightdone: number;
  showBool: boolean;

  constructor(
    private http: HttpService,
  ) {
    this.showBool = false;
   }

    

  ngOnInit() {
    this.getDoneList();
  
  
  
    setInterval( () => {
        this.getDoneList();
    }, 600000)
    }

    getDoneList(){
      let postData = { 'action' : 'getdonelist' };
      this.goBackOffice(postData);
    }

    async goBackOffice(data){

      let url = 'trelo/mgmt_trelo.php';
      this.http.goBackOffice(data, url).subscribe(res=>{
        this.done = res;
        this.showBool = true;
        this.getHeight();
        console.log('trello', res);
      }, error => {
        console.log(error);
      })
     }

     getHeight(){
      this.top = this.donelist.nativeElement.offsetHeight;
      this.innerHeight = window.innerHeight;
     
      console.log('innerheight', this.innerHeight, 'offsettop',  document.getElementById('donelist').offsetTop);
    
      this.heightdone = this.innerHeight -  document.getElementById('donelist').offsetTop - 50;
     }

    getRelativeTime(date){
      if(date){
        return moment(date).fromNow();
      }
   
    }

}
