import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DialogData2 } from '../mgmt-gallery.component';

import { StyleRenderer, lyl, WithStyles } from '@alyle/ui';
import { ImgCropperConfig, ImgCropperEvent, LyImageCropper, ImgCropperErrorEvent, ImgCropperLoaderConfig } from '@alyle/ui/image-cropper';
import { Platform } from '@angular/cdk/platform';

const STYLES = () => ({
  cropper: lyl `{
    max-width: 400px
    height: 300px
  }`,
  sliderContainer: lyl `{
    text-align: center
    max-width: 400px
    margin: 14px
  }`
});

@Component({
  selector: 'app-avatarcrop',
  templateUrl: './avatarcrop.component.html',
  styleUrls: ['./avatarcrop.component.scss'],
  providers: [
    StyleRenderer
  ]
})
export class AvatarcropComponent implements OnInit {

  classes = this.sRenderer.renderSheet(STYLES);
  croppedImage?: string;
  scale: number;
  ready: boolean;
  minScale: number;
  @ViewChild(LyImageCropper) cropper: LyImageCropper;

  cropperStaticWidth: number;
  cropperStaticHeight: number;

  myConfig: ImgCropperConfig;
  cropperBool: boolean;


  constructor(
    public dialogRef: MatDialogRef<AvatarcropComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData2,
    readonly sRenderer: StyleRenderer,
    private _platform: Platform
  ) { 


  }

  ngOnInit() {
    this.cropperStaticWidth = this.data['cropperStaticWidth'];
    this.cropperStaticHeight = this.data['cropperStaticHeight'];

    this.myConfig = {
      // autoCrop: true,
      width: this.cropperStaticWidth, // Default `250`
      height: this.cropperStaticHeight, // Default `200`
      fill: '#ff2997', // Default transparent if type = png else #000
      type: 'image/jpeg', // Or you can also use `image/jpeg`
      responsiveArea: true,
      output: {
        width: 200,
        height: 0
      },
    
    };

   
    //console.log("croper", this.cropperStaticWidth, 'cropper', this.cropperStaticHeight);


  }

  ngAfterViewInit() {

    // demo: Load image from URL and update position, scale & rotate
    // this is supported only for browsers
    if (this._platform.isBrowser) {
      const config: ImgCropperLoaderConfig = {
        scale: 0.745864772531767,
        xOrigin: 642.380608078103,
        yOrigin: 236.26357452128866,
        // areaWidth: 100,
        // areaHeight: 100,
        rotation: 0,
        originalDataURL: ''
      };



      this.cropper.loadImage(config);
    }

    


  }


  onCropped(e: ImgCropperEvent) {
    this.croppedImage = e.dataURL;
    console.log('cropped img: ', e);
  }
  onLoaded(e: ImgCropperEvent) {
    console.log('img loaded', e);
  }
  onError(e: ImgCropperErrorEvent) {
    console.warn(`'${e.name}' is not a valid image`, e);
  }

  onNoClick() {
    this.dialogRef.close();
  }

  selectCroppedImage(){
    var transfert = [{'status' : 'ok', 'imagetosave' : this.croppedImage}]
    this.dialogRef.close(transfert);
  }

}
