import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../http.service';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { EditFactureComponent } from './edit-facture/edit-facture.component';
import * as moment from 'moment';
import 'moment/locale/fr';

import { EmailcompComponent } from '../../emailcomp/emailcomp.component';


import CommonFunc  from '../../commonfunc';


@Component({
  selector: 'app-allfactures',
  templateUrl: './allfactures.component.html',
  styleUrls: ['./allfactures.component.css']
})
export class AllfacturesComponent implements OnInit {

  allfactures : any;
  total : any;
  state : string;
  selecteddate: any;
  societe: string;
  windowWidth: any;
  startYear : number
  endYear: number;
  displayedColumns: any;

  yearArray : any = [];

  spinnerProgress: boolean;

 

  constructor(
    private http: HttpService,
    private route :ActivatedRoute,
    public dialog: MatDialog,
    private commonFunc : CommonFunc
 //   public dialogRef: MatDialogRef<AllfacturesComponent>,
  ) {
    this.spinnerProgress = false;
  
    this.societe = 'all';
    this.startYear = 2019
    this.selecteddate = new Date().getFullYear();
    this.endYear = new Date().getFullYear() + 1;

    moment.locale('fr');
   }

  ngOnInit() {

    this.createYearArray();

    this.route.params.subscribe( state => {
      console.log('state',state);
      this.state = state['type'];

      this.windowWidth = window.innerWidth;
      if(this.windowWidth > 650) {
        if(this.state == 'clients'){
          this.displayedColumns = ['Model', 'Clients', 'Montant', 'dateemission', 'datepaiement', 'Facture', 'Actions'];
        } else {
          this.displayedColumns = ['Model', 'Clients', 'Montant', 'dateemission', 'datepaiement', 'Facture', 'Actions'];
        }
       
      } else {
        this.displayedColumns = ['Model', 'Clients', 'Montant', 'dateemission',  'Facture' ];
      }


      this.getAllfactures(this.state);
    })

  }

  createYearArray(){
    for(var i = 0; i <= (this.endYear - this.startYear); i++){
      this.yearArray.push(this.startYear + i);
  }
  console.log('yearArray', this.yearArray);

  }

  SelecteDate(date){
    this.selecteddate = date;
    this.getAllfactures(this.state);
  }

  SelecteEntreprise(societe){
    this.societe = societe;
    this.http.getAllfactures(this.state, this.selecteddate, this.societe).subscribe(res=>{
      console.log(res);
      this.allfactures = res['factures'];
      this.total = res['chiffres'];
    })
  }

  getAllfactures(type){
    console.log('get all factures');
    this.http.getAllfactures(type, this.selecteddate, this.societe).subscribe(res=>{
      console.log(res);
      this.allfactures = res['factures'];
      this.total = res['chiffres'];
    })
  }

  goFacturation(elem){
    window.open(elem);
  }


  getSum(type, tva = false){
    var sum = 0;
    let res;

    if(this.allfactures){
      if(type == 'total'){
        for(var i = 0; i < this.allfactures.length; i++){
          sum = sum + parseInt(this.allfactures[i].montant);
          res = sum.toString() + ' €';
        }
      }
  
      if(type == 'already'){
        for(var i = 0; i < this.allfactures.length; i++){
          if(this.allfactures[i].datepaiement !== null){
            sum = sum + parseInt(this.allfactures[i].montant);
            res = sum.toString() + ' €';
          }
         
        }
      }
      
      if(type == 'waiting'){
        for(var i = 0; i < this.allfactures.length; i++){
          if(this.allfactures[i].datepaiement == null){
            sum = sum + parseInt(this.allfactures[i].montant);
            res = sum.toString() + ' €';
          }
         
        }
      }
  
      if(tva){
        return sum
      } else {
        return res;
      }

    } else {
      return '0';
    }

  }

  getBenefOrCost(type){
    var sum = 0;
    var total;

    for(var i = 0; i < this.allfactures.length; i++){
      if(type == 'cost'){
        sum = sum + parseInt(this.allfactures[i].cost);
      }

      if(type == 'benef'){
        sum = sum + parseInt(this.allfactures[i].benef);
      }
    
      total = sum.toString() + ' € H.T';
    }
    return total;
  }

  getTva(type){
    if(type == 'collected'){
      return this.getSum('already', true)*0.2 + ' €';
    }

    if(type == 'waiting'){
      return this.getSum('waiting', true)*0.2 + ' €';
    }
  }

  getCssClass(elem){
    if(elem.datepaiement){
      return 'paid';
    } else {
      var now = moment(new Date()); //todays date
      var end = moment(elem.dateemission, 'DD-MM-YYYY'); // another date
      var duration = moment.duration(now.diff(end));
      //console.log('ecart',duration.asDays());
      if(duration.asDays()>30 && duration.asDays() < 40){
        return 'retard';
      }
      if(duration.asDays() > 40){
        return 'verylate';
      }
    }
    }

    getRelativeTime(date){
      return moment(date).fromNow();
    }

  

  setDate(numfacture){
    const dialogRef = this.dialog.open(EditFactureComponent, {  
      width: '350px',
     //  height: '500px',
     data: {id : numfacture, 'text' : 'Date de Paiement'}
     });

     dialogRef.afterClosed().subscribe(result => {
      if(result){
        if(result.status == 'set'){
          this.setFactureDate(numfacture, result.date);
        }
      }

    }) 
  }

  setFactureDate(numfacture, date){
    this.http.editFacture(
      numfacture,
      date,
    ).subscribe(res=>{
      if(res == 'ok'){
        this.getAllfactures(this.state);
      }
    })
  }


  deleteFac(numfacture){
    let postData = {
     'action' : 'deletefacture', 'numfacture' : numfacture
    };

    this.http.goBackOffice(postData, 'facturation/testfac.php').subscribe(res=>{
      console.log(res);
      if(res['status'] == 'ok'){
        this.getAllfactures(this.state);
      }
    })
  }

  goMail(elem){
    const dialogRef = this.dialog.open(EmailcompComponent, {  
      data: {numfacture : elem.numerofacture, type: 'relancefacture', attachements : [elem.factureurl] }
     });

     dialogRef.afterClosed().subscribe(result => {

    }) 
  }

  async DownloadAsZip(){

    this.spinnerProgress = true;

    var pdfUrlList = [];
    for(var i = 0; i < this.allfactures.length; i++)
    {
      pdfUrlList[i] =  {url: this.allfactures[i].factureurl, name : this.allfactures[i].numerofacture, ext : "pdf"};
    }

    await this.commonFunc.createZip(pdfUrlList, "factures.zip");
    this.spinnerProgress = false;

  }

  dateHaveBeenSet(e){
    console.log("dateHaveBeenSet", e);
    this._goFilter(e.startdate, e.enddate);
  }

  _goFilter(start, end){

    moment.locale('fr');
    var newDateSet = [];
    let startDate = new Date(moment(start).format('YYYY-MM-DD'));
    let endDate = new Date(moment(end).format('YYYY-MM-DD'));

    console.log('startDate', startDate,  'endDate', endDate );

    for(var i = 0; i < this.allfactures.length; i++) {
        let oneFacture = this.allfactures[i];
        
        if(oneFacture.datepaiement){

          let dateToCompare = new Date( moment(oneFacture.datepaiement, 'DD-MM-YYYY').format('YYYY-MM-DD') );
          console.log('dateToCompare', oneFacture.datepaiement,  'dateToCompare.moment.format()', dateToCompare );
  
          if(dateToCompare.getTime() < endDate.getTime() && dateToCompare.getTime() > startDate.getTime() ) {
            newDateSet.push(oneFacture);
          }
          
        }



    }
    console.log("filtered", newDateSet);
    this.allfactures = newDateSet;
  }

}
