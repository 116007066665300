import { Component, OnInit, Input } from '@angular/core';



@Component({
  selector: 'app-statsone',
  templateUrl: './statsone.component.html',
  styleUrls: ['./statsone.component.scss']
})
export class StatsoneComponent implements OnInit {

  @Input() getBookingStats: any;
  @Input() getPlacementStats: any;
  @Input() getRegistrationStats: any;


  constructor(

  ) { 


  }

  async ngOnInit() {
 
    }


 


}
