<mat-spinner class="centred"

*ngIf="spinnerProgress2">
</mat-spinner>

<form  *ngIf="!spinnerProgress2" [formGroup]="chgStateGroup" style="width:90%;margin-top:15px;margin-left:50%; transform: translate(-50%, 0);">



  <mat-form-field style="display:block;margin-top:10px;">
    
    <mat-select placeholder="Sender" formControlName="sender" >
      <mat-option value="contact@seemodels.co">contact@seemodels.co</mat-option>
      <mat-option value="pa@seemodels.co">pa@seemodels.co</mat-option>
      <mat-option value="jb@seemodels.co">jb@seemodels.co</mat-option>
      <mat-option value="admin@seemodels.co">admin@seemodels.co</mat-option>
      <mat-option value="jf@seemodels.co">jf@seemodels.co</mat-option>
    </mat-select>


  </mat-form-field>




  <mat-form-field style="display:block;margin-top:10px;">

    <mat-label>Select Destinataire</mat-label>
    <input type="text" placeholder="Select Destinataire" aria-label="Number" matInput [matAutocomplete]="auto" formControlName="destinataire">
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let x of destinataireArray" [value]="x" >{{x}}</mat-option>
    </mat-autocomplete>

  </mat-form-field>
  
  <div style="display: flex; flex-direction: row; justify-content: space-between;">
    <div>
      <button mat-stroked-button mat-strokked (click)="selectCountry1()" >Select Destinataire</button>
    </div>
  

      
  <div *ngIf="attachements" style="display: flex; flex-direction: column; justify-content: flex-start;">
    <div  style="display: flex;flex-direction: row;" >
      <div *ngFor="let x of attachements" style="margin:2px;padding: 5px;display: flex;flex-direction: column; align-items: center; margin: 0px 5px;border:1px solid black; box-sizing: border-box;" >
        <div (click)="openFile(x)" style="display: flex;align-items: center;cursor: pointer;">
          <div>
            <img src="../../assets/pdf-file.png"  style="height: 40px; " />
          </div>
          <div>
            {{ getFileName(x) }}
          </div>
        </div>

        <div>
         <button mat-stroked-button mat-strokked (click)="deletePieceJointe(x)">Delete</button>
        </div>
      
      </div>
     
    </div>
  </div>

</div>
  
           <mat-form-field style="display:block;margin-top:10px;">
            <textarea matInput placeholder="Subject" formControlName="subject"></textarea>
          </mat-form-field>
        
        

          <angular-editor style="margin-top:10px; min-width: 320px" id="editor1" formControlName="htmlContent" [config]="editorConfig" [(ngModel)]="htmlContent"></angular-editor>
        
          <div class="bTnPannelForMobile">
            <div style="display: flex; flex-direction: row; justify-content: space-between; width: 100%; min-width: 100%;">
              <div style="display: flex; flex-direction: flex-start;">
                <button mat-button (click)="onNoClick()">Cancel</button>
                <button mat-button (click)="onOkClick()" cdkFocusInitial>Ok</button>
              </div>
  
              <div style="display: flex; flex-direction: row; justify-content: center;">
                <div>
                  <img src="../../../assets/flag/fr.png" style="height: 20px; margin: 5px; cursor: pointer;" />
                </div>
            <div>
              <img src="../../../assets/flag/uk.png" style="height: 20px; margin: 5px ; cursor: pointer;" />
            </div>
               
              
              </div>
            </div>

   
          </div>
          
          
        </form>