<div style="position:relative;height:100%;" >

   <div class="fastActionCtn" >
     
     <!--
    <div class="actions" *ngIf="visibility == 1">
      <img src="assets/avatar_female.png" (click)="goPublicProfile()" height="30px" style="position:absolute; left:50%; top:50%; transform: translate(-50%, -50%)" />
    </div>
    -->

    <div class="actions">
      <img src="assets/email.png" (click)="goPush()" height="30px" style="position:absolute; left:50%; top:50%; transform: translate(-50%, -50%)" />
    </div>

   <!--
<div class="actions" (click)="toggleVisibilty()">
          <img *ngIf="visibility !== '-1' " src="assets/hide.png"  height="30px" style="position:absolute; left:50%; top:50%; transform: translate(-50%, -50%)" />
          <img *ngIf="visibility == '-1' " src="assets/eye.png"  height="30px" style="position:absolute; left:50%; top:50%; transform: translate(-50%, -50%)" />
    </div> -->
 


 <!--
    <div class="actions" (click)="toggleFavorites()">
      <img *ngIf="favorite == true " src="assets/favorite.png"  height="30px" style="position:absolute; left:50%; top:50%; transform: translate(-50%, -50%)" />
      <img *ngIf="favorite == false " src="assets/notfavorite.png"  height="30px" style="position:absolute; left:50%; top:50%; transform: translate(-50%, -50%)" />
    </div>
      --> 

      <!--
    <div class="actions">
      <img src="assets/meeting.png" (click)="setMeeting()" height="30px" style="position:absolute; left:50%; top:50%; transform: translate(-50%, -50%)" />
    </div>
      -->



 <!--  <div class="actions">
      <img src="assets/pdf-file.png" (click)="generatePDF()" height="30px" style="position:absolute; left:50%; top:50%; transform: translate(-50%, -50%)" />
    </div>-->
   <div class="actions" (click)="editProfile()">
      <img src="assets/edit.png"  height="30px" style="position:absolute; left:50%; top:50%; transform: translate(-50%, -50%)" />
    </div>  
    
    <!--
    <div class="actions">
      <img src="assets/push.png" height="30px" (click)="SendPush()" style="position:absolute; left:50%; top:50%; transform: translate(-50%, -50%)" />
    </div>
    -->
  

 <!--   <div class="actions">
      <img src="assets/like.png" height="30px" (click)="SendLike()" style="position:absolute; left:50%; top:50%; transform: translate(-50%, -50%)" />
    </div>-->
    <div class="actions removeOnMobile">
      <img src="assets/trash.png" height="30px" (click)="Delete()" style="position:absolute; left:50%; top:50%; transform: translate(-50%, -50%)" />
    </div>

  </div>


</div>
