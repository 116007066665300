import { Component, OnInit, Input  } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { HttpService } from '../http.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-seeevent',
  templateUrl: './seeevent.component.html',
  styleUrls: ['./seeevent.component.css']
})



export class SeeeventComponent implements OnInit {


//  newsTitle: string;
//  trustedUrl: any;
bgImage: string;
contenu: any;

  @Input() title: string;
  @Input() category: string;
  @Input() content: any;
  @Input() backImage: any;

  constructor(
    private _Activatedroute:ActivatedRoute,
    private get: HttpService,
    private sanitizer: DomSanitizer
  ) {
     this.bgImage = 'http://bmyscouter.co/db/clients/events/event1536676412.jpg';
   //   this.contenu = this.sanitizer.bypassSecurityTrustHtml(this.content);
   }

  ngOnInit() {
    this._Activatedroute.params.subscribe( params => {
      console.log('params',params.id);

    })
  }



}
