<div style="display: flex; flex-direction: column; justify-items: center; align-items: center; width: 70%; margin-left: 50%; transform: translate(-50%, 0);">
    <div *ngIf="notes">
                <div *ngFor="let x of notes">
                                <mat-card>
                                  <div class="options"> <i style="font-size:12px !important; cursor: pointer;" class="material-icons" (click)="deleteNote(x.noteid)">delete</i> </div>
                                        {{x.note}}
                                   <div class="date">{{x.date}} <span *ngIf="x.by !== '?' " style="font-size: 10px;">(by {{x.by}})</span></div>
                                    </mat-card>
                        </div>
    </div>

    <div *ngIf="!notes" style="margin-top: 20px">
                <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                                <div>
                                    <img src="../../../assets/note.png"  style="height: 50px" />
                                </div>
                                <div style="text-align: center;">
                                  No Notes
                                </div>
                              </div>
    </div>


    <div>
        <button class="fab" (click)="addNote()" mat-fab color="primary">+</button>
      </div>    

</div>

