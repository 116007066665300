
       <div *ngIf="!spinnerProgress">
        <table *ngIf="dataSource2" mat-table [dataSource]="dataSource2" class="mat-elevation-z1" style="min-width: 100%">

          <ng-container matColumnDef="phone" style="margin:5px">
            <th mat-header-cell *matHeaderCellDef>
              <div style="display: flex; flex-direction: column; justify-content: space-evenly;">
                <div>
                  Phone ?
                </div>
              </div>
            </th>
            <td mat-cell *matCellDef="let x">
              <div>{{x.phone}}</div>
            </td>
          </ng-container>

          <ng-container matColumnDef="status" style="margin:5px">
            <th mat-header-cell *matHeaderCellDef >
              <div>
                Status ?
              </div>
            </th>
            <td mat-cell *matCellDef="let x">
              {{x.status}}
            </td>
          </ng-container>


          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef>
              Date ?
            </th>
            <td mat-cell *matCellDef="let x">
              {{x.date}}
            </td>
          </ng-container>


          <ng-container matColumnDef="message">
            <th mat-header-cell *matHeaderCellDef>
                <div style="display: flex; flex-direction: column; justify-content: space-evenly;">
                  <div>
                    Message ?
                  </div>
                </div>
            </th>
            <td mat-cell *matCellDef="let x">
              <div style = "min-width: 250px;max-width: 250px; overflow: hidden">
                {{x.message}}
              </div>
            </td>
          </ng-container>


          <tr mat-header-row *matHeaderRowDef="displayedColumns2 ; sticky : true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
        </table>
       </div>
     