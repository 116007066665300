<div *ngIf="packageSelected" style="position: relative;" >


  <div  style="position: relative; " #content >

    <div  [style.height.px]="contentHeight * 0.8 "  [style.maxHeight.px]="contentHeight * 0.8" style="  overflow: scroll; position: relative;" 

    >

    <div 
    style="position: absolute; 
    top: 5px; 
    width: 100%; 
    display: flex; flex-direction: row; 
    justify-content: space-between; 
    z-index: 100;
    align-items: center;
    background-color: rgba(0,0,0, 0.1);
    padding-left: 5px;
    padding-right: 5px;
    ">
    <div>{{ packageName }}</div>
    <div style="display: flex; flex-direction: row;" >
      <button mat-button (click)="closePackage()"  >Close Package</button>
      <button mat-button (click)="savePackageToDB()"  >Save</button>
    </div>
    </div>

      <div class="gallerycomp_imgCtnUpper" style="margin-top: 50px; z-index: 150;" cdkDropList  (cdkDropListDropped)="drop($event)">

        <div class="gallerycomp_imgRow" *ngFor="let x of packageImgSelected; let i = index;" cdkDrag>

          <div class="delCursor" (click)="deletePannel(i)">
            <div>
              <img src="assets/trash.png" style="width: 15px;" />
            </div>
            
          </div>
        
            <div class="moveCursor">
              <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
                <path d="M0 0h24v24H0z" fill="none"></path>
              </svg>
            </div>
  
       

          <div style="display: flex; flex-direction: row; flex-wrap: nowrap;padding: 15px;" >
           
            <div  class="event-card2" *ngFor="let y of x; let j = index"   >

              <img [src]="y.resize" class="imgClass" [ngClass]="getClass(i, j)" (click)="SelectPicsToChange(i, j)" />

           

              <div class="event-card2-btn" (click)="removeFromSelection(i, j)">
                <div class="event-card2-btn-inner">
                  <span class="centred" style="color: white;">X</span>
                </div>
              
              </div>

            </div>

            <div  class="event-card2-small"  *ngIf="checkIndex(i)" (click)="SelectPicsToChange(i, 1, 'empty')" >

              <div  style="width: 100%; height: 100%; background-color: rgba(0,0,0 0.5);" [ngClass]="getClass(i, 1)"  ></div>  

     

            </div>


          </div>



        </div>

        <div style="display: flex; flex-direction: column;" (click)="addNewPannel()">
          <div>
            <img src="../../../../../assets/add.png"  style="width: 40px;" />
          </div>
          
        </div>

      </div>



    </div>


    <div 
    class="notSelectedPics" >

    <div style="display: flex; flex-direction: row; overflow-x: scroll;">
      <div *ngFor="let x of packageImgNotSelected; let i = index"  class="event-card2" (click)="selectImgForReplace(i)" >
       
        <img   [src]="x.resize" class="imgClass" />
        <div style="position: absolute; top: 20px; width: 20px;background-color: black; right: 0px; top: 0px; height: 20px;">
          <div class="event-card2-btn-inner">
            <span class="centred" style="color: white; cursor: pointer;" (click)="removeFromUnselectedList(i)">X</span>
          </div>
        
        </div>
 
      </div>
    </div>

 
    </div>

  </div>

</div>

<div *ngIf="!packageSelected">


  <div style="position: relative; height: 50px;">




    <div style="position: absolute; top: 20px; right: 20px;">
      <button mat-button (click)="_addNewPackage()">Create New Package</button>

    </div>

  </div>

  <div style="display: flex; flex-direction: row;">

    <div *ngFor="let x of showPackageList" [ngClass]="getClassStatus(x.packageStatus)" >
      <div [style.backgroundImage]="getImage(x.packageCover)" class="showPackageItem" >

        <!--

       <div class="showPackageItem-inner-base showPackageItem-inner" (click)="deletePackage(x.packageId)">
          <div class="showPackageItem-inner-inside">
            <div>
              <img src="../../../../../assets/delete.png" style="width: 12px;" />
            </div>
          </div>
        </div>
        -->
 

        <div class="showPackageItem-inner-base showPackageItem-innerLeft" (click)="showSpecificPackage(x.publiclink.longlink)">
          <div class="showPackageItem-inner-inside">
            <div>
              <img src="../../../../../assets/eye.png" style="width: 16px;" />
            </div>
          </div>
        </div>

        <div class="showPackageItem-inner-base showPackageItem-innerBottomLeft" (click)="selecteThisAsPublic(x.packageId)">
          <div class="showPackageItem-inner-inside">
            <div>
              <img src="../../../../../assets/value.png" style="width: 16px;" />
            </div>
          </div>
        </div>


        <div class="showPackageItem-inner-base showPackageItem-innerCenter"  (click)="getShowPackageDetails(x.packageId)" >
          <div class="showPackageItem-inner-inside">
            <div>
              <img src="../../../../../assets/picture.png" style="width: 16px;" />
            </div>
          </div>
        </div>


      </div>
      <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
        <div style="font-weight: 700;">{{x.packageName}}</div>
        <div>({{x.packageContentNumber}} Pics)</div>
      </div>

    </div>

  </div>

</div>