
<form [formGroup]="myGroup"  style="width:80%; margin-left:50%; transform: translate(-50%, 0);padding:5px;">




    <mat-form-field style="display:block">
      <input matInput [matDatepicker]="picker" placeholder="Date d'Emission" formControlName="dateemission">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

  <!--  <mat-form-field style="display:block">
      <input matInput [matDatepicker]="picker1" placeholder="Date de Paiement" formControlName="datepaiement">
      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
      <mat-datepicker #picker1></mat-datepicker>
    </mat-form-field>-->

    <mat-form-field style="display:inline-block;width:100%;">
      <input matInput placeholder="Montant" formControlName="montant">
     </mat-form-field>

     <mat-form-field style="display:inline-block;width:100%;">
      <input matInput placeholder="Intitulé" formControlName="intitule">
     </mat-form-field>



    <div mat-dialog-actions align="end">
       <button mat-button (click)="close()">Cancel</button>
       <button mat-button cdkFocusInitial (click)="addFacture()">Ok</button>
     </div>

</form>