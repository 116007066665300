import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../http.service';
import { MatSnackBar} from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

import { AddDocumentsComponent } from './add-documents/add-documents.component';

export interface DialogData {
  category: string;
}



@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {

  doctype: any;
  spinnerProgress: boolean;

  constructor(
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private http : HttpService
  ) { }

  ngOnInit() {
    this._getDocType();
  }

  addDocuments(){
    const dialogRef = this.dialog.open(AddDocumentsComponent, {
      width: '250px',
      data: { category : this.doctype  }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if(result !== undefined){
        if(result == 'OK'){
          this.openSnackBar('file added', 'Congrats !');
          this._getDocType();
        }else {
          this.openSnackBar('Error', 'Try Again !');
        }
      }
 
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  _getDocType(){
    this.spinnerProgress = true;
    this.http.getDocType().subscribe(res=>{
      console.log('get doc type', res);
      this.spinnerProgress = false;
      this.doctype = res;
    })
  }


}
