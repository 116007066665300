import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../../../http.service';

import {
  DomSanitizer,
  SafeResourceUrl,
  SafeUrl
} from '@angular/platform-browser';

import {
  MatDialogRef,
} from '@angular/material/dialog';

@Component({
  selector: 'app-fromboa',
  templateUrl: './fromboa.component.html',
  styleUrls: ['./fromboa.component.scss']
})
export class FromboaComponent implements OnInit {

  boa: any;

  constructor(
    private http : HttpService,
    private sanitization: DomSanitizer,
    public dialogRef: MatDialogRef <FromboaComponent> ,
  ) { }

  ngOnInit() {
    this.getboa();
  }


  getboa(){
    this.http.getAllBoa().subscribe(res=>{
      console.log('boa',res);
        this.boa = res;
    })
    }

    goSelect(modelhash, agencyhash, montantht, annonceur){
      var temp = { 'status': 'ok', 'agency_hash' : agencyhash, 'model_hash' : modelhash, 'montantht' : montantht, 'annonceur' : annonceur};
      this.dialogRef.close(temp);
    }



    getImage(data) {
      return this.sanitization.bypassSecurityTrustStyle(`url(${data})`);
    }


}
