<div *ngIf="logs">
  
<div style="display: flex; flex-direction: row; justify-content: center; width: 100%; margin-top: 10px; "  >
      <div class="choiceBtn" (click)="filterType('all')">
        <div class="font18">All</div>
      </div>
      <div class="choiceBtn" (click)="filterType('agency')" id="agency">
        <div><img src="../../../assets/client/agency.png" class="img18" /></div>
        <div class="choiceTitle font18">Agency</div>
      </div>
      <div class="choiceBtn" (click)="filterType('see')" id="photographer">
        <div><img src="../../../assets/client/photographer.png" class="img18" /></div>
        <div class="choiceTitle font18">Photographers</div>
      </div>
      <div class="choiceBtn" (click)="filterType('accept')" id="client">
        <div><img src="../../../assets/client/client.png" class="img18" /></div>
        <div class="choiceTitle font18">Client</div>
      </div>
    </div>

     <div style="display: flex; flex-direction: row; justify-content: center; width: 100%; margin-top: 10px;">
    <div class="choiceBtn" (click)="filterType('all')">
      All
    </div>
    <div class="choiceBtn selected" (click)="filterType('mail')">
      <div><img src="../../../assets/email.png" class="img12"/></div>
      <div class="choiceTitle font12 ">Mail</div>
    </div>
    <div class="choiceBtn" (click)="filterType('see')">
      <div><img src="../../../assets/eye.png" class="img12" /></div>
      <div class="choiceTitle font12">See</div>
    </div>
    <div class="choiceBtn" (click)="filterType('accept')">
      <div><img src="../../../assets/checked.svg" class="img12" /></div>
      <div class="choiceTitle font12">Accept</div>
    </div>
    <div class="choiceBtn" (click)="filterType('refuse')">
      <div><img src="../../../assets/cancel.svg" class="img12" /></div>
      <div class="choiceTitle font12">Refuse</div>
    </div>
  </div>


  
  
  
 
  
  <div
    style="display:flex; flex-direction: column; width: 100%; align-items: center; justify-content: flex-start; overflow: auto; height: 75vh; margin-top: 20px; margin-bottom: 20px">
  
    <table  mat-table [dataSource]="logs" class="mat-elevation-z1" style=" width: 90%; ">
  
      <ng-container matColumnDef="contact">
        <th mat-header-cell *matHeaderCellDef>contact</th>
        <td mat-cell *matCellDef="let x">{{x.agency_contact}} </td>
      </ng-container>
  
      <ng-container matColumnDef="who">
        <th mat-header-cell *matHeaderCellDef>Who?</th>
        <td mat-cell *matCellDef="let x"><img [src]="x.model_hash" height="40px" /></td>
      </ng-container>
  
      <!-- Name Column -->
      <ng-container matColumnDef="what">
        <th mat-header-cell *matHeaderCellDef>What?</th>
        <td mat-cell *matCellDef="let x">{{x.log_type}} </td>
      </ng-container>
  
      <!-- Name Column -->
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>Date</th>
        <td mat-cell *matCellDef="let x">{{x.date}} </td>
      </ng-container>
  
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns ; sticky : true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  
  
  </div>
  
  </div>
  
  <div style="display: flex; flex-direction: column; justify-content: center; align-items: center; margin-top: 20px" *ngIf="!logs">
    <div>
      <img src="../../../assets/box.png" style="height: 50px" />
    </div>
    <div style="text-align: center;">
      No Log
    </div>
  </div>
  