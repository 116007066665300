

<div class="grid-stats-ctn">
  <div class="grid-stats-ctn-title">Booking</div>
  <div class="grid-stats-flex-row">

    <div class="grid-box-ctn" *ngFor="let x of statsA">
        <div class="grid-box" style="background-color: #554b4b;">
          <div class="grid-box-value">{{ x.chiffre }}</div>
          <div class="grid-box-label">{{ x.label }}</div>
        </div>
      </div>
    </div>
    <div class="grid-stats-flex-row">
    <div class="grid-box-ctn" *ngFor="let x of statsB">
      <div class="grid-box" [style.backgroundColor]="x.color">
        <div class="grid-box-value">{{ x.chiffre }}</div>
        <div class="grid-box-label">{{ x.label }}</div>
      </div>
      <div class="percentFlex">
        <div *ngIf="x.percent < 0" class="triangleRougeNegatif"></div>
        <div *ngIf="x.percent > 0" class="triangleVertPositif"></div>
        <div class="grid-box-figure">{{ x.percent }} <span>%</span></div>
      </div>
    
    </div>

  </div>
</div>
