import { Component, Input, OnInit } from '@angular/core';
import { HttpService } from '../../../http.service';


@Component({
  selector: 'app-placement-stats',
  templateUrl: './placement-stats.component.html',
  styleUrls: ['./placement-stats.component.scss']
})
export class PlacementStatsComponent implements OnInit {


  spinnerProgress: boolean;

 @Input() stats: any;

  constructor(
    private get: HttpService,
  ) { }

  async ngOnInit(){
  
  }




}
