import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { FiltermodalComponent } from './filtermodal/filtermodal.component';


@Component({
  selector: 'app-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss']
})
export class DateFilterComponent implements OnInit {

  @Output() newItemEvent = new EventEmitter<string>();

  constructor(
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
  }

  openFilter(){
    const dialogRef = this.dialog.open(FiltermodalComponent, {  
      width: 'auto',
      height: 'auto'
     });

     dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if(result && result.status == "set"){
        this.newItemEvent.emit(result);
      }
    })
  }

}
