

<form [formGroup]="myGroup"  style="width:80%; margin-left:50%; transform: translate(-50%, 0);padding:5px;">


    <mat-form-field style="display:block" >
        <input matInput [matDatepicker]="picker" placeholder="start date" formControlName="startdate">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <mat-form-field style="display:block">
        <input matInput [matDatepicker]="picker2" placeholder="end date" formControlName="enddate">
        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </mat-form-field>


</form>

<div class="bTnPannelForMobile">
    <button mat-button (click)="close()">Cancel</button>
    <button mat-button cdkFocusInitial (click)="okClick()">Ok</button>
  </div>

