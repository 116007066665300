<div class="factureGrid">
    <div class="submenu" id="submenu" >

        <div class="sideNavClick" (click)="toggleMobileMenu()">
            <div class="sideNavClickInner">
              <div class="centred">
                <div class="navTrigger" id="navTriggerFacture">
                    <i></i><i></i><i></i>
                  </div>
              </div>
            </div>
          </div>

        <div class="menuInner" >
            <div>
    
                <div class="menuLibele">
                    <div  (click)="goFactures('all')" class="Weight">
                     <div class="btnTitle">
                        All Factures
                   
                     </div>
                    </div>
                    <div class="subLibele" (click)="goFacTodo()">
                        <div class="subLibeleTitle">
                            Todo
                            <div class="badge" *ngIf="facturetodo > 0">{{facturetodo}}</div>
                        </div>
                        
                    
                    </div>
    
                    <div class="subLibele" (click)="goFactures('agency')">
                        Agency
                    </div>
                    <div class="subLibele" (click)="goFactures('clients')">
                        Direct Clients
                    </div>
                    <!--
   <div class="subLibele" (click)="goBoa()">
                       B.O.A
                    </div>
                    -->
                 
                 </div>
                                                                                                                                                                           
                 <div class="menuLibele">
                    <div  (click)="goPayments('all')" class="Weight">
                     <div class="btnTitle">
                        Payments
                    
                    </div> 
                 
                    </div>
                    <div class="subLibele" (click)="goPayments('todo')">
                        <div class="subLibeleTitle">
                        To Do
                        <div class="badge" *ngIf="paymenttodo">{{paymenttodo}}</div>
                    </div>
                    </div>
                    <div class="subLibele" (click)="goPayments('past')">
                        Past
                    </div>
                    <div class="subLibele" (click)="goFDP()">
                        Fiche de Paie
                    </div>
                 </div>
    
                <div class="menuLibele">
                   <div  (click)="goRepresentation('all')" class="Weight">
                    All Representations
                   </div>
                   <div class="subLibele" (click)="goRepresentation('actual')">
                       Actual
                   </div>
                   <div class="subLibele" (click)="goRepresentation('past')">
                       Past
                   </div>
                </div>
    
                <div class="menuLibele removeOnMobile">
                    <div class="Weight">
                        Statistics
                    </div>
                    <div class="subLibele" (click)="goStats()">
                        by Model
                    </div>
                    <div class="subLibele" (click)="goRepartition()">
                        by Month
                    </div>
                 </div>
                
                
                
                 <div (click)="goFrais()" class="menuLibele">
                    Frais
                 </div>
    
    
    
            </div>
    
            <div>
                <div (click)="goContrats()" class="menuLibeleBottom">
                    Contrats
                 </div>
            </div>
    
        </div>
    
    
    
    </div>
    
    <div class="facturePannel" >
        <router-outlet> 
        </router-outlet>
    </div>
</div>
