import { Component, OnInit, Input } from '@angular/core';
import {MatDialog } from '@angular/material/dialog';
import { HttpService } from '../../http.service';
import { AddfichedepaieComponent } from './addfichedepaie/addfichedepaie.component';

import { EditFactureComponent } from '../../facturesystem/allfactures/edit-facture/edit-facture.component';
import { EmailcompComponent } from '../../emailcomp/emailcomp.component';
import * as moment from 'moment';

@Component({
  selector: 'app-fichedepaie',
  templateUrl: './fichedepaie.component.html',
  styleUrls: ['./fichedepaie.component.scss']
})
export class FichedepaieComponent implements OnInit {

  @Input() hash: string;
  docs : any;
  displayedColumns: string[] = ['libelle', 'brutEmployeur',  'netMannequin', 'paid_on', 'Action'];
  status : string;
  loader : boolean;

  expandedElement: any;

  constructor(
    public dialog: MatDialog,
    private http :HttpService
  ) {
    this.status = "nok";
    this.loader = false;
    this.expandedElement = null;
   }

  ngOnInit(): void {
    this.getDocs();
  }

  addDocs(){
    let dialogRef = this.dialog.open(AddfichedepaieComponent, {
      //height: '100%',
     
      data: { modal : this.hash  }
    });
    dialogRef.afterClosed().subscribe(result => {
    //  console.log('The dialog was closed');
      console.log('result', result);
     if(result == 'close'){
      this.getDocs();
     }
    });
  }

  async getDocs(){
    this.loader = true;
    var funcE = { type: 'FDP', func: 'getOneModelFDP' };   
    let dataE = { 'modal' : this.hash };   
    var Postdata = { func: funcE, data : dataE };

    console.log('on click', Postdata);
    let res = await this.http.goAcess(Postdata);
    this.loader = false;
    console.log('on click', res);
    this.docs = res;
    if(res !=="noresult"){
      this.status = "ok";
    }
    
  }

  downloadfile(url){
    window.open(url);
  }

  setDate(facid){
    const dialogRef = this.dialog.open(EditFactureComponent, {  
      width: '350px', 
      data: { text : "Date de Paiement"  }
     });

     dialogRef.afterClosed().subscribe(result => {
      if(result){
        if(result.status == 'set'){
         this.setDatePaiement(facid, result.date);
        }
      }

    }) 
  }

  async setDatePaiement(facid, date){
    this.loader = true;
    var funcE = { type: 'BDD', func: 'updateValueinTable' };  

    let valuesE = [{'label': "paid_on", "value": date}]; 
    let criteriaE = [{'label': "id", "value": facid }]; 

    let dataE = { 'table' : 'modal_fdp', 'values' : valuesE, 'criteria' : criteriaE };   
    var Postdata = { func: funcE, data : dataE };

    console.log('on click', Postdata);
    let res = await this.http.goAcess(Postdata);
   this.loader = false;
    this.getDocs(); 
    console.log('on click', res);
  }

  getDate(date){
   let newdate = moment(date).format('DD/MM/YYYY');; 
   return newdate;
  }

  async delFile(facid){
    this.loader = true;
    var funcE = { type: 'DOCS', func: 'deleteDoc' };  
    let dataE = { 'type' : 'fdp', 'fileid' : facid };   
    var Postdata = { func: funcE, data : dataE };

    console.log('on click', Postdata);
    let res = await this.http.goAcess(Postdata);
    this.loader = false;
    this.getDocs(); 
    console.log('on click', res);
  }

  sendMail(facid){
    
    const dialogRef = this.dialog.open(EmailcompComponent, {  
      data: { email : "Date de Paiement", "type" : "fdp", "factureid" : facid  }
     });

     dialogRef.afterClosed().subscribe(result => {


  })
}

}
