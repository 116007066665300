<div class="sliderClass" *ngIf="sliderClass">

  <div class="modalCloseBtn" (click)="onNoClick()">
    <div class="centred" style="color:white">X</div>
  </div>

  <div>

    <div [formGroup]="myGroup" class="paddingTopOnMobile">

      <!--  <mat-form-field class="example-full-width" >
          <input matInput placeholder="Ask for" value="" formControlName="who" required >
        </mat-form-field>-->

      <ng-container *ngIf="selected !== 'other' ">
        <div *ngIf="!meetingType" style="text-align: center;">
          (please select event type)
        </div>
        <div style="display: flex; flex-direction: row; justify-content: center; flex-wrap: wrap;">
        
          <div class="flexItem " (click)="meetingType = 'casting'; createCustomForm() " [ngClass]="{'flexItem__Selected': meetingType === 'casting'}">Casting</div>
          <div class="flexItem" (click)="meetingType = 'option' ; createCustomForm() " [ngClass]="{'flexItem__Selected': meetingType === 'option'}">Option</div>
          <div class="flexItem" (click)="meetingType = 'castbookings' ; createCustomForm() " [ngClass]="{'flexItem__Selected': meetingType === 'castbookings'}">Booking</div>
          <div class="flexItem" (click)="meetingType = 'testshoot' ; createCustomForm() " [ngClass]="{'flexItem__Selected': meetingType === 'testshoot'}">Test Shoot</div>
          <div class="flexItem" (click)="meetingType = 'divers'; createCustomForm() " [ngClass]="{'flexItem__Selected': meetingType === 'divers'}">Divers</div>
          <div class="flexItem" (click)="meetingType = 'agencymeeting'; createCustomForm() " [ngClass]="{'flexItem__Selected': meetingType === 'agencymeeting'}">Agency Appointment</div>

        </div>


      <div style="display: flex; flex-direction: row; justify-content: center; margin-top: 20px; flex-wrap: wrap;">
        <button class="BtnLongOnMobile" mat-stroked-button (click)="goSelectModal()" >Select Model</button>
        <button class="BtnLongOnMobile" mat-stroked-button (click)="chooseAgency('agency')" >Select Client/Photographer/Agence</button>
        <button class="BtnLongOnMobile" mat-stroked-button (click)="joinDocs()">Join Docs</button>

      </div>

        <mat-form-field class="example-full-width" style="margin-top: 20px;">
          <input type="text" placeholder="Ask for" aria-label="Number" matInput formControlName="who"
            [matAutocomplete]="auto" required>
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let x of contactoption" [value]="x.contact">
              {{x.contact}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <input matInput placeholder="Phone Number" value="" formControlName="phone" [matAutocomplete]="auto2">
          <mat-autocomplete #auto2="matAutocomplete">
            <mat-option *ngFor="let x of teloption" [value]="x">
              {{x}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>



        <mat-form-field class="example-full-width">
          <input matInput placeholder="Adress" formControlName="where" required>
        </mat-form-field>



      </ng-container>


      <mat-form-field class="example-full-width">
        <input type="text" placeholder="More Details" aria-label="Number" matInput formControlName="more"
          [matAutocomplete]="auto1">
        <mat-autocomplete #auto1="matAutocomplete">
          <mat-option *ngFor="let x of moredetails" [value]="x">
            {{x}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>




      <mat-form-field class=" example-full-half" style="margin-right: 10px;">
        <input matInput [selectMode]="'rangeFrom'" [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" placeholder="FROM ?"
        formControlName="from" [(ngModel)]="fromDate"  required>
        <owl-date-time #dt1 [firstDayOfWeek]="1"></owl-date-time>
      </mat-form-field>



      <mat-form-field class=" example-full-half">
        <input matInput [selectMode]="'rangeTo'" [owlDateTime]="dt2" [owlDateTimeTrigger]="dt2" placeholder="TO ?"
          formControlName="to" [(ngModel)]="toDate">
        <owl-date-time #dt2 [firstDayOfWeek]="1"></owl-date-time>
      </mat-form-field>


      <div style="display: flex; flex-direction : row; justify-content : center; position: relative; max-width: 100%; flex-wrap: wrap;" *ngIf="t && t.length > 0">
        <div [formGroup]="x"
          style="border: 1px solid black; position: relative; margin-right: 10px;display: flex; flex-direction: column; justify-content: center;padding: 5px; align-items: center; margin-bottom: 5px;"
          *ngFor="let x of t; let i = index">

          <div class="avatarFace" [style.backgroundImage]="getImage(x.avatar)" style="width: 80px; height: 80px;">
            <div class="avatarName">{{x.first}}</div>
          </div>

          <div style="position: absolute; top: 0; right: 0; 
          cursor: pointer; color: white; width: 20px; height: 20px; 
          background-color: black;
          "
          (click)="deleteModalFromArray(i)"
          >
        <div style="position: relative; width: 100%; height: 100%; position: relative;">
        <div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">
          <i  class="material-icons" style="font-size: 15px;">
            delete_outline
          </i>
        </div>
        </div>  
        </div>
          
          <div *ngIf="meetingType == 'castbookings' " style="display: flex; flex-direction: column;">

            <mat-form-field>
              <input matInput placeholder="Facturation" formControlName="facturation">
            </mat-form-field>
            <mat-form-field>
              <input matInput placeholder="In Pocket" formControlName="inpocket">
            </mat-form-field>
          </div>


          <label>Send a Booking Request ?</label>
          <mat-radio-group aria-label="Send an Email booking request ?" formControlName="bookingrequest"
            style="margin-left: 10px;">
            <mat-radio-button value="send">Yes</mat-radio-button>
            <mat-radio-button value="nosend">No</mat-radio-button>
          </mat-radio-group>

          <label>Send a SMS Booking Request ?</label>
          <mat-radio-group aria-label="Send a SMS booking request ?" formControlName="smsbookingrequest"
          style="margin-left: 10px;">
          <mat-radio-button value="send">Yes</mat-radio-button>
          <mat-radio-button value="nosend">No</mat-radio-button>
        </mat-radio-group>

        </div>
      </div>



    </div>

    <div>



      <div style="position: relative;margin-top: 50px;
      margin-bottom: 20px;
      padding-bottom: 20px;">
        <div class="bTnStandard  centred" (click)="lastBook2()">{{bookstate}} </div>
      </div>


    </div>
  


  </div>



</div>