
 <ul class="w3-ul " style="overflow-y: auto; height: auto; ">
      <li class="w3-bar bar-hover" style="cursor:pointer;z-index: 10; position:relative;border-bottom : 1px solid #ddd">
       

      <img [src]="profile?.avatar" class="w3-bar-item w3-circle w3-hide-small" style="width:85px">
        <div class="w3-bar-item" *ngIf="profile" >
         <span class="w3-large">{{profile.first}} {{profile.last}}</span><br>
          <span>Last connect :{{profile.lastconnect}}</span>
        </div>
      </li>

    </ul>

    <ul class="w3-ul" style="margin-top:20px">
      <li style="cursor:pointer;" (click)="goModel()">
          <img src="assets/user.png" class="w3-bar-item w3-circle w3-hide-small" style="width:32px;">
          <span style="margin-left: 10px">
              See Profil
          </span>   
      </li>
      <li style="cursor:pointer;" (click)="setMeeting()">
        <img src="assets/meeting.png" class="w3-bar-item w3-circle w3-hide-small" style="width:32px;">
        <span style="margin-left: 10px">
            Set Meeting
        </span>   
    </li>
    </ul>
