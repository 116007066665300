<div [formGroup]="chgStateGroup" style="margin-top:15px;">



    <mat-form-field>

        <mat-select placeholder="Type de Fichier" formControlName="type">
            <mat-option *ngFor="let x of doctypes" [value]="x.id">
                {{x.type}}
            </mat-option>
        </mat-select>
    </mat-form-field>




    <mat-form-field class="input">
        <input matInput placeholder="Nom du fichier" formControlName="name">
    </mat-form-field>



    <div style="margin-top:15px;margin-bottom: 15Px;">
        <button *ngIf="file == null" mat-stroked-button  color="primary" (click)="uploadFile()">Add Files</button>
        <button *ngIf="file !== null" mat-stroked-button  color="primary" (click)="sendFile()">Send</button>
        <input type="file" id="file1" style="display:none" (change)="upload($event.target.files)">
    </div>

  
</div>