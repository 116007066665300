

  <mat-tab-group>
      
      <mat-tab [label]="x.label" *ngFor="let x of pushmenu">
            <ng-template matTabContent>
<app-push [model]="x.model"></app-push>
</ng-template>
</mat-tab>

</mat-tab-group>


