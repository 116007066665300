import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import * as JsEncryptModule from 'jsencrypt';
import { element } from 'protractor';

@Injectable({
  providedIn: 'root'
})
export class HttpService { 

token: string;

httpTask : any;
isWorking : boolean;

  constructor(
    private http: HttpClient

  ) 
  { 
   this.httpTask = [];
  } 

  deCryptData(data){
    var encrypt = new JsEncryptModule.JSEncrypt();
    var myPrivateKey =  localStorage.getItem('myPrivateKey');

    encrypt.setKey(myPrivateKey);

    var a = data.split(':');
    var val = "";

    for (let i = 0; i < a.length - 1; i++) {
        val += encrypt.decrypt(a[i]);
    }
    return JSON.parse(val);
  }

  CryptData(data) {

    var encrypt = new JsEncryptModule.JSEncrypt();
    var publicKey =  localStorage.getItem('backPublicKey');
    encrypt.setKey(publicKey);

    var dataCrypted = "";
    var pos = 0;
        while (pos < data.length) {
            dataCrypted += encrypt.encrypt(data.slice(pos, Math.min(pos + 100, data.length)));
            dataCrypted += ":";
            pos += 100;
        }
        return dataCrypted; 
    }

    goBackOffice(elem, url = null){

  
      if(url == null){
        url = this.getBaseUrl('agencyMgmt/test.php');
      } else {
        url = this.getBaseUrl(url);
      }
    
      elem.token = localStorage.getItem('token');
      //elem = this.CryptData(elem);
     
     // elem.sessionToken = localStorage.getItem('sessionToken');
      
      console.log('tosend', elem, 'tourl', url)
      return this.http.post(url, elem);
    }

  
    async goAcess(postData, url = null){
      console.log('postData', postData, 'url', url);
      this.httpTask.push( {'postData' : postData, 'url' : url} );
      console.log('http task', this.httpTask);
      let res = await this._goAcess();
      console.log('res', res);
      return res;
    }

    async _goAcess(){
      let data = this.httpTask[0];
      console.log('_goAccess',data);
      let res = await this.goExecute(data.postData, data.url);
      return res;
    }

    async goExecute(postData, urlE = null){
      this.httpTask.shift();
      let url;

      if( (postData.func !== 'startSession') && (postData.func !== 'endSession' ) ){
        postData = this.CryptData(JSON.stringify(postData));
        postData = {  'data' : postData, 'sessionToken' : localStorage.getItem('sessionToken') };  
      } 

      if(!urlE){
        url = 'https://bmyscouter.co/db/clients/access.php';
      } else {
        url = 'https://bmyscouter.co/db/clients/' + urlE;
      }
  
      console.log('postdata', postData);
     
      const requestOptions = {
        method: 'POST',
        headers: {  'Content-Type': 'application/json;charset=UTF-8' },
        body: JSON.stringify(postData)
    };
  
    let res = await fetch( url, requestOptions)
    .then(response =>  response.json())
    .then(data => {
        return data;
    });
    
    if( (postData.func !== 'startSession') && (postData.func !== 'endSession' ) ){
      return this.deCryptData(res);
    } else {
      return res;
    }

    }
    


/*
    async goAcess(postData, urlE = null){
   
      let url;

      if( (postData.func !== 'startSession') && (postData.func !== 'endSession' ) ){
        postData = this.CryptData(JSON.stringify(postData));
        postData = {  'data' : postData, 'sessionToken' : localStorage.getItem('sessionToken') };  
      } 

      if(!urlE){
        url = 'https://bmyscouter.co/db/clients/access.php';
      } else {
        url = 'https://bmyscouter.co/db/clients/' + urlE;
      }
  
      console.log('postdata', postData);
     
      const requestOptions = {
        method: 'POST',
        headers: {  'Content-Type': 'application/json;charset=UTF-8' },
        body: JSON.stringify(postData)
    };
  
    let res = await fetch( url, requestOptions)
    .then(response =>  response.json())
    .then(data => {
        return data;
    });
    
    if( (postData.func !== 'startSession') && (postData.func !== 'endSession' ) ){
      return this.deCryptData(res);
    } else {
      return res;
    }

    }
*/

  getModel(type, genre){
 
    let postData = new FormData();
    postData.append('type', type);
    postData.append('genre', genre);
    postData.append('token', localStorage.getItem('token'));

  //  console.log('token envoyé', localStorage.getItem('token'));
    let url = 'https://bmyscouter.co/db/clients/getModel.php';
    return this.http.post(url, postData);
  }

  getNews(elem){
    let postData = new FormData();
    postData.append('category', elem);
    postData.append('token', localStorage.getItem('token'));
    let url = 'https://bmyscouter.co/db/clients/getAllNews.php';
    return this.http.post(url, postData);
  }

  seeModel(elem){
  
    let postData = new FormData();
    postData.append('hash', elem);
    postData.append('token', localStorage.getItem('token'));
    let url = 'https://bmyscouter.co/db/clients/getOneModel.php';
    return this.http.post(url, postData)
  }
 

  getPics(elem){
   
    let postData = new FormData();
    postData.append('hash', elem);
    postData.append('token', localStorage.getItem('token'));
    let url = 'https://bmyscouter.co/db/clients/getOneModelPics.php';
    return this.http.post(url, postData);
  }

  getVideo(elem){
   
    let postData = new FormData();
    postData.append('hash', elem);
    postData.append('token', localStorage.getItem('token'));
    let url = 'https://bmyscouter.co/db/clients/getOneVideo.php';
    return this.http.post(url, postData);
  }

   

  putNews(elem){
  console.log(elem);
    let url = 'https://bmyscouter.co/db/clients/putNews.php';
    return this.http.post(url, elem);
  }

  delNews(elem){
    let url = 'https://bmyscouter.co/db/clients/delNews.php';
     return this.http.post(url, elem);
  }

  readNews(elem){
    let url = 'https://bmyscouter.co/db/clients/getNews.php';
    return this.http.post(url, elem);
  }

  getMess( from: string, to : string){

    let postData = new FormData();
    postData.append('from', from);
    postData.append('to', to);
    postData.append('token', localStorage.getItem('token'));
    let url = 'https://bmyscouter.co/db/clients/getNews.php';
    return this.http.post(url, postData);
  }




  
  publish(state: string, eventid: string){
    console.log(state, eventid);
    let postData = new FormData();
    postData.append('eventid', eventid);
    postData.append('publish', state);
    let url = 'https://bmyscouter.co/db/clients/publishEvent.php';
    return this.http.post(url, postData);
  }

  getCustomMess(type: string, model: string){
    let postData = new FormData();
    postData.append('validation', type);
    postData.append('model', model);
    let url = 'https://bmyscouter.co/db/clients/getCustomMess.php';
    return this.http.post(url, postData);
  }

  sendAppMess(all){
    console.log(all);



    let url = 'https://bmyscouter.co/db/clients/SendAppMess.php';
   return this.http.post(url, all);
}

sendMess(all){
  let url = 'https://bmyscouter.co/db/clients/SendMessage.php';
  return this.http.post(url, all);
}

sendCustomMess(all){
  let url = 'https://bmyscouter.co/db/clients/sendCustomMess.php';
 return this.http.post(url, all);
}

getMailConv(postData){

let url = 'https://bmyscouter.co/db/clients/getMailConv.php';
return this.http.post(url, postData);
}

getAllConvers(type: string, hash){

  let postData = new FormData();
 // postData.append('hash', uid);
  postData.append('type', type);
  postData.append('modelhash', hash);
  postData.append('token', localStorage.getItem('token'));

  let url = 'https://bmyscouter.co/db/clients/getAllConvers.php';
  return this.http.post(url, postData);

}

goArchive(convers, action){
  let postData = new FormData();
  postData.append('nbconv', convers);
  postData.append('action', action);
  let url = 'https://bmyscouter.co/db/clients/updateConv.php';
  return this.http.post(url, postData);
}

changeValue(input){
  let url = 'https://bmyscouter.co/db/clients/changeValue.php';
  return this.http.post(url, input);
}

SendLike(input){
  let postData = new FormData();
  postData.append('hash', input);
  postData.append('token', localStorage.getItem('token'));

  let url = 'https://bmyscouter.co/db/clients/sendLike.php';
  return this.http.post(url, postData);
}

deleteModal(input){
  let postData = new FormData();
  postData.append('hash', input);
  postData.append('token', localStorage.getItem('token'));

  let url = 'https://bmyscouter.co/db/clients/deleteModal.php';
  return this.http.post(url, postData);
}

download(modelhash, action){
  let postData = new FormData();
  postData.append('model', modelhash);
  postData.append('action', action);
  postData.append('token', localStorage.getItem('token'));

  let url = 'https://seemodels.co/download/createDownload.php';
  return this.http.post(url, postData);
}


generatePDF(input){
  let postData = new FormData();
  postData.append('hash', input);
  postData.append('user', 'rbo');

  let url = 'https://bmyscouter.co/db/clients/generatePDF.php';
  return this.http.post(url, postData);
}

/*
getSpecs(modal, type){
  let postData = new FormData();
  postData.append('hash', modal);
  postData.append('type', type);
  postData.append('token', localStorage.getItem('token'));
  let url = 'https://bmyscouter.co/db/clients/getSpecs.php';
  return this.http.post(url, postData);
}*/

getModelLog(modal){
  let postData = new FormData();
  postData.append('modal', modal);
  postData.append('token', localStorage.getItem('token'));
  let url = 'https://bmyscouter.co/db/clients/getSpecs.php';
  return this.http.post(url, postData);
}



toggleVisibilty(elem,elem1){
  let postData = new FormData();
  postData.append('hash', elem);
  postData.append('visibility', elem1);


  postData.append('token', localStorage.getItem('token'));
  let url = 'https://bmyscouter.co/db/clients/toggleVisibilty.php';
  return this.http.post(url, postData);
}

toggleFavorites(model, favorite){
  let postData = new FormData();
  postData.append('hash', model);
  postData.append('favorite', favorite);

  postData.append('token', localStorage.getItem('token'));
  let url = 'https://bmyscouter.co/db/clients/toggleFavorite.php';
  return this.http.post(url, postData);
}

getVisibility(hash){
  let postData = new FormData();
  postData.append('hash', hash);
  postData.append('token', localStorage.getItem('token'));
  let url = 'https://bmyscouter.co/db/clients/getVisibility.php';
  return this.http.post(url, postData);
}

getStats(hash){
  let postData = new FormData();
  postData.append('hash', hash);
  postData.append('token', localStorage.getItem('token'));
  let url = 'https://bmyscouter.co/db/clients/getStats.php';
  return this.http.post(url, postData);
}

sendEmail(all){
  let url = 'https://bmyscouter.co/db/clients/sendEmail.php';
  return this.http.post(url, all);
}

/*
getAgency(location, type){
  let postData = new FormData();
  postData.append('token', localStorage.getItem('token'));
  postData.append('location', location);
  postData.append('type', type);
  let url = 'https://bmyscouter.co/db/clients/agencyMgmt/getAgency.php';
  return this.http.post(url, postData);
}*/
/*
getCriteria(hash){
  let postData = new FormData();
  postData.append('hash', hash);
  postData.append('token', localStorage.getItem('token'));
  let url = 'https://bmyscouter.co/db/clients/agencyMgmt/getCriteria.php';
 // let url = 'https://boulanger.ovh/romain/seemodels/zzz.php';
  return this.http.post(url, postData);
}*/
/*
addAgency(elem){
  console.log(elem);
    let url = 'https://bmyscouter.co/db/clients/agencyMgmt/test.php';
    return this.http.post(url, elem);
  }*/

  getBaseUrl(url){
    let baseUrl = 'https://bmyscouter.co/db/clients/';
    let res = baseUrl + url;
    return res;
  }



  
  goBackOffice2(elem, url){  
    elem.token = localStorage.getItem('token');
    console.log('tosend', elem, 'tourl', url)
    return this.http.post(url, elem);
  }



  updateLogo(agencyhash,logo){
    let postData = new FormData();
    postData.append('logo', logo);
    postData.append('agencyhash', agencyhash);
    postData.append('action', 'updatelogo');
    postData.append('token', localStorage.getItem('token'));

    let url = 'https://bmyscouter.co/db/clients/agencyMgmt/addAgency.php';
    return this.http.post(url, postData);
  }

  getAgencyDetails(hash){
    let postData = new FormData();
    postData.append('hash', hash);
    postData.append('token', localStorage.getItem('token'));
    let url = 'https://bmyscouter.co/db/clients/agencyMgmt/getAgencyDetails.php';
    return this.http.post(url, postData);
  }

  changeSliderValue(input){
    let url = 'https://bmyscouter.co/db/clients/agencyMgmt/changeValue.php';
    return this.http.post(url, input);
  }

  getSuggested(type, agence, gender){
 
    let postData = new FormData();
    postData.append('type', type);
    postData.append('hash', agence);
    postData.append('gender', gender);
    postData.append('token', localStorage.getItem('token'));

    let url = 'https://bmyscouter.co/db/clients/agencyMgmt/getSuggested.php';
    return this.http.post(url, postData);
  }


  delLog(logid){
    let postData = new FormData();
    postData.append('token', localStorage.getItem('token'));
    postData.append('logid', logid);
    let url = 'https://bmyscouter.co/db/clients/delLog.php';
    return this.http.post(url, postData);
  }

  getFaq(){
    let postData = new FormData();
    postData.append('token', localStorage.getItem('token'));
    let url = 'https://bmyscouter.co/db/clients/getFaq.php';
    return this.http.post(url, postData);
  }

  addFaq(category, question, answer){
    let postData = new FormData();
    postData.append('category', category);
    postData.append('question', question);
    postData.append('answer', answer);
    postData.append('action', 'add');
    postData.append('token', localStorage.getItem('token'));

    let url = 'https://bmyscouter.co/db/clients/addDelFaq.php';
    return this.http.post(url, postData);
  }

  delFaq(faqid){
    let postData = new FormData();
    postData.append('faqid', faqid);
    postData.append('action', 'del');
    postData.append('token', localStorage.getItem('token'));

    let url = 'https://bmyscouter.co/db/clients/addDelFaq.php';
    return this.http.post(url, postData);
  }

  getFaqContact(faqid){
    let postData = new FormData();
    postData.append('faqid', faqid);
    postData.append('action', 'get');
    postData.append('token', localStorage.getItem('token'));

    let url = 'https://bmyscouter.co/db/clients/addDelFaq.php';
    return this.http.post(url, postData);
  }

  updateFaq(category, question, answer, faqid){
    let postData = new FormData();
    postData.append('faqid', faqid);
    postData.append('action', 'update');
    postData.append('token', localStorage.getItem('token'));

    postData.append('category', category);
    postData.append('question', question);
    postData.append('answer', answer);

    let url = 'https://bmyscouter.co/db/clients/addDelFaq.php';
    return this.http.post(url, postData);
  }

  saveImage(model, pics, imagetoadd, albumid){

    let postData = new FormData();
    postData.append('model', model);
    postData.append('pics', pics);

    postData.append('desc', imagetoadd);
    postData.append('albumid', albumid);
    postData.append('token', localStorage.getItem('token'));

    let url = 'https://bmyscouter.co/db/clients/saveImage.php';
    return this.http.post(url, postData);
  }

  saveVideo(model, video){
    let postData = new FormData();
    postData.append('model', model);
    postData.append('movie', video);
    postData.append('desc', 'video');
    postData.append('token', localStorage.getItem('token'));

    let url = 'https://bmyscouter.co/db/clients/saveVideo.php';
    return this.http.post(url, postData);
  }

  getProfile(convers, me){
    let postData = new FormData();
    postData.append('convers', convers);
    postData.append('token', localStorage.getItem('token'));
    postData.append('me', me);
    let url = 'https://bmyscouter.co/db/clients/getProfile.php';
    return this.http.post(url, postData);
  }

  remMess(mailid){
    console.log(mailid);
    let postData = new FormData();
    postData.append('mailid', mailid);
    postData.append('token', localStorage.getItem('token'));

    let url = 'https://bmyscouter.co/db/clients/remMess.php';
    return this.http.post(url, postData);
  }

  getEmailContent(elem, language, country, calendar){
    let postData = new FormData();
    postData.append('token', localStorage.getItem('token'));
    postData.append('language', language);
    postData.append('country', country);
    postData.append('calendar', calendar);
  for (var i = 0; i < elem.length; i++) {
      postData.append('model[]', elem[i]);
  }

    let url = 'https://bmyscouter.co/db/clients/generateEmail.php';
    return this.http.post(url, postData);

  }

  sendAutomatedEmail(elem){
    let postData = new FormData();
    postData.append('token', localStorage.getItem('token'));
    
    let url = 'https://bmyscouter.co/db/clients/sendAutomatedEmail.php';
    return this.http.post(url, elem);
  }


  changeImageVisibility(elemid, visibility){
    let postData = new FormData();
    postData.append('imageid', elemid);
    postData.append('visibility', visibility);
    postData.append('token', localStorage.getItem('token'));

    postData.append('action', 'togglevisibility');

    let url = 'https://bmyscouter.co/db/clients/changeImageVisibility.php';
    return this.http.post(url, postData);
  }

  makeImageMain(imageid, modal){
    let postData = new FormData();
    postData.append('imageid', imageid);
    postData.append('modal', modal);
    postData.append('token', localStorage.getItem('token'));

    postData.append('action', 'makemain');

    let url = 'https://bmyscouter.co/db/clients/changeImageVisibility.php';
    return this.http.post(url, postData);
  }

  deleteImageGallery(imageid){
    let postData = new FormData();
    postData.append('imageid', imageid);
    postData.append('token', localStorage.getItem('token'));

    let url = 'https://bmyscouter.co/db/clients/deleteImageGallery.php';
    return this.http.post(url, postData);

  }

  getMenu(elem){
    let postData = new FormData();
    postData.append('token', localStorage.getItem('token'));
    postData.append('menu', elem);

    let url = 'https://bmyscouter.co/db/clients/getMenu.php';
    return this.http.post(url, postData);
  }

  selectCountry(type){
    let postData = new FormData();
    postData.append('token', localStorage.getItem('token'));
    postData.append('type', type);

    let url = 'https://bmyscouter.co/db/clients/selectCountry.php';
    return this.http.post(url, postData);
  }

  prepareFormAgency(elem){
    console.log(elem);
    let postData = new FormData();
    postData.append('token', localStorage.getItem('token'));
    postData.append('hash', elem);

    let url = 'https://bmyscouter.co/db/clients/prepareFormAgency.php';
    return this.http.post(url, postData);
  }

  getPublicProfile(model, type){
    let postData = new FormData();
    postData.append('token', localStorage.getItem('token'));
    postData.append('model', model);
    postData.append('type', type);
    postData.append('agency', 'seemo');

    let url = 'https://bmyscouter.co/db/clients/pp/getSelectedProfile.php';
    return this.http.post(url, postData);
  }



  checkSendedMail(agency, model){
    console.log('agency', agency, 'model', model);
    let postData = new FormData();
    
    for (var i = 0; i < agency.length; i++) {
      postData.append('agency[]', agency[i]);
  }

  for (var i = 0; i < model.length; i++) {
    postData.append('model[]', model[i]);
}

  postData.append('token', localStorage.getItem('token'));
  let url = 'https://bmyscouter.co/db/clients/checkSendedMail.php';
  return this.http.post(url, postData);
  }

  addFrais(typefrais,observation,montantht,montantttc, date, societe, justif)
  {
    let postData = new FormData();
    
    postData.append('typefrais', typefrais);
    postData.append('observation', observation);
    postData.append('montantht', montantht);
    postData.append('montantttc', montantttc);
    postData.append('justif', justif);
    postData.append('date', date);
    postData.append('societe', societe);

    postData.append('action', 'add');
    postData.append('token', localStorage.getItem('token'));

    let url = 'https://bmyscouter.co/db/clients/frais/mgmt_frais.php';
    return this.http.post(url, postData);
  }

  editFrais(typefrais, observations, montantht, montantttc, date, societe, id)

  {
    let postData = new FormData();
    
    postData.append('typefrais', typefrais);
    postData.append('observation', observations);
    postData.append('montantht', montantht);
    postData.append('montantttc', montantttc);
    postData.append('date', date);
    postData.append('id', id);
    postData.append('societe', societe);

    postData.append('action', 'update');
    postData.append('token', localStorage.getItem('token'));

    let url = 'https://bmyscouter.co/db/clients/frais/mgmt_frais.php';
    return this.http.post(url, postData);
  }

  getFrais(societe, year)
  {
    let postData = new FormData();
    postData.append('action', 'get');
    postData.append('societe', societe);
    postData.append('year', year);
  
    postData.append('token', localStorage.getItem('token'));

    let url = 'https://bmyscouter.co/db/clients/frais/mgmt_frais.php';
    return this.http.post(url, postData);
  }

  delFrais(id){
    let postData = new FormData();
    postData.append('action', 'del');
    postData.append('id', id);
    postData.append('token', localStorage.getItem('token'));

    let url = 'https://bmyscouter.co/db/clients/frais/mgmt_frais.php';
    return this.http.post(url, postData);
  }

  getFraisContent(id){
    let postData = new FormData();
    postData.append('action', 'edit');
    postData.append('id', id);
    postData.append('token', localStorage.getItem('token'));

    let url = 'https://bmyscouter.co/db/clients/frais/mgmt_frais.php';
    return this.http.post(url, postData);
  }

  getListCollab(){
    let postData = new FormData();
    postData.append('token', localStorage.getItem('token'));
   
    postData.append('action', 'getlist');

    let url = 'https://bmyscouter.co/db/clients/collaboration/mgmt_collaboration.php';
    return this.http.post(url, postData);
  }

  getMyRepresentation(model){
    let postData = new FormData();
    postData.append('token', localStorage.getItem('token'));
    postData.append('action', 'getmyrepresentation');
    postData.append('model', model);

    let url = 'https://bmyscouter.co/db/clients/collaboration/mgmt_collaboration.php';
    return this.http.post(url, postData);
  }

  setcollabend(colabid, enddatate){
    let postData = new FormData();
    postData.append('token', localStorage.getItem('token'));
    postData.append('action', 'setcollabend');
    postData.append('colabid', colabid);
    postData.append('enddate', enddatate);

    let url = 'https://bmyscouter.co/db/clients/collaboration/mgmt_collaboration.php';
    return this.http.post(url, postData);
  }

  addMac(model, datedebut, contract){

    let postData = new FormData();
    postData.append('token', localStorage.getItem('token'));
    postData.append('action', 'addmac');

    postData.append('mannequin', model);
    postData.append('contract', contract);
    postData.append('date', datedebut);

    let url = 'https://bmyscouter.co/db/clients/collaboration/mgmt_collaboration.php';
    return this.http.post(url, postData);
  }

  addCollab(mannequin,agence,date,agencycontract)
  {
    console.log('tosend', date);
    let postData = new FormData();
    postData.append('token', localStorage.getItem('token'));
    postData.append('action', 'addcollab');

    postData.append('mannequin', mannequin);
    postData.append('agence', agence);
    postData.append('date', date);
  //  postData.append('modelcontract', modelcontract);
    postData.append('contract', agencycontract);

    let url = 'https://bmyscouter.co/db/clients/collaboration/mgmt_collaboration.php';
    return this.http.post(url, postData);
  }

  addEd(mannequin,date,modelcontract)
  {
    console.log('tosend', date);
    let postData = new FormData();
    postData.append('token', localStorage.getItem('token'));
    postData.append('action', 'addcollab');

    postData.append('mannequin', mannequin);
    postData.append('date', date);
    postData.append('modelcontract', modelcontract);

    let url = 'https://bmyscouter.co/db/clients/collaboration/mgmt_collaboration.php';
    return this.http.post(url, postData);
  }

  getCollab(state)
  {
    let postData = new FormData();
    postData.append('token', localStorage.getItem('token'));
    postData.append('action', 'getcollab');
    postData.append('state', state);

    let url = 'https://bmyscouter.co/db/clients/collaboration/mgmt_collaboration.php';
    return this.http.post(url, postData);
  }

  getCompagnyInfo(country = 'fr')
  {
    let postData = new FormData();
    postData.append('token', localStorage.getItem('token'));
    postData.append('action', 'getinfos');

    postData.append('country', country);

    let url = 'https://bmyscouter.co/db/clients/entreprise/mgmt_compagny.php';
    return this.http.post(url, postData);
  }

  generateContractMAC(sexe, first, last, birth, nationality, modeladdress, compagny, address1, address2, siret){
    let postData = new FormData();
    postData.append('token', localStorage.getItem('token'));

    postData.append('sexe', sexe);
    postData.append('first', first);
    postData.append('last', last);
    postData.append('birth', birth);
    postData.append('nationality', nationality);
    postData.append('modeladdress', modeladdress);

    postData.append('compagny', compagny);
    postData.append('address1', address1);
    postData.append('address2', address2);
    postData.append('siret', siret);

    postData.append('action', 'generatemac');
    

    let url = 'https://bmyscouter.co/db/clients/contract/mgmt_contract.php';
    return this.http.post(url, postData);
  }

  generateContractMCA(first, last, agencyname, agencyaddress, representingcountry, representingcity, compagny, address1, address2, siret, language){
    let postData = new FormData();
    postData.append('token', localStorage.getItem('token'));
    postData.append('action', 'generatemca');

    postData.append('first', first);
    postData.append('last', last);

    postData.append('agencyname', agencyname);
    postData.append('agencyaddress', agencyaddress);

    postData.append('representingcountry', representingcountry);
    postData.append('representingcity', representingcity);


    postData.append('compagny', compagny);
    postData.append('address1', address1);
    postData.append('address2', address2);
    postData.append('siret', siret);

    postData.append('language', language);

    let url = 'https://bmyscouter.co/db/clients/contract/mgmt_contract.php';
    return this.http.post(url, postData);
  }

  saveOrder(items, model)
  {
      let postData = new FormData();
      postData.append('token', localStorage.getItem('token'));

      for (var i = 0; i < items.length; i++) {
        postData.append('items[]', items[i].id);
    }

      postData.append('model',model);
  
      postData.append('action', 'saveorder');
  
      let url = 'https://bmyscouter.co/db/clients/saveOrder.php';
      return this.http.post(url, postData);    
}

mgmtMeeting(model, agency, state)
{
  let postData = new FormData();
  postData.append('token', localStorage.getItem('token'));

  postData.append('state', state);
  postData.append('agency',agency);
  postData.append('model',model);

  let url = 'https://bmyscouter.co/db/clients/pp/mgmt_meeting.php';
  return this.http.post(url, postData);    
}

getModeltype(elem)
{
  let postData = new FormData();
  postData.append('token', localStorage.getItem('token'));
  postData.append('action',elem);

  let url = 'https://bmyscouter.co/db/clients/getModelType.php';
  return this.http.post(url, postData); 
}

addCategoryToModel(model, value, category){
  let postData = new FormData();
  postData.append('token', localStorage.getItem('token'));
  postData.append('action','addcategorytomodel');
  postData.append('value', value);
  postData.append('hash', model);
  postData.append('category', category);

  let url = 'https://bmyscouter.co/db/clients/getModelType.php';
  return this.http.post(url, postData); 
}

getCategoryModel(model, type){
  let postData = new FormData();
  postData.append('token', localStorage.getItem('token'));
  postData.append('action','getcategorymodel');
  postData.append('hash', model);
  postData.append('categorytype', type);

  let url = 'https://bmyscouter.co/db/clients/getModelType.php';
  return this.http.post(url, postData); 
}

saveCategoryOrder(category, categorytype){
  let postData = new FormData();
  postData.append('token', localStorage.getItem('token'));
  postData.append('action','savecategoryorder');

  for (var i = 0; i < category.length; i++) {
    postData.append('category[]', category[i]);
}
postData.append('categorytype', categorytype);

  let url = 'https://bmyscouter.co/db/clients/getModelType.php';
  return this.http.post(url, postData); 
}

deleteModalCategory(model, value, type){
  let postData = new FormData();
  postData.append('token', localStorage.getItem('token'));
  postData.append('action','delcategorytomodel');
  postData.append('value', value);
  postData.append('hash', model);
  postData.append('type', type);

  let url = 'https://bmyscouter.co/db/clients/getModelType.php';
  return this.http.post(url, postData); 
}

getCategoryNumber(category, categorytype){
  let postData = new FormData();
  postData.append('token', localStorage.getItem('token'));
  postData.append('action','getnumberincategory');
  postData.append('category', category);
  postData.append('categorytype', categorytype);


  let url = 'https://bmyscouter.co/db/clients/getModelType.php';
  return this.http.post(url, postData); 
}

deleteModelCategory(category){
  let postData = new FormData();
  postData.append('token', localStorage.getItem('token'));
  postData.append('action','deletecategory');
  postData.append('category', category);

  let url = 'https://bmyscouter.co/db/clients/getModelType.php';
  return this.http.post(url, postData); 
}

changeCategoryName(oldname, newname, categorytype){
  let postData = new FormData();
  postData.append('token', localStorage.getItem('token'));
  postData.append('action','renamecategory');
  postData.append('oldname', oldname);
  postData.append('newname', newname);
  postData.append('categorytype', categorytype);

  let url = 'https://bmyscouter.co/db/clients/getModelType.php';
  return this.http.post(url, postData); 
}

transfertToCategory(oldcategory, newcategory){
  let postData = new FormData();
  postData.append('token', localStorage.getItem('token'));
  postData.append('action','transfertcategory');
  postData.append('oldcategory', oldcategory);
  postData.append('newcategory', newcategory);

  let url = 'https://bmyscouter.co/db/clients/getModelType.php';
  return this.http.post(url, postData); 
}

createCatName(catname, categorytype){
  let postData = new FormData();
  postData.append('token', localStorage.getItem('token'));
  postData.append('action','createcategory');
  postData.append('catname', catname);
  postData.append('categorytype', categorytype);

  let url = 'https://bmyscouter.co/db/clients/getModelType.php';
  return this.http.post(url, postData); 
}

getUsers()
{
  let postData = new FormData();
  postData.append('token', localStorage.getItem('token'));
  postData.append('action', 'getusers');

  let url = 'https://bmyscouter.co/db/clients/users/mgmt_clients.php';
  return this.http.post(url, postData); 
}

getCategory()
{
  let postData = new FormData();
  postData.append('token', localStorage.getItem('token'));
  postData.append('action', 'getallcategory');

  let url = 'https://bmyscouter.co/db/clients/users/mgmt_clients.php';
  return this.http.post(url, postData); 
}

addUsers(first, last, email, password, category, action, id)
{
  let postData = new FormData();
  postData.append('token', localStorage.getItem('token'));
  postData.append('action', action);

  if(action == 'adduser'){
    postData.append('password', password);
  }

  postData.append('first', first);
  postData.append('last', last);
  postData.append('email', email);
 
  postData.append('hash', id);

  for (var i = 0; i < category.length; i++) {
    postData.append('category[]', category[i]);
}

  let url = 'https://bmyscouter.co/db/clients/users/mgmt_clients.php';
  return this.http.post(url, postData); 
}

delUsers(hash){
  let postData = new FormData();
  postData.append('token', localStorage.getItem('token'));
  postData.append('action', 'deluser');

  postData.append('hash', hash);

  let url = 'https://bmyscouter.co/db/clients/users/mgmt_clients.php';
  return this.http.post(url, postData); 
}

getOneUsers(hash){
  let postData = new FormData();
  postData.append('token', localStorage.getItem('token'));
  postData.append('action', 'getoneusers');

  postData.append('hash', hash);

  let url = 'https://bmyscouter.co/db/clients/users/mgmt_clients.php';
  return this.http.post(url, postData); 
}


prepareFacture(){
  let postData = new FormData();
  postData.append('token', localStorage.getItem('token'));
  postData.append('action', 'preparefacture');
  
  let url = 'https://bmyscouter.co/db/clients/facturation/mgmt_facture.php';
  return this.http.post(url, postData); 
}

addFacture(idcollab, dateemission, intitule, montant)
{
  let postData = new FormData();

  postData.append('idcollab', idcollab);
  postData.append('dateemission', dateemission);
 // postData.append('datepaiement', datepaiement);
  postData.append('intitule', intitule);
  postData.append('montant', montant);

  postData.append('token', localStorage.getItem('token'));
  postData.append('action', 'addfacture');
  
  let url = 'https://bmyscouter.co/db/clients/facturation/mgmt_facture.php';
  return this.http.post(url, postData); 
}

getfacture(id){
  let postData = new FormData();

  postData.append('token', localStorage.getItem('token'));
  postData.append('action', 'getfactures');
  postData.append('idcollab', id);
  
  let url = 'https://bmyscouter.co/db/clients/facturation/mgmt_facture.php';
  return this.http.post(url, postData); 
}

delFacture(factureid){
  let postData = new FormData();
  postData.append('token', localStorage.getItem('token'));
  postData.append('action', 'delfactures');
  postData.append('factureid', factureid);
  
  let url = 'https://bmyscouter.co/db/clients/facturation/mgmt_facture.php';
  return this.http.post(url, postData); 
}

editFacture(numfacture, datepaiement){
  let postData = new FormData();

  postData.append('token', localStorage.getItem('token'));
  postData.append('action', 'editfacture');
  postData.append('datepaiement', datepaiement);
  postData.append('numfacture', numfacture);
  
  let url = 'https://bmyscouter.co/db/clients/facturation/mgmt_facture.php';
  return this.http.post(url, postData); 
}

getPushCategory(){
  let postData = new FormData();

  postData.append('token', localStorage.getItem('token'));
  
  let url = 'https://bmyscouter.co/db/clients/getPushCategory.php';
  return this.http.post(url, postData); 
}

changeCategory( model, category){
  let postData = new FormData();
  postData.append('action', 'mgmtcategory');
  postData.append('model', model);
  
  for (var i = 0; i < category.length; i++) {
      postData.append('category[]', category[i]);
  }

  postData.append('token', localStorage.getItem('token'));
  
  let url = 'https://bmyscouter.co/db/clients/mgmt_category.php';
  return this.http.post(url, postData); 
}

initCategory(model){
  let postData = new FormData();
  postData.append('model', model);
  postData.append('action', 'getinitcategory');
  postData.append('token', localStorage.getItem('token'));
  
  let url = 'https://bmyscouter.co/db/clients/mgmt_category.php';
  return this.http.post(url, postData); 
}

mgmtSignature(elem, action){
  let postData = new FormData();
  postData.append('signature', elem);
  postData.append('action', action);
  postData.append('token', localStorage.getItem('token'));
  
  let url = 'https://bmyscouter.co/db/clients/mgmt_personal.php';
  return this.http.post(url, postData); 
}

getPushLog(model, action, country = 'France', type){
  let postData = new FormData();
  postData.append('model', model);
  postData.append('action', action);
  postData.append('type', type);

  for (var i = 0; i < country.length; i++) {
    postData.append('country[]', country[i]);
}

  postData.append('token', localStorage.getItem('token'));
  
  let url = 'https://bmyscouter.co/db/clients/pushlog.php';
  return this.http.post(url, postData); 
}

getClientType(){
  let postData = new FormData();
  postData.append('action', 'getdistinctclient');
  postData.append('token', localStorage.getItem('token'));
  
  let url = 'https://bmyscouter.co/db/clients/pushlog.php';
  return this.http.post(url, postData); 
}

getEmailFromHash(hash){
  let postData = new FormData();
  postData.append('action', 'getemailfromhash');
  postData.append('token', localStorage.getItem('token'));

  for (var i = 0; i < hash.length; i++) {
    postData.append('hash[]', hash[i]);
}
  
  let url = 'https://bmyscouter.co/db/clients/pushlog.php';
  return this.http.post(url, postData);
}   

getEvents(){
  let postData = new FormData();
  postData.append('action', 'mybooking');
  postData.append('token', localStorage.getItem('token'));

  console.log('token', localStorage.getItem('token') );

  let url = 'https://bmyscouter.co/db/clients/users/mgmt_booking.php';
  return this.http.post(url, postData);
}

getMyEvents(modelhash){
  let postData = new FormData();
  postData.append('action', 'mymodelbooking');
  postData.append('model', modelhash);
  postData.append('token', localStorage.getItem('token'));

  console.log('token', localStorage.getItem('token') );

  let url = 'https://bmyscouter.co/db/clients/users/mgmt_booking.php';
  return this.http.post(url, postData);
}

getModelEvent(modal){
  let postData = new FormData();
  postData.append('action', 'getmodelevent');
  postData.append('token', localStorage.getItem('token'));

  postData.append('modal', modal);


  let url = 'https://bmyscouter.co/db/clients/users/mgmt_booking.php';
  return this.http.post(url, postData);
}

bookMeeting(type, who, phone, where, from, to, more, bookingrequest, model, agency = null){
  let postData = new FormData();
  postData.append('token', localStorage.getItem('token') );
  console.log( localStorage.getItem('token') );
  postData.append('action', 'bookmeeting');
  postData.append('type', type);

  if(from[0] !== null){
    postData.append('from', from[0].toISOString());
  }

  if(to[1] !== null){
    postData.append('to', to[1].toISOString());
  }

  postData.append('who', who);
  postData.append('phone', phone);
  postData.append('where', where);
  postData.append('more', more);
  postData.append('bookingrequest', bookingrequest);

  if(agency && agency.length > 1){
    postData.append('agency', agency);
  }

  if(model.constructor === Array){
    for (var i = 0; i < model.length; i++) {
      postData.append('model[]', model[i]);
  }
  } else {
    postData.append('model', model);
  }



  let url = 'https://bmyscouter.co/db/clients/users/mgmt_booking.php';
  return this.http.post(url, postData);
}

getEventDetails(eventid){
  let postData = new FormData();
  postData.append('token',  localStorage.getItem('token'));
  postData.append('action', 'geteventdetails');
  postData.append('eventid', eventid);

  let url = 'https://bmyscouter.co/db/clients/users/mgmt_booking.php';
  return this.http.post(url, postData);
}

cancelEvent(eventid){
  let postData = new FormData();
  postData.append('token',  localStorage.getItem('token'));
  postData.append('action', 'cancelevent');
  postData.append('eventid', eventid);

  let url = 'https://bmyscouter.co/db/clients/users/mgmt_booking.php';
  return this.http.post(url, postData);
}

getPushTemplate(){
  let postData = new FormData();
  postData.append('token',  localStorage.getItem('token'));
  postData.append('action', 'getpushtemplate');

  let url = 'https://bmyscouter.co/db/clients/templateMail/mgmt_pushtemplate.php';
  return this.http.post(url, postData);
}

updatePushTemplate(fr, eng, type){
  let postData = new FormData();
  postData.append('token',  localStorage.getItem('token'));
  postData.append('action', 'updatepushtemplate');

  postData.append('htmlContentFR', fr);
  postData.append('htmlContentENG', eng);

  postData.append('type', type);

  let url = 'https://bmyscouter.co/db/clients/templateMail/mgmt_pushtemplate.php';
  return this.http.post(url, postData);
}

rotatePics(picsid){
  let postData = new FormData();
  postData.append('token',  localStorage.getItem('token'));
  postData.append('action', 'rotateimg');

  postData.append('picsid', picsid);

  let url = 'https://bmyscouter.co/db/clients/rotatePics.php';
  return this.http.post(url, postData);
}

updateToken(token){
  let postData = new FormData();
  postData.append('token', localStorage.getItem('token'));
  postData.append('browsertoken', token);

  let url = 'https://bmyscouter.co/db/clients/updateToken.php';
  return this.http.post(url, postData);
}

getAllfactures(type, date, societe){
  let postData = new FormData();
  postData.append('token', localStorage.getItem('token'));
  postData.append('action', 'getallfactures');
  postData.append('type', type);
  postData.append('date', date);
  postData.append('societe', societe);

  let url = 'https://bmyscouter.co/db/clients/facturation/mgmt_facture.php';
  return this.http.post(url, postData);
}

getAllBoa(){
  let postData = new FormData();
  postData.append('token', localStorage.getItem('token'));
  postData.append('action', 'getallboa');

  let url = 'https://bmyscouter.co/db/clients/facturation/mgmt_facture.php';
  return this.http.post(url, postData);
}

getMyFactures(modelhash){
  let postData = new FormData();
  postData.append('token', localStorage.getItem('token'));
  postData.append('action', 'getmodelfacture');
  postData.append('model', modelhash);

  let url = 'https://bmyscouter.co/db/clients/facturation/mgmt_facture.php';
  return this.http.post(url, postData);
}

getModelAvatar(modelhash){
  let postData = new FormData();
  postData.append('token', localStorage.getItem('token'));
  postData.append('model', modelhash);

  postData.append('todo', 'getmodelavatar');

  let url = 'https://bmyscouter.co/db/clients/feedback/addManualLog.php';
  return this.http.post(url, postData);
}

addManualLog(agency, model, action){
  let postData = new FormData();
  postData.append('token', localStorage.getItem('token'));
  postData.append('agency', agency);
  postData.append('model', model);
  postData.append('action', action);

  postData.append('todo', 'savelog');

  let url = 'https://bmyscouter.co/db/clients/feedback/addManualLog.php';
  return this.http.post(url, postData);
}

getPublicLink(hash){
  let postData = new FormData();
  postData.append('token', localStorage.getItem('token'));
  postData.append('model', hash);
  postData.append('action', 'getpubliclink');

  let url = 'https://bmyscouter.co/db/clients/pp/getPublicLink.php';
  return this.http.post(url, postData);
}

getAlbumList(hash){
  let postData = new FormData();
  postData.append('token', localStorage.getItem('token'));
  postData.append('hash', hash);
  postData.append('action', 'getalbum');

  let url = 'https://bmyscouter.co/db/clients/getAlbum.php';
  return this.http.post(url, postData);
}

getAlbumPics(albumid, modelhash){
  let postData = new FormData();
  postData.append('token', localStorage.getItem('token'));
  postData.append('hash', modelhash);
  postData.append('albumid', albumid);

  postData.append('action', 'getalbumdetails');

  let url = 'https://bmyscouter.co/db/clients/getAlbum.php';
  return this.http.post(url, postData);
}

createAlbum(modelhash, albumname){
  let postData = new FormData();
  postData.append('token', localStorage.getItem('token'));
  postData.append('hash', modelhash);
  postData.append('albumname', albumname);

  postData.append('action', 'createalbum');

  let url = 'https://bmyscouter.co/db/clients/getAlbum.php';
  return this.http.post(url, postData);
}


renameAlbum(modelhash, albumid, albumname){
  let postData = new FormData();
  postData.append('token', localStorage.getItem('token'));
  postData.append('hash', modelhash);
  postData.append('albumid', albumid);
  postData.append('albumname', albumname);

  postData.append('action', 'renamealbum');

  let url = 'https://bmyscouter.co/db/clients/getAlbum.php';
  return this.http.post(url, postData);
}


transfertPics(picsidtotransfert, albumidtotransfert, modelhash){
  let postData = new FormData();
  postData.append('token', localStorage.getItem('token'));
  postData.append('hash', modelhash);
  postData.append('albumidtotransfert', albumidtotransfert);

  for (var i = 0; i < picsidtotransfert.length; i++) {
    postData.append('picsidtotransfert[]', picsidtotransfert[i]);
}

  postData.append('action', 'transfertpics');

  let url = 'https://bmyscouter.co/db/clients/getAlbum.php';
  return this.http.post(url, postData);
}

deleteAlbum(albumid, modelhash){
  let postData = new FormData();
  postData.append('token', localStorage.getItem('token'));

  postData.append('hash', modelhash);
  postData.append('albumid', albumid);

  postData.append('action', 'deletealbum');

  let url = 'https://bmyscouter.co/db/clients/getAlbum.php';
  return this.http.post(url, postData);
}

handleFileInput(type, name, desc, file){
  let postData = new FormData();
  postData.append('name', name);
  postData.append('type', type);
  postData.append('desc', desc);
  postData.append('file', file);

  postData.append('token', localStorage.getItem('token'));

  let url = 'https://bmyscouter.co/db/clients/documents/upload_document.php';
  return this.http.post(url, postData);
}

handleFileInput2(type, name, modal,  file, agency = null){
  let postData = new FormData();

  postData.append('type', type);
  postData.append('name', name);
  postData.append('modal', modal);

  postData.append('file', file);
  postData.append('agency', agency);

  postData.append('token', localStorage.getItem('token'));

  let url = 'https://bmyscouter.co/db/clients/documents/modeldocs/upload_document.php';
  return this.http.post(url, postData);
}

getModelDocs(modelhash){
  let postData = new FormData();

  postData.append('modal', modelhash);
  postData.append('action', 'getmodeldocs');
  postData.append('token', localStorage.getItem('token'));

  let url = 'https://bmyscouter.co/db/clients/documents/modeldocs/documents.php';
  return this.http.post(url, postData);
}

deleteModelDocs(fileid, modelhash){
  let postData = new FormData();

  postData.append('modal', modelhash);
  postData.append('fileid', fileid);
  postData.append('action', 'deletedocs');
  postData.append('token', localStorage.getItem('token'));

  let url = 'https://bmyscouter.co/db/clients/documents/modeldocs/documents.php';
  return this.http.post(url, postData);
}

getDocType(){
  let postData = new FormData();
  postData.append('token', localStorage.getItem('token'));
  postData.append('action', 'getdoctype');

  let url = 'https://bmyscouter.co/db/clients/documents/documents.php';
  return this.http.post(url, postData);
}

saveNote(note,modal, type){
  let postData = new FormData();
  postData.append('token', localStorage.getItem('token'));

  postData.append('note', note);
  postData.append('modal', modal);
  postData.append('type', type);
  postData.append('action', 'addnote');

  let url = 'https://bmyscouter.co/db/clients/note/mgmt_note.php';
  return this.http.post(url, postData);
}

getModalNotes(modal, type){
  let postData = new FormData();
  postData.append('token', localStorage.getItem('token'));

  postData.append('modal', modal);
  postData.append('type', type);
  postData.append('action', 'getnote');

  let url = 'https://bmyscouter.co/db/clients/note/mgmt_note.php';
  return this.http.post(url, postData);
}


delModalNotes(noteid, type){
  let postData = new FormData();
  postData.append('token', localStorage.getItem('token'));

  postData.append('noteid', noteid);
  postData.append('action', 'delnote');
  postData.append('type', type);

  let url = 'https://bmyscouter.co/db/clients/note/mgmt_note.php';
  return this.http.post(url, postData);
}

getdocuments(type){
  let postData = new FormData();
  postData.append('token', localStorage.getItem('token'));
  postData.append('action', 'getdocuments');
  postData.append('type', type);

  let url = 'https://bmyscouter.co/db/clients/documents/documents.php';
  return this.http.post(url, postData);
}

deleteFile(fileid){
  let postData = new FormData();
  postData.append('token', localStorage.getItem('token'));
  postData.append('action', 'deletefile');
  postData.append('fileid', fileid);

  let url = 'https://bmyscouter.co/db/clients/documents/documents.php';
  return this.http.post(url, postData);
}

addTemplate(mess){
  let postData = new FormData();
  postData.append('token', localStorage.getItem('token'));
  postData.append('action', 'addtemplates');
  postData.append('mess', mess);

  let url = 'https://bmyscouter.co/db/clients/templates.php';
  return this.http.post(url, postData);
}

delMessage(messid){
  let postData = new FormData();
  postData.append('token', localStorage.getItem('token'));
  postData.append('action', 'deltemplates');
  postData.append('messid', messid);

  let url = 'https://bmyscouter.co/db/clients/templates.php';
  return this.http.post(url, postData);
}

getTemplates(){
  let postData = new FormData();
  postData.append('token', localStorage.getItem('token'));
  postData.append('action', 'getalltemplates');
  
  let url = 'https://bmyscouter.co/db/clients/templates.php';
  return this.http.post(url, postData);
}

getModelStats(modal){
  let postData = new FormData();
  postData.append('token', localStorage.getItem('token'));
  postData.append('action', 'getppstats');
  postData.append('modal', modal);
  
  let url = 'https://bmyscouter.co/db/clients/pp/getPPStats.php';
  return this.http.post(url, postData);
}


downloadFile(fileurl){
  let postData = new FormData();

  postData.append('url', fileurl);
  postData.append('action', 'dlfile');
  postData.append('token', localStorage.getItem('token'));

  let url = 'https://bmyscouter.co/db/clients/documents/modeldocs/documents.php';
  return this.http.post(url, postData);
}

uploadBoa(boanumber, file){
  let postData = new FormData();

  postData.append('file', file);
  postData.append('boanumber', boanumber);
  postData.append('action', 'uploadboa');
  postData.append('token', localStorage.getItem('token'));

  let url = 'https://bmyscouter.co/db/clients/facturation/mgmt_facture.php';
  return this.http.post(url, postData);
}


deleteBoa(boanumber){
  let postData = new FormData();

  postData.append('boanumber', boanumber);
  postData.append('action', 'deleteboa');
  postData.append('token', localStorage.getItem('token'));

  let url = 'https://bmyscouter.co/db/clients/facturation/mgmt_facture.php';
  return this.http.post(url, postData);
}

selectClientCategory(type){
  let postData = new FormData();

  postData.append('action', 'getclientcategory');
  postData.append('type', type);
  postData.append('token', localStorage.getItem('token'));

  let url = 'https://bmyscouter.co/db/clients/agencyMgmt/selectClientCategory.php';
  return this.http.post(url, postData);
}

}
