import { Component, Inject } from '@angular/core';
import { HttpService } from '../../http.service';
import {  MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, Validators, UntypedFormGroup, UntypedFormControl } from '@angular/forms';

import { collabData } from '../../pictures/modelrepresentation/modelrepresentation.component';

import { ChooseagencyComponent } from '../../agenda/gobook/chooseagency/chooseagency.component';

@Component({
    selector: 'add-collaboration',
    templateUrl: 'add-collaboration.html',
    styleUrls: ['./addcollab.component.css']
})

export class CollaborationComponent {

    myGroup: UntypedFormGroup;
    myGroup2: UntypedFormGroup;
  
   
    agency: any;
    agencyhash : string;
    agencyname: string;

    fileToUpload: File = null;
    agency_contract: File = null;

    spinnerProgress: boolean;
    choose : boolean;

    mac: boolean;
    modelagreement : boolean;

    constructor(
        public dialogRef: MatDialogRef<CollaborationComponent>,
        @Inject(MAT_DIALOG_DATA) public data: collabData,
        private http: HttpService,
        private fb: UntypedFormBuilder,
        public dialog: MatDialog,
    ) {
      //  this.createForm();
        this.choose = true;
        this.mac = false;
        this.modelagreement = false;
    }

    ngOnInit() {
        console.log('add collab', this.data);
       
    }

    createForm() {
        this.myGroup = this.fb.group({
            //mannequin: ['', Validators.required],
            agence: new UntypedFormControl({ value: '', disabled: true }),
            date: ['',],
        });
    }

    createForm2() {
        this.myGroup2 = this.fb.group({
            date: ['',],
        });
    }


    getList() {
        this.http.getListCollab().subscribe(res => {
            console.log(res);
            //this.model = res['model'];
            this.agency = res['agency'];
        })
    }

    addCollab() {
            console.log('collab');
            this.http.addCollab(
                this.data['model'],
                this.agencyhash,
                this.myGroup.get('date').value.toISOString(),
                this.agency_contract,
            ).subscribe(res => {
                this.spinnerProgress = false;
                console.log(res);
                if (res == 'ok') {
                    this.dialogRef.close(res);
                }
            }, error => {
                console.log('error', error);
            }
            )
    }

    addMac(){
        this.http.addMac(
            this.data['model'],
            this.myGroup2.get('date').value.toISOString(),
            this.agency_contract,
        ).subscribe(res=>{

            console.log('add mac', res);
            if(res == 'ok'){
                this.dialogRef.close(res);
            }
        })
    }

    addcontract(elem) {
        document.getElementById(elem).click();
    }

    fileChangeEvent(files: FileList, contract): void {
            this.agency_contract = null;
            this.agency_contract = files.item(0);        
    }

    downloadSwitch(elem){
        this.choose = false;
        switch (elem){
            case 'mac':
     
            this.mac = true;
            console.log('mac', this.mac);
            this.createForm();
         //   this.getList();
            
            break;

            case 'modelagreement':
            this.modelagreement = true;
            this.createForm2();
            break;
        }
    }

    chooseAgency(){
           
    const dialogRef = this.dialog.open(ChooseagencyComponent, {  
        data : { type: 'agency' }
       });
 
   dialogRef.afterClosed().subscribe(result => {
     console.log('The dialog was closed', result);
     if (result !== undefined) {
        //this.dialogRef.close(result);
        this.agency = result;
        this.prepareFormAgencyHash(this.agency);
    }
   })
    }

    prepareFormAgencyHash(agencyhash){
        this.http.prepareFormAgency(agencyhash).subscribe(res=>{
          console.log('res agency', res);
          if(res){

            this.myGroup.get('agence').setValue(res['name']);
            this.agencyhash = agencyhash;
          }
        })
      }

}