<div class="paddingTopOnMobile" style="padding-left: 10px;">


  <div style="display: flex; flex-direction: column; justify-content: center; margin-top: 50px;">
    <div *ngFor="let x of tosend; let i = index">{{x}} <img src="../../../../assets/trash.png" style="height: 20px; cursor:pointer;" (click)="deleteDesti(i)" /></div>
</div>

<div class="bTnPannelForMobile" >
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button (click)="onOkClick()" cdkFocusInitial>Ok</button>
</div>


</div>
