import { Component, OnInit } from '@angular/core';
import { MatDialog} from '@angular/material/dialog';
import { MatSnackBar} from '@angular/material/snack-bar';
import { HttpService } from '../http.service';
import { DetailsmodalComponent } from './detailsmodal/detailsmodal.component';
import { ClientMenuComponent } from './client-menu/client-menu.component';

export interface Data {
  id: string;
}


@Component({
  selector: 'app-client-page',
  templateUrl: './client-page.component.html',
  styleUrls: ['./client-page.component.css']
})
export class ClientPageComponent implements OnInit {

  models: any;

  constructor(
    private get: HttpService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.getmodel('favorites', 'both');
  }

  getmodel(type, gender){
    this.get.getModel(type, gender).subscribe( res => {
      console.log(res);
        this.models = res['modal'];
    })
  } 

  seeDetails(id){
    const dialogRef = this.dialog.open(DetailsmodalComponent, {  
      width: '100vw',
     height: '100%',
     data: { id: id }
     });

 dialogRef.afterClosed().subscribe(result => {
   console.log('The dialog was closed', result);


  })
}

}
