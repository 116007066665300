

    <table *ngIf="video" style=" width: 90%; margin: 5px; margin-left: 50%; transform: translate(-50%, 0); margin-top: 20px;" class="w3-table w3-striped w3-border ">
  <tr>
    <th>Name</th>
    <th>Public Profile</th>
    <th>Actions</th>
 
  </tr>
  <tr *ngFor="let x of video; let i = index;">
    <td class="limiteColumnSizeOnMobile">{{ x.name }}</td>

    <td>
      <mat-slide-toggle [checked]="x.visibility == '1'" (change)="changeVideoVisibility(x.id, x.visibility)"></mat-slide-toggle>

    </td>
 
    <th>
      <i (click)="seeVideo(x.src)" style="cursor: pointer" class="material-icons">
            remove_red_eye
        </i>

     <i (click)="delVideo(x.id)" style="cursor: pointer" class="material-icons">
      delete_outline
      </i>  
    </th>



  </tr>

</table> 

<button  (click)="uploadVideo()" mat-fab color="primary">+</button>

<mat-spinner class="centred"  *ngIf="spinnerProgress"> </mat-spinner>



<input id="inputID" type="file" name="file" style="display:none;"
(change)="fileChangeEvent($event)" />

