import { Component, OnInit, Input } from '@angular/core';
import { HttpService } from '../http.service';
import { Router,ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-full-agency',
  templateUrl: './full-agency.component.html',
  styleUrls: ['./full-agency.component.css']
})
export class FullAgencyComponent implements OnInit {

  @Input() agency: string;
  @Input() type: string;

  selected_model: any = [];

  constructor(
    private get: HttpService,
    private router:Router,
  ) { }

  models: any;
  
  ngOnInit() {
    //this.getmodel('approved');
    console.log('full agency', this.agency);
  }

}
