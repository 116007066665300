import { Component, OnInit } from '@angular/core';
import {  MatDialog } from '@angular/material/dialog';
import { HttpService } from '../../http.service';
import { MatSnackBar} from '@angular/material/snack-bar';

import { FacturationComponent } from '../facturation/facturation.component'; 
import { RelanceComponent } from './relance/relance.component';

import { UntypedFormControl } from '@angular/forms';
import {  MatTableDataSource } from '@angular/material/table';

import { UsefilterComponent } from '../../stats/statsone/usefilter/usefilter.component';
import { SetcollabendComponent } from '../../pictures/modelrepresentation/setcollabend/setcollabend.component';

export interface collabData {
  id: string;
}
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-contract',
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.css']
})
export class ContractComponent implements OnInit {


  collaboration : any;
  ed: any;
  nbrecollab: any;
  displayedColumns: string[] = ['Mannequin', 'Agence', 'Début', 'Fin', 'Location', 'email', 'statement'];
  state : any;

  whoFilter = new UntypedFormControl('');
  agencyFilter = new UntypedFormControl('');
  locationFilter = new UntypedFormControl('');
  
  filterValues = {
    who: '',
    agency: '',
    location : '',
  };

  dataSource = new MatTableDataSource();

  

  constructor(
    private http: HttpService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private route :ActivatedRoute,
  ) {
    this.dataSource.filterPredicate = this.createFilter();
   }

  ngOnInit() {
    //this.state = this.route.snapshot.paramMap.get('state');
    this.route.params.subscribe( state => {
      console.log('state',state);
      this.state = state['state'];
      this.getCollab(this.state);
    })
    console.log('state', this.state);

  }

 
getCollab(state){
  this.http.getCollab(state).subscribe( res => {
    console.log(res);
    
    this.collaboration = res['colab'];
    this.dataSource.data = this.collaboration;
    this.nbrecollab = res['colab'].length;
    this.ed = res['ed'];
  })
}

goContract(elem){
  window.open(elem);
}

goFacturation(idcollab){
  const dialogRef = this.dialog.open(FacturationComponent, {  
    width: '1000px',
    height: '800px',
   data: { id: idcollab }
   });

dialogRef.afterClosed().subscribe(result => {
  console.log('The dialog was closed', result);

});


}



createFilter(): (data: any, filter: string) => boolean {
  let filterFunction = function(data, filter): boolean {
    console.log('filter', data, filter);
    let searchTerms = JSON.parse(filter);
    return data.agency.toString().indexOf(searchTerms.agency.toString()) !== -1
       && data.model.toString().indexOf(searchTerms.who.toString()) !== -1
       && data.location.toString().indexOf(searchTerms.location.toString()) !== -1
    
      ;
  }
  return filterFunction;
}

async initialiseFilter(){
  console.log('initialiser filter');
  this.whoFilter = new UntypedFormControl('');
  this.agencyFilter = new UntypedFormControl('');
  this.locationFilter = new UntypedFormControl('');

  this.filterValues = {
    who: '',
    agency: '',
    location : ''
  };

  await this.getCollab(this.state);
  this.dataSource.data = this.collaboration;
}

useFilter(elem){
    
 
  var transfert = [];
  
  if(elem == 'modelhash'){
    for(var i = 0; i < this.collaboration.length; i++){
        transfert[i] = this.collaboration[i].model;
      }
  }
  

  if(elem == 'agencyhash'){
    for(var i = 0; i < this.collaboration.length; i++){
        transfert[i] = this.collaboration[i].agency;
      }
  }

  if(elem == 'location'){
    for(var i = 0; i < this.collaboration.length; i++){
        transfert[i] = this.collaboration[i].location;
      }
  }



  const dialogRef = this.dialog.open(UsefilterComponent, {
    data: {type: elem, data : transfert }
  });

  dialogRef.afterClosed().subscribe(index => {
    console.log('The dialog was closed', index);
    if(index !== undefined){
      console.log('Selected', index);

      if(elem == 'modelhash'){
        this.filterValues.who = index;
      }
   
      if(elem == 'agencyhash'){
        this.filterValues.agency = index;
      }

      if(elem == 'location'){
        this.filterValues.location = index;
      }

      this.dataSource.filter = JSON.stringify(this.filterValues);
    }
  });
}


setEnd(collabid){
  const dialogRef = this.dialog.open(SetcollabendComponent, {  
   data: { collabid : collabid }
   });

dialogRef.afterClosed().subscribe(result => {
 console.log('The dialog was closed', result);
 if(result == 'ok'){
   this.getCollab(this.state);
 }
})
}

seeFacture(url){
  window.open(url);
}

goRelance(collabid){
  const dialogRef = this.dialog.open(RelanceComponent, {  
    data: { collabid : collabid }
    });
 
 dialogRef.afterClosed().subscribe(result => {
  console.log('The dialog was closed', result);
  if(result == 'ok'){
 //   this.getCollab(this.state);
  }
 })
}

}
