<div
    style="display:flex; flex-direction: column; height: 100%; width: 100%; justify-content: center;align-items: center;">



        <table *ngIf="agencyfac" mat-table [dataSource]="agencyfac" class="mat-elevation-z1"
        style=" width: 90%; margin: 5px;  margin-top: 20px;">

        <ng-container matColumnDef="agency">
            <th mat-header-cell *matHeaderCellDef>Clients</th>
            <td mat-cell *matCellDef="let x">
                <img [src]="x.agency" height="40px" *ngIf="x.agency"/>
                <div *ngIf="!x.agency">{{x.name}}</div>
            </td>
        </ng-container>

        <ng-container matColumnDef="emission">
            <th mat-header-cell *matHeaderCellDef>Date d'Emission</th>
            <td mat-cell *matCellDef="let x">
                {{ x.date_emission }}
            </td>
        </ng-container>

        <ng-container matColumnDef="paiement">
            <th mat-header-cell *matHeaderCellDef>Date de Paiement</th>
            <td mat-cell *matCellDef="let x">
                {{ x.date_paiement }}
            </td>
        </ng-container>

        <ng-container matColumnDef="numerofac">
            <th mat-header-cell *matHeaderCellDef>Num. de Facture</th>
            <td mat-cell *matCellDef="let x">
                <div style="text-decoration: underline; color: blue; cursor: pointer;" (click)="seeFacture(x.fac)">
                    {{ x.numerofac }}
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="montant">
            <th mat-header-cell *matHeaderCellDef>
                <div  style="text-decoration: underline; color: blue; cursor: pointer;" (click)="toggleMontant()">
                    {{pricelabel}}
                </div>
            </th>
            <td mat-cell *matCellDef="let x">
                {{ dispalyMontant(x.montant) }}
            </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"  [ngClass]="getCssClass(row)"></tr>
    </table>

    <div *ngIf="agencyfac">Total Invoices : {{  getTotal('comm') }}</div>
   


        <table  *ngIf="clientsfac" mat-table [dataSource]="clientsfac" class="mat-elevation-z1"
        style=" width: 90%; margin: 5px;  margin-top: 20px;">
    
        <ng-container matColumnDef="agency">
            <th mat-header-cell *matHeaderCellDef>Clients</th>
            <td mat-cell *matCellDef="let y">
                <img [src]="y.agency" height="40px" *ngIf="y.agency"/>
                <div *ngIf="!y.agency">{{y.name}}</div>
            </td>
        </ng-container>
    
        <ng-container matColumnDef="emission">
            <th mat-header-cell *matHeaderCellDef>Date d'Emission</th>
            <td mat-cell *matCellDef="let y">
                {{ y.date_emission }}
            </td>
        </ng-container>
    
        <ng-container matColumnDef="paiement">
            <th mat-header-cell *matHeaderCellDef>Date de Paiement</th>
            <td mat-cell *matCellDef="let y">
                {{ y.date_paiement }}
            </td>
        </ng-container>
    
        <ng-container matColumnDef="numerofac">
            <th mat-header-cell *matHeaderCellDef>Num. de Facture</th>
            <td mat-cell *matCellDef="let y">
                <div style="text-decoration: underline; color: blue; cursor: pointer;" (click)="seeFacture(y.fac)">
                    {{ y.numerofac }}
                </div>
            </td>
        </ng-container>
    
        <ng-container matColumnDef="montant">
            <th mat-header-cell *matHeaderCellDef>
                <div  style="text-decoration: underline; color: blue; cursor: pointer;" (click)="toggleMontant()">
                    {{pricelabel}}
                </div>
            </th>
            <td mat-cell *matCellDef="let y">
                {{ dispalyMontant(y.montant) }}
            </td>
        </ng-container>
    
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="getCssClass(row)"></tr>
    
    </table>
    
    <div *ngIf="clientsfac">Total Invoices : {{  getTotal('directclient') }}</div>



    <div style="margin-top: 20px" *ngIf="!clientsfac && !agencyfac">
        <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
            <div>
                <img src="../../../assets/client/agency.png" style="height: 50px" />
            </div>
            <div style="text-align: center; margin-top : 20px">
                No Factures
            </div>
        </div>

    </div>

</div>