import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { HttpService } from '../http.service';
import { ChatComponent } from '../chat/chat.component';
import { MessprofileComponent } from '../messprofile/messprofile.component';

@Component({
  selector: 'app-convers',
  templateUrl: './convers.component.html',
  styleUrls: ['./convers.component.css']
})
export class ConversComponent implements OnInit {

  @ViewChild('chat', { static: true }) child:ChatComponent;
  @ViewChild('messprofile', { static: true }) child2 :MessprofileComponent;

  conversation: any = [];
  numConvers: number;
  resnull: boolean;
  uid: string;
  active : number;
  box: string;
  @Input() hash: string;

  constructor(
    private http: HttpService,


  ) {
    //this.uid = '0266e33d3f546cb5436a10798e657d97';
   }

  ngOnInit() {
    console.log('chat2', this.hash);
  this.getAllConvers('Mailbox');
  }

  getAllConvers(type : string){
   // console.log('allconvers', this.uid, type);
this.box = type;
 
    this.http.getAllConvers(type, this.hash).subscribe( res => {
      this.conversation = '';
      console.log('res',res);
      if (res !== null){
        this.conversation = res;
        this.child.getChat(this.conversation[0].conversation);
        this.child2.getProfile(this.conversation[0].conversation, this.uid);
      } else {
        this.conversation = null;
      }
      
    })
  }

  goConvers(convers){

  this.active = convers;
   this.child.getChat(convers);
  this.child2.getProfile(convers, this.uid);

  var z = 'first'+convers;
  var y = 'mess'+convers;
  document.getElementById(y).classList.add('read');
  document.getElementById(z).classList.add('read');

  }

  goArchive(convers : string, index: string, action: string){

   // this.conversation.splice(index, 1);

   this.http.goArchive(convers, action).subscribe(res => {
      if(res){
        this.conversation.splice(index, 1);
      //  this.conversation.splice(0,index);
      }
    })
  }

  SelectConvers(){
    console.log('select convers');
  }
  



}
