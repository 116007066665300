<div class="FolioCtn">
        
        <ng-container  *ngFor="let x of  polaroid; let i = index" >
                <div  class="galleryImg"
                [style.backgroundImage]="x.url ? getImage(x.url) : 'url(assets/girl_vide.jpg)' "
                
                *ngIf="x.url"
                >
                
                </div>
        </ng-container>

        
        <div *ngFor="let x of  gallery; let i = index"  class="galleryImg"
        [style.backgroundImage]="x.url ? getImage(x.url) : 'url(assets/girl_vide.jpg)' "
       
        >
        
        
        </div>

</div>
