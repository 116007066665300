import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatDialog } from '@angular/material/dialog';

import { ChoosemodelComponent } from '../genmac/choosemodel/choosemodel.component';
import { ChooseagencyComponent } from '../../../agenda/gobook/chooseagency/chooseagency.component';

import { HttpService } from '../../../http.service';
import { MatStepper } from '@angular/material/stepper';

import { ActivatedRoute, Params  } from '@angular/router'; 

import {
  DomSanitizer,
} from '@angular/platform-browser';

import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-genfac',
  templateUrl: './genfac.component.html',
  styleUrls: ['./genfac.component.scss']
})
export class GenfacComponent implements OnInit {

  @ViewChild('stepper', { static : true}) private myStepper: MatStepper;


  factureFormGroup: UntypedFormGroup;
  tvaFormGroup: UntypedFormGroup;
  droitsFromGroup : UntypedFormGroup;

  clientsBool : any;

  agency : any;
  modal: any;
  t : any;

  download : boolean;
  type : string;
  agencycountry: string;
  agencycity: string;

  modelhash: any;
  agencyhash: any;
  agencylogo: any;

  facturelines: any;
  selectedmodels: any;

  seemodels: string;

  Work : {
    hash: string;
    note : string;
    montantht: number;
  }

  agencyType: any;
  entreprise: string;

  url : any;
  language: any;
  agencyName: any;
  modelFirstName : any;
  modelLastName : any;


  public innerHeight: any;
  eventhash:any;

  htmlContent: any;
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '200px',
    minHeight: '200px',
    placeholder: 'Enter text here...',
    translate: 'no',
    uploadUrl: 'v1/images', // if needed
    customClasses: [ // optional
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };

  constructor(
    private _formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private http : HttpService,
    private sanitization: DomSanitizer,
    private route: ActivatedRoute
  ) {
    this.download = false;
    this.facturelines = [];
    this.selectedmodels = [];
    this.t = [];

    this.agencyType = 'clients';
    this.seemodels = 'fr';

    this.innerHeight = window.innerHeight -70;
    console.log('inner height', this.innerHeight);
    this.language = 'fr';
    this.getTemplate();
    this.agencyName = "Marque";
    this.modelFirstName  = "Prenom";
    this.modelLastName= "Nom";
   }

   getTemplate(){
    this.htmlContent = '<b>SHOOTING</b> - ' +  this.agencyName + ' &nbsp;<div>Mannequin : ' + this.modelFirstName + ' ' + this.modelLastName + '&nbsp;</div><div><br></div><div><u><b>PRESTATION :</b></u></div><div><ul><li>Durée: 1 journée&nbsp;</li><li>Support(s): shooting&nbsp;</li></ul></div><div><b><u>DROITS :</u>&nbsp;</b></div><div><ul><li>Type(s): Web &amp; Reseaux Sociaux</li><li>Territoire(s): France</li><li>Durée: 6 Mois</li></ul></div>';
   }

   @HostListener('window:resize', ['$event'])
onResize(event) {
  this.innerHeight = window.innerHeight -100;
}

resizeIframe(obj) {
  obj.style.height = this.innerHeight + 'px';
}

getNumFacture(){
  let now =  new Date()
  var fullYear = now.getFullYear().toString() 
  fullYear = fullYear.replace('20','')


  var monthNumber = now.getMonth();

  var newMonthNumber 
  if(monthNumber < 10){
    newMonthNumber = '0' + monthNumber.toString()
  } else {
    newMonthNumber = monthNumber.toString();
  }

  let societe
  if(this.seemodels = 'fr'){
    societe = 'SEE'
  }

  if(this.seemodels = 'fr'){
    societe = 'SEEMO'
  }

  console.log('monthNumber', monthNumber)

  let facName = `${societe}-${fullYear}-${newMonthNumber}-X`
  //this.tvaFormGroup
  //return 
  this.tvaFormGroup.get('numfacture').setValue(facName);
 
}

  ngOnInit() {


    this.factureFormGroup = this._formBuilder.group({
      intitule: ['', Validators.required],
      montantht: ['', Validators.required],
 
    })

    this.tvaFormGroup = this._formBuilder.group({
      tva : [false, Validators.required],
      numcommande: ['', Validators.required],
      numfacture : [ '' , Validators.required],
      language: ['', Validators.required],
    })

    this.eventhash = this.route.snapshot.paramMap.get('eventhash');
    if(this.eventhash !== 'null'){
    this.prepareFactureForm(this.eventhash);
    }
    this.getNumFacture();
  }

  async prepareFactureForm(eventhash){
   
    var funcE = { type: 'BOOKING', func: 'prepareInvoice' };  
    var criteriaE =  { 'label': 'event_hash', 'value': eventhash };

    var Postdata = { func: funcE, data : criteriaE };
    console.log('Postdata', Postdata);
    let res = await this.http.goAcess(Postdata);
    console.log('res', res);
    if(res){
      this.clientsBool = true;
      this.agencyhash = res['client']['clienthash'];
      this.agencylogo = res['client']['logo'];
      this.agencyType = res['client']['agencyType'];

      for(var i = 0; i < res['models'].length; i++){
        var temp = res['models'][i];
        this.prepareModelForm(temp['modelhash']);
      }

      setTimeout(() => {
        for(var j = 0; j < res['models'].length; j++){
          var temp = res['models'][j];

          this.t[j].patchValue({
            facturation : temp['modelfacturation'],
            inpocket : temp['inpocket'],
            jobdate : temp['jobdate']
          });
        }
  
      }, 1500)

      setTimeout(()=>{                    
        this.getPreviewUrl();
   }, 100);
      
    }
  }

  async getClient(agencyhashE){
    var funcE = { type: 'BDD', func: 'getoneLineFromTable' };  
    var criteriaE = [ { 'label': 'agency_hash', 'value': agencyhashE } ];
    var dataE = { table: 'agency', values: criteriaE };    
    var Postdata = { func: funcE, data : dataE };
    let res = await this.http.goAcess(Postdata);
    console.log('res', res);
    if(res){
      this.setClientValue(res);
    }
  }

  setClientValue(data){
    this.clientsBool = true;
    this.agencyhash = data['agency_hash'];
  }

  addMoreLine(){
    if(this.factureFormGroup.get('intitule').value && this.factureFormGroup.get('montantht').value){
      var temp =  {'intitule' : this.factureFormGroup.get('intitule').value, 'value' :  this.factureFormGroup.get('montantht').value};
      this.factureFormGroup.get('intitule').setValue('');
      this.factureFormGroup.get('montantht').setValue('');
  
      this.facturelines.push(temp);
      console.log('factures', this.facturelines);
    }

  }

  editLine(index){
    var intitule = this.facturelines[index]['intitule'];
    var montantht = this.facturelines[index]['value'];

    console.log('intitule', intitule, 'montantht', montantht);
    this.facturelines.splice(index, 1);

    this.factureFormGroup.get('intitule').setValue(intitule);
    this.factureFormGroup.get('montantht').setValue(montantht);
  }


  

  chooseModel(){

    console.log('choose model', this.selectedmodels);

    const dialogRef = this.dialog.open(ChoosemodelComponent, {
      data: {
        selecttype: 'single'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('choose model', result);
      if(result[0]['status'] == 'ok'){
        console.log('status ok');

        this.prepareModelForm(result[0]['hash']);
      }
    });
  }

  chooseAgency(typeS){
    this.agencyType = typeS;
    this.t = [];
    const dialogRef = this.dialog.open(ChooseagencyComponent, 
      {
      data : { type : typeS  }
      });

    dialogRef.afterClosed().subscribe(result => {
      console.log('choose model', result);
     this.prepareFormAgencyHash(result);

    });
  }

    
  prepareFormAgencyHash(agencyhash){
    this.http.prepareFormAgency(agencyhash).subscribe(res=>{
     this.agencyName = res['name'];
     console.log('choose agency', res);
      if(res){
        this.agencyhash = res['agencyhash'];
        this.agencylogo = res['logo'];
      //  this.myStepper.next();
        this.clientsBool = true;
        this.getTemplate();
        this.getPreviewUrl();
      }
    })
  }



  prepareModelForm(hash){
    console.log('prepare form', hash);
  

    
    this.http.seeModel(hash).subscribe(res=>{
      console.log('one model', res);
      this.modal = res['personal'];
      console.log('one model', this.modal);
      this.getTemplate();

      this.modelhash = this.modal.user_hash;

      if(this.agencyType == 'clients'){
        var temp = this._formBuilder.group({
          facturation: ['', Validators.required],
          inpocket: ['', Validators.required],
          jobdate: ['', Validators.required],
      });

    
  
      }

      if(this.agencyType == 'agency'){
        var temp = this._formBuilder.group({
          statement_start: ['', Validators.required],
          statement_end: ['', Validators.required],
         // bookingrequest: ['send', Validators.required]
      });
      }

      
      temp['avatar'] = this.modal.user_avatar;
      temp['modelhash'] = this.modal.user_hash;
      this.t.push(temp);
      
    })
  }

  getImage(data) {
    return this.sanitization.bypassSecurityTrustStyle(`url(${data})`);
  }


   getPreviewUrl(){
    // this.getNumFacture();
     let base = 'https://bmyscouter.co/db/clients/facturation/preview_facture.php?';
     let client = 'client=' + this.agencyhash;
     let societe = '&seemodels=' + this.seemodels;
     let clienttype = '&clienttype=' + this.agencyType;
     let tva = '&tva=' + this.tvaFormGroup.get('tva').value;
     let numcommande = '&numcommande=' + this.tvaFormGroup.get('numcommande').value;
     let numfacture = '&numfacture=' + this.tvaFormGroup.get('numfacture').value;
     let language = '&language=' + this.tvaFormGroup.get('language').value;

     let factureIntitule = '';
     let factureValeur = '';
     for(var i = 0; i < this.facturelines.length; i++){
      factureIntitule = factureIntitule + '&factureline[]=' + encodeURIComponent(this.facturelines[i].intitule);
      factureValeur = factureValeur + '&facturevaleur[]=' + this.facturelines[i].value;
     }


     let repartModelMontantHT = '';
     let repartModelInPocket = '';
     let repartModelJobDate = '';
     let repartStatementEnd = '';
     let repartStatementStart = '';
     let repartModelHash = '';


     for(var i = 0; i < this.t.length; i++){
       var temp = '';
       if(this.agencyType == 'clients'){
        temp = this.t[i].get('jobdate').value;
        repartModelMontantHT = repartModelMontantHT + '&repartModelMontantHT[]=' + this.t[i].get('facturation').value;
        repartModelJobDate = repartModelJobDate + '&repartModelJobDate[]=' + temp[0];
        repartModelHash = repartModelHash + '&repartModelHash[]=' + this.t[i]['modelhash'];
        repartModelInPocket = repartModelInPocket + '&repartModelInPocket[]=' + this.t[i].get('inpocket').value;
       }

    
      if(this.agencyType == 'agency'){
        var temp1 = this.t[i].get('statement_start').value.toISOString().split('T');
        var temp2 = this.t[i].get('statement_end').value.toISOString().split('T');
        repartModelHash = repartModelHash + '&repartModelHash[]=' + this.t[i]['modelhash'];

        repartStatementStart = repartStatementStart + '&repartStatementStart[]=' + temp1[0];
        repartStatementEnd = repartStatementEnd + '&repartStatementEnd[]=' + temp2[0];
      }

      
     }

     let repartAll;
     if(this.agencyType == 'clients'){
      repartAll = repartModelHash + repartModelMontantHT + repartModelJobDate + repartModelInPocket;
     }

     if(this.agencyType == 'agency'){
      repartAll = repartModelHash + repartModelMontantHT + repartModelJobDate + repartStatementStart + repartStatementEnd;
     }
     
         
     base = base + client + clienttype + factureIntitule + factureValeur + societe + tva + numcommande + numfacture + language + repartAll;
     
     console.log('preview url', base);
     this.url = this.sanitization.bypassSecurityTrustResourceUrl(base);
     return base;
    // window.open(base);
   }



   generatePDF(){
   
     let temp = this.getPreviewUrl() + '&generate=ok';
     console.log('generate pdf', temp);
     window.open(temp);
   }

   addRight(index){
     console.log('add right', index);
     
   }
}

