import { Component, OnInit, Inject } from '@angular/core';
import { HttpService } from '../../../http.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-chooseagency',
  templateUrl: './chooseagency.component.html',
  styleUrls: ['./chooseagency.component.css']
})
export class ChooseagencyComponent implements OnInit {

  countrylist: any;
  agency: any;
  agencyBool;
  clientTypeList: any;

  clientTypeBool:boolean;
  CountryList: boolean;
  loading : boolean;
  selectedType : any;


  constructor(
    private http: HttpService,
    public dialogRef: MatDialogRef<ChooseagencyComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.agencyBool = false;
    this.clientTypeBool = true;
    this.loading = false;
   }

  ngOnInit() {
    console.log('transfert data', this.data);
    this.getAgency();
    //this.getCountry('agency');
  }

  getCountry(type){
    this.http.selectCountry(type).subscribe( res => {
      this.countrylist = res;
      console.log('country list', this.countrylist);
      this.clientTypeBool = false;
      this.CountryList = true;
     })
  }

  SelectCountry(elem){
this.getAgency2(elem);
  }

  getAgency(){

    var postData = {  'action' : 'getclienttype' } ;
    
    this.http.goBackOffice(postData).subscribe(res=>{
      this.agencyBool = true;
      this.clientTypeList = res;
      console.log( 'resultat', this.clientTypeList);
    })
  }

  SelectType(elem){
    this.selectedType = elem;
    this.getCountry(this.selectedType);
  }

  getAgency2(location){
    this.CountryList = false;

    var criteria = [
      { 'label' : 'client_type' , 'value' : this.selectedType },
      { 'label' : 'agency_location_country', 'value' : location},
    ];

    var postData = {  'table' : 'agency', 'action' : 'getall', 'criteria' : criteria} ;
    console.log( 'postData', postData);
    this.http.goBackOffice(postData).subscribe(res=>{
      this.agencyBool = true;
      this.agency = this.sortByLetter(res);
      console.log( 'resultat', this.agency);
    })
  }

  SelectAgency(elem){
    this.dialogRef.close(elem);
  }

  getDetails($event, customId) {
   
    console.log($event, customId);
    if ($event.type == 'mouseover') {
      document.getElementById(customId).classList.add('reveal');
    } else {
      document.getElementById(customId).classList.remove('reveal');
    }

  }

  sortByLetter(dataE){
    var letters = 'abcdefghijklmnopqrstuvwxyz';

    var temp = [];

    for(var i = 0; i < letters.length; i++){
      var letter = letters[i];
 

      var tmp = [];
      for(var j = 0; j < dataE.length; j++){
    //    console.log('letter', letter, 'data', dataE[j].first.toLowerCase().charAt(0))
        if(dataE[j].agency_name.toLowerCase().charAt(0) == letter){
            tmp.push(dataE[j]);
          
        }
        dataE.slice(j, 1);
      }
      if(tmp.length > 0){
        temp[i] = {'letter': letter, 'data' : tmp };
      }
      
    }

    var filtered = temp.filter(function (el) {
      return el != null;
    });
   // console.log('filtered', filtered);
    return filtered;
  }

  async goSearch(valueE){
    console.log(valueE);
  
    this.loading = true;

    var funcE = { type: 'SEARCH', func: 'searchClient' };  
    var dataE = { 'firstorlast' : valueE, 'type' : this.selectedType };
    var Postdata = { func: funcE, data : dataE };
    console.log('Postdata', Postdata);

    let res = await this.http.goAcess(Postdata);
  
    this.agency = this.sortByLetter(res);
  //  console.log( 'resultat', this.models);
  this.loading = false;
    console.log('res', this.agency);

  }

  
}
