import { Component, OnInit, Input } from '@angular/core';
import { HttpService } from '../../http.service';
import { BookingdetailsComponent } from '../../agenda/bookingdetails/bookingdetails.component';
import {MatDialog } from '@angular/material/dialog';

export interface DialogData2 {
  eventdetails: string;
}

@Component({
  selector: 'app-modelmeeting',
  templateUrl: './modelmeeting.component.html',
  styleUrls: ['./modelmeeting.component.scss']
})
export class ModelmeetingComponent implements OnInit {

  @Input() hash: string;
  events : any;
  displayedColumns: string[] = [ 'agency', 'contact', 'address', 'date', 'step',  ];
  

  constructor(
    private get: HttpService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.getModalEvent();
  }

  getModalEvent(){
    this.get.getModelEvent(this.hash).subscribe(res=>{
      console.log('modal event', res);
      this.events = res;
    })
  }

  handleEvent(elem){
     console.log(elem);
 
     const dialogRef = this.dialog.open(BookingdetailsComponent, 
       {
         height: 'auto',
         width: 'auto',
         data: { eventdetails : elem }
       });
    
  dialogRef.afterClosed().subscribe(result => {
    if(result == 'ok'){
      this.getModalEvent();
    }
   })
 
   }

   formatDate(isodate){
    var dateOptions : Intl.DateTimeFormatOptions = {  year: 'numeric', month: 'long', day: '2-digit', hour : 'numeric', minute : 'numeric' };
    const Travel = { dateOptions };

    var res = new Date(isodate).toLocaleDateString('fr-FR', Travel.dateOptions);
    return res;
   }

}
