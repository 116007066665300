<div *ngIf="clientTypeBool">
  <div
    *ngFor="let x of clientTypeList"
    class="agency-card"
    (click)="SelectType(x)"
  >
    <div
      style="
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      "
    >
      {{ x }}
    </div>
  </div>
</div>

<div *ngIf="CountryList">
  <div
    *ngFor="let x of countrylist"
    class="agency-card"
    (click)="SelectCountry(x.name)"
  >
    <div
      style="
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      "
    >
      {{ x.name }}
    </div>
  </div>
</div>

<div *ngIf="agencyBool">

 
    <mat-spinner *ngIf="loading">
    </mat-spinner>


  <div style="display: flex;flex-direction: column; justify-content:  center; align-items: center; margin-bottom: 5px;" *ngIf="agency">
    <div>
      <input placeholder="Find a client" (keyup.enter)="goSearch($event.target.value)" />
     
    </div>

  </div>

  <div style="display: flex; flex-direction: row; flex-wrap: wrap" *ngIf="!loading">

 
  
    <div *ngFor="let x of agency;" style="display: flex; flex-direction: row;flex-wrap: wrap;">

      <div class="agency-card cardLetter"  >
        <div class="event-card-round-letter">
          <div class="bigLetter">
            {{ x.letter }}
          </div>
        </div>
      </div>

      <div
      class="agency-card"
      *ngFor="let y of x.data; let i = index"
      (mouseover)="getDetails($event,y.agency_hash)"
    (mouseout)="getDetails($event,y.agency_hash)"
    (click)="SelectAgency(y.agency_hash)"
      [style.backgroundImage]="'url(' + y.agency_logo + ')'"
    >
      <div
        id="{{ y.agency_hash }}"
        [ngClass]="{
          agencyInfos: y.agency_logo !== '',
          agencyInfos2: y.agency_logo == ''
        }"
      >
        <div
          *ngIf="y.agency_name"
          style="
            font-size: 14px;
            text-align: center;
            color: white;
            white-space: wrap;
            padding: 5px;
          "
        >
          {{ y.agency_name }}
        </div>
      </div>
    </div>


    </div>
  


   

  
    
  </div>


 

  <!--
  <div class="agency-card" *ngFor="let x of agency; let i = index" (mouseover)="getDetails($event,i)"
    (mouseout)="getDetails($event,i)" [style.backgroundImage]=" 'url('+ x.agency_logo+')' "
    (click)="SelectAgency(x.agency_hash)">
    <img src="../../assets/ig_star.png"
      style="position: absolute; top: 10px; left: calc(50% + 35px); height: 20px;z-index: 1000"
      *ngIf="x.client_modelslink" />

    <div id="{{ 'details' + i }}" [ngClass]="{
    'agencyInfos': x.agency_logo !==  '',
    'agencyInfos2': x.agency_logo == ''
  }">

      <div *ngIf="x.agency_name"
        style="font-size: 14px; text-align: center; color: white; white-space: wrap; padding: 5px;">{{
    x.agency_name}}
      </div>

    </div>

  </div>
--></div>
