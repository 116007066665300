<mat-spinner class="centred"

*ngIf="spinnerProgress2">
</mat-spinner>


<div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">


  <div>
    <table
    *ngIf="!spinnerProgress2"
      class="w3-table w3-striped w3-bordered"
      style="
        width: 80%;
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
      "
    >
      <tr class="w3-bar">
    
        <th>When</th>
        <th><span style="white-space: nowrap">Nbre of Email</span></th>

        <th>Selected Model</th>
        <th>Action</th>
      </tr>
    
      <tr  class="w3-bar" *ngFor="let x of schedule; let i = index">
    
        <td style="min-width: 100%;">
        <div style="white-space: nowrap;">
          {{ getRelativeTime(x.schedule_time) }} 
        </div> 
        </td>
        
        <td>
          {{ x.nbresent }}
        </td>
    

    
        <td>
          <div style="display: flex; flex-direction: row">
            <div *ngFor="let y of x.model_send" style="margin: 2px">
              <img [src]="y" style="height: 50px; border-radius: 50%" />
            </div>
          </div>
        </td>
    
        <td>
          <i (click)="goPushDetailsModal(x.schedule_time, x.modelsendstring)" style="cursor: pointer" class="material-icons">
            remove_red_eye
          </i>

        </td>
    
      </tr>
    </table>
    
  </div>

  <div style="margin-top: 50px; text-align: center;">
    Scheduled Push Email are check every hour at HH:09
  </div>

</div>


<button class="faba" (click)="sendModel()" mat-fab color="primary">+</button>
