<mat-spinner class="centred" *ngIf="spinnerProgress2"> </mat-spinner>

<div
  style="
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  "
>
  <div>Schedule Time : {{ getRelativeTime(scheduleTime) }}</div>
  <div style="display: flex; flex-direction: row">
    <div *ngFor="let x of modelSend">
      <img [src]="x" style="height: 60px; border-radius: 50%; margin: 5px" />
    </div>
  </div>
</div>

<table
  *ngIf="!spinnerProgress2"
  class="w3-table w3-striped w3-bordered"
  style="
    width: 80%;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  "
>
  <tr class="w3-bar">
  
    <th><span style="white-space: nowrap">Destinataire</span></th>

    <th>Link</th>
    <th>Action</th>
  </tr>

  <tr class="w3-bar" *ngFor="let x of push; let i = index">


    <td>
      {{ x.to_email }}
    </td>

    <td>
      <span style="cursor: pointer;" (click)="goLink(x.package_link)">{{ x.package_link }}</span>
    </td>

    <td>
      <i style="cursor: pointer" (click)="delSchedule(x.id)" class="material-icons"> delete </i>
    </td>
  </tr>
</table>

<div mat-dialog-actions>
  <div
    style="
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      min-width: 100%;
    "
  >
    <div style="display: flex; flex-direction: flex-start">
      <button mat-button (click)="onNoClick()">Close</button>
    </div>
  </div>
</div>
