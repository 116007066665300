
    <mat-spinner class="centred"
    *ngIf="spinnerProgress">
    </mat-spinner>
  


<form [formGroup]="chgStateGroup" *ngIf="!spinnerProgress" style="width:80%;margin-top:15px;margin-left:50%; transform: translate(-50%, 0);">

    
  Your gonna approved this model, please choose the way you want to inform him/here.
  

      <div style="display:block; margin-top:10px;">
      <mat-checkbox formControlName="email">E-Mail</mat-checkbox>
      <mat-checkbox formControlName="appmess">App Message</mat-checkbox>
    </div>

     <mat-form-field style="display:block;margin-top:10px;">
      <textarea  formControlName="subject" matInput placeholder="Subject">{{SubjectMessage}}</textarea>
    </mat-form-field>
  
  
    <mat-form-field style="display:block;margin-top:10px;">
      <textarea  formControlName="message" matInput placeholder="App Message">{{ApprovedMessage}}</textarea>
    </mat-form-field>
  
    <div mat-dialog-actions >
      <button mat-button (click)="onNoClick()">Cancel</button>
      <button mat-button (click)="onOkClick()" cdkFocusInitial>Ok</button>
    </div>
    
    
  </form>

