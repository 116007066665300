<div>
        <button class="fab" (click)="addDocuments()" mat-fab color="primary">+</button>
        <mat-tab-group>

                <mat-tab *ngFor="let x of doctype" [label]="x.doctype">
                        <ng-template matTabContent>
                 <app-doccategory [type]="x.doctype"></app-doccategory>
                        </ng-template>
                      </mat-tab>
              
            
            </mat-tab-group>

      
</div>
