
<mat-form-field class="example-chip-list">
    <mat-chip-list #chipList aria-label="Fruit selection">
      <mat-chip
        *ngFor="let fruit of fruits"
        [selectable]="selectable"
        [removable]="removable"
        (removed)="remove(fruit)">
        {{fruit}}
        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
      </mat-chip>
      <input
        placeholder="Category"
        #fruitInput
        [formControl]="fruitCtrl"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="addOnBlur"
        (matChipInputTokenEnd)="add($event)">
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
      <mat-option *ngFor="let fruit of filteredFruits | async" [value]="fruit">
        {{fruit}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>