<mat-spinner class="centred" *ngIf="loader">
</mat-spinner>

<div *ngIf="!loader"
  style="display:flex; flex-direction: column; height: 100%; width: 100%; justify-content: center;align-items: center;">


  <table *ngIf="status == 'ok' " mat-table [dataSource]="docs" class="mat-elevation-z1"
    style=" width: 90%; margin: 5px;  margin-top: 20px;">
    <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->

    <!-- Weight Column -->
    <ng-container matColumnDef="libelle">
      <th mat-header-cell *matHeaderCellDef>libelle</th>
      <td mat-cell *matCellDef="let x">{{x.libelle}} </td>
    </ng-container>

    <!-- Position Column -->
    <ng-container matColumnDef="brutEmployeur">
      <th mat-header-cell *matHeaderCellDef>Brut Emp.</th>
      <td mat-cell *matCellDef="let x">{{x.brutEmployeur}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="netMannequin">
      <th mat-header-cell *matHeaderCellDef>Net Mannequin</th>
      <td mat-cell *matCellDef="let x">{{x.netMannequin}} </td>
    </ng-container>



    <!-- Symbol Column -->
    <ng-container matColumnDef="paid_on">
      <th mat-header-cell *matHeaderCellDef>paid_on</th>
      <td mat-cell *matCellDef="let x">


        <div>
          <button *ngIf="!x.paid_on" mat-stroked-button style="margin-left: 10px" (click)="setDate(x.id)">Set Paid
            Date</button>
          <div *ngIf="x.paid_on">{{ getDate(x.paid_on) }}</div>

        </div>

      </td>
    </ng-container>




    <ng-container matColumnDef="Action">
      <th mat-header-cell *matHeaderCellDef>Action</th>
      <td mat-cell *matCellDef="let x">
        <div>
          <i (click)="downloadfile(x.url)" style="cursor: pointer; margin-right: 5px;" class="material-icons">
            get_app
          </i>
          <i (click)="sendMail(x.id)" style="cursor: pointer; margin-right: 5px;" class="material-icons">mail</i>
          <i (click)="delFile(x.id)" style="cursor: pointer" class="material-icons">
            delete_outline
          </i>
        </div>
      </td>


    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
        <div class="example-element-detail" [@detailExpand]=" 'element' == expandedElement ? 'expanded' : 'collapsed'">
          <div>qsdqsd</div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
    class="example-element-row"
    [class.example-expanded-row]="expandedElement === 'element' "
      (click)="expandedElement = expandedElement === 'element' ? null : 'element' "></tr>
  </table>



  <div style="margin-top: 20px" *ngIf="docs == 'noresult' ">
    <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
      <div>
        <img src="../../../assets/box.png" style="height: 50px" />
      </div>
      <div style="text-align: center;">
        No Fiche de Paie
      </div>
    </div>

  </div>

  <div>
    <button class="fab" (click)="addDocs()" mat-fab color="primary">+</button>
  </div>

</div>