import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';


@Component({
  selector: 'app-pdfviewer',
  templateUrl: './pdfviewer.component.html',
  styleUrls: ['./pdfviewer.component.scss']
})
export class PdfviewerComponent implements OnInit {

  pdfSrc : string;

  fileExt:string;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<PdfviewerComponent>,
  ) { }

  ngOnInit(): void {
    this.pdfSrc = this.data['fileUrl'];
    this.fileExt = this.data['fileType'];
  }

  closeWindow(){
    this.dialogRef.close();
  }

}
