<div [formGroup]="chgStateGroup" style="margin-top: 15px">
  <mat-form-field class="input">
    <mat-select placeholder="Libelle" formControlName="libelle">
      <mat-option *ngFor="let x of libelle" [value]="x">
        {{ x }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="input">
    <input
      matInput
      placeholder="Brut Employeur"
      formControlName="brutEmployeur"
    />
  </mat-form-field>

  <mat-form-field class="input">
    <input
      matInput
      placeholder="Net Mannequin"
      formControlName="netMannequin"
    />
  </mat-form-field>

  <mat-form-field style="display: block; margin-top: 10px">
    <mat-select
      placeholder="Relative Jobs"
      multiple
      (selectionChange)="changeTO($event)"
    >
      <mat-option *ngFor="let y of relativeJobs" [value]="y">
        {{ y.note }} - {{ y.in_pocket }} € - ( {{ y.numero_facture }} )
      </mat-option>
    </mat-select>
  </mat-form-field>

  <table class="w3-table w3-striped" *ngIf="relativeJobsSelected">
    <tr>
      <th>Job Name</th>
      <th>Brut Employeur</th>
      <th>Facture</th>
    </tr>
    <tr *ngFor="let x of relativeJobsSelected">
      <td>
        <div style="display: flex; flex-direction: row; align-items: center">
          <div style="margin-right: 10px">{{ x.note }}</div>
          <div>
            <i
              (click)="setNewMontant(x.id, 'libelle')"
              style="cursor: pointer"
              class="material-icons"
            >
              edit
            </i>
          </div>
        </div>
      </td>
      <td >
        <div style="display: flex; flex-direction: row; align-items: center">
            <div style="margin-right: 10px">{{ x.in_pocket }}</div>
            <div>
              <i
                (click)="setNewMontant(x.id, 'montant')"
                style="cursor: pointer"
                class="material-icons"
              >
                edit
              </i>
            </div>
          </div>
      </td>
      <td>{{ x.numero_facture }}</td>
    </tr>
  </table>

  <div
    style="
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-top: 20px;
    "
  >
    <div>
      <button
        *ngIf="file == null"
        mat-stroked-button
        color="primary"
        (click)="uploadFile()"
      >
        Add Fiche de Paie
      </button>
      <input
        type="file"
        id="file1"
        style="display: none"
        (change)="upload($event.target.files)"
      />
    </div>
  </div>

  <div
    style="
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-top: 20px;
    "
  >
    <div>
      <button mat-stroked-button (click)="goCancel()">Cancel</button>
    </div>
    <div style="margin-left: 10px">
      <button mat-stroked-button (click)="save()">Save</button>
    </div>
  </div>
</div>
