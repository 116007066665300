

<div  style="float:left;height:100%; background-color: #FAFAFA; min-width: 30%; max-width: 30%;">

  <div *ngIf="!conversation">
    Pas de reusltat
  </div>
  
  <div style="height: 30px;width:100%; position:relative;">
    <img style="cursor:pointer;" height="24" src="./assets/settings.png" [matMenuTriggerFor]="menu"/>
   <span style="position: relative;font-weight: 400; letter-spacing: 1px; text-align: center;"> {{box}}</span>

    <input matInput placeholder="Search a Model" (click)="SelectConvers()">


  </div>

  <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="getAllConvers('Mailbox')">Mailbox</button>
  <!--  <button mat-menu-item (click)="getAllConvers('Unread')">Unread</button>-->
    <button mat-menu-item (click)="getAllConvers('Archive')">Archive</button>
    <button mat-menu-item (click)="getAllConvers('Delete')">Trash</button>
  </mat-menu>


    <ul class="w3-ul w3-card-4" style="overflow-y: auto; height: calc(100% - 30px); " *ngIf="conversation" >
      
      <li class="w3-bar bar-hover" [ngClass]="{'active': active == x.conversation}" style="cursor:pointer;z-index: 10; position:relative;" [id]="x.conversation" *ngFor="let x of conversation; let i = index" [attr.data-index]="i"  (click)="goConvers(x.conversation)">
        <div *ngIf="x.conversation == active"  [matMenuTriggerFor]="menu2" style="position: absolute ; right: 5px;bottom:5px;">
          <img height="15" src="./assets/settings2.png" />
        </div> 

        <mat-menu #menu2="matMenu">
            <button mat-menu-item (click)="goArchive(x.conversation, i, 'archive')">Archive</button>
          <button mat-menu-item (click)="goArchive(x.conversation, i, 'delete')">Delete</button>
        </mat-menu>
    <!--   <span class="w3-bar-item w3-button w3-xlarge w3-right" style="z-index: 20" (click)="goArchive(x.conversation, i, 'archive')">
        <img height="32" src="./assets/archive2.png" />
       </span> -->
      <img [src]="x.avatar" class="w3-bar-item w3-circle w3-hide-small" style="width:85px">
        <div class="w3-bar-item">
         <span id="{{'first'+x.conversation}}" class="w3-large" [ngClass]="x.read == 1 ? 'read': 'unread' ">{{x.first}}</span><br>
          <span id="{{'mess'+x.conversation}}" [ngClass]="x.read == 1 ? 'read': 'unread'">{{x.message | messPipe:20 }}</span>
        </div>
      </li>

    </ul>
    
  </div>

  <div  class="chatCtn">
    <app-chat #chat ></app-chat>

  </div>

  <div  style="height:100%; background-color: #FAFAFA; min-width: 30%; max-width: 30%;float:left;" class="removeOnMobile" >
    <app-messprofile #messprofile></app-messprofile>
  </div>