import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Component, ElementRef, ViewChild, OnInit, Input} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocomplete} from '@angular/material/autocomplete';
import {  MatChipInputEvent} from '@angular/material/chips';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

import { HttpService } from '../../http.service';

@Component({
  selector: 'app-modelcategory',
  templateUrl: './modelcategory.component.html',
  styleUrls: ['./modelcategory.component.scss']
})
export class ModelcategoryComponent implements OnInit {

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  fruitCtrl = new UntypedFormControl();
  filteredFruits: Observable<string[]>;

  allFruits: any;
  fruits : any;

  @ViewChild('fruitInput', {static: false}) fruitInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', {static: false}) matAutocomplete: MatAutocomplete;

  @Input() hash: string;
  @Input() type: string;

  constructor(
    private get: HttpService,
  ) {
    this.fruits = [];
 /*   this.filteredFruits = this.fruitCtrl.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) => fruit ? this._filter(fruit) : this.allFruits.slice()));*/
   }

  ngOnInit() {
    this.getCategoryModel();
    this.getModeltype();
  }

  getCategoryModel(){
    this.get.getCategoryModel(this.hash, this.type).subscribe(res=>{
      console.log('getCategoryModel', res);
      if(res['status'] == 'exist'){
        this.fruits = res['data'];
      }
    })
      
    }



  getModeltype(){
    this.get.getModeltype('listcategory').subscribe(res=>{
      console.log('list cagegory', res);
      if(this.type == 'modal'){
        this.allFruits = res['modelcategory'];
      }

      if(this.type == 'client'){
        this.allFruits = res['clientcategory'];
      }
     
      this.filteredFruits = this.fruitCtrl.valueChanges.pipe(
        startWith(null),
        map((fruit: string | null) => fruit ? this._filter(fruit) : this.allFruits.slice()));
    })
  }


  add(event: MatChipInputEvent): void {
    console.log('add cate', event);
    // Add fruit only when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;

      // Add our fruit
      if ((value || '').trim()) {
        this.fruits.push(value.trim());      
      }

      // Reset the input value
      if (input) {
        input.value = '';
      }

      this.fruitCtrl.setValue(null);
    }


  }

  addCategory(value){
    console.log('add category to model', value);
    this.get.addCategoryToModel(this.hash, value, this.type).subscribe(res=>{
      console.log('add category',res);
    /*  if(res == 'ok'){
        this.getModeltype();
      }*/
    })
  }

  remove(fruit: string): void {
    const index = this.fruits.indexOf(fruit);
    console.log('category', fruit);
    if (index >= 0) {
      this.fruits.splice(index, 1);
      this.deleteModalCategory(fruit);
    }
  }

  deleteModalCategory(value){
    this.get.deleteModalCategory(this.hash, value, this.type).subscribe(res=>{
      console.log('add category',res);
    })
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    console.log('selected', event);
    this.fruits.push(event.option.viewValue);
    this.addCategory(event.option.viewValue);
    this.fruitInput.nativeElement.value = '';
    this.fruitCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allFruits.filter(fruit => fruit.toLowerCase().indexOf(filterValue) === 0);
  }



}
