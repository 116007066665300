import { Component, OnInit, Inject } from '@angular/core';
import {  MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-confirmmodal',
  templateUrl: './confirmmodal.component.html',
  styleUrls: ['./confirmmodal.component.scss']
})
export class ConfirmmodalComponent implements OnInit {

  text : any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<ConfirmmodalComponent>,
  ) { }

  ngOnInit(): void {
    this.text = this.data['text']
  }

  onNoClick(){
    this.dialogRef.close();
  }

  onOkClick(){
    this.dialogRef.close();
  }

}
