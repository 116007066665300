<div class="sidenav" id="sidenav">
  <div class="sideNavClick" (click)="toggleMobileMenu()">
    <div class="sideNavClickInner">
      <div class="centred">
        <div class="navTrigger" id="navTrigger">
          <i></i><i></i><i></i>
        </div>
      </div>
    </div>
  </div>
  <ul class="paddingTopOnMobile">
    <li class="logoCtn">
      <img
        src="assets/logo_seemo.png"
        style="height: 50px; margin-left: 50%; transform: translate(-50%, 0)"
      />
    </li>

    <li *ngFor="let x of menu">
      <a [routerLink]="[x.item]" routerLinkActive="active">
        <div class="ctnMenu">
          <div class="titleMenu" [style.width.px]="titleMenuWidth">
            <span class="titleLabel">{{ x.name }}</span>
          </div>
        </div>
      </a>
    </li>

    <li style="position: relative;">
        <div (click)="goFastMeet()" class=" titleMenu" [style.width.px]="titleMenuWidth" >
          <span class="titleLabel">Fast Meet</span>
        </div>
    </li>

    <li style="position: relative">
      <div (click)="goFastLink()" class="titleMenu"   [style.width.px]="titleMenuWidth">
        <div class="titleLabel">Fast Link</div>
      </div>
    </li>


  </ul>

    <!--
  <div (click)="agendaBool = !agendaBool" class="agendaIcon removeOnMobile">
    <i style="cursor: pointer" class="material-icons">calendar_today</i>
  </div>


  <div [ngStyle]="{'display':agendaBool ? 'block' : 'none' }" class="agendaNotif">
    <app-agenda></app-agenda>
  </div>
  
  -->

  <div
    class="circle removeOnMobile"
    [matMenuTriggerFor]="menu"
    *ngIf="!user_avatar"
    [style.backgroundImage]="'url(' + user_avatar + ')'"
  >
    <span class="initials">{{ initials }}</span>
  </div>

  <div
    class="circle removeOnMobile"
    *ngIf="user_avatar"
    [matMenuTriggerFor]="menuBan"
    [style.backgroundImage]="'url(' + user_avatar + ')'"
  >
    <span class="initials"></span>
  </div>

  <mat-menu #menuBan="matMenu">
    <ng-container *ngFor="let x of banmenu">
      <button *ngIf="x" mat-menu-item (click)="this[x.func]()">
        {{ x.label }}
      </button>
    </ng-container>
  </mat-menu>
</div>
