import { Component, OnInit, Inject } from '@angular/core';
import {  MatDialogRef,  MAT_DIALOG_DATA,  MatDialog } from '@angular/material/dialog';

import { DialogData } from '../push.component';
import { HttpService } from '../../../http.service';


@Component({
  selector: 'app-seedestinataire',
  templateUrl: './seedestinataire.component.html',
  styleUrls: ['./seedestinataire.component.scss']
})
export class SeedestinataireComponent implements OnInit {

  tosend: any;
  
  constructor(
    public dialogRef: MatDialogRef<SeedestinataireComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private http : HttpService,
  ) { }

  ngOnInit() {
    console.log('destinataire', this.data['tosend']);
    this.tosend = this.data['tosend'];
  }

  deleteDesti(i){
    console.log('destinataire index', i);
    this.tosend.splice(i,1);
    console.log('new desti', this.tosend);
  }

  onNoClick(){
    this.dialogRef.close('empty');
  }

  onOkClick(){
    this.dialogRef.close(this.tosend);
  }

}
