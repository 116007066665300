<div style="display: flex; flex-direction: row; overflow-y: auto;">

  <div style="border: 4px solid black">

      <table style="width:100%">
          <tr>
            <th>Sended To :</th>
          </tr>
          <tr>
            <td>
                <div *ngFor="let x of in" [style.backgroundImage]=" 'url('+ x.agencyhash +')' "  class="agency-card" [ngClass]="getState(x)" >

   
                    <div class="bulle">
                      <span class="tooltiptext2">
                        <div *ngFor="let y of x.data"> <div style="display: inline-block; white-space: nowrap">{{ y.agencycontact }} {{ y.type }} {{ y.date }}</div>
                        </div>
                      </span>
              
                    <div style="position:relative; width: 100%; height: 100%">
                     <div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)"> {{x.numrows}} </div>
                    </div>
                 
                    </div>
              
                
                  </div>
            </td>
          </tr>
          <tr>
        </table>


     




  </div>

  <div style="border: 1 px solid black">

      <table style="width:100%">
          <tr>
            <th>   Not Sended To : </th>
          </tr>
          <tr>
            <td>
                <div *ngFor="let x of notin" [style.backgroundImage]=" 'url('+ x.agencyhash +')' "  class="agency-card" >
      
                  </div>
            </td>
          </tr>
          <tr>
        </table>


 

  </div>

</div>