
<div  *ngIf="showPackageList" style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
    <div>Please select the showpackage to send</div>
    <div style="margin-top: 20px;display: flex; flex-direction: row; overflow-x: scroll;max-width: 100%;">

        <div  *ngFor="let x of showPackageList" (click)="selectThisShowPackage(x.packageId)" style="margin: 10px;">
            <div [style.backgroundImage]="getImage(x.packageCover)" class="showPackageItem"  ></div>
            <div style="text-align: center;">{{ x.packageName }}</div>
        </div>
     
    
    </div>
</div>





<div *ngIf="!showPackageList">
    <div>
        No showpackage created for this model !
    </div>
  <div>
      please create 1 before
  </div>
    
</div>

<div mat-dialog-actions style="margin-top: 20px;" >
    <button mat-button (click)="onNoClick()">Cancel</button>
</div>
