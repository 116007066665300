import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog,  MatDialogRef,  MAT_DIALOG_DATA} from '@angular/material/dialog';
//import { DialogData } from '../agenda.component';
//timport { DialogData2 } from '../../pictures/modelmeeting/modelmeeting.component';
import { HttpService } from '../../http.service';

import { BookingchangeComponent } from './bookingchange/bookingchange.component';
import { JoindocsComponent } from '../gobook/joindocs/joindocs.component';

import { ModelOverviewComponent } from '../../pictures/model-overview/model-overview.component';
import { AgencyDetailsComponent } from '../../agency-details/agency-details.component';


export interface DialogData {
  eventdetails: string;
}


@Component({
  selector: 'app-bookingdetails',
  templateUrl: './bookingdetails.component.html',
  styleUrls: ['./bookingdetails.component.scss']
})
export class BookingdetailsComponent implements OnInit {

  event : any;
  details : any;
  start: string;
  end: string;
  checked : false;
  loader : boolean;

  docs : any;
  displayedColumns: string[] = ['Name', 'Type', 'Date', 'Delete', 'Download', 'Public'];

  constructor(
    public dialogRef: MatDialogRef<BookingdetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    //@Inject(MAT_DIALOG_DATA) public data2: DialogData2,
    private http: HttpService,
    public dialog: MatDialog,
  ) {
    this.loader = false;
   }

  ngOnInit() {
    console.log(this.data);
    this.event = this.data['eventdetails'];
    this.getEventDetails();
  }

  getEventDetails(){
    this.http.getEventDetails(this.event).subscribe(res=>{
      console.log('event details', res);
      var dateOptions : Intl.DateTimeFormatOptions = {  year: 'numeric', month: 'long', day: '2-digit', hour : 'numeric', minute : 'numeric' };
      const Travel = { dateOptions };

      this.start = new Date(res['from']).toLocaleDateString('fr-FR', Travel.dateOptions);
      this.end = new Date(res['to']).toLocaleDateString('fr-FR', Travel.dateOptions);
      this.details = res;
    })
  }
  onOkClick(){
    this.dialogRef.close('ok');
  }

  cancelEvent(){
    this.http.cancelEvent(this.event).subscribe(res => {
      console.log(res);
      if(res == 'deleted'){
        this.dialogRef.close('update');
      }
    })
  }

  changeData(){

    const dialogRef = this.dialog.open(BookingchangeComponent, 
      {
        height: 'auto',
        width: 'auto',
        data: { eventdetails : this.event }
      });
   
 dialogRef.afterClosed().subscribe(result => {
  if(result == 'update'){
    this.dialogRef.close('update');
  }
  })

  }

  joinDocs(){
   
      const dialogRef = this.dialog.open(JoindocsComponent, 
        {
          height: 'auto',
          width: 'auto',
        });
     
    dialogRef.afterClosed().subscribe(result => {
     
        if(result.status == 'success'){
          this.uploadFileAttachedToEvent(result.data.fileName, result.data.fileContent);
        }
    })
   
  }

  async uploadFileAttachedToEvent(fileName, fileContent){
    this.loader = true;
    let fileE = { 'filename' : fileName, 'filecontent' : fileContent };
    let dataE = { 'bookingid': this.event, 'file' : fileE };

    var funcE = { type: 'BOOKING', func: 'addFileToBooking' };    
    var Postdata = { func: funcE, data : dataE };

    console.log('res', Postdata);
    let res = await this.http.goAcess(Postdata);
    this.loader = false;
    this.getEventDetails();
    console.log('res', res);
  }

  async deleteFile(fileid){
    this.loader = true;

    let dataE = { 'fileid': fileid };

    var funcE = { type: 'BOOKING', func: 'deleteFileFromBooking' };    
    var Postdata = { func: funcE, data : dataE };

  
    let res = await this.http.goAcess(Postdata);
    console.log('res', Postdata, 'res', res);
    this.loader = false;
    this.getEventDetails();
  }

  async toggleFileVisibility(fileid){
   // this.loader = true;

    let dataE = { 'fileid': fileid };

    var funcE = { type: 'BOOKING', func: 'toggleFileVisibility' };    
    var Postdata = { func: funcE, data : dataE };

    console.log('res', Postdata);
    let res = await this.http.goAcess(Postdata);
    console.log('res', Postdata, 'res', res);
  //  this.loader = false;
    this.getEventDetails();
  }

  goLink(url){
    window.open(url);
  }

  goModel(elem: string): void {
    const dialogRef = this.dialog.open(ModelOverviewComponent, {
      data: { modal: elem },
    });

    dialogRef.afterClosed().subscribe((result) => {
      //     console.log('The dialog was closed');
    });
  }

  goAgency(elem: string) {
    console.log('router', elem);
    // this.router.navigate(['members/agency',elem] );

    const dialogRef = this.dialog.open(AgencyDetailsComponent, {
      data: { agency: elem },
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed', result);
    });
  }
 


}
