<div style="display: flex; flex-direction: column;">
  <div>
    <div
      *ngIf="spinnerProgress"
      style="
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 100vh;
        width: 100%;
      "
    >
      <mat-spinner> </mat-spinner>
    </div>

    <div *ngIf="!spinnerProgress">
      <div *ngIf="type !== 'search'">
        <!--
        <mat-button-toggle-group
          #group="matButtonToggleGroup"
          (change)="UpdateGender($event.value)"
          [value]="gender"
          style="left: 50%; transform: translate(-50%, 0)"
        >
          <mat-button-toggle value="male">
            <img src="assets/male.png" height="40px" class="logo" />
          </mat-button-toggle>
          <mat-button-toggle value="both">
            <img src="assets/both.png" height="40px" class="logo" />
          </mat-button-toggle>
          <mat-button-toggle value="female">
            <img src="assets/female.png" height="40px" class="logo" />
          </mat-button-toggle>
        </mat-button-toggle-group>
        -->

        <div
          style="
            display: flex;
            flex-direction: columns;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
          "
        >



        </div>
      </div>

      
      <div *ngFor="let y of modelsToShow; let j = index"  style="margin-top: 10px">
      <div class="event-card card-letter">
        <div class="event-card-letter">{{y.letter}}</div>
      </div>
      
      <div
      class="event-card"
      *ngFor="let x of y.data; let i = index"
      (mouseover)="getDetails($event, i, j)"
      (mouseout)="getDetails($event, i, j)"
      [style.backgroundImage]="
        x.portrait ? 'url(' + x.portrait + ')' : 'url(assets/girl_vide.jpg)'
      "
      (click)="goModel(x.hash)"
    >
      <div
        style="
          position: absolute;
          bottom: 0;
          height: 20px;
          width: 100%;
          background-color: black;
          opacity: 0.7;
        "
      >
        <span class="name">{{ x.first }}</span>
      </div>

      <div id="{{ 'details' + i + j }}" class="modelInfos">
        <img
          *ngIf="x.register == 'Facebook'"
          class="registration_type"
          src="assets/facebook.png"
        />
        <img
          *ngIf="x.register == 'activated'"
          class="registration_type"
          src="assets/email_activated.png"
        />
        <img
          *ngIf="x.register == 'notactivated'"
          class="registration_type"
          src="assets/email_notactivated.png"
        />
        <div
          class="avatar"
          style="
            z-index: 200;
            position: relative;
            width: 50px;
            height: 80px;
            top: 5px;
            left: 50%;
            transform: translate(-50%, 0);
          "
        >
          <img *ngIf="x.avatar" src="{{ x.avatar }}" class="avatar" />
          <div *ngIf="!x.avatar">
            <img
              *ngIf="x.gender == 'female'"
              src="assets/avatar_female.png"
              class="avatar"
            />
            <img
              *ngIf="x.gender == 'male'"
              src="assets/avatar_male.png"
              class="avatar"
            />
          </div>
        </div>

        <div
          style="
            position: relative;
            margin: 5px;
            color: white;
            font-family: 'Montserrat', sans-serif;
          "
        >
          <div *ngFor="let y of x.mensurations">
            <div *ngIf="y.valeur" style="display: block; font-size: 10px">
              {{ y.name }} : {{ y.valeur }}
            </div>
          </div>
        </div>
      </div>
    </div>

      </div>


    </div>
  </div>

  <div style="text-align: center; font-size: 32px">{{ nombre }} Models</div>
</div>
