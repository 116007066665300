import { Component, OnInit, Inject } from '@angular/core';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { HttpService } from '../../../http.service';

import { DialogData } from '../modelnotes.component';
import { UntypedFormBuilder, Validators, UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-addnotes',
  templateUrl: './addnotes.component.html',
  styleUrls: ['./addnotes.component.scss']
})
export class AddnotesComponent implements OnInit {

  note: any;
  chgStateGroup : UntypedFormGroup;

  constructor(
    private http: HttpService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<AddnotesComponent>,
    private fb: UntypedFormBuilder,
  ) {
    this.prepareForm();
   }

   prepareForm(){
    this.chgStateGroup = this.fb.group({
      note: ['', Validators.required],
    });
  }

  ngOnInit() {
    console.log('modal', this.data);
  }

  goCancel(): void {
    this.dialogRef.close('close');
  }

  saveNote(){
   console.log('save note', this.chgStateGroup.get('note').value);
   this.http.saveNote(this.chgStateGroup.get('note').value, this.data['modal'], this.data['type']).subscribe(res=>{
     console.log('save note', res);
     this.dialogRef.close('success');
   })
 
  }

}
