<div style="display: block" *ngIf="stats">
  <div style="display: flex;flex-direction: row; min-height: 100vh;width:100% ">
    <div style="width: 70%">
      <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions"
        [legend]="barChartLegend" [chartType]="barChartType">
      </canvas>
    </div>

    <div style="width: 30%">

      <div style="margin-top: 30px; display: flex; flex-direction: row; justify-content: space-evenly; width: 100%; margin-left: auto; margin-right: auto;">
        <div  [ngClass]=" showtype == 'ca' ? 'active': 'noactive'" (click)="SelecteShowType('ca')">CA</div>
        <div  [ngClass]=" showtype == 'rem' ? 'active': 'noactive'"(click)="SelecteShowType('rem')">Rem</div>
        <div  [ngClass]=" showtype == 'gain' ? 'active': 'noactive'" (click)="SelecteShowType('gain')">Gain</div>
      </div>

   
      <div>
        <canvas baseChart
        [data]="pieChartData"
        [labels]="barChartLabels"
        [chartType]="pieChartType"
        [options]="pieChartOptions"
        [legend]="pieChartLegend"
        >
      </canvas>
      </div>

    </div>

  </div>

</div>