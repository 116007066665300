<div style="margin-top: 20px; display: flex; flex-direction: column; justify-content: center; align-items: center;">
  <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;" *ngIf="!events">
    <div>
      <img src="../../../assets/box.png" style="height: 50px" />
    </div>
    <div style="text-align: center;">
      No Events
    </div>
  </div>


  <table *ngIf="events" mat-table [dataSource]="events" class="mat-elevation-z1" style=" width: 90%; ">

    <ng-container matColumnDef="agency">
      <th mat-header-cell *matHeaderCellDef>Agency</th>
      <td mat-cell *matCellDef="let x">
        <img [src]="x.logo" height="40px" />
      </td>
    </ng-container>

    <ng-container matColumnDef="contact">
      <th mat-header-cell *matHeaderCellDef>Meeting with</th>
      <td mat-cell *matCellDef="let x">
        {{x.persontoask}}
        <span *ngIf="x.personcontact">
          ( {{ x.personcontact }} )
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="address">
      <th mat-header-cell *matHeaderCellDef>Adresse</th>
      <td mat-cell *matCellDef="let x">
        <div style="max-width: 150px;">
          {{x.eventaddress}}
        </div>
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>Date</th>
      <td mat-cell *matCellDef="let x">{{ formatDate(x.start) }}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="step">
      <th mat-header-cell *matHeaderCellDef>Etat</th>
      <td mat-cell *matCellDef="let x">
        <img *ngIf="x.step == 'accepted' " src="../../../assets/booking/ok-mark.png" height="20px" />
        <img *ngIf="x.step == 'refused' " src="../../../assets/booking/stop.png" height="20px" />
        <img *ngIf="x.step == 'pending' " src="../../../assets/booking/wait.png" height="20px" />
       
      </td>
    </ng-container>




    <tr mat-header-row *matHeaderRowDef="displayedColumns ; sticky : true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>


</div>
