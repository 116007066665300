import { Component, OnInit, Input, Inject } from '@angular/core';
import {  MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import { HttpService } from '../http.service';
import { Router, ActivatedRoute } from "@angular/router";

import { AgencyDetailsComponent } from '../agency-details/agency-details.component';

export interface DialogData {
  agency: string;
  type: string;
}


@Component({
  selector: 'app-agency-card',
  templateUrl: './agency-card.component.html',
  styleUrls: ['./agency-card.component.css']
})
export class AgencyCardComponent implements OnInit {

  @Input() location: string;
  @Input() type: string;
  @Input() category: string;
  @Input() searchValue : string;

  spinnerProgress2: boolean;
  agency: any;
  croppedImageNews: any;
  agencytopush: any;

  clientToshow : any;

  constructor(
    private get: HttpService,
    private router: Router,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
  ) {
    this.spinnerProgress2 = false;
  }

  ngOnInit() {

    if(this.type !== 'search'){
      if(this.category){
        this.getAgency2(this.location, this.type);
      } else {
        this.getAgency3(this.location);
      }
    } else {
      this.goSearch(this.searchValue);
    }


  }


  getAgency2(location, type) {
    this.spinnerProgress2 = true;

    var criteria = [
      { 'label': 'client_type', 'value': type },
      { 'label': 'agency_location_country', 'value': location },
    ];

    var postData = { 'table': 'agency', 'action': 'getall', 'criteria': criteria };
    this.get.goBackOffice(postData).subscribe(res => {
      console.log('resultat', res);
      this.spinnerProgress2 = false;
      this.agency = this.sortByLetter(res);
      console.log('resultat', this.agency);
    })
  }


  getAgency3(label) {
    this.spinnerProgress2 = true;

    var criteria = [
      { 'label': 'view', 'value': label },
    ];

    var postData = { 'table': 'client_label', 'action': 'getallcategory', 'criteria': criteria };
    this.get.goBackOffice(postData).subscribe(res => {
      this.spinnerProgress2 = false;
      this.agency = this.sortByLetter(res);
      console.log('resultat', this.agency);
    })
  }

  getDetails($event, index) {
    let detail = 'details'.concat(index);
    console.log(detail);
    if ($event.type == 'mouseover') {
      document.getElementById(detail).classList.add('reveal');
    } else {
      document.getElementById(detail).classList.remove('reveal');
    }

  }

  goAgency(elem: string) {
    console.log('router', elem);
    // this.router.navigate(['members/agency',elem] );

    const dialogRef = this.dialog.open(AgencyDetailsComponent, {
      data: { agency: elem, type: this.type },
      panelClass : 'nopadding-box'
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if( (result == 'delete') || (result == 'update') ){
        this.getAgency2(this.location, this.type);
      }
    });
  }

  CreateAgency() {
    const dialogRef = this.dialog.open(CreateAgency, {
      width: '400px',
      //height: '400px',
      data: {
        type: this.type, location: this.location
      },
      panelClass: 'nopadding-box'

    });

    dialogRef.afterClosed().subscribe(result => {
      if ( (result == 'update') || (result == 'inserted') ) {
        this.getAgency2(this.location, this.type);
        this.openSnackBar('Congrats !', 'Inserted')

      }
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  async goSearch(valueE){
    console.log(valueE);
    this.spinnerProgress2 = true;
    this.agency = [];

    var funcE = { type: 'SEARCH', func: 'searchClient' };  
    var dataE = { 'firstorlast' : valueE, 'location' : this.location };
    var Postdata = { func: funcE, data : dataE };
    console.log('Postdata', Postdata);

    let res = await this.get.goAcess(Postdata);
    console.log('res', res);
    this.spinnerProgress2 = false;

   // this.agency = this.sortByLetter(res);
    this.agency = this.sortByLetter(res);
  }


  sortByLetter(dataE){
    var letters = 'abcdefghijklmnopqrstuvwxyz';

    var temp = [];

    for(var i = 0; i < letters.length; i++){
      var letter = letters[i];
 

      var tmp = [];
      for(var j = 0; j < dataE.length; j++){
    //    console.log('letter', letter, 'data', dataE[j].first.toLowerCase().charAt(0))
        if(dataE[j].agency_name.toLowerCase().charAt(0) == letter){
            tmp.push(dataE[j]);
          
        }
        dataE.slice(j, 1);
      }
      if(tmp.length > 0){
        temp[i] = {'letter': letter, 'data' : tmp };
      }
      
    }

    var filtered = temp.filter(function (el) {
      return el != null;
    });
   // console.log('filtered', filtered);
    return filtered;
  }


}


@Component({
  selector: 'create-agency',
  templateUrl: 'create-agency.html',
  styleUrls: ['./createagency.component.css']
})

export class CreateAgency {
  //spinnerProgress : boolean;
  chgStateGroup: UntypedFormGroup;
  chgStatePhotograph: UntypedFormGroup;
  chgStateAdvisor: UntypedFormGroup;

  croppedImageNews: any;
  message: any;
  result: any;
  type: any;
  location: any;
  action: any;

  role : number;

  constructor(
    public dialogRef: MatDialogRef<CreateAgency>,
    private get: HttpService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: UntypedFormBuilder,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,

  ) {
    this.role = parseInt(localStorage.getItem('role'));
    //  this.spinnerProgress = false;
  }

  ngOnInit(): void {
    console.log('data create', this.data);
    this.type = this.data['type'];
    this.location = this.data['location'];

    if ( (this.type == 'agency') || (this.type == 'clients') ){
      this.createForm();
    }

    if (this.type == 'photograph') {
      console.log('create photograph');
      this.createFormPhotograph();
    }

    if (this.type == 'advisor') {
      console.log('create advisor');
      this.createAdvisor();
    }

    if (this.type == 'customlist') {
      this.createCustomList();
    }

    if (this.data['agency']) {
      this.action = 'update';
      this.getType(this.data['agency']);
    }




  }

  onNoClick(): void {
    this.dialogRef.close('echec');
  }

  createCustomList() {
    this.chgStatePhotograph = this.fb.group({
      name: ['', Validators.required],
    });
  }

  createFormPhotograph() {
    this.chgStatePhotograph = this.fb.group({
      name: ['', Validators.required],
      city: ['', Validators.required],
      country: ['', Validators.required],
      instagram: ['', Validators.required],
      website: ['', Validators.required],
      modelslink: ['', Validators.required],
    });
  }

  createForm() {
    this.chgStateGroup = this.fb.group({
      name: ['', Validators.required],
      city: ['', Validators.required],
      country: ['', Validators.required],
      tva: ['', Validators.required],
      siret: ['', Validators.required],
      address: ['', Validators.required],
    });
  }

  createAdvisor(){
    this.chgStateAdvisor = this.fb.group({
      name: ['', Validators.required],
    });
  }



  getType(agencyhash) {
    var criteria = [
      { 'label': 'agency_hash', 'value': agencyhash },
    ];

    var postData = { 'table': 'agency', 'action': 'get', 'criteria': criteria };
    this.get.goBackOffice(postData).subscribe(res => {
      console.log(res);
      if (res) {
        this.prepareForm(res)
      }



    })

  }

  prepareFormPhotograph(data) {
    console.log('data prepare', data);
    this.chgStatePhotograph.get('name').setValue(data['agency_name']);
    this.chgStatePhotograph.get('city').setValue(data['agency_location_city']);
    this.chgStatePhotograph.get('country').setValue(data['agency_location_country']);
    this.chgStatePhotograph.get('instagram').setValue(data['client_instagram']);
    this.chgStatePhotograph.get('website').setValue(data['client_website']);
    this.chgStatePhotograph.get('modelslink').setValue(data['client_modelslink']);
  }

  prepareFormAgency(data) {
    this.chgStateGroup.get('name').setValue(data['agency_name']);
    this.chgStateGroup.get('city').setValue(data['agency_location_city']);
    this.chgStateGroup.get('country').setValue(data['agency_location_country']);
    this.chgStateGroup.get('tva').setValue(data['agency_tva']);
    this.chgStateGroup.get('siret').setValue(data['agency_siret']);
    this.chgStateGroup.get('address').setValue(data['agency_address']);
  }



  prepareForm(data) {
    switch (data['client_type']) {
      case 'photograph':
        this.type = 'photograph';
        this.createFormPhotograph();
        this.prepareFormPhotograph(data);
        break;

      case 'agency':
        this.type = 'agency';
        this.createForm();
        this.prepareFormAgency(data);
        break;

        case 'clients':
          this.type = 'clients';
          this.createForm();
          this.prepareFormAgency(data);
          break;
    }
  }



  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }



  InsertClient() {

    if(this.role == 1){
      this.chgStateGroup.get('country').setValue('Camille');
    }

    if ( (this.type == 'agency') || (this.type == 'clients') ){
      var data = [
        { 'label': 'agency_name', 'value': this.chgStateGroup.get('name').value },
        { 'label': 'agency_location_city', 'value': this.chgStateGroup.get('city').value },
        { 'label': 'agency_location_country', 'value': this.chgStateGroup.get('country').value },
        { 'label': 'agency_siret', 'value': this.chgStateGroup.get('siret').value },
        { 'label': 'agency_tva', 'value': this.chgStateGroup.get('tva').value },
        { 'label': 'agency_address', 'value': this.chgStateGroup.get('address').value },
        { 'label': 'client_type', 'value': this.type }
      ];
    }

    if (this.type == 'photograph') {
      var data = [
        { 'label': 'agency_name', 'value': this.chgStatePhotograph.get('name').value },
        { 'label': 'agency_location_city', 'value': this.chgStatePhotograph.get('city').value },
        { 'label': 'agency_location_country', 'value': this.chgStatePhotograph.get('country').value },
        { 'label': 'client_instagram', 'value': this.chgStatePhotograph.get('instagram').value },
        { 'label': 'client_website', 'value': this.chgStatePhotograph.get('website').value },
        { 'label': 'client_modelslink', 'value': this.chgStatePhotograph.get('modelslink').value },
        { 'label': 'client_type', 'value': this.type }
      ];
    }

    if (this.type == 'advisor') {
      var data = [
        { 'label': 'agency_name', 'value': this.chgStateAdvisor.get('name').value },
        { 'label': 'agency_location_country', 'value': this.location },
        { 'label': 'client_type', 'value': this.type }
      ];
    }

    if (this.type == 'customlist') {
      var data = [
        { 'label': 'agency_name', 'value': this.chgStatePhotograph.get('name').value },
        { 'label': 'agency_location_country', 'value': 'France' },
        { 'label': 'client_type', 'value': this.type }
      ];
    }

    this.saveToDb(data);
  }

  saveToDb(data) {
    let postData;
    if (this.action == 'update') {
      var criteria = [
        { 'label': 'agency_hash', 'value': this.data['agency'] }
      ];

      postData = { 'table': 'agency', 'action': 'update', 'values': data, 'criteria': criteria };


    } else {
      postData = { 'table': 'agency', 'action': 'insert', 'data': data };

    }


    this.get.goBackOffice(postData).subscribe(res => {
      //  this.spinnerProgress = false;
      console.log(res);
      if (res == 'inserted') {
        this.dialogRef.close(res);
      }

      if (res == 'updated') {
        this.dialogRef.close(res);
      }
      //  
    },
      error => {
        //   this.spinnerProgress = false;
        this.openSnackBar('Error!', '');
        console.log(error);
      }
    )


  }


}

@Component({
  selector: 'image-crop',
  templateUrl: 'image-crop.html',
})


export class LogoCrop {
  imageChangedEvent: any = '';
  croppedImage: any = '';
  getImage: any;

  constructor(
    public dialogRef: MatDialogRef<LogoCrop>,
    // @Inject(MAT_DIALOG_DATA) public data: DialogData,

  ) {

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onOkClick(): void {
    console.log('cropped', this.croppedImage);
    sessionStorage.setItem("imgtemp", this.croppedImage.base64);
    this.dialogRef.close();
  }

  fileChangeEvent(event: any): void {
    console.log(event.target.files);
    this.imageChangedEvent = event;
  }

  imageCropped(image: string) {
    this.croppedImage = image;
    console.log('this.cropped', this.croppedImage)
  }

  imageLoaded() {
    // show cropper
  }
  loadImageFailed() {
    // show message
  }


}
