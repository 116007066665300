<div style="width:100%; height: 100%;" [style.height.px]="innerHeight">
    <div style="float: left; width: 50%; height: 100%">

        <mat-spinner class="centred"  *ngIf="spinner">
        </mat-spinner>
      

        <mat-tab-group *ngIf="!spinner" [(selectedIndex)]="demo1TabIndex">

            <mat-tab label="Recto">
                <ng-template matTabContent>
                    <div>
                        <div style="position: relative; width: 100%; height: 100% ">


                            <div style="width: 148mm; height: 210mm; position: relative; border: 1px solid black; ">
                                <div (click)="openCrop('picture1')"
                                    style="border: 2mm solid black; width: 106mm; height: 137mm; position: absolute; top: 11mm; left: 21mm;cursor: pointer; ">
                                    <img *ngIf="!compcard['picture1']" src="https://via.placeholder.com/400x518"
                                        style=" width: 102mm; height: 133mm;" />
                                    <img *ngIf="compcard['picture1']" style=" width: 102mm; height: 133mm;" [src]="compcard['picture1']" />
                                </div>

                                <div
                                    style="position: absolute; bottom: 40mm; left: 50%; transform: translate(-50%, 0);">
                                    <div
                                        style="text-transform: uppercase; font-weight: 900; color: black; font-size: 46px; white-space: nowrap;">
                                        FirstName LastName</div>
                                </div>
                                <div style="position: absolute; bottom: 23mm; right: 13mm;">
                                    <div
                                        style="display: flex; flex-direction: row; justify-content: center; align-items: center;">
                                        <div class="compagnyName">See Models</div>
                                        <div style="margin-left: 10px">
                                            <img src="../../../../../assets/logo_seemo.png" style="height: 30px" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </ng-template>
            </mat-tab>

            <mat-tab label="Verso">
                <ng-template matTabContent>
                    <div>
                        <div style="position: relative; width: 100%; height: 100% ">

                            <div
                                style="width: 148mm; height: 210mm; position: relative; border: 1px solid black;padding-left: 7mm; padding-right: 7mm; padding-top: 15mm; padding-bottom: 15mm; ">
                                <div style="position: relative; height: 100%; ">
                                    <div style=" position: absolute; top: 0; left: 0; width: 77mm; height: 56mm; cursor: pointer;"
                                        (click)="openCrop('picture2')">
                                        <img *ngIf="compcard['picture2']" [src]="compcard['picture2']" style=" width: 77mm; height: 56mm;" />
                                        <img *ngIf="!compcard['picture2']" src="https://via.placeholder.com/291x212"
                                            style=" width: 77mm; height: 56mm;" />
                                    </div>
                                    <div style=" position: absolute; top: 0; right: 0; width: 55mm; height: 74mm;cursor: pointer;"
                                        (click)="openCrop('picture3')">
                                        <img *ngIf="compcard['picture3']" [src]="compcard['picture3']"
                                            style=" width: 55mm; height: 74mm;" />
                                        <img *ngIf="!compcard['picture3']" src="https://via.placeholder.com/208x280"
                                            style=" width: 55mm; height: 74mm;" />
                                    </div>
                                    <div style="position: absolute; top: 59mm; left: 0; width: 77mm; height: 111mm;cursor: pointer;"
                                        (click)="openCrop('picture4')">
                                        <img *ngIf="compcard['picture4']" [src]="compcard['picture4']"
                                            style=" width: 77mm; height: 111mm;" />
                                        <img *ngIf="!compcard['picture4']" src="https://via.placeholder.com/291x420"
                                            style=" width: 77mm; height: 111mm;" />

                                    </div>

                                    <div
                                        style="position: absolute;  top: 84mm; left: 80mm; display: flex; flex-direction: column; ">
                                        <div>AAA: XXX</div>
                                        <div>BBB: XXX</div>
                                        <div>CCC: XXX</div>
                                        <div>DDD: XXX</div>
                                        <div>EEE: XXX</div>
                                        <div>FFF: XXX</div>
                                    </div>

                                    <div style="position: absolute; right: 14mm; bottom: 36mm;">
                                        <img src="../../../../../assets/logo_seemo.png" style="height: 55px" />
                                    </div>

                                    <div
                                        style="position: absolute;bottom: 0; left: 50%; transform: translate(-50%, 0);">
                                        <span
                                            style="white-space: nowrap; font-weight: 700; font-size: 3mm">contact@seemodels.co
                                            - +33 (0)6 64 70 82 51</span>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </ng-template>
            </mat-tab>

            <mat-tab label="Generate">
                <ng-template matTabContent>
                    <div style="display: flex; flex-direction: column; align-items: center;">
                        <div class="btnCtn" *ngIf="!generate">
                            <button mat-raised-button color="primary" (click)="generatePDF()" >Generate PDF</button>
                        </div>

                        <div *ngIf="generate">

                            <div class="btnCtn">
                                <button mat-raised-button color="primary" (click)="downloadPDF()" >Download PDF</button>
                            </div>
        
                            <div class="btnCtn">
                                <button mat-raised-button color="primary" (click)="sendEmailWithAttach()" >Send This PDF</button>
                            </div>
        
                            
                            <div class="btnCtn">
                                <button mat-raised-button color="primary" (click)="sendEmailForImpression()" >Send for IMPRESSION</button>
                            </div>
                        </div>
    
                    </div>


                </ng-template>
            </mat-tab>


        </mat-tab-group>





    </div>
    <div style="float: right; width: 50%;" [style.height.px]="innerHeight"  *ngIf="!spinner">

        <div 
       
        style="
      margin-left: 5px;
      margin-right: 5px;
        width: 100%;
      border: 1px solid black;
    ">

            <iframe width="100%" [height]="innerHeight" [src]="url" (onload)="resizeIframe(this)"></iframe>
        </div>


    </div>
</div>