
<form [formGroup]="myGroup"  style="width:80%; margin-left:50%; transform: translate(-50%, 0);padding:5px;">


   
     <mat-form-field style="display:inline-block;width:100%;">
       <input matInput placeholder="first" formControlName="first">
     </mat-form-field>
   
   
     <mat-form-field style="display:inline-block;width:100%;">
        <input matInput placeholder="Last" formControlName="last">
       </mat-form-field>

       <mat-form-field style="display:inline-block;width:100%;">
        <input matInput placeholder="Login" formControlName="login">
       </mat-form-field>

       <mat-form-field style="display:inline-block;width:100%;">
        <input matInput placeholder="E-Mail" formControlName="email">
       </mat-form-field>

       <mat-form-field style="display:inline-block;width:100%;" *ngIf="data['action'] !== 'edit' "> 
        <input matInput placeholder="Password" formControlName="password">
       </mat-form-field>

       <mat-form-field style="display:inline-block;width:100%;">
          <mat-select placeholder="Category" formControlName="category" multiple (selectionChange)="changeTO($event)">

              <mat-option *ngFor="let x of category" [value]="x.value">
              {{x.view}}
              </mat-option>
  
          </mat-select>
        </mat-form-field>
   
       <div mat-dialog-actions align="end">
          <button mat-button (click)="close()">Cancel</button>
          <button mat-button cdkFocusInitial (click)="addUsers()">Ok</button>
        </div>

   
 </form>