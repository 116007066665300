import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup, FormControl } from '@angular/forms';
import { MatDialog,  MatDialogRef,  MAT_DIALOG_DATA} from '@angular/material/dialog';

import { trigger, transition, animate, style } from '@angular/animations';

//import { DialogData } from '../../approved/approved.component';
import { HttpService } from '../../http.service';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ChooseagencyComponent } from './chooseagency/chooseagency.component';
import { ChoosemodelComponent } from '../../facturesystem/gencontract/genmac/choosemodel/choosemodel.component';
import { JoindocsComponent } from './joindocs/joindocs.component';

import {
  DomSanitizer,
} from '@angular/platform-browser';

@Component({
  selector: 'app-gobook',
  templateUrl: './gobook.component.html',
  styleUrls: ['./gobook.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({transform: 'translateX(100%)'}),
        animate('300ms ease-in', style({transform: 'translateX(0%)'}))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({transform: 'translateX(-100%)'}))
      ])
    ])
  ]
})
export class GobookComponent implements OnInit {


  myGroup: UntypedFormGroup;
  t : any;


  booked: boolean;
  type: boolean;
  visible: boolean;
  last : boolean;
  sliderClass: boolean;
  anim: boolean;
  chooseagency: boolean;


  selected : any;
  selected2 : any;
  agency: any;
  result : any;
  agencyhash: any;

  contactoption: any;
  moredetails: any;
  teloption: any;

  bookstate: string;
  bookBoolean : Boolean;

  fromDate: any;
  toDate: any;

  bookingrequest: string;
  smsbookingrequest: string;

  meetingType: any;

  

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<GobookComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private http: HttpService,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private sanitization: DomSanitizer,
  ) {
    this.selected = [];
    this.selected2 = [];
    this.booked = false;
    this.sliderClass = true;
    this.type = true;
    this.visible = false;
    this.last = false;
    this.anim = false;
    this.chooseagency = false;
    this.agencyhash = null;
    this.createForm();
    this.bookstate = 'Book';
    this.bookBoolean = true;
    this.meetingType = 'notselected';
    //this.data = [];
   }

  ngOnInit() {
    this.bookingrequest = 'yes';
    console.log('data gobook', this.data);
    this.checkModal(this.data);
  }

  checkModal(data){
    if(data['modal'] == 'none'){
     // this.goSelectModal();
     this.setEventDate();
    } else {
      this.meetingType = 'casting';
      this.data['modal'] = [data['modal']];
      this.data['avatars'] = [data['avatar']];
      console.log('set time', data['event']);
      //this.fromDate = new FormControl(new Date());
      this.createCustomForm();
      this.setEventDate();
    }
  }

  setEventDate(){
    this.fromDate = [ this.data['event'].start , ''];
    this.toDate = [ '' , this.data['event'].end];
  }

  goSelectModal(){
    const dialogRef = this.dialog.open(ChoosemodelComponent, 
      {
        height: 'auto',
        width: 'auto',
        data: {   selecttype: 'multiple', showpackage : false },
        panelClass: 'nopadding-box'
      });
   
 dialogRef.afterClosed().subscribe(result => {
   this.data = [];
   console.log(result);
   if(result[0].status == 'ok'){
     this.data['modal'] = result[0].hash;
     this.data['avatars'] = result[0].avatars;
     this.createCustomForm();
   }
  
  })
}

joinDocs(){
  const dialogRef = this.dialog.open(JoindocsComponent, 
    {
      height: 'auto',
      width: 'auto',
    });
 
dialogRef.afterClosed().subscribe(result => {
 console.log(result);
})
}

  createForm(){
    this.myGroup = this.fb.group({
      who: ['', Validators.required],
      where: ['', Validators.required],
      from: ['', Validators.required],
      to: ['', Validators.required],
      phone: ['', Validators.required],
      more: ['', Validators.required],
      bookingrequest: ['nosend', Validators.required],
      smsbookingrequest: ['nosend', Validators.required]
    });

  }

  deleteModalFromArray(index){
    this.t.splice(index, 1);
    this.data['modal'].splice(index, 1);
    this.data['avatars'].splice(index, 1);
  }

  createCustomForm(){

    console.log("create custom form", this.data);

    if(this.data['modal'] && this.data['modal'].length){
      if(this.data['modal'].constructor === Array){

        this.t = [];
        for (let i = 0; i < this.data['modal'].length; i++) {
         
          var temp;
          if(this.meetingType == 'castbookings'){
            temp = this.fb.group({
              facturation: ['', Validators.required],
              inpocket: ['', Validators.required],
              bookingrequest: ['send', Validators.required],
              smsbookingrequest: ['send', Validators.required]
          });
          } else {
            temp = this.fb.group({
              bookingrequest: ['send', Validators.required],
              smsbookingrequest: ['send', Validators.required]
          });
          }
           
        
          temp['avatar'] = this.data['avatars'][i];
          temp['modalhash'] = this.data['modal'][i]['hash'];
          this.t.push(temp);
      }
  
      } else {
         temp = this.fb.group({
          facturation: ['', Validators.required],
          inpocket: ['', Validators.required],
          bookingrequest: ['send', Validators.required]
      });
      temp['avatar'] = this.data['avatars'];
      temp['modalhash'] = this.data['modal'];
        this.t = temp;
      }
    }




  console.log('custom form', this.t, this.meetingType );
  }


  getImage(data) {
    return this.sanitization.bypassSecurityTrustStyle(`url(${data})`);
  }


  onNoClick(){
    this.dialogRef.close();
  }


  chooseAgency(data){
    const dialogRef = this.dialog.open(ChooseagencyComponent, 
      {
        height: '80%',
        width: '80%',
        data: { type: data },
        panelClass: 'nopadding-box'
      });
   
 dialogRef.afterClosed().subscribe(result => {
   this.prepareForm(result);
   console.log(result);
   this.agencyhash = result;
   this.createCustomForm();
  })
  }

  async lastBook2(){
    let models = [];

    for (let i = 0; i < this.data['modal'].length; i++) {
       
        if(this.meetingType == 'castbookings'){
      models[i] = { 
        'modelhash' : this.data['modal'][i], 
        'facturation' : this.t[i].get('facturation').value, 
        'inpocket' :  this.t[i].get('inpocket').value, 
        'bookingrequest' :  this.t[i].get('bookingrequest').value, 
        'smsbookingrequest' :  this.t[i].get('smsbookingrequest').value, 
      }
    } else {
      models[i] = { 
        'modelhash' : this.data['modal'][i], 
        'bookingrequest' :  this.t[i].get('bookingrequest').value, 
        'smsbookingrequest' :  this.t[i].get('smsbookingrequest').value, 
      }
    }
  }



    let dataE = {
      'meetingType' : this.meetingType,
      'who' : this.myGroup.get('who').value, 
      'phone' : this.myGroup.get('phone').value, 
      'where' : this.myGroup.get('where').value, 
      'from' : this.myGroup.get('from').value[0].toISOString(), 
      'to' :   this.myGroup.get('to').value[1].toISOString(),
      'more' : this.myGroup.get('more').value,
      'models' : models,
      'agencyhash' :   this.agencyhash
    }
    console.log('last book2', dataE);
    var funcE = { type: 'BOOKING', func: 'setBooking' };    
    var Postdata = { func: funcE, data : dataE };

   // if(this.bookBoolean){

      this.bookstate = 'Booking....';
      this.bookBoolean = false;

    let res = await this.http.goAcess(Postdata);
    console.log('res', res);
    if(res){
      
      this.bookstate = 'Book';
      this.bookBoolean = true;
      this.openSnackBar('Participant have been informed', 'Congrats !');
      this.dialogRef.close("inserted");
    } else {
      this.openSnackBar('Error Somewhere', 'Be Carreful !');
    }
   
   // }

  }



  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  prepareForm(agencyhash){
    console.log('prepareForm');
    this.http.prepareFormAgency(agencyhash).subscribe(res=>{
      console.log(res);
      this.result = res;
      if(this.result){
        this.myGroup.get('where').setValue(this.result.address);
        this.moredetails = this.result.moredetails;
        if(this.result.contact){
          this.contactoption = this.result.contact;
        } else {
          this.contactoption = [this.result.name];
        }
    
     //   this.teloption = this.result.tel;
        this.fromDate = [ this.data['event'].start , ''];
        this.toDate = [ '' , this.data['event'].end];
      }


    })
  }

  

}
