import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../../http.service';


@Component({
  selector: 'app-boa',
  templateUrl: './boa.component.html',
  styleUrls: ['./boa.component.scss']
})
export class BoaComponent implements OnInit {

  boa: any;
  boanumber: any;
  file : any;

  constructor(
    private http : HttpService,
  ) { }

  ngOnInit() {
    this.getboa();
  }

  getboa(){
    this.http.getAllBoa().subscribe(res=>{
      console.log('boa',res);
        this.boa = res;
    })
    }

    uploadBoa(boanumber){
      this.boanumber = boanumber;
      document.getElementById('file').click();
    }

    upload(elem){
      this.file = elem[0];

      this.http.uploadBoa(this.boanumber, this.file).subscribe(res=>{
        console.log('upload', res);
        if(res == 'OK'){
          this.getboa();
        }
      })
    }

    delBoa(boanumber){
 this.http.deleteBoa(boanumber).subscribe(res=>{
   console.log('del boa', res);
   if(res == 'OK'){
    this.getboa();
   }
 })
    }


}
