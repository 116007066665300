import { Component, OnInit, Input } from '@angular/core';
import { HttpService } from '../../http.service';

@Component({
  selector: 'app-modellog',
  templateUrl: './modellog.component.html',
  styleUrls: ['./modellog.component.scss']
})
export class ModellogComponent implements OnInit {

  @Input() hash: string;
  specs : any;
  lat: any;
  long : any;
  notes : any;
  displayedColumns: string[] = [ 'contact', 'who', 'what', 'date' ];
  selectedtype: string;

  constructor(
    private get: HttpService,
  ) {
    this.selectedtype = 'all';
   }

  ngOnInit() {
    this.getData();
  }



  getData(){

    if(this.selectedtype == 'all'){
      var criteria = [
        { 'label' : 'model_hash' , 'value' : this.hash },
      ];
    } else {
      var criteria = [
        { 'label' : 'model_hash' , 'value' : this.hash },
        { 'label' : 'log_type' , 'value' : this.selectedtype },
      ];
    }
    
    


    var switchvalue = [
      { 'label' : 'agency_hash' , 'type' : 'agency' }
    ];

    var postData = {  'table' : 'agency_send_log', 'action' : 'getall', 'criteria' : criteria, 'switchvalue' : switchvalue} ;

    this.get.goBackOffice(postData).subscribe(res => {
      console.log('res', res);
      this.notes = res;
    })
  }

  filterType(type){
    this.selectedtype = type;
    this.getData();
  }

  getIcon(type) {
    let icon = '';
    switch (type) {
      case 'mail':
        icon = 'assets/mail.png';
        break;

      case 'see':
        icon = 'assets/eye.png';
        break;

      case 'nointerest':
        icon = 'assets/choice/refuse.png';
        break;

      case 'close':
        icon = 'assets/choice/close.png';
        break;
      case 'interest':
        icon = 'assets/checked.png';
        break;

      case 'gopola':
        icon = 'assets/gopola.png';
        break;

      case 'govideo':
        icon = 'assets/govideo.png';
        break;

      case 'zip':
        icon = 'assets/choice/zip.png';
        break;

      case 'pdf':
        icon = 'assets/choice/pdf.png';
        break;

      case 'share':
        icon = 'assets/choice/share.png';
        break;

      case 'download':
        icon = 'assets/choice/download.png';
        break;

      case 'DATA | getOneModelData | albumdetails':
        icon = 'assets/album.png';
        break;

      case 'DATA | getOneModelData | getallalbums':
        icon = 'assets/gopola.png';
        break;

      case 'DATA | getOneModelData | full':
        icon = 'assets/eye.png';
        break;

      case 'I want to represent here':
        icon = 'assets/contract.png';
        break;

      case 'I Pass':
        icon = 'assets/choice/refuse.png';
        break;

        case 'Request an appointment':
          icon = 'assets/choice/star.png';
          break;

          case 'DATA | getMySelection':
            icon = 'assets/choice/selection.png';
            break;

            case 'DATA | getModelSample':
              icon = 'assets/choice/sample.png';
              break;

        

      case '1st Choice':
        icon = 'assets/choice/star.png';
        break;

      case '2nd Choice':
        icon = 'assets/choice/star2.png';
        break;
    }

    return icon;
  }

}
