import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup} from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';

import { HttpService } from '../http.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { SeedestinataireComponent } from '../push-manager/push/seedestinataire/seedestinataire.component';
import { CountrylogpushComponent } from '../push-manager/push/countrylogpush/countrylogpush.component';

@Component({
  selector: 'app-emailcomp',
  templateUrl: './emailcomp.component.html',
  styleUrls: ['./emailcomp.component.scss']
})
export class EmailcompComponent implements OnInit {

  chgStateGroup: UntypedFormGroup;
  spinnerProgress2 : boolean;
  htmlContent : any;

  attachements : any;
  destinataireArray: any;
  attached:any;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '300px',
    minHeight: '300px',
    placeholder: 'Enter text here...',
    translate: 'no',
    uploadUrl: 'v1/images', // if needed
    customClasses: [ // optional
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };

  constructor(
    public dialogRef: MatDialogRef<EmailcompComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialog: MatDialog,
    private http : HttpService,
    private fb: UntypedFormBuilder,
  ) {
    this.attached = null;
   }

  ngOnInit(): void {
    this.createForm();
    console.log('data', this.data);

    if(this.data['type'] == 'sendMailWithAttach'){
      this.attachements = this.data['attachements']
    }

    if(this.data['type'] == 'relancefacture'){
      this.getPreparedMessage(this.data['numfacture']);
      this.attachements = this.data['attachements']
    }

    if(this.data['type'] == 'sendfastlink'){
      this.getFastLinkMessage(this.data['fastlink']);
    }


    if(this.data['type'] == 'compcard'){
      this.attachements = this.data['attachements']
      this.destinataireArray = [this.data['email']];
      this.getMessageForCompcardImpression();
    }

    if(this.data['email'] !== ''){
      this.chgStateGroup.get('destinataire').setValue(this.data['email']);
      this.destinataireArray = [this.data['email']];
    }

    if(this.data['type'] == 'fdp'){

      this.getMessageForSendingFDP(this.data['factureid']);
    }

    if(this.data['type'] == 'statement'){

      this.getMessageForSendingStatement(this.data['modal']);
    }
    
    


  }



  onNoClick(){
    this.dialogRef.close();
  }

  async getMessageForCompcardImpression(){
    var funcE = { type: 'RELANCE', func: 'getMessageForCompcardImpression' };  
    
    var Postdata = { func: funcE };

    console.log('on click', Postdata);
    let res = await this.http.goAcess(Postdata);
    console.log('on click', res);
    this.chgStateGroup.get('htmlContent').setValue(res);
    this.chgStateGroup.get('subject').setValue("Demande de devis pour impression");
    this.chgStateGroup.get('sender').setValue('jb@seemodels.co');
    
    
  }

  async getMessageForSendingStatement(modalHash){
    var funcE = { type: 'RELANCE', func: 'getMessageForStatement' };  
    var dataE = {  'modal': modalHash };  
    var Postdata = { func: funcE, data : dataE };

    console.log('on click', Postdata);
    let res = await this.http.goAcess(Postdata);
    console.log('on click', res);
    this.chgStateGroup.get('htmlContent').setValue(res.message);
    this.chgStateGroup.get('subject').setValue(res.subject);

    this.chgStateGroup.get('destinataire').setValue(res.email);
    this.destinataireArray = [res.email];

      let b64data = res.attachements.data.replace("data:application/vnd.ms-excel;base64,", '');
      let B64Decode =  btoa(b64data);
      res.attachements.data = "data:application/vnd.ms-excel;base64," + B64Decode;
    this.attached = [res.attachements];
  }

  async getMessageForSendingFDP(factureid){
    var funcE = { type: 'RELANCE', func: 'getMessageForSendingFDP' };  
    var dataE = {  'factureid': factureid };  
    var Postdata = { func: funcE, data : dataE };

    console.log('on click', Postdata);
    let res = await this.http.goAcess(Postdata);
    console.log('on click', res);
    this.chgStateGroup.get('htmlContent').setValue(res.message);
    this.chgStateGroup.get('subject').setValue(res.subject);

    this.chgStateGroup.get('destinataire').setValue(res.email);
    this.destinataireArray = [res.email];

   // let B64Decode =  atob(res.attachements.data);
   // res.attachements.data = B64Decode;
    this.attached = [res.attachements];
  }
  

  async getFastLinkMessage(fastlink){
    var funcE = { type: 'RELANCE', func: 'getFastlinkSmsMessage' };  
    
    var dataE = { 
      'fastlink': fastlink
    };    
    var Postdata = { func: funcE, data : dataE };

    console.log('on click', Postdata);
    let res = await this.http.goAcess(Postdata);
    console.log('on click', res);
    this.chgStateGroup.get('htmlContent').setValue(res.message);
    this.chgStateGroup.get('subject').setValue(res.subject);
    this.chgStateGroup.get('sender').setValue('jb@seemodels.co');
    
  }



  async getPreparedMessage(elem){
    var funcE = { type: 'RELANCE', func: 'getRelanceContent' };  
    
    var dataE = { 
      'numfacture': elem
    };    
    var Postdata = { func: funcE, data : dataE };

    console.log('on click', Postdata);
    let res = await this.http.goAcess(Postdata);
    console.log('on click', res);
    this.chgStateGroup.get('htmlContent').setValue(res.message);
    this.chgStateGroup.get('subject').setValue(res.subject);
    this.chgStateGroup.get('sender').setValue('jb@seemodels.co');
    this.destinataireArray = res.contactmail;
    
  }

  async onOkClick(){
    this.spinnerProgress2 = true;
    var funcE = { type: 'RELANCE', func: 'sendRelanceEmail' };  

    var destinataires = [];
    if( Array.isArray(this.chgStateGroup.get('destinataire').value) ){
      destinataires = this.chgStateGroup.get('destinataire').value;
    } else {
      destinataires[0] = this.chgStateGroup.get('destinataire').value;
    }
   
    var dataE = { 
      'to': destinataires,
      'subject': this.chgStateGroup.get('subject').value, 
      'htmlContent' : this.chgStateGroup.get('htmlContent').value ,
      'attachements' : this.attached !== null ? this.attached : await this.getAttachements(this.attachements), 
      'sender' : this.chgStateGroup.get('sender').value,
      'type': this.data['type'], 'typedata' : this.data
    };    
    var Postdata = { func: funcE, data : dataE };

    console.log('on click', Postdata);
    let res = await this.http.goAcess(Postdata);
    this.spinnerProgress2 = false;
    console.log('on click', res);
    if(res){
    this.dialogRef.close(res);
    }
   

  }



selectCountry1(){
  let dialogRef = this.dialog.open(CountrylogpushComponent, {
    data: {country: "France", model: 'aze'},
    height: 'auto',
    width: 'auto',
  });

  dialogRef.afterClosed().subscribe(result => {
    console.log('tosend', result);   
    this.chgStateGroup.get('destinataire').setValue(result);
  });
}

createForm(){
  this.chgStateGroup = this.fb.group({
    destinataire: ['', Validators.required],
    subject: ['', Validators.required],
    htmlContent: ['', Validators.required],
    calendar: ['', Validators.required],
    sender: ['contact@seemodels.co', Validators.required],
    });
}

async getAttachements(allAttachements){

  if(allAttachements && allAttachements.length > 0){
    let data = [];
    let name = [];
    let temp = [];

    for(var i = 0;i < allAttachements.length; i++){
      data[i] = await this.getString(allAttachements[i]);
      name[i] = this.getFileName(allAttachements[i]);
      temp[i] = { 'data' : data[i], 'name' : name[i] };
    }
    return temp;
  } else {
    return null;
  }

   
}

getFileName(file){
  return file.substring(file.lastIndexOf('/') + 1)
}

deletePieceJointe(file){
  for(var i = 0; i < this.attachements.length; i++){
    if(file == this.attachements[i]){
      this.attachements.splice(i, 1)
    }
  }
}

openFile(url){
  window.open(url)
}

async getString(url){
  let res = await this.getBase64ImageFromUrl(url)
  .then(result => {
    return result;
  })
  .catch(err => console.error(err));
  return res;
}


async getBase64ImageFromUrl(imageUrl) {
  var res = await fetch(imageUrl);
  var blob = await res.blob();

  return new Promise((resolve, reject) => {
    var reader  = new FileReader();
    reader.addEventListener("load", function () {
        resolve(reader.result);
    }, false);

    reader.onerror = () => {
      return reject(this);
    };
    reader.readAsDataURL(blob);
  })
}




}
