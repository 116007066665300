import { Component, OnInit, Input } from '@angular/core';
import { HttpService } from '../../../http.service';


@Component({
  selector: 'app-booking-stats',
  templateUrl: './booking-stats.component.html',
  styleUrls: ['./booking-stats.component.scss']
})
export class BookingStatsComponent implements OnInit {

  statsA : any;
  @Input() statsB: any;

  stats: any;
  spinnerProgress: boolean;


  constructor(
    private get: HttpService,
  ) {
    this.statsA = [
      {'chiffre': 73, 'label' : 'Packages Sent'},
      {'chiffre': 68, 'label' : 'Packages Opened'},
      {'chiffre': 21, 'label' : 'Reminders Sent'},
      {'chiffre': 73, 'label' : 'Packages Sent'},
    ]
   }


  async ngOnInit(){
    await this.fullStats();
  }

  async fullStats(){

  }
  



}
