import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../http.service';

import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';

@Component({
  selector: 'app-statistiques',
  templateUrl: './statistiques.component.html',
  styleUrls: ['./statistiques.component.scss'],
})
export class StatistiquesComponent implements OnInit {
  stats: any;

  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}], yAxes: [{}] },
  };

  public barChartType: ChartType = 'bar';
  public barChartLegend = true;

  barChartLabels: any;
  barChartData: any;

  showtype: string;

  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'top',
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;
        },
      },
    },
  };

  public pieChartData: any;
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartLabels: Label[] = ['CA', 'Rem', 'Gain'];

  constructor(private http: HttpService) {}

  ngOnInit(): void {
    this.getStats();
  }

  async getStats() {
    var funcE = { type: 'STATS', func: 'getFacturationStats' };
    var Postdata = { func: funcE };
    let res = await this.http.goAcess(Postdata);
    console.log('res', res);
    this.stats = res;
    this.barChartLabels = res.barChartLabels;
    this.barChartData = res.barChartData;
    this.SelecteShowType('ca');
  }

  SelecteShowType(elem){
    this.showtype = elem;
    switch(elem){
      case 'ca':
        this.pieChartData = this.stats.barChartData[0].data;
      break;

      case 'rem':
        this.pieChartData = this.stats.barChartData[1].data;
      break;

      case 'gain':
        this.pieChartData = this.stats.barChartData[2].data;
      break;
    }
  }

  getSomme(elem) {
    var res;
    var somme = 0;
    switch (elem) {
      case 'ca':
        res = this.barChartData[0].data;
        break;

      case 'rem':
        res = this.barChartData[1].data;
        break;

      case 'gain':
        res = this.barChartData[2].data;
        break;
    }
    for (var i = 0; i < res.length; i++) {
      somme = somme + parseInt(res[i]);
    }
    return somme;
  }
}
