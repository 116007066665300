import { Component, OnInit } from '@angular/core';
import { HttpService } from '../http.service';
import { Router } from "@angular/router";
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material/icon';
import { MatSnackBar} from '@angular/material/snack-bar';
import { UntypedFormBuilder, Validators, UntypedFormGroup} from '@angular/forms';

export interface Feed {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-page1',
  templateUrl: './page1.component.html',
  styleUrls: ['./page1.component.scss']
})
export class Page1Component implements OnInit {

  spinnerProgress:boolean;
  news: any;
  bool: any;
  result: any;
  filter: any;
  publishedAt: any;
  date : any;
  myGroup: UntypedFormGroup;

  constructor(   
    private get: HttpService,
    private route: Router,
    iconRegistry: MatIconRegistry, 
    sanitizer: DomSanitizer,
    public snackbar: MatSnackBar,
    private fb: UntypedFormBuilder,
   
    ) {
      iconRegistry.addSvgIcon(
        'delete_outline',
        sanitizer.bypassSecurityTrustResourceUrl('assets/baseline-delete_outline-24px.svg'));
        
    this.createForm();
  }
  
  feed: Feed[] = [
    {value: 'all', viewValue: 'All'},
    {value: 'News', viewValue: 'News'},
    {value: 'Tips & Advice', viewValue: 'Tips & Advice'},
    {value: 'Model of the week', viewValue: 'Model of the week'},
    {value: 'Special Event', viewValue: 'Special Event'}
  ];

  ngOnInit() {

    this.getNews('all');
  }


  createForm(){
    this.myGroup = this.fb.group({
      publishedAt: ['', Validators.required],
    });
  }

  updateDate(date, event){
    console.log(date, event);
    let input = new FormData();
    input.append('tochange', 'publishedAt');
    input.append('value', date.toISOString());
    input.append('eventid', event);

    this.get.changeValue(input).subscribe ( res => {
      if(res){
        this.openSnackBar('Date Update !','Undo');
      }
    })
  }

  deleteEvent(index, id){
    console.log(id);
    this.spinnerProgress = true;
    let input = new FormData();
    input.append('eventid', id);

    this.get.delNews(input).subscribe ( res => {
      console.log(res);
      this.spinnerProgress = false;
      this.news.splice(index,1);
      this.openSnackBar('News Delete !','Undo')
    },
    error => {
      this.spinnerProgress = false;
      this.openSnackBar('Error!','')
    }
  )
  
  }

  getNews(category : string){
    this.spinnerProgress = true;
    this.get.getNews(category).subscribe( res => {
      this.spinnerProgress = false,
      this.news = res;
    //  console.log(this.news);
    }
  )
  }

  CreateNews(){
    this.route.navigate(['members/page1','add']);
  }
  
  openSnackBar(message: string, action: string) {
    this.snackbar.open(message, action, {
      duration: 2000,
    });
  }

  goNews(elem: string){
    this.route.navigate(['members/page1/',elem]);
  }

  onChange($event, newsid){
 
      this.get.publish($event.checked, newsid).subscribe( res => {
        this.result = res;
        console.log(this.result);
        if (res){
          this.spinnerProgress = false; 
          this.openSnackBar(this.result.message,'');
        }
      },
    error => {
      console.log(error);
      this.spinnerProgress = false;
      this.openSnackBar('Error!','Please try later')
    }
    )
  }

  doFilter(filter: string){
    console.log(filter);
    this.getNews(filter);
  }

}

