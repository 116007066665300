import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { HttpService } from '../../../../http.service';

import { DomSanitizer,  } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { CreateAlbumComponent } from './create-album/create-album.component';
import { ChoosealbumfortransfertComponent } from './choosealbumfortransfert/choosealbumfortransfert.component';
import { RenameAlbumComponent } from './rename-album/rename-album.component';

import { AvatarcropComponent } from './avatarcrop/avatarcrop.component';
import { Lightbox } from 'ngx-lightbox';

import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { EditFactureComponent } from '../../../../facturesystem/allfactures/edit-facture/edit-facture.component';


export interface DialogData2 {
  imageid: string;
}


export interface DialogData {
  modal: string;
  nbrepics: number;
}

@Component({
  selector: 'app-mgmt-gallery',
  templateUrl: './mgmt-gallery.component.html',
  styleUrls: ['./mgmt-gallery.component.scss']
})
export class MgmtGalleryComponent implements OnInit {

  @Input() modal: string;


  polaroid: any;
  gallery: any;
  materials : any;
  albums : any;

  size: string;
  imagetoadd: number;
  spinnerProgress: boolean;
  return : any;
  nbreoffile: any;
  nbreofalbum: any;

  showalbum:boolean;
  showpics: boolean;
  transfert: boolean;
  changeImageBool: boolean;
  galleryBoolSlider: boolean;
  packageSelection : boolean;

  tempalbumid : number;
  totransfert: any;
  private _albums: any;
  packageId: number;

  selectedImgForPackage : any;
  todoList : [];
  doneList : [];

  
  loading : boolean;

    @ViewChild('content') content: ElementRef;
    contentHeight: any;
    showPackageList : any;

  constructor(
    private http: HttpService,
    private sanitization: DomSanitizer,
    private dialog : MatDialog,
    public snackBar: MatSnackBar,
    private _lightbox: Lightbox
  ) {
    this.size = 'medium';
    this.showalbum = true;
    this.showpics = false;
    this.tempalbumid= 0;
    this.transfert = false;
    this.changeImageBool = true;
    this.galleryBoolSlider = false;

    this.selectedImgForPackage = [];
    this.packageSelection= true;
    this.loading = false;
   }

   open(index: number): void {
    // open lightbox
    this.setUpGallerySlider(this.gallery);

    this._lightbox.open(this._albums, index);
  }
 
  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

  setUpGallerySlider(gallery){
    console.log('gallery', gallery);
    this._albums = [];
    for (let i = 0; i < gallery.length; i++) {
      const src = gallery[i].resize;
      const caption = 'Image ' + i;
      const thumb = 'demo/img/image' + i + '-thumb.jpg';
      const album = {
         src: src,
         caption: caption,
         thumb: thumb
      };
      console.log('album', album);
      this._albums.push(album);
    }
    console.log('_albums', this._albums);
  }


  ngOnInit() {
    //this.getData(this.modal);
      this.getAlbumList(this.modal);


}

openGallerySlider(){
  this.galleryBoolSlider = true;
}
  

 async getAlbumList(elem){
    this.http.getAlbumList(elem).subscribe(res => {
      console.log('album list', res);
      this.albums = res;
     
      this.nbreofalbum = this.getLength( [this.albums]);
    })
  }

  showAlbum(id){
   // console.log('show album', id);
    this.showalbum = false;
    this.tempalbumid= id;
    this.http.getAlbumPics(id, this.modal).subscribe(res => {
      console.log('show album details',res);
      this.gallery  = res;
      this.nbreoffile = this.getLength( [this.gallery]);
      this.showpics = true;

            setTimeout( () => {
        this.contentHeight = window.innerHeight-120;
        this.getShowPackageModel();
        console.log("content height", this.contentHeight);
      }, 150)
   
    })
   
  }

  
    async getShowPackageModel()
    {
    var funcE = { type: 'PACKAGE', func: 'getAllPackageFromOneModel' };  
    var dataE = { modalhash: this.modal };    

    var Postdata = { func: funcE, data : dataE };
    let res = await this.http.goAcess(Postdata);
    console.log("res",res);
    this.showPackageList = res;
    }

  createAlbum(){
   // console.log('create album');
    let dialogRef = this.dialog.open(CreateAlbumComponent, {
      height: 'auto',
      width: 'auto', 
      data: { modal: this.modal }
    });
    dialogRef.afterClosed().subscribe(result => {

        if(result['status']){
          this.albums.push(result['data']);
        }


    });
  }

  async closeAlbum(){
    this.showpics = false;
    await this.getAlbumList(this.modal);
    this.tempalbumid= 0;
    this.showalbum = true;
    this.transfert = false;
    this.changeImageBool = true;
    this.totransfert = [];
  }

  getLength(data){
    var temp = 0;
    for(var i = 0; i<data.length; i++){
      if(data[i]){
        temp = temp + data[i].length;
      }
    }

    return temp;

  }

  getImage(data){
    if(data){
      return this.sanitization.bypassSecurityTrustStyle(`url(${data})`);
    } else {
      return this.sanitization.bypassSecurityTrustStyle(`url('../../../assets/placeholder.png')`);
    }
    
  }

  UpdateSize(elem){
    console.log(elem);
    this.size = elem;
  }

  showMenu(i){
    var elem = 'menu' + i;
    if(document.getElementById(elem).style.display == 'flex'){
      document.getElementById(elem).style.display = 'none';
    } else {
      document.getElementById(elem).style.display = 'flex';
    }
  }

  useAsSpecial(imageurlorid, type, index){
      switch(type){
        case 'avatar':
          this.cropAvatar(imageurlorid, index);
          break;

        case 'main':
          this.makeMain(imageurlorid, index, this.modal);
      }
 
  }

  cropAvatar(imageurl, index){
    console.log('choose album to transfert');
    let dialogRef = this.dialog.open(AvatarcropComponent, {
      height: 'auto',
      width: 'auto', 
      data: { image: imageurl }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('result crop', result);
      if(result[0]['status'] == 'ok'){
        console.log('saving avvatar', result[0]['imagetosave'])
        this.SaveAvatar(result[0]['imagetosave']);
      }
    });
  }

  makeMain(imageid, index, modal){
    this.http.makeImageMain(imageid, modal).subscribe(res=>{
      console.log(res);
      if(res['makemain'] == 'ok'){
        this.showMenu(index);
      }
    })
  }
  

  changeImageVisibility(id, visibility, index, type){
    if(this.changeImageBool){
      this.http.changeImageVisibility(id, visibility).subscribe(res=>{
        console.log(res);
          if(type == 'gallery'){
            this.gallery[index].visibility = res['visibility'];
          }
  
          if(type == 'polaroid'){
            this.polaroid[index].visibility = res['visibility'];
          }
  
          if(type == 'materials'){
            this.materials[index].visibility = res['visibility'];
          }
  
      })
    }

  }

  rotatePics(elem, index, type){
    console.log(elem, index, type, 'rotate');

    if(type == 'gallery'){
      this.gallery[index].resize = '';
      this.gallery[index].fullurl = '';
    }

    if(type == 'polaroid'){
      this.polaroid[index].url = '';
      this.polaroid[index].fullurl = '';
    }

    if(type == 'materials'){
      console.log('materials');
      this.materials[index].url = '';
      this.materials[index].fullurl = '';
    }

    this.http.rotatePics(elem).subscribe(res=>{
      console.log(res);
      if(res['status'] == 'rotate'){
        if(type == 'gallery'){
          console.log('type' , type, elem)
          this.gallery[index].resize = res['resize'] + '?' + new Date().getTime();
          this.gallery[index].fullurl = res['full'] + '?' + new Date().getTime();

        }

        if(type == 'polaroid'){
          this.polaroid[index].url = res['resize'] + '?' + new Date().getTime();;
          this.polaroid[index].fullurl = res['full'] + '?' + new Date().getTime();;
        }

        if(type == 'materials'){
          console.log('materials');
          this.materials[index].url = res['resize'] + '?' + new Date().getTime();;
          this.materials[index].fullurl = res['full'] + '?' + new Date().getTime();;
        }
      }
    })
  }

  DeletePics(id, index, type){
    this.http.deleteImageGallery(id).subscribe(res => {
      console.log('delete', res);
      if(res == 'delete'){
        switch(type){
          case 'gallery':
          this.gallery.splice(index, 1);
          break;

          case 'materials':
              this.materials.splice(index, 1);
          break;
        }
      } else {
        this.openSnackBar('An Error has occured', 'Somewhere !');
      }
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  addto(desc){
      // console.log(desc);
       this.imagetoadd = desc;
     document.getElementById('loaddata').click();

  }

  async  fileChangeEvent(event: any, imgtype) {

    console.log('event',imgtype);
    if(imgtype == undefined){
      imgtype = this.imagetoadd;
    }
    console.log('event',imgtype);
    const files = event.target.files;
    this.spinnerProgress = true;
    for (var value  of files){
      console.log('inboucle',value );
      const temp = await this.saveImagePola(this.modal, value, imgtype);
      if(temp == 'ok'){
      //  this.getData(this.modal);
      this.showAlbum(this.tempalbumid);
      }
    }
    this.spinnerProgress = false;
  }

  async SaveAvatar(image){
    const temp = await this.saveImagePola(this.modal, image, 'user_avatar');
    console.log('avatar', temp);
  }
  
  
  async saveImagePola(modal, image, desc){
    console.log('modal', modal, 'image', image, 'desc', desc, 'albumid', this.tempalbumid);
  
    return new Promise(resolve => 
    this.http.saveImage(modal, image, desc, this.tempalbumid).subscribe( res => {
      this.return = res;
  
      console.log('return',res);
      resolve(this.return.status);
    }, error =>{
      console.log('erreur', error);
      this.openSnackBar('An Error has occured', 'Somewhere !');
    })
  );
   // console.log('modal', modal, 'image', image, 'desc', desc)
  }

  getClass(elem, visibility){

    let classtosend;
    switch(elem){
      case 'small':
          classtosend = 'small';
      break;

      case 'medium':
          classtosend = 'medium';
      break;

      case 'big':
          classtosend = 'big';
      break;
    }

    if(visibility == 1){
      classtosend = classtosend.concat(' selected');
    }
    return classtosend;

  }

  transfertToAlbum(){
    this.transfert = true;
    this.changeImageBool = false;
    this.totransfert = [];
  }

  addToTransfert(picsid: number){
    if(!this.totransfert.includes(picsid)){
      this.totransfert.push(picsid);
    } else {
      let index = this.totransfert.indexOf(picsid);
      this.totransfert.splice(index, 1);
    }

//console.log('to transfert', this.totransfert);
  }

  chooseAlbumToTransfert(){
    console.log('choose album to transfert');
    let dialogRef = this.dialog.open(ChoosealbumfortransfertComponent, {
      height: 'auto',
      width: 'auto', 
      data: { modal: this.modal, nbrepics: this.totransfert.length }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.transfert = false;
      this.changeImageBool = true;
      if(result !== undefined){
       
        console.log(result);
        this.transfertPics(this.totransfert, result);
      }

    });
  }

  async transfertPics(picstotransfert, albumtottransfertto){
  //  console.log('transfert pics', picstotransfert, albumtottransfertto);
   await this.http.transfertPics(picstotransfert, albumtottransfertto, this.modal).subscribe(res=>{
    //  console.log(res);
      if(res == 'ok'){
        this.showAlbum(this.tempalbumid);
        this.openSnackBar('Pics have been transfert ', 'Congrats!');
      }
    })
  }

  async deleteAlbum(){
this.http.deleteAlbum(this.tempalbumid, this.modal).subscribe(res=>{
  //console.log('delete',res);
  if(res == 'ok'){
    this.closeAlbum();
    this.openSnackBar('Album has been deleted ', 'Congrats!');
  } else {
    this.openSnackBar('An error occured ', 'Sorry !');
  }
})
  }

  async renameAlbum(){
    let dialogRef = this.dialog.open(RenameAlbumComponent, {
      height: 'auto',
      width: 'auto', 
      data: { modal: this.modal, albumid : this.tempalbumid }
    });
    dialogRef.afterClosed().subscribe(result => {
     
      if(result == 'ok' || result == 'cancel'){
        this.closeAlbum();
        if(result == 'ok'){
          this.openSnackBar('Album has been rename ', 'Congrats!');
        }
        
      } else {
        console.log('erreur', result);
        this.openSnackBar('An error occured ', 'Sorry !');
      }


    });
    

  }

  drop(event: CdkDragDrop<string[]>) {
    console.log("event", event);
    if (event.previousContainer === event.container) {
// Donothing
    } else {
      
      console.log("event previous contaner data", event.previousContainer.data, "event container data", event.container.data, "event previous", event.previousIndex, 'current', event.currentIndex);
      
      //var tempO = this.gallery[event.previousIndex];

    //  this.gallery.splice(event.previousIndex, 1);

    //  this.selectedImgForPackage.push(tempO);
      //Save Selection in DB
      this.addImgToPackage(this.gallery[event.previousIndex], this.packageId);
    }
  }

  async addImgToPackage(imgId, packageId){
    this.loading = true;
    
    var funcE = { type: 'PACKAGE', func: 'addImgToPackage' };  
    var dataE = { imgId: imgId.id, packageId : packageId };    

    var Postdata = { func: funcE, data : dataE };
    let res = await this.http.goAcess(Postdata);

    setTimeout( () => {
      this.loading = false;
    }, 250)
   
    console.log("add img to package", res);
  }

  removeFromSelection(indexE){
    //Remove from DB
    this.selectedImgForPackage.splice(indexE, 1);
  }

  selectThisPackage(packageId){
    this.packageId = packageId
    this.packageSelection = false;
  }

  toggleVisibility(){
    if(this.packageSelection){
      return 'opacity0';
    } else {
      return 'opacity1';
    }
  }

  _addNewPackage(){
    
    const dialogRef = this.dialog.open(EditFactureComponent, {  
      width: '350px',
     //  height: '500px',
     data: { 'type': 'number',  'text' : 'Package Name'}
     });

     dialogRef.afterClosed().subscribe(result => {
       console.log("new pack", result);
      if(result){
        if(result.status == 'set'){
          this.addNewPackage(result.date);
        }
      }

    }) 
  }

  async addNewPackage(packageName){
    var funcE = { type: 'PACKAGE', func: 'addNewPackage' };  

    var dataE = {  "packageName" : packageName, "modelHash": this.modal };    

    var Postdata = { func: funcE, data : dataE };
    let res = await this.http.goAcess(Postdata);

    if(res == 'ok') {
      this.getShowPackageModel();
    }
  }

  

}


