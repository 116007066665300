import { Component, OnInit, Input } from '@angular/core';
import {MatDialog } from '@angular/material/dialog';
import { HttpService } from '../../http.service';

import { AddnotesComponent } from './addnotes/addnotes.component';

export interface DialogData {
  modal: string;
}


@Component({
  selector: 'app-modelnotes',
  templateUrl: './modelnotes.component.html',
  styleUrls: ['./modelnotes.component.scss']
})
export class ModelnotesComponent implements OnInit {

  notes : any;
  @Input() hash: string;
  @Input() type: string;

  constructor(
    public dialog: MatDialog,
    private http :HttpService
  ) { }

  ngOnInit() {
    this.getNotes();
  }

  addNote(){
    let dialogRef = this.dialog.open(AddnotesComponent, {
      //height: '100%',
      width: '440px',
      data: { modal : this.hash, type : this.type  },
      panelClass: 'nopadding-box'
    });
    dialogRef.afterClosed().subscribe(result => {
    //  console.log('The dialog was closed');
      console.log('result', result);
     if(result == 'success'){
      this.getNotes();
     }
    });
  }

  getNotes(){
    this.http.getModalNotes(this.hash , this.type).subscribe(res=>{
      console.log('notes', res);
      this.notes = res;
    })
  }

  deleteNote(noteid){
    this.http.delModalNotes(noteid, this.type).subscribe(res => {
      if(res && (res == 'ok') ){
        this.getNotes();
      }
    })
  }

}
