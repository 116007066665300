import { Component, OnInit, Inject } from '@angular/core';
import { HttpService } from '../http.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { UntypedFormBuilder, Validators, UntypedFormGroup} from '@angular/forms';
import { MatSnackBar} from '@angular/material/snack-bar';

export interface faqData {
  faq: string;
//  faqtoupdate: string;
}


@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

  faq: any;

  constructor(
    private get: HttpService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    this.getFaq();
  }


  getFaq(){
    this.get.getFaq().subscribe( res => {
    //  console.log('res',res);
      this.faq = res;
    })
  }


  addFaq(): void {
    const dialogRef = this.dialog.open(addFaq, {
      width: '500px',
    //  height: '500px',
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
    if (result) {
      if (result != 'echec'){
        // snackbar
        this.faq.push(result);
        this.openSnackBar('Congrats ! ', 'Faq Added !');
      } else {
        this.openSnackBar('Error ! ', 'Somewhere');
      }
    };
    })

  }

  delFaq(faqid, index): void {
    console.log(faqid);
    const dialogRef = this.dialog.open(delFaq, {
      width: '500px',
      data:  faqid
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
    if (result) {
      if (result == 'ok'){
        this.faq.splice(index, 1);
        this.openSnackBar('Congrats ! ', 'Faq Deleted !');
    };
    }})
  }

  editFaq(faqtoupdate) : void {

    const dialogRef = this.dialog.open(editFaq, {
      width: '500px',
      data : faqtoupdate
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
    if (result) {
      if (result == 'ok'){
        this.openSnackBar('Congrats ! ', 'Faq updated !');
    };
    }})
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }


  
}


@Component({
  selector: 'add-faq',
  templateUrl: 'add-faq.html',
})


export class addFaq {

  myGroup: UntypedFormGroup;
  category: any;

  constructor(
    public dialogRef: MatDialogRef<addFaq>,
    private fb: UntypedFormBuilder,
    private get: HttpService,
   // @Inject(MAT_DIALOG_DATA) public data: DialogData,
    
  )
   {
     this.createForm();
    this.category = [{ value : 'Beginner', view : 'Beginner'}, { value : 'Expert', view : 'Expert' }, { value : 'Aspiring Model', view : 'Expert' }];
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onOkClick() : void {

    this.get.addFaq(
      this.myGroup.get('category').value, 
      this.myGroup.get('question').value, 
      this.myGroup.get('answer').value
     ).subscribe( res => {
      if (res == 'nok'){
        this.dialogRef.close('echec');
      } else {
        this.dialogRef.close(res);
      }
    })
  }

  createForm(){
    this.myGroup = this.fb.group({
      category: ['', Validators.required],
      question: ['', Validators.required],
      answer: ['', Validators.required],
    });
  }

}


@Component({
  selector: 'del-faq',
  templateUrl: 'del-faq.html',
})

export class delFaq {



  constructor(
    public dialogRef: MatDialogRef<addFaq>,
   // @Inject(MAT_DIALOG_DATA) public data: DialogData,
   @Inject(MAT_DIALOG_DATA) public data: faqData,
   private get: HttpService,
    
  )
   {

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onOkClick(elem) : void {
    console.log(this.data);
    this.get.delFaq(elem).subscribe( res => {
      this.dialogRef.close(res);
    })
    
  }

  ngOnInit() {
    console.log(this.data);
  }

}


@Component({
  selector: 'edit-faq',
  templateUrl: 'edit-faq.html',
})

export class editFaq {

  myGroup: UntypedFormGroup;
  result : any;
  category: any;

  constructor(
    public dialogRef: MatDialogRef<editFaq>,
   // @Inject(MAT_DIALOG_DATA) public data: DialogData,
   @Inject(MAT_DIALOG_DATA) public data: faqData,
   private get: HttpService,
   private fb: UntypedFormBuilder,
  )
   {
    this.createForm();
    this.category = [{ value : 'Beginner', view : 'Beginner'}, { value : 'Expert', view : 'Expert' }, { value : 'Aspiring Model', view : 'Expert' }];
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onOkClick() : void {
    this.get.updateFaq(
      this.myGroup.get('category').value, 
      this.myGroup.get('question').value, 
      this.myGroup.get('answer').value,
      this.data
     ).subscribe( res => {
      if (res == 'nok'){
        this.dialogRef.close('echec');
      } else {
        this.dialogRef.close(res);
      }
    })
  }
    
  

  ngOnInit() {
    console.log(this.data);
    this.prepareForm();
  }

  createForm(){
    this.myGroup = this.fb.group({
      category: ['', Validators.required],
      question: ['', Validators.required],
      answer: ['', Validators.required],
      });
  }


  prepareForm(){
    this.get.getFaqContact(this.data).subscribe( res => {
      this.result = res;
      console.log(this.result);
      this.myGroup.get('category').setValue(this.result.category);
     this.myGroup.get('question').setValue(this.result.question);
    this.myGroup.get('answer').setValue(this.result.answer);

    })
  }

}