import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Page1Component } from './page1/page1.component';
import { Page2Component } from './page2/page2.component';
import { ModelOverviewComponent } from './pictures/model-overview/model-overview.component';
import { EventsComponent } from './events/events.component';
import { FaqComponent } from './faq/faq.component';
import { LoginComponent } from './login/login.component';
import { LogedComponent } from './loged/loged.component';
import { MessageComponent } from './message/message.component';
import { StatsComponent } from './stats/stats.component';
import { AgencyComponent } from './agency/agency.component';
import { AgencyDetailsComponent } from './agency-details/agency-details.component';
import { PushManagerComponent } from './push-manager/push-manager.component';
//import {  AuthGardService as AuthGuard } from './auth-gard.service';
//import { PublicprofileComponent } from './publicprofile/publicprofile.component';
import { FraisComponent } from './frais/frais.component';
import { ContractComponent } from './facturesystem/contract/contract.component';
import { AdminComponent } from './admin/admin.component';
import { ClientPageComponent } from './client-page/client-page.component';
import { ShowPersonal } from './banniere/showpersonal.component';
import { FacturationComponent } from './facturesystem/facturation/facturation.component';
import { AgendaComponent } from './agenda/agenda.component';

import { DocumentsComponent } from './banniere/documents/documents.component';
import { GencontractComponent } from './facturesystem/gencontract/gencontract.component';

//Contact
import { ContactComponent } from './contact/contact.component';
import { ContactclientComponent } from './contact/contactclient/contactclient.component';
import { FacturesystemComponent } from './facturesystem/facturesystem.component';

import { AllfacturesComponent } from './facturesystem/allfactures/allfactures.component';
import { BoaComponent } from './facturesystem/facturation/boa/boa.component';
import { FacturerappelComponent } from './facturesystem/facturerappel/facturerappel.component';
import { PaymentsComponent } from './facturesystem/payments/payments.component';
import { GenfacComponent } from './facturesystem/gencontract/genfac/genfac.component';
import { GenmacComponent } from './facturesystem/gencontract/genmac/genmac.component';
import { StatistiquesComponent } from './facturesystem/statistiques/statistiques.component';
import { StatsrepartComponent } from './facturesystem/statsrepart/statsrepart.component'
import { FdpComponent } from './facturesystem/fdp/fdp.component';

import { TodoComponent } from './todo/todo.component';
import { FastpushComponent } from './fastpush/fastpush.component'; // for FullCalendar!
import { PushprogramComponent } from './push-manager/push/pushprogram/pushprogram.component';


const routes: Routes = [
  { path: 'login', component: LoginComponent },
//  { path: 'profile/:id', component : PublicprofileComponent},
  { path: 'clientside', component : ClientPageComponent},
  { path: 'members', component: LogedComponent, //canActivate: [AuthGuard],
  children: [
    { path: 'stats', component: StatsComponent },
    { path: 'page1', component: Page1Component},
    { path: 'page1/:id', component: EventsComponent },
    { path: 'page1/add', component: EventsComponent },
    { path : 'todo', component : TodoComponent },
    { path: 'page2', component: Page2Component , },
      { path: 'page2/:id', component: ModelOverviewComponent },
  { path: 'contacts', component: ContactComponent, children : [
    { path: 'clients', component: ContactclientComponent },
    { path: 'agency/:id', component: AgencyComponent },
    { path: 'agency', component: AgencyDetailsComponent },
  ]},
    { path: 'faq', component: FaqComponent },
    { path: 'message', component: MessageComponent },
    { path: 'push', component: PushManagerComponent },
    { path: 'agency/:id', component: AgencyDetailsComponent },
   
   // { path: 'contract', component: ContractComponent },
    { path: 'admin', component: AdminComponent },
    { path: 'personal', component: ShowPersonal },
    { path : 'fastpush' , component: FastpushComponent },
    { path : 'pushprogram' , component : PushprogramComponent },
    //{ path: 'facturation', component: FacturationComponent },
    { path: 'agenda', component: AgendaComponent },
    { path: 'documents', component: DocumentsComponent },
  //  { path: 'goContract', component : GencontractComponent },
    { path : 'facturesystem' , component : FacturesystemComponent, 
    children : [
      { path: 'representation/:state', component: ContractComponent },
      { path: 'facturation/:type', component: AllfacturesComponent },
      { path: 'frais', component: FraisComponent },
      { path: 'gencontract', component: GencontractComponent },
      { path: 'boa', component: BoaComponent },
      { path: 'rappel', component: FacturerappelComponent },
      { path: 'payments/:type', component: PaymentsComponent },
      { path: 'generatefac/:eventhash', component: GenfacComponent },  
      { path: 'generatemac', component: GenmacComponent },  
      { path: 'statistiques', component: StatistiquesComponent },  
      { path: 'repartition', component: StatsrepartComponent },  
      { path: 'fdp', component: FdpComponent },  
    ] 
  }
  ]
},
  { path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {path: '**', redirectTo: 'login', pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
