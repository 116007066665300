<div [formGroup]="myGroup" style="display: flex; flex-direction: column; justify-content: center;">
    <div>
            <textarea placeholder="Write a message..." rows="5" cols="50" [(ngModel)]="mess" style="border: 1px solid black;
            height: 60px;
            padding-left: 10px ; flex-grow: 5; flex-wrap: wrap;
            background-color:transparent" formControlName="mess"></textarea>
    </div>


<div style="display: flex; flex-direction: row;  justify-content: flex-end;">
        <div style="margin:10px;cursor: pointer;"  color="primary" (click)="onNoClick()">Cancel</div>
        <div  style="margin:10px;cursor: pointer;" mat-raised-button color="primary" (click)="onOkClick()">Add Template</div>
</div>


</div>