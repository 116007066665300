<div class="seemo">


  <div class="modalCloseBtn" (click)="close(null)">
    <div class="centred" style="color:white">X</div>
  </div>

  <div class="sidebarModel">
    <div class="agency-name">
      <div style="position:absolute;left:50%;top:50%; transform: translate(-50%, -50%); white-space: nowrap;">{{ details?.agency_name }}
      </div>
    </div>

    <div class="modelAvatar" style="position: relative;height: 100px;width: 100%">
      <div (click)="uploadLogo()"
        style="position: absolute; cursor:pointer; right: 35%; top:10px; z-index: 100; border-radius: 50%;background-color:black;width: 30px; height: 30px">
        <div style="position: relative;width: 100%;height: 100%;">
          <img src="../../assets/camera.svg" style="height: 20px; width: 20px;position: absolute;top:50%;left:50%;transform: translate(-50%, -50%);" />
        </div>
      </div>
      <img [src]="details.agency_logo"
        style="position:absolute;top:10px;border-radius:50%;left:50%;transform:translate(-50%,0);z-index: 50;border: 1Px solid #efefef;"
        width="80px" height="80px"*ngIf="details?.agency_logo" />

        <img src="../../assets/avatar_female.png"
        style="position:absolute;top:10px;border-radius:50%;left:50%;transform:translate(-50%,0);z-index: 50;border: 1Px solid #efefef;"
        width="80px" height="80px" *ngIf="!details?.agency_logo"/>
    </div>

    <div class="area">
      Located in : {{ details?.agency_location_city }}, {{ details?.agency_location_country }}
    </div>

    <div style="text-align:center;margin-top:5px;" *ngIf="type == 'agency' || type == 'clients' ">
      <span *ngIf="details?.agency_name" style="display:block"> {{ details.agency_name }}</span>
      <span *ngIf="details?.agency_address" style="display: block"> {{ details.agency_address }}</span>
      <span *ngIf="details?.agency_siret" style="display: block">SIRET : {{ details.agency_siret }}</span>
      <span *ngIf="details?.agency_tva" style="display: block">TVA : {{ details.agency_tva }}</span>
    </div>

    <div style="text-align:center;margin-top:5px;" *ngIf="type == 'photograph' ">
      <span *ngIf="details?.agency_name" style="display:block"> {{ details.agency_name }}</span>
      <span *ngIf="details?.client_website" style="display: block; cursor: pointer;" (click)="goPage(details.client_website)">Website : {{ details.client_website }}</span>
      <span *ngIf="details?.client_modelslink" style="display: block; cursor: pointer;" (click)="goPage(details.client_modelslink)">Models.com : {{ details.client_modelslink }}</span>
      <span *ngIf="details?.client_instagram" style="display: block; cursor: pointer;" (click)="goInsta(details.client_instagram)">@Instagram : {{ details.client_instagram }}</span>
    </div>

    <div style="margin-top: 15px" *ngIf=" type !== 'customlist' ">
      <app-modelcategory  [hash]="data.agency" [type]="'client'"></app-modelcategory>
    </div>
    


  </div>

  <div class="fullModel">
    <app-full-agency [agency]="data.agency" [type]=" type " ></app-full-agency>
  </div>



  <div class="fastAction">
    <app-fastaction-agency [agency]="data.agency"></app-fastaction-agency>
  </div>

</div>