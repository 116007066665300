import { Component, OnInit, Inject } from '@angular/core';

import {
  HttpService
} from '../../../../../http.service';

import {
MatDialogRef,
MAT_DIALOG_DATA
} from '@angular/material/dialog';

import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';


@Component({
  selector: 'app-selectshowpackage',
  templateUrl: './selectshowpackage.component.html',
  styleUrls: ['./selectshowpackage.component.scss']
})
export class SelectshowpackageComponent implements OnInit {

  showPackageList: any;

  constructor(
    private http: HttpService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sanitization: DomSanitizer,
    public dialogRef: MatDialogRef < SelectshowpackageComponent > ,
  ) { }

  ngOnInit(): void {
    console.log("transfert", this.data);
    this.getShowPackageModel( this.data['modelhash']);
  }

  async getShowPackageModel(modelHash)
  {
  var funcE = { type: 'PACKAGE', func: 'getAllPackageFromOneModel' };  
  var dataE = { modalhash: modelHash };    

  var Postdata = { func: funcE, data : dataE };
  let res = await this.http.goAcess(Postdata);
  console.log("res",res);
  this.showPackageList = res;
  }

    
  getImage(data){
    if(data){
      return this.sanitization.bypassSecurityTrustStyle(`url(${data})`);
    } else {
      return this.sanitization.bypassSecurityTrustStyle(`url('../../../assets/placeholder.png')`);
    }
 
  }

  onNoClick(){
    this.dialogRef.close();
  }

  selectThisShowPackage(showpackId){

    var transfert = [{
      'status': 'ok',
      'hash': this.data['modelhash'],
      'showpackid' : showpackId
    }];
    this.dialogRef.close(transfert);


  }


}
