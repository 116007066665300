<div class="agency-name">
  <div style="position:absolute;left:50%;top:50%; transform: translate(-50%, -50%);">Criteria</div>
</div>

<div style="display:flex; flex-direction: column;  height:100%;">

    <mat-tab-group>
        <mat-tab label="Female">
          <ng-template matTabContent>

              <mat-card *ngFor="let x of female" >
                  {{x.name}} : <div style="margin:10px">
                    <!--
   <ng5-slider [(value)]="x.value" [(highValue)]="x.maxvalue"  (userChangeEnd)="changeSliderValue($event, x.part, 'female')" [options]="x.option"></ng5-slider>
                    -->
                 
                  </div>
                </mat-card>

          </ng-template>
        </mat-tab>

        <mat-tab label="Male">
          <ng-template matTabContent>

              <mat-card *ngFor="let x of male" >
                  {{x.name}} : <div style="margin:10px">
                    <!--
    <ng5-slider [(value)]="x.value" [(highValue)]="x.maxvalue"  (userChangeEnd)="changeSliderValue($event, x.part, 'male')" [options]="x.option"></ng5-slider>
                    -->
                
                  </div>
                </mat-card>

          </ng-template>
        </mat-tab>
            
    
      </mat-tab-group>




</div>
