<mat-radio-group class="example-radio-group">
<mat-grid-list cols="2" rowHeight="50px">

   

  <mat-grid-tile>
        <mat-radio-button [checked]="state == 1 "  value="Approved" (click)="stateApproved('approved')">Approved</mat-radio-button>
    </mat-grid-tile>

    <mat-grid-tile>
        <mat-radio-button [checked]="state == 0 " value="notapproved" (click)="stateApproved('notapproved')">Not Approved</mat-radio-button>
    </mat-grid-tile>
  </mat-grid-list>
  </mat-radio-group>



  


