import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { UntypedFormBuilder, Validators, UntypedFormGroup} from '@angular/forms';

import { HttpService } from '../../../../../http.service';
import { DialogData } from '../mgmt-gallery.component';


@Component({
  selector: 'app-create-album',
  templateUrl: './create-album.component.html',
  styleUrls: ['./create-album.component.scss']
})
export class CreateAlbumComponent implements OnInit {

  myGroup: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<CreateAlbumComponent>,
      private fb: UntypedFormBuilder,
      private http: HttpService,
      @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit() {
    console.log('transfer', this.data);
    this.createForm();
  }

  onNoClick(): void {
  
    this.dialogRef.close();
  }

 async onOkClick() {
    await this.createAlbum();
    
  }

  createForm(){
    this.myGroup = this.fb.group({
      albumname: ['', Validators.required],
    });
  }

  async createAlbum(){
    await this.http.createAlbum(this.data['modal'], this.myGroup.get('albumname').value).subscribe(res=> {
      console.log(res);
      if(res['status'] == 'ok'){
        this.dialogRef.close(res);      
      }
    })
  }

}
