<p *ngIf="!change">Category : {{category}}</p>
<div [formGroup]="chgStateGroup"  *ngIf="change">
    <mat-form-field>
        <input matInput placeholder="New Name" formControlName="newname">
      </mat-form-field>
</div>


<p>Number of Models in this category : {{ number }}</p>

<div style="display: flex; flex-direction: row; justify-content: space-between;">
    <button mat-stroked-button (click)="changeName()" *ngIf="!change">Change Name</button>
    <button mat-stroked-button (click)="validNewName()" *ngIf="change">Valid New Name</button>
    <button mat-stroked-button (click)="deleteCategory()">Delete</button>
</div>
