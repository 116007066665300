<div style="position: relative; height: calc(100vh - 120px)">
  <table
    *ngIf="contact"
    mat-table
    [dataSource]="contact"
    class="mat-elevation-z1"
    style="
      max-width: 90%;
      width: 90%;
      margin: 5px;
      margin-left: 50%;
      transform: translate(-50%, 0);
      margin-top: 20px;
    "
  >
    <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

    <!-- Position Column -->
    <ng-container matColumnDef="position">
      <th mat-header-cell *matHeaderCellDef>First Name</th>
      <td mat-cell *matCellDef="let x">{{ x.contact_firstname }}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Last Name</th>
      <td mat-cell *matCellDef="let x">{{ x.contact_lastname }}</td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="weight">
      <th mat-header-cell *matHeaderCellDef>Phone</th>
      <td mat-cell *matCellDef="let x">{{ x.contact_phone }}</td>
    </ng-container>

    <ng-container matColumnDef="Fonction">
      <th mat-header-cell *matHeaderCellDef>Fonction</th>
      <td mat-cell *matCellDef="let x">{{ x.contact_fonction }}</td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="symbol">
      <th mat-header-cell *matHeaderCellDef>E-Mail</th>
      <td mat-cell *matCellDef="let x">
        <div class="limitSizeOnMobile">{{ x.contact_mail }}</div>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let x; let i = index">
        <div class="actionRowResp">
          <div >
            <i
              (click)="editContact(x.contact_id)"
              style="cursor: pointer"
              class="material-icons"
            >
              edit
            </i>
          </div>
          <div class="removeOnMobile">
            <i
              (click)="delContact(x.contact_id, i)"
              style="cursor: pointer"
              class="material-icons"
            >
              delete_outline
            </i>
          </div>
          <div>
            <i
              (click)="sendEmail(x.contact_mail)"
              style="cursor: pointer"
              class="material-icons"
            >
              alternate_email
            </i>
          </div>
          <div>
            <i
              *ngIf="x.contact_phone"
              (click)="sendSMS(x.contact_phone)"
              style="cursor: pointer"
              class="material-icons"
            >
              settings_cell
            </i>
          </div>
          <div class="removeOnMobile">
            <i
              (click)="addToList(x.contact_id)"
              style="cursor: pointer"
              class="material-icons"
            >
              list
            </i>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <div
    *ngIf="!contact"
    style="
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
    "
  >
    <div>
      <img src="assets/contacts.png" style="height: 80px" />
    </div>

    <div style="font-size: 24px">Please add some contacts</div>
  </div>

  <button class="faba" (click)="addContact()" mat-fab color="primary">+</button>
</div>
