<div  class="main">

        <div class="box" (click)="goContact('clients')" *ngIf="role > 1">
            <div class="flexTitle">
                <div>
                    <img src="../../../assets/client/client.png" style="height: 50px;" />
                </div>
                <div class="flexSubtitle">
                    Clients
                </div>
            </div>
        </div>
    
        <div class="box" (click)="goContact('agency')" *ngIf="role > 1">
            <div class="flexTitle">
                <div>
                    <img src="../../../assets/client/agency.png" style="height: 50px;" />
                </div>
                <div class="flexSubtitle">
                      Agency
                </div>
            </div>
        </div>
    
        <div class="box" (click)="goContact('photograph')">
            <div class="flexTitle">
                <div>
                    <img src="../../../assets/client/photographer.png" style="height: 50px;" />
                </div>
                <div class="flexSubtitle">
                      Photographers
                </div>
            </div>
        </div>


        <!--


        <div class="box" (click)="goContact('advisor')">
            <div class="flexTitle">
                <div>
                    <img src="../../../assets/client/advisor.png" style="height: 50px;" />
                </div>
                <div class="flexSubtitle">
                      Advisor
                </div>
            </div>
        </div>

        <div class="box" (click)="validateClient()">
            <div class="flexTitle">
                <div>
                    <img src="../../../assets/client/question.png" style="height: 50px;" />
                </div>
                <div class="flexSubtitle">
                      Client to Validate
                </div>
            </div>
        </div>
    
    
        <div class="box" (click)="goContact('customlist')">
            <div class="flexTitle">
                <div>
                    <img src="../../../assets/client/list.png" style="height: 50px;" />
                </div>
                <div class="flexSubtitle">
                     Custom List
                </div>
            </div>
        </div>
        -->


    
    
    </div>
    