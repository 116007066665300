import { Component, OnInit, Input } from '@angular/core';
import { HttpService } from '../../http.service';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-modelsumary',
  templateUrl: './modelsumary.component.html',
  styleUrls: ['./modelsumary.component.scss']
})
export class ModelsumaryComponent implements OnInit {

  @Input() hash: string;
  polaroid : any;
  gallery: any;
  materials : any;


  constructor(
    private http: HttpService,
    private sanitization: DomSanitizer
  ) { }

  ngOnInit() {
    this.getData(this.hash);
  }

  getData(elem){
 
    this.http.getPics(elem).subscribe( res => {
      console.log('res', res);
      this.polaroid = res['polaroid'];
      this.gallery = res['gallery'];
      this.materials = res['materials'];

    });
  }

  getImage(data){
    return this.sanitization.bypassSecurityTrustStyle(`url(${data})`);
  }

  goUrl(url){
    window.open(url);
  }

}
