import { Component, OnInit, Input, HostListener } from '@angular/core';
import { AvatarcropComponent } from '../mgmt-gallery/avatarcrop/avatarcrop.component';
import {   MatDialog } from '@angular/material/dialog';

import { HttpService } from '../../../../http.service';
import {
  DomSanitizer,
} from '@angular/platform-browser';

import { EmailcompComponent } from '../../../../emailcomp/emailcomp.component';

@Component({
  selector: 'app-compcard',
  templateUrl: './compcard.component.html',
  styleUrls: ['./compcard.component.scss']
})
export class CompcardComponent implements OnInit {

  @Input() hash: string;
  public innerHeight: any;

  mainRecto : any;
  leftVerso: any;
  RightVerso: any;
  bottomVerso: any;

  url : any;
  urlWithNosanitization: string;

  compcard:any;
  spinner: boolean;

  demo1TabIndex: number = 0;
  compcardUrl:string;

  generate : boolean = false;

  constructor(
    private dialog : MatDialog,
    private http : HttpService,
    private sanitization: DomSanitizer,
  ) {
    this.innerHeight = window.innerHeight -70;
    console.log('inner height', this.innerHeight);
    this.mainRecto = null;
    this.leftVerso =  null;
    this.RightVerso =  null;
    this.bottomVerso = null;
   }

  ngOnInit(): void {
    this.getCompcard();
    this.getUrl();

  }

 


  @HostListener('window:resize', ['$event'])
onResize(event) {
  this.innerHeight = window.innerHeight -50;
}

resizeIframe(obj) {
  obj.style.height = this.innerHeight + 'px';
}

getUrl(){
    this.url = this.sanitization.bypassSecurityTrustResourceUrl("");
    let temp = "https://bmyscouter.co/db/clients/access/compcard/template.php?hash=" + this.hash + "&display=preview&time=" + new Date().getTime(); 
    this.urlWithNosanitization = "https://bmyscouter.co/db/clients/access/compcard/template.php?hash=" + this.hash + "&display=print";
    console.log("get url", temp)
    this.url = this.sanitization.bypassSecurityTrustResourceUrl(temp);
}


openCrop(elem){

  let dataE;

  switch(elem){
    case 'picture1':
     dataE = {'cropperStaticHeight': 503, 'cropperStaticWidth': 385};
    break;

    case 'picture2':
      dataE = {'cropperStaticHeight': 211, 'cropperStaticWidth': 291};
    break;

    case 'picture3':
      dataE = {'cropperStaticHeight': 280, 'cropperStaticWidth': 208};
    break;

    case 'picture4':
      dataE = {'cropperStaticHeight': 420, 'cropperStaticWidth': 291};
    break;
  }


  let dialogRef = this.dialog.open(AvatarcropComponent, {
    data: dataE,
    height: 'auto',
    width: 'auto', 
  });
  dialogRef.afterClosed().subscribe(result => {
    if(result[0]['status'] == 'ok'){
     
      switch(elem){
        case 'picture1':
          this.mainRecto = result[0]['imagetosave'];
        break;

        case 'picture2':
          this.leftVerso = result[0]['imagetosave'];
        break;

        case 'picture3':
          this.RightVerso = result[0]['imagetosave'];
        break;

        case 'picture4':
          this.bottomVerso = result[0]['imagetosave'];
        break;
      }
     
    
      this.sendImgToBack(elem, result[0]['imagetosave'] );
    }
  
    console.log("result", result);
  });
}


async sendImgToBack(label, value){
  this.spinner = true;
  let imgs = { "label" : label + '.jpg', "value" : value };
  var funcE = { type: 'COMPCARD', func: 'saveImgForCompcard' };  
  var dataE = { 
    'imgs': imgs, 'modelHash' : this.hash
  };    
  var Postdata = { func: funcE, data : dataE };
  let res = await this.http.goAcess(Postdata);
  console.log('on click', res);
 
  //let res = await this.http.goAcess(Postdata);
  this.getUrl();
  this.spinner = false;
}

async getCompcard(){
  this.spinner = true;
  let dataE = { 'modelHash' :  this.hash };
  var funcE = { type: 'COMPCARD', func: 'getOneCompcard', data : dataE };  
  
  var Postdata = { func: funcE, data : dataE };

  let res = await this.http.goAcess(Postdata);
  this.compcard = res;
  this.spinner = false;

 
  console.log('on click', res);

}

async generatePDF(){

  this.spinner = true;
  
  var dataE = { 'url' :  this.urlWithNosanitization, 'name' : 'compcard.pdf',  'format' : 'A5' };
  var funcE = { type: 'PDF', func: 'genericUrlToPdf' };  
  
  var Postdata = { func: funcE, data : dataE };
  console.log('on click', Postdata);

  let res = await this.http.goAcess(Postdata);
  this.compcardUrl = res;
  this.generate = true;
  this.spinner = false;
  this.demo1TabIndex = 3;
  console.log('on click', res);

}


downloadPDF(){
  window.open(this.compcardUrl);
}

sendEmailWithAttach(){
  const dialogRef = this.dialog.open(EmailcompComponent, {  
    data: {  attachements : [ this.compcardUrl], type : "sendMailWithAttach" }
   });

   dialogRef.afterClosed().subscribe(result => {

  }) 
}

sendEmailForImpression(){
  const dialogRef = this.dialog.open(EmailcompComponent, {  
    data: { attachements : [this.compcardUrl], "type" : "compcard",  "email" : "boulogne@copytop.com" }
   });

   dialogRef.afterClosed().subscribe(result => {

  }) 
}


}
