import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-statstwo',
  templateUrl: './statstwo.component.html',
  styleUrls: ['./statstwo.component.scss']
})
export class StatstwoComponent implements OnInit {

  @Input() getAgencyOrClientLog;

  constructor() { }

  ngOnInit(): void {
  }

}
