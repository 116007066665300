<form   [formGroup]="chgStateGroup">

<mat-form-field style="display:block;margin-top:10px; border:1px solid #ececec">
    <textarea cols="30" rows="10" matInput placeholder="SMS Content"
        formControlName="smscontent"></textarea>
</mat-form-field>


<mat-form-field style="display:block;margin-top:50px;">
    
  <mat-select placeholder="Sender" formControlName="sender" >
    <mat-option value="contact@seemodels.co">contact@seemodels.co</mat-option>
    <mat-option value="pa@seemodels.co">pa@seemodels.co</mat-option>
    <mat-option value="jb@seemodels.co">jb@seemodels.co</mat-option>
    <mat-option value="admin@seemodels.co">admin@seemodels.co</mat-option>
  </mat-select>


</mat-form-field>


<mat-form-field style="display:block;margin-top:10px;">
    <textarea  matInput placeholder="Subject" formControlName="subject"></textarea>
  </mat-form-field>


  <angular-editor style="margin-top:50px; min-width: 320px" id="editor1" formControlName="htmlContent" [config]="editorConfig" [(ngModel)]="htmlContent"></angular-editor>

  <div style="display: flex; flex-direction: flex-start; margin-top: 10px;">
    <button mat-button  (click)="onNoClick()">Cancel</button>
    <button mat-button  cdkFocusInitial  (click)="onOkClick()">ok</button>
  </div>

</form>