

<div style="width: 95%; margin-left: auto; margin-right: auto; margin-top: 20px;">
  <full-calendar 
  #calendar
  [plugins]="options.plugins"
  defaultView="timeGridWeek"
  [header]="{
      center: 'dayGridMonth,timeGridWeek,listWeek, timeGridDay'
    }"
  ></full-calendar>
</div>



