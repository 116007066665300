import { Component, OnInit, Inject, HostListener } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatDialog } from '@angular/material/dialog';

import { ChoosemodelComponent } from './choosemodel/choosemodel.component';
import { ChooseagencyComponent } from '../../../agenda/gobook/chooseagency/chooseagency.component';

import { HttpService } from '../../../http.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';

import {
  DomSanitizer,
} from '@angular/platform-browser';

@Component({
  selector: 'app-genmac',
  templateUrl: './genmac.component.html',
  styleUrls: ['./genmac.component.scss']
})
export class GenmacComponent implements OnInit {

 
  contentFormGroup: UntypedFormGroup;
  agencyFormGroup: UntypedFormGroup;

  model : any;
  modal: any;
  agency : any;

  download : boolean;
  type : string;
  agencycountry: string;
  agencycity: string;

  modelavatar: any;
  modelhash: any;
  agencyavatar: any;

  seemodels : string;
  public innerHeight: any;

  url : any;

  agencyhash: any;

  htmlContent: any;
  agencyData: any;
  language: any;

  btnBool : boolean;
  btnStatus: string;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '400px',
    minHeight: '400px',
    placeholder: 'Enter text here...',
    translate: 'no',
    uploadUrl: 'v1/images', // if needed
    customClasses: [ // optional
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };

  constructor(
    private _formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private http : HttpService,
    private sanitization: DomSanitizer,
   // public dialogRef: MatDialogRef<GenmacComponent>,
  //  @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.download = false;
    this.seemodels = 'fr';
    this.language = 'fr';
    this.innerHeight = window.innerHeight -70;
    this.btnBool = false;
    this.btnStatus = 'Download PDF';
   }

   @HostListener('window:resize', ['$event'])
   onResize(event) {
     this.innerHeight = window.innerHeight -70;
   }

   
resizeIframe(obj) {
  obj.style.height = this.innerHeight + 'px';
}

  ngOnInit() {
  
  //  this.type = this.data['type'];
    console.log('type', this.type);

    this.contentFormGroup = this._formBuilder.group({
      htmlContent: ['', Validators.required],    
    });



    this.getContent();
  }

  async contractToPdf(){

    let contractUrl = this.getPreviewUrl();

    var funcE = { type: 'CONTRACT', func: 'modelContractToPdf' };  
    var dataE = { 'contracturl' : contractUrl, 'futurename' : 'testname' };
    var Postdata = { func: funcE, data : dataE };
    console.log('Postdata', Postdata);
    this.btnBool = true;
    this.btnStatus = 'Please WAIT';
    let res = await this.http.goAcess(Postdata);
    console.log('res', res);
    this.btnBool = false;
    this.btnStatus = 'Download PDF';
    this.dlFile(res['file'], 'name','pdf');
  }

  async getContent(){
    var funcE = { type: 'CONTRACT', func: 'getMac' };  
    var dataE = { 'language' : this.language };
    var Postdata = { func: funcE, data : dataE };
    let res = await this.http.goAcess(Postdata);
    //console.log('res', res);
    this.contentFormGroup.get('htmlContent').setValue(res);
    this.prepareHtmlContent();
  }

  prepareHtmlContent(){
    let content =   this.contentFormGroup.get('htmlContent').value;

    if(this.language == 'uk'){
      if(this.agency){
        content = content.replace(/RepresentingCity/g,this.agencyData['city'] );
        content = content.replace(/RepresentingCountry/g, this.agencyData['country']);
      }

    }


    this.contentFormGroup.get('htmlContent').setValue(content);
    this.getPreviewUrl();
  }

  prepareFormAgency(){
    this.agencyFormGroup = this._formBuilder.group({
      agencyname: ['', Validators.required],
      agencyaddress: ['', Validators.required],
    });
  }

  chooseModel(){
    const dialogRef = this.dialog.open(ChoosemodelComponent, {
      data: {
        selecttype: 'single'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('choose model', result);
      if(result[0]['status'] == 'ok'){
        console.log('status ok');
        this.prepareModelForm(result[0]['hash']);
      }
    });
  }

  chooseAgency(){
    const dialogRef = this.dialog.open(ChooseagencyComponent, 
      {
      data : { type : 'agency'  }
      });

    dialogRef.afterClosed().subscribe(result => {
      console.log('choose model', result);
     this.prepareFormAgencyHash(result);
    });
  }

  prepareFormAgencyHash(agencyhash){
    this.http.prepareFormAgency(agencyhash).subscribe(res=>{
      console.log('res agency', res);
      if(res){
        this.agency = true;
        this.agencyavatar = res['logo'];
        this.agencyhash = agencyhash;
        this.agencyData = res;
       // this.agencyFormGroup.get('agencyname').setValue(res['name']);
       // this.agencyFormGroup.get('agencyaddress').setValue(res['address']);
       // this.agencycountry = res['country'];
       // this.agencycity = res['city'];
       // this.goFormAgency();

       this.prepareHtmlContent();

      }
    })
  }

  prepareModelForm(hash){
    console.log('prepare form', hash);
    this.http.seeModel(hash).subscribe(res=>{
      console.log('one model', res);
      this.modal = res['personal'];
      console.log('one model', this.model);
   this.modelavatar = this.modal['user_avatar'];
   this.modelhash = this.modal['user_hash'];

      this.model = true;
      this.getPreviewUrl();
    })
  }


  dlFile(data: Response, name, type) {

    switch(type){
      case 'pdf' :
      type = 'application/pdf' 
      break;
    }
  
    var blob = this.b64toBlob(data, type, '512');
    var blobUrl = URL.createObjectURL(blob);
  
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.href = blobUrl;
    a.download = name;
  //  this.spinnerProgress = false;
    a.click();
    window.URL.revokeObjectURL(blobUrl);
    a.remove();
  }

  b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;
  
    var byteCharacters = atob(b64Data);
    var byteArrays = [];
  
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
  
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      var byteArray = new Uint8Array(byteNumbers);
  
      byteArrays.push(byteArray);
    }
  
    var blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }


  getPreviewUrl(){
    let base = 'https://bmyscouter.co/db/clients/access/contrat_templates/template_mac.php?';
    let model = '&model=' + this.modelhash;
    let client = '&client=' + this.agencyhash;
    let language = '&language=' + this.language;
    let societe = '&seemodels=' + this.seemodels;

    let htmlContent = '&htmlContent=' + encodeURIComponent(this.contentFormGroup.get('htmlContent').value);
      
    base = base + client + societe + model + language + htmlContent;
    
    console.log('preview url', base);
    this.url = this.sanitization.bypassSecurityTrustResourceUrl(base);
    
    return base;
   // window.open(base);
  }

  onEdit(){
    console.log('on edit');
  }





}
