import { Component, OnInit } from '@angular/core';
import { MatDialog,  MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, Validators, UntypedFormGroup} from '@angular/forms';

import { HttpService } from '../../../http.service';

@Component({
  selector: 'app-addtemplates',
  templateUrl: './addtemplates.component.html',
  styleUrls: ['./addtemplates.component.scss']
})
export class AddtemplatesComponent implements OnInit {

  myGroup: UntypedFormGroup;
  mess : any;

  constructor(
    public dialogRef: MatDialogRef<AddtemplatesComponent>,
    private fb: UntypedFormBuilder,
    private http : HttpService
  ) { }

  ngOnInit() {
    this.createForm();
  }

  onNoClick(): void {
    this.dialogRef.close('close');
  }

  onOkClick(){
    var message =  this.myGroup.get('mess').value;
    this.http.addTemplate(
      message
    ).subscribe( res => {
      console.log(res);
      this.dialogRef.close(res);
    })
  }

  createForm(){
    this.myGroup = this.fb.group({
     mess: ['', Validators.required],
      });
  }

}
