import { Component, Inject } from '@angular/core';
import { HttpService } from '../http.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { UntypedFormBuilder, Validators, UntypedFormGroup} from '@angular/forms';
import { fraisData} from './frais.component';


@Component({
    selector: 'add-frais',
    templateUrl: 'add-frais.html',
  })
  
  export class addFrais {
    
    category: any;
    myGroup: UntypedFormGroup;
    imagetoadd:any;
   // base64Img: any;
    spinnerProgress: boolean;
    result : any;
    action: any;
    id: any;
    tempdate :any;
    fileToUpload: File = null;

    societe : any;

    constructor(
      public dialogRef: MatDialogRef<addFrais>,
      @Inject(MAT_DIALOG_DATA) public data: fraisData,
     private http: HttpService,
     private fb: UntypedFormBuilder,
    )
     {
        this.createForm();
        this.category = [
            { value : 'Restaurant', view : 'Restaurant'},
             { value : 'Transport', view : 'Transport' },
              { value : 'Communication', view : 'Communication' },
              { value : 'Evenementiel', view : 'Evenementiel' },
              { value : 'Autre Achat', view : 'Autre Achat' },
            ];
    }
  
    onNoClick(): void {
      this.dialogRef.close();
    }
  
    onOkClick(elem) : void {
      console.log(this.data);
        this.dialogRef.close();      
    }
  
    ngOnInit() {
      console.log('data',this.data);
      this.action = this.data['action'];
      this.id = this.data['id'];
      this.getSociete();
      if(this.data['action'] == 'edit'){
        this.prepareForm();
      }
    }

    async getSociete(){
      var dataE = { 'table' : 'societe' };
      var funcE = { type: 'BDD', func: 'GetFullTable' };    
      var Postdata = { func: funcE, data : dataE };
      console.log('on click', Postdata);
      let res = await this.http.goAcess(Postdata);
      console.log('societe', res);
      this.societe = res;
   
    }

    createForm(){
        this.myGroup = this.fb.group({
            typefrais: ['', Validators.required],
            observations: ['', Validators.required],
            montantht: ['', ],
            montantttc: ['', ],
            date: ['', Validators.required],
            societe: ['', Validators.required]
        });
      }

      prepareForm(){
        this.http.getFraisContent(this.data['id']).subscribe( res => {
          this.result = res;
          console.log(this.result);
        this.myGroup.get('typefrais').setValue(this.result.typefrais);
         this.myGroup.get('observations').setValue(this.result.observations);
        this.myGroup.get('montantht').setValue(this.result.montantht);
        this.myGroup.get('montantttc').setValue(this.result.montantttc);
        this.myGroup.get('date').setValue(this.result.date);
        this.myGroup.get('societe').setValue(this.result.societe);
        })
      }

      addJustif(){
      document.getElementById('inputID').click();
      }

      fileChangeEvent(event: any): void {
        this.readThis(event.target);
     }
    
    
  /*  readThis(inputValue: any): void {
      var file:File = inputValue.files[0];
      var myReader:FileReader = new FileReader();
    
      myReader.onloadend = (e) => {
        this.base64Img = myReader.result;
      }
      myReader.readAsDataURL(file);
    }*/

    readThis(files: FileList): void {
      this.fileToUpload = files.item(0);
 }

    addFrais(){
        this.http.addFrais(
            this.myGroup.get('typefrais').value, 
            this.myGroup.get('observations').value, 
            this.myGroup.get('montantht').value,
            this.myGroup.get('montantttc').value,
            this.myGroup.get('date').value.toISOString(),
            this.myGroup.get('societe').value,
            this.fileToUpload
           ).subscribe( res => {
               this.result = res;
               console.log(this.result);
            if (this.result.status == 'ok'){
              this.dialogRef.close(res);
            } else {
              this.dialogRef.close('echec');
            }
          })
    }

    editFrais(){
      this.tempdate =  this.myGroup.get('date').value.toISOString();
      this.http.editFrais(
        this.myGroup.get('typefrais').value, 
        this.myGroup.get('observations').value, 
        this.myGroup.get('montantht').value,
        this.myGroup.get('montantttc').value,
        this.tempdate,
        this.myGroup.get('societe').value,
        this.id 
      ).subscribe( res => {
        if(res == 'update'){
          this.dialogRef.close(res);
        }
      })
    }
    
    
  
  }