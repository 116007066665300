<div style="display:flex; flex-direction: column">
  <div style=" display: flex; flex-direction: row; flex-wrap: wrap;">
    <mat-card *ngIf="specs?.user_agency.length > 0">Agencies : <div *ngFor="let z of specs.user_agency">{{ z.name }} in
        {{ z.city }} </div>
    </mat-card>
    <mat-card>Can do naked pictures: {{specs?.user_naked}} </mat-card>
    <mat-card>Can do topless : {{specs?.user_topless}} </mat-card>
    <mat-card *ngIf="specs?.user_piercing.length > 0">Piercing : <span *ngFor="let x of specs.user_piercing">{{ x }}
      </span> </mat-card>
    <mat-card *ngIf="specs?.user_tatoo.length > 0">Tatoo : <span *ngFor="let x of specs.user_tatoo">{{ x }} </span>
    </mat-card>
    <mat-card *ngIf="specs?.user_languages.length > 0">Languages : <span *ngFor="let y of specs.user_languages">{{ y }}
      </span> </mat-card>
    <mat-card>
      <app-mgmtcategory [model]=" hash "></app-mgmtcategory>
    </mat-card>
  </div>






</div>