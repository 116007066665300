
<form *ngIf="type == 'agency' || type == 'clients' " [formGroup]="chgStateGroup" class="paddingTopOnMobile"
  style="width:90%;margin-top:15px;margin-left:50%; transform: translate(-50%, 0);">


  <mat-form-field style="display:block">
    <input matInput placeholder="Agency Name" formControlName="name">
  </mat-form-field>


  <mat-form-field style="display:block">
    <input matInput placeholder="Agency city" formControlName="city">
  </mat-form-field>

  <mat-form-field style="display:block" *ngIf="role > 1">
    <input matInput placeholder="Agency Country" formControlName="country">
  </mat-form-field>

  <mat-form-field style="display:block">
    <input matInput placeholder="Siret" formControlName="siret">
  </mat-form-field>


  <mat-form-field style="display:block">
    <input matInput placeholder="TVA" formControlName="tva">
  </mat-form-field>

  <mat-form-field style="display:block">
    <input matInput placeholder="Address" formControlName="address">
  </mat-form-field>


</form>



<form *ngIf="type == 'photograph' " [formGroup]="chgStatePhotograph"
  style="width:90%;margin-top:15px;margin-left:50%; transform: translate(-50%, 0);">


  <mat-form-field style="display:block">
    <input matInput placeholder="Photograph First Name" formControlName="name">
  </mat-form-field>


  <mat-form-field style="display:block">
    <input matInput placeholder="City" formControlName="city">
  </mat-form-field>

  <mat-form-field style="display:block">
    <input matInput placeholder="Country" formControlName="country">
  </mat-form-field>


  <mat-form-field style="display:block">
    <input matInput placeholder="Instagram" formControlName="instagram">
  </mat-form-field>

  <mat-form-field style="display:block">
    <input matInput placeholder="Website" formControlName="website">
  </mat-form-field>

  
  <mat-form-field style="display:block">
    <input matInput placeholder="Models.com Link" formControlName="modelslink">
  </mat-form-field>


</form>

<form *ngIf="type == 'customlist' " [formGroup]="chgStatePhotograph"
  style="width:90%;margin-top:15px;margin-left:50%; transform: translate(-50%, 0);">


  <mat-form-field style="display:block">
    <input matInput placeholder="Advisor Group Name" formControlName="name">
  </mat-form-field>


</form>


<form *ngIf="type == 'advisor' " [formGroup]="chgStateAdvisor"
  style="width:90%;margin-top:15px;margin-left:50%; transform: translate(-50%, 0);">


  <mat-form-field style="display:block">
    <input matInput placeholder="Custom List Name" formControlName="name">
  </mat-form-field>


</form>



<div class="bTnPannelForMobile">
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button (click)="InsertClient()" cdkFocusInitial>Ok</button>
</div>