import { Component, OnInit } from '@angular/core';
import { HttpService } from '../http.service';

@Component({
  selector: 'app-page2',
  templateUrl: './page2.component.html',
  styleUrls: ['./page2.component.scss']
})
export class Page2Component implements OnInit {


  type: any;
  category: any;

  classic : boolean;

  constructor(
    private get: HttpService,
  )
   {
    this.classic = false;
    

  }
   

  ngOnInit() {
    this.getModeltype();

  }

  getModeltype(){
    this.get.getModeltype('allcategory').subscribe(res=>{
      console.log(res);
      this.type = res['classic'];
      this.category = res['category'];
    })
  }


toggleMenu(){
  console.log('toggle menu');
  document.getElementById('choice').classList.toggle('show');
}

switchDisplay(elem){
  switch(elem){
    case 'category':
    this.classic = false;
    break;

    case 'classic':
      this.classic = true;
      break;
  }
}
  
switchCategory(e){
  console.log('togllge', e);
  if(e.checked == true){
    this.switchDisplay('category');
  } else {
    this.switchDisplay('classic');
  }
}

  
}
