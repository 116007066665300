import { Component, OnInit, Inject, Input } from '@angular/core';
import { HttpService } from '../../http.service';
import {  MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, Validators, UntypedFormGroup} from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { UntypedFormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

import { PushlogComponent } from './pushlog/pushlog.component';
import { PushcustomizerComponent } from './pushcustomizer/pushcustomizer.component';

import { CountrylogpushComponent } from './countrylogpush/countrylogpush.component';
import { SeedestinataireComponent } from './seedestinataire/seedestinataire.component';

import { ChoosemodelComponent } from '../../facturesystem/gencontract/genmac/choosemodel/choosemodel.component';
import { EditFactureComponent } from '../../facturesystem/allfactures/edit-facture/edit-facture.component';

export interface DialogData {
  tosend: string;
 }

 export interface DialogData2 {
  selecttype: string;
 }



@Component({
  selector: 'app-push',
  templateUrl: './push.component.html',
  styleUrls: ['./push.component.css']
})
export class PushComponent implements OnInit {

  @Input() model: string;

  spinnerProgress: boolean;
  models: any;
  tosend: Array<{id: number, hash: string}>;
  resultat : any;

  constructor(
    private get: HttpService,
    public dialog: MatDialog
  ) {
    this.tosend = [];
   }

  ngOnInit() {
    this.getmodel(this.model);
  }

  getmodel(elem){
  
    this.get.getModel(elem, 'both').subscribe( res => {
    //  console.log( 'resultat', res);
      this.spinnerProgress = false;
      this.resultat = res;
      this.models =this.resultat['modal'];
  
    })
  } 

  addToSend(elem){

    if(this.tosend.includes(elem)){
  //    console.log('slice',elem);
      var index = this.tosend.indexOf(elem);
      this.tosend.splice(index, 1);
    } else {
   //   console.log('push', elem);
      this.tosend.push(elem);
    }

//console.log(this.tosend);
  }

  sendModel(){
    let dialogRef = this.dialog.open(sendEmailComponent, {
      height: 'auto',
      width: '80%',
      minWidth: '80%',
      data: {model: this.tosend }
    });

    dialogRef.afterClosed().subscribe(result => {
   //   console.log('The dialog was closed');
  //    console.log('result', result);
    });
  }

  goLog(id){    
    let dialogRef = this.dialog.open(PushlogComponent, {
      height: 'auto',
      width: 'auto',
      data: {model: id }
    });

    dialogRef.afterClosed().subscribe(result => {
   //   console.log('The dialog was closed');
  //    console.log('result', result);
    });

  }




}


@Component({
  selector: 'send-email',
  templateUrl: 'sendemail.html',
 // styleUrls: ['./sendemail.module.css']
})
export class sendEmailComponent {

  chgStateGroup: UntypedFormGroup;
 // chgStateGroup2: FormGroup;

  result : any;
  spinnerProgress2: boolean;
  token: string;
  pokemonGroups: any;
  to = new UntypedFormControl();
  select: any;
  message : any;
  countrylist: any;
  country: string;

  tosend: any;
  htmlContentModel: any;

  selectedmodel: any;
  selectedAvatars : any;
  type : any;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '300px',
    minHeight: '300px',
    placeholder: 'Enter text here...',
    translate: 'no',
    uploadUrl: 'v1/images', // if needed
    customClasses: [ // optional
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };
 

  date: any;
  checked: boolean;
  shortlink: string;
  logs : any;
  
  constructor(
    public dialogRef: MatDialogRef<sendEmailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: UntypedFormBuilder,
    private get: HttpService,
    public snackBar: MatSnackBar,
    private dialog : MatDialog
  ) {
    this.createForm();
  //  this.createForm2();
    this.to = new UntypedFormControl();
    this.country = 'all';
    this.logs = [];
    }

    

ngOnInit(){
  this.selectedmodel = this.data['model'];
  this.type = this.data['type'];
  console.log(this.data);

  if(this.selectedmodel !== null){
    
  }


 this.selectCountry('agency');
}
    
  onNoClick(): void {
    this.dialogRef.close();
  }



createForm(){
  this.chgStateGroup = this.fb.group({
   // datetosend: ['', Validators.required],
    subject: ['', Validators.required],
    htmlContent: ['', Validators.required],
    calendar: ['', Validators.required],
    sender: ['contact@seemodels.co', Validators.required],

    clientmail: ['', Validators.required],
    accesstoalbum : [false, Validators.required],
    accesstodocs : [false, Validators.required],
    polaroids : [true, Validators.required],
    videos : [true, Validators.required],
    log : [true, Validators.required],
    phonenumber: ['', Validators.required],
    menuType : ['1', Validators.required],
   galleryname: ['', Validators.required],

    });
}

setDate(){
  const dialogRef = this.dialog.open(EditFactureComponent, {  
    width: '350px',
   //  height: '500px',
   data: {'text' : 'Date to Schedule', type : 'timepicker'}
   });

   dialogRef.afterClosed().subscribe(result => {
     console.log('date', result);
    if(result){
      if(result.status == 'set'){
        this.date = result.date;
      }
    }

  }) 
}


async prepareForm(tempE){
  var funcE = { type: 'PUSH', func: 'getTemplate' };  
  var dataE = {'models': this.selectedmodel, 'type' : tempE }
  var Postdata = { func: funcE, data : dataE };
  let res = await this.get.goAcess(Postdata);
  console.log('on click', res);
  this.chgStateGroup.get('htmlContent').setValue(res.content);
  this.chgStateGroup.get('subject').setValue(res.subject);
}


changeCountry(elem){
  this.country = elem.value;
 // this.prepareForm();
}



openSnackBar(message: string, action: string) {
  this.snackBar.open(message, action, {
    duration: 2000,
  });
}

selectCountry(type){
  this.get.selectCountry(type).subscribe( res => {
   this.countrylist = res;
 //  console.log(this.country);
  })
}

changeTO(agency){
 // console.log('to changed', agency);
  this.get.checkSendedMail(agency.value, this.data['model']).subscribe( res => {
    console.log(res);
  })
}

selectCountry1(){
  this.tosend = [];
  let dialogRef = this.dialog.open(CountrylogpushComponent, {
    data: {country: this.country, model: this.data['model']},
    height: 'auto',
    width: 'auto',
    panelClass: 'nopadding-box'
  });

  dialogRef.afterClosed().subscribe(result => {
  //  console.log('tosend', result);
    if(result !== 'log'){
      this.tosend = result;
    }

    if(result == 'log'){
      this.openSnackBar('Feedback Added', 'Congrats');
    }
   

  });
}

seeDestinataire(){
  let dialogRef = this.dialog.open(SeedestinataireComponent, {
    data: {tosend: this.tosend},
    height: 'auto',
    width: 'auto',
    panelClass: 'nopadding-box'
  });

  dialogRef.afterClosed().subscribe(result => {
 //   console.log('tosend', result);
    if(result !== 'empty'){
      this.tosend = result;
     
    }
    
  });
}


goCustomize(){
  let dialogRef = this.dialog.open(PushcustomizerComponent, {
    height: 'auto',
    width: 'auto',
    panelClass: 'nopadding-box'
  });

  dialogRef.afterClosed().subscribe(result => {
if(result == 'ok'){
  this.openSnackBar('Push Template', 'Updated');
}
  });
}

selectModel(){
  let dialogRef = this.dialog.open(ChoosemodelComponent, {
    height: 'auto',
    width: 'auto',
    data: {
      selecttype: 'multiple', showpackage : true
    },
    panelClass: 'nopadding-box'
  });

  dialogRef.afterClosed().subscribe(result => {
    console.log('result', result);
    if(result[0]['status'] == 'ok'){
      this.selectedAvatars = result[0]['avatars'];
      this.selectedmodel = result[0]['hash'];
      console.log("this.selectedmodel", this.selectedmodel);
    }
      });
}

async getFastLink(destinataire){
  
  let dataE = {
    'clientmail' : destinataire, 
    'albums' : this.chgStateGroup.get('accesstoalbum').value, 
    'docs' : this.chgStateGroup.get('accesstodocs').value, 
    'polaroids' : this.chgStateGroup.get('polaroids').value, 
    'videos' : this.chgStateGroup.get('videos').value,
    'galleryname' : this.chgStateGroup.get('galleryname').value,
    'log' : this.chgStateGroup.get('log').value,
   'menuType' : this.chgStateGroup.get('menuType').value,
'selectedmodels' : this.selectedmodel,
'shortlink' : 1
  };

 
  var funcE = { type: 'MGMTTOKEN', func: 'generateToken' };  
  var Postdata = { func: funcE, data : dataE };
  let res = await this.get.goAcess(Postdata);
  console.log('on click', res);

  this.shortlink = res['shortlink'];
  return this.shortlink;
}

async sendEmailAll(){
  
  this.spinnerProgress2 = true;

  let token = [];
  let htmlContent = [];
  let first = [];

  for (var i = 0; i < this.tosend.length; i++) {
    token[i] = await this.getFastLink(this.tosend[i]);
    first[i] = await this.getNameFromEmail(this.tosend[i]);
    htmlContent[i] = this.prepareContent(this.htmlContentModel, token[i], first[i]);
    await this.sendEmailGeneric(this.tosend[i], htmlContent[i]);
  }
  this.spinnerProgress2 = false;

}

async saveForLaterPush(){
  let token = [];
  let htmlContent = [];
  let first = [];
  this.spinnerProgress2 = true;
  for (var i = 0; i < this.tosend.length; i++) {
    token[i] = await this.getFastLink(this.tosend[i]);
    first[i] = await this.getNameFromEmail(this.tosend[i]);
    htmlContent[i] = this.prepareContent(this.htmlContentModel, token[i], first[i]);
    await this.saveForPushGeneric(this.tosend[i], htmlContent[i], token[i]);
  }
  this.spinnerProgress2 = false;
}

prepareContent(contentE, token, firstName){
  contentE = contentE.replaceAll('[FAST LINK]', token);

  if(firstName.status == 'set'){
    contentE = contentE.replaceAll('[Contact Name]', firstName.firstname);
  }
 
  return contentE;
}

async getNameFromEmail(mail){
  var funcE = { type: 'MAIL', func: 'getNameFromEmail' };  
  var dataE = {  'mail' : mail };    

  var Postdata = { func: funcE, data : dataE };

  console.log('on click', Postdata);
  let res = await this.get.goAcess(Postdata);
  return res;
}


async sendEmailGeneric(destinataireE, html){

  var funcE = { type: 'RELANCE', func: 'sendRelanceEmail' };  

  var dataE = { 
    'to': [destinataireE],
    'subject': this.chgStateGroup.get('subject').value, 
    'htmlContent' : html,
    'sender' : this.chgStateGroup.get('sender').value,
    'type': 'sendfastlink'
  };    


  var Postdata = { func: funcE, data : dataE };

  console.log('on click', Postdata);
  let res = await this.get.goAcess(Postdata);
  console.log('on click', res);
  
  let prepareLog = {'type' : 'email', 'destinataire' : destinataireE, 'status' : res.toUpperCase() };
  this.logs.push(prepareLog);
}

async saveForPushGeneric(destinataireE, html, fastlink){

  var funcE = { type: 'PUSH', func: 'saveForLaterPush' };  

  var dataE = { 
    'to': destinataireE,
    'subject': this.chgStateGroup.get('subject').value, 
    'htmlContent' : html,
    'sender' : this.chgStateGroup.get('sender').value,
    'time': this.date,
    'selectedmodels' : this.selectedmodel,
    'fastlink' : fastlink
  };    


  var Postdata = { func: funcE, data : dataE };
  let res = await this.get.goAcess(Postdata);

  let prepareLog = {'type' : 'scheduleemail', 'destinataire' : destinataireE, 'status' : res.toUpperCase() };
  this.logs.push(prepareLog);
}




}


