
  <app-date-filter></app-date-filter>

  <div class="downloadBtn" (click)="DownloadAsZip()">
    <i style="font-size:30px; color: white" class="material-icons centred">download</i> 
  </div> 

  <mat-spinner class="centred"  *ngIf="spinnerProgress"> </mat-spinner>


<div class="fdpCtn"  *ngIf="!spinnerProgress">
    <table class="w3-table-all">
        <thead>
          <tr class="w3-light-grey">

            <th>
              <div class="flexRow">
                <div class="rowCol">Model</div>
                <div class="rowCol removeOnMobile">Brut Employeur</div>
                <div class="rowCol">Libelle</div>
                <div class="rowCol">Net Mannequin</div>
                <div class="rowCol">Paid on</div>
                <div class="rowCol">Action</div>
              </div>
            </th>


          </tr>
        </thead>
         
            <tr *ngFor="let x of fdp; let i = index"  >
              <td >
                <div (click)="showDetails(x.id, i)" class="flexRow" >
                  <div class="rowCol">
                    <img [src]="x.user_avatar" height="50px" style="border-radius: 50%;" />
                  </div>
                  <div class="rowCol removeOnMobile">{{x.brutEmployeur}}</div>
        
                  <div class="rowCol">{{x.libelle}}</div>
                  <div class="rowCol">{{x.netMannequin}}</div>
                  <div class="rowCol">{{ getDate(x.paid_on) }}</div>
                  <div class="rowCol">
                    <div class="actionFlexCtn">
                      
                      <div>
                        <i (click)="downloadfile(x.url)" style="cursor: pointer; margin-right: 5px;" class="material-icons">
                          get_app
                        </i>
    
                      </div>
           
  
                      <div>
                        <i  (click)="viewFile(x.url, 'pdf')" style="cursor: pointer" class="material-icons">
                          remove_red_eye
                          </i>
                      </div>
         
                    </div>
      


                  </div>
                </div>

                <div class="hidden" id="{{ 'hidden_' + i }}">
                  <div *ngFor="let y of details" class="flexRow">
                    <div class="rowCol">{{y.job_libelle}}</div>
                    <div style = "display: flex; flex-direction: row;" class="rowCol">
                      <div>Facturation : {{y.montant_ht}}</div>
                      <div >Brut Employeur : {{y.job_in_pocket}}</div>
                    </div>
                    
                   
                  </div>
                
              </div>

              </td>
            </tr>

         

           

      </table>
</div>

