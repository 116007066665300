import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup} from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-smsandemailcomp',
  templateUrl: './smsandemailcomp.component.html',
  styleUrls: ['./smsandemailcomp.component.scss']
})
export class SmsandemailcompComponent implements OnInit {

  chgStateGroup: UntypedFormGroup;
  htmlContent : any;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '300px',
    minHeight: '300px',
    placeholder: 'Enter text here...',
    translate: 'no',
    uploadUrl: 'v1/images', // if needed
    customClasses: [ // optional
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };


  constructor(
    public dialogRef: MatDialogRef<SmsandemailcompComponent>,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data,
  ) { }

  ngOnInit(): void {
    console.log('inject', this.data);
    this.createForm();
    this.chgStateGroup.get('smscontent').setValue(this.data['smscontent']);
    this.chgStateGroup.get('sender').setValue(this.data['emailsender']);
    this.chgStateGroup.get('subject').setValue(this.data['emailsubject']);
    //this.chgStateGroup.get('htmlContent').setValue(this.data['htmlcontent']);
    this.htmlContent = this.data['htmlcontent'];
  }



  createForm(){
    this.chgStateGroup = this.fb.group({
      smscontent: ['', Validators.required],
      htmlContent: ['', Validators.required],
      subject: ['', Validators.required],
      sender: ['', Validators.required]
      });
  }


  onNoClick(){
    let resu = { 'status' : 'cancel' };
    this.dialogRef.close(resu);
  }

  onOkClick(){   
    let res = { 
      'smcontent' : this.chgStateGroup.get('smscontent').value , 
      'emailcontent' : this.chgStateGroup.get('htmlContent').value, 
      'emailsender' : this.chgStateGroup.get('sender').value, 
      'emailsubject' : this.chgStateGroup.get('subject').value 
    };
    let resu = { 'status' : 'change', 'data' : res };
    this.dialogRef.close(resu);
  }

}
