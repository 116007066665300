import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { GetBooking } from './getbooking';

@Component({
  selector: 'custommenu',
  templateUrl: 'custommenu.html',
  styleUrls: ['custommenu.css']
})

export class CustommenuComponent {

  @Input() background: string;
  @Input() gender: string;

  text: string;
  srcLogo: string;
  srcMenu: string;


  @ViewChild('menuID',{ static: true }) menuID: ElementRef;
  @ViewChild('menuCtn', { static: true }) menuCtn: ElementRef;
  @ViewChild('bTnD', { static: true }) bTnD: ElementRef;
  @ViewChild('AllMenu', { static: true }) AllMenu: ElementRef;

  constructor(
    public dialog: MatDialog
  ) {
   

  }

  ngOnInit(){
    console.log('gender', this.gender);
   
    this.menuID.nativeElement.style.display = 'none';
    
    if (this.background == 'transparent'){
      this.menuCtn.nativeElement.style.backgroundColor  = 'transparent';
      this.srcLogo = 'assets/menu/logoo3.png';
      this.srcMenu = 'assets/menu/64cc084708cc6f156cd1216b378cbcfe.svg';

    } else {
      this.menuCtn.nativeElement.style.backgroundColor  = 'white';
      this.bTnD.nativeElement.classList.add("bTnDActive");
      this.srcLogo = 'assets/menu/logoo4.png';
      this.menuCtn.nativeElement.style.borderBottom = "1px solid #e8e8e8";
      this.srcMenu = 'assets/menu/A64cc084708cc6f156cd1216b378cbcfe_b.svg';
    }
  }
  

  toggleMenu(){
  

    if (this.background == 'transparent'){
   
  
    if (this.menuID.nativeElement.style.display === 'none'){
      this.menuID.nativeElement.style.display = "block";
      this.menuCtn.nativeElement.style.backgroundColor = "#fff";
      this.srcLogo = 'assets/menu/logoo4.png';
      this.srcMenu = 'assets/menu/23e9830fbce5c5fde7158849ae6d99e0.svg';
      this.bTnD.nativeElement.classList.add("bTnDActive");

      if(this.detectmob()){
       
        this.AllMenu.nativeElement.style.height = '100%';
      } 


    } else {
      this.menuID.nativeElement.style.display = "none";
      this.menuCtn.nativeElement.style.backgroundColor = "transparent";
      this.srcLogo = 'assets/menu/logoo3.png';
      this.srcMenu = 'assets/menu/64cc084708cc6f156cd1216b378cbcfe.svg'
      this.bTnD.nativeElement.classList.remove("bTnDActive");

      if(this.detectmob()){
        this.AllMenu.nativeElement.style.height = 'auto';
      } 
      
    }
  }

  else {


    this.srcLogo = 'assets/menu/logoo4.png';

    if (this.menuID.nativeElement.style.display === 'none'){
      this.menuID.nativeElement.style.display = "block";
      this.menuCtn.nativeElement.style.backgroundColor = "#fff";
      this.srcMenu = 'assets/menu/23e9830fbce5c5fde7158849ae6d99e0.svg';

      if(this.detectmob()){
       
        this.AllMenu.nativeElement.style.height = '100%';
      } 


    } else {
      this.menuID.nativeElement.style.display = "none";
      this.menuCtn.nativeElement.style.backgroundColor = this.background;
      this.srcLogo = 'assets/menu/logoo4.png';
      this.srcMenu = 'assets/menu/A64cc084708cc6f156cd1216b378cbcfe_b.svg';

      if(this.detectmob()){
        this.AllMenu.nativeElement.style.height = 'auto';
      } 

    }

  }

  }



  goModal(elem){
    console.log('go mdoal', elem);
  }

  goPage(elem){
   // this.navCtrl.push(elem, {}, { animate: false });
   console.log(elem);
  }

  goRoot(){
  //  this.navCtrl.push(FakerootPage, {}, { animate: false });
  }

  detectmob() { 
    if(window.innerWidth <= 1024 && window.innerHeight <= 768) {
      return true;
    } else {
      return false;
    }
   }

   getBook(): void {
    const dialogRef = this.dialog.open(GetBooking, {
      width: '250px',
     // data: {name: this.name, animal: this.animal}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
     //this.animal = result;
    });
  }
  

}
