<div id="scrollMenu" (scroll)="onScroll($event)" style="
position:fixed; 
top: 0; 
background: #111; 
z-index: 2; 
overflow: hidden; 
height: 60px; 
left: 0; 
color: #fff;
width: 100%;">

<div style="position:absolute;top:0;left:0; padding: 10px 11px">

  <ul style="margin:0;padding:0;">
    <li style="color: white; list-style-type: none;float:left;padding: 10px;text-transform: uppercase; font-weight: 500">See Models</li>
 <!--   <li style="color: white; list-style-type: none;float:left;padding: 10px;">See Models</li> -->
  </ul>
</div>

<div style="position:absolute; left: 50%;top:50%; transform: translate(-50%, -50%);text-transform: uppercase; color: white; opacity: 0.7 ">Search</div>

<div class="hamburger">
  <span class="line1"></span>
  <span class="line2"></span>
  <span class="line3"></span>
</div>

</div>

