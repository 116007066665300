<div  style="overflow-y: scroll; overflow-x: hidden;" >
  <button *ngIf="selecttype == 'multiple' && modelBool " mat-button (click)="selectOther()">select other Model</button>
  <div style="width: 100%; display: flex; flex-direction: column; justify-content: flex-start;" *ngIf="modelBool">

    <div *ngIf="loading" style="display: flex; flex-direction: column; justify-content: center; align-items: center; min-height: 100vh; width: 100%">
      <mat-spinner>
      </mat-spinner>
    </div>

    <div style="display: flex;flex-direction: column; justify-content:  center; align-items: center; margin-bottom: 5px;">
      <div>
        <input placeholder="Find a model" (keyup.enter)="goSearch($event.target.value)" />
       
      </div>

    </div>

    <div  *ngIf="!loading"
      style="display: flex; flex-direction: row; flex-wrap: wrap; overflow-y: scroll; width: 100%"
      >
    
      <div *ngFor="let y of model" style="display: flex; flex-direction: row; flex-wrap: wrap;">

        <div class="avatarFace" style="background-color: #2db2d8;">
        <div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); font-size: 32px; color: white; text-transform: uppercase;">{{y.letter}}</div>
        </div>


        <!--
          (click)="selectAvatar(x.hash, x.avatar)"
        -->
        <div *ngFor="let x of y.data" class="avatarFace"
     
        [style.backgroundImage]="getImage(x.avatar)"
      
          
          [id]="x.hash"
          >

          <div class="avatarName" style="font-size: 16px;">{{x.first}}</div>

          <div class="viewPackageBtn"   (click)="selectShowPackage(x.hash, x.avatar)">
            <div class="viewPackageBtnInner">
              <img style="position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);" width="20px"
              src="assets/eye_white.png" />
            </div>
      
          </div>

          <div class="deleteBtn" (click)="deleteThisModel(x.hash)">
            <div class="deleteBtnInner">
              <img style="position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);" width="20px"
              src="assets/delete.png" />
            </div>
          </div>

        </div>

      </div>

    </div>
  
  </div>
  
  
  <div *ngIf="!modelBool" style="padding-top: 150px;">
    <div
      style="display: flex; flex-direction: row; justify-content: center; align-items: center; flex-wrap: wrap; overflow: auto;">
      <div *ngFor="let x of type"  [ngClass]="getClass(x.label)"   (click)="getModel(x.label)">
        <div style="display: flex; flex-direction: column; justify-content : center; align-items: center; min-height: 100%;">
          <div class="flexItem">{{x.label}}</div>
        </div>
       
      </div>
  
    </div>
  
    <div
      style="display: flex; flex-direction: column; justify-content: center; align-items: center; flex-wrap: wrap; overflow: auto;">
      <div *ngFor="let x of category"  [ngClass]="getClass(x.label)" (click)="getModel(x.label)">
        <div style="display: flex; flex-direction: column; justify-content : center; align-items: center; min-height: 100%;">
          <div class="flexItem">{{x.label}}</div>
        </div>
       
      </div>
    </div>
  
  </div>

  <div  class="bTnPannelForMobile" *ngIf="selecttype == 'multiple' ">
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button (click)="onOkClick()" cdkFocusInitial>Ok</button>
  </div>

</div>

