import { Component, OnInit, Inject } from '@angular/core';
import { HttpService } from '../../http.service';


import { AddfactureComponent } from './addfacture/addfacture.component';
import { EditFactureComponent } from '../allfactures/edit-facture/edit-facture.component';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { collabData } from '../contract/contract.component';

export interface factureData {
  id: string;
}

export interface factureEdit {
  id: string;
}

@Component({
  selector: 'app-facturation',
  templateUrl: './facturation.component.html',
  styleUrls: ['./facturation.component.css']
})
export class FacturationComponent implements OnInit {

  factures : any;
  colab: any;

  constructor(
    private http: HttpService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: collabData,
  ) { }

  ngOnInit() {
    console.log(this.data);
    this.getfacture(this.data['id']);
  }

  addFacture(){
    const dialogRef = this.dialog.open(AddfactureComponent, {  
      width: '350px',
     //  height: '500px',
     data: {id : this.data['id']}
     });

     dialogRef.afterClosed().subscribe(result => {
      console.log(result);
       if(result){
        if(result['status'] == 'ok'){
          this.getfacture(this.data['id']);
        }
       }


    })
  }

  getfacture(id){
    this.http.getfacture(id).subscribe(res=>{
      console.log(res);
      this.factures = res['facture'];
      this.colab = res['collab'];
    })
  }

  goFacture(url){
    window.open(url);
  }

  editFacture(numfacture){
    const dialogRef = this.dialog.open(EditFactureComponent, {  
      width: '350px',
     //  height: '500px',
     data: {id : numfacture}
     });

     dialogRef.afterClosed().subscribe(result => {
      if(result == 'ok'){
        this.getfacture(this.data['id']);
      }

    }) 
  }

  delFacture(factureid){
    this.http.delFacture(factureid).subscribe(res => {
      if(res == 'ok'){
        this.getfacture(this.data['id']);
      }
    })
  }



}
