<div style="height: calc(100vh - 50px); ">

    <div style="min-height: 50%; max-height: 50%;">
        <div class="grid-stats-ctn-title">Most Recent Registrations</div>
        <app-lastsubscribe [last]="getLastSubscribe" ></app-lastsubscribe>
    </div>
    <div style="min-height: 50%; max-height: 50%;">
        <div class="grid-stats-ctn-title">Most Recent Actions</div>
        <app-lastactions [last]="getLastAction"></app-lastactions>
    </div>

    </div>  