<div style="position:relative ; height: 100%;">

<table style=" width: 90%; margin: 5px; margin-left: 50%; transform: translate(-50%, 0); margin-top: 20px;" class="w3-table w3-striped w3-border w3-card-4">
  <tr>
    <th>Question</th>
    <th>Answer</th>
    <th>Category</th>

  </tr>
  <tr *ngFor="let x of faq; let i = index;">
    <td>{{ x.question }}</td>
    <td>{{ x.answer }}</td>
    <td>{{ x.category }}</td>
    <th>
      <i (click)="editFaq(x.faqid)" style="cursor: pointer" class="material-icons">
        edit
        </i>

     <i (click)="delFaq(x.faqid, i)" style="cursor: pointer" class="material-icons">
      delete_outline
      </i>  
    </th>

  </tr>

</table> 

<button class="fab" (click)="addFaq()" mat-fab color="primary">+</button>

</div>