<div class="factureSettingsCtn"  [style.height.px]="innerHeight">
  <div style="border: 1px solid #000; margin-top: 10px">
    <div style="
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        flex : 1;
      ">
      <div class="label">
        Choose Clients
        <div class="badge">1</div>
      </div>

      <div *ngIf="!clientsBool" style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        ">
        <div style="display: flex; flex-direction: row; justify-content: center">
          <div class="boxItem" (click)="chooseAgency('clients')">
            <div>
              <img src="../../../../assets/client/client.png" style="height: 20px" />
            </div>
            <div class="boxItemTitle">Clients</div>
          </div>

          <div class="boxItem" (click)="chooseAgency('agency')">
            <div>
              <img src="../../../../assets/client/agency.png" style="height: 20px" />
            </div>
            <div class="boxItemTitle">Agency</div>
          </div>
        </div>
      </div>

      <div style="display: flex; flex-direction: column; justify-content: center; " *ngIf="clientsBool">
        <div [style.backgroundImage]="'url(' + agencylogo + ')'" class="avatar2"
          style="margin-left: 50%; transform: translate(-50%, 0)"></div>

      </div>

      <div *ngIf="clientsBool" (click)="clientsBool = !clientsBool"
        style="position: absolute; bottom: 10px; right: 10px; cursor: pointer; color: blue; font-style: italic; font-size: 12px;">
        Change Client
      </div>


    </div>
  </div>

  <div style="
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 15px;
        border: 1px solid black;
        padding-top: 10px;
        padding-bottom: 10px;
      ">
    <div class="label">
      Choose at least 1 model
      <div class="badge">2</div>
    </div>

    <div style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          position: relative;
          flex-wrap: wrap;
        ">
      <div [formGroup]="x" style="
            border: 1px solid black;
            margin-top: 2px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            position: relative;
          " *ngFor="let x of t; let i = index">
        <div (click)="t.splice(i, 1)" style="
              position: absolute;
              top: 0;
              right: 0;
              width: 25px;
              height: 25px;
              background-color: black;
              cursor: pointer;
            ">
          <div style="position: relative; height: 100%; width: 100%">
            <img style="
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                " width="20px" src="assets/delete.png" />
          </div>
        </div>

        <div class="avatarFace" style="width: 50px; height: 50px; margin: 5px !important"
          [style.backgroundImage]="getImage(x.avatar)">
          <div class="avatarName">{{ x.first }}</div>
        </div>

        <div *ngIf="agencyType == 'clients'" style="display: flex; flex-direction: row">
          <mat-form-field>
            <input matInput placeholder="Facturation" formControlName="facturation" />
          </mat-form-field>
          <mat-form-field style="margin-left: 5px;">
            <input matInput placeholder="In Pocket" formControlName="inpocket" />
          </mat-form-field>

          <mat-form-field style="margin-left: 5px;">
            <input matInput [matDatepicker]="picker3" placeholder="Job Date" formControlName="jobdate" />
            <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
            <mat-datepicker #picker3></mat-datepicker>
          </mat-form-field>
        </div>

        <div *ngIf="agencyType == 'agency'" style="display: flex; flex-direction: column">
          <mat-form-field>
            <input matInput [matDatepicker]="picker1" placeholder="Statement Starting Date"
              formControlName="statement_start" />
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>

          <mat-form-field>
            <input matInput [matDatepicker]="picker2" placeholder="Statement Ending Date"
              formControlName="statement_end" />
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </div>

    <button mat-stroked-button (click)="chooseModel()" style="margin-top: 20px">
      Click to add a Model
    </button>
  </div>

  <div style="border: 1px solid #000; padding: 10px; margin-top: 10px">
    <div style="display: flex; flex-direction: column; justify-content: center">
      <div class="label">
        Add facture lines
        <div class="badge">3</div>
      </div>


      <table class="w3-table w3-bordered  w3-striped" *ngFor="let x of facturelines; let i = index">
        <tr>
          <td>{{ x.intitule }}</td>
          <td>{{ x.value }}</td>
          <td>
            <div (click)="facturelines.splice(i, 1)" style="cursor: pointer;">Delete</div>
          </td>
          <td>
            <div (click)="editLine(i)" style="cursor: pointer;">Edit</div>
          </td>
        </tr>


      </table>

      <div [formGroup]="factureFormGroup">





        <div style="display: flex; flex-direction: column; justify-content: center">

          <angular-editor style="margin-top:10px; min-width: 320px" id="editor1" formControlName="intitule"
            [config]="editorConfig" [(ngModel)]="htmlContent"></angular-editor>

          <mat-form-field style="margin-left: 10px">
            <input matInput placeholder="Montant H.T" formControlName="montantht" required />
          </mat-form-field>
        </div>

      </div>


      <button mat-stroked-button (click)="addMoreLine(); getPreviewUrl()">Add More</button>
    </div>
  </div>

  <div style="
        display: flex;
        flex-direction: column;
        justify-content: center;
        border: 1px solid black;
        margin-bottom: 5px;
        margin-top: 5px;
      ">
    <div style="display: flex; flex-direction: row; justify-content: center">
      <div class="boxItem" (click)="seemodels = 'fr'; getPreviewUrl()"
        [ngClass]="seemodels == 'fr' ? 'active' : 'my-class2'">
        <div>
          <img src="../../../../assets/client/client.png" style="height: 20px" />
        </div>
        <div class="boxItemTitle">See Models FR</div>
      </div>

      <div class="boxItem" (click)="seemodels = 'uk';  getPreviewUrl()"
        [ngClass]="seemodels == 'uk' ? 'active' : 'my-class2'">
        <div>
          <img src="../../../../assets/client/agency.png" style="height: 20px" />
        </div>
        <div class="boxItemTitle">See Models UK</div>
      </div>
    </div>
  </div>

  <div [formGroup]="tvaFormGroup" style="
        display: flex;
        flex-direction: column;
      
        border: 1px solid #000;
        padding: 10px;
        margin-top: 10px;
        
      " *ngIf="!download">
    <div style="
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        ">
      <div>TVA (VAT) :</div>

      <div>
        <mat-slide-toggle formControlName="tva" (change)="getPreviewUrl();"></mat-slide-toggle>
      </div>

    </div>

  
      <mat-form-field style="margin-left: 10px">
        <input matInput placeholder="Numero de Commande" formControlName="numcommande" />
      </mat-form-field>

      <mat-form-field style="margin-left: 10px">
        <input matInput placeholder="Numero de Facture" formControlName="numfacture" />
      </mat-form-field>
    
    

    <mat-form-field>
      <mat-label>Language</mat-label>
      <mat-select [(ngModel)]="language" (ngModelChange)="getPreviewUrl()" formControlName="language"> 
        <mat-option [value]="'fr'">Français</mat-option>
        <mat-option [value]="'eng'">English</mat-option>
      </mat-select>
    </mat-form-field>

    <button mat-stroked-button (click)="getPreviewUrl()" style="margin-top: 15px">
     Refresh Preview
    </button>

    <button mat-stroked-button (click)="generatePDF()" style="margin-top: 15px">
      Generate PDF
    </button>

  </div>
  




<div *ngIf="download">Download...</div>
</div>

<div [style.height.px]="innerHeight" 
      class="iFrameCtn">

  <iframe width="100%" [height]="innerHeight" [src]="url" (onload)="resizeIframe(this)"></iframe>
</div>