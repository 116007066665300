<div style="position: relative; height: 50px;">
  <mat-button-toggle-group #group="matButtonToggleGroup" (change)="UpdateSize($event.value)" [value]="size"
    style="left:50%; transform: translate(-50%, 0);" *ngIf="showpics" class="removeOnMobile" >
    <mat-button-toggle value="small">
      <mat-icon aria-hidden="false" style="font-size: 18px">format_size</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle value="medium">
      <mat-icon aria-hidden="false" style="font-size: 26px">format_size</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle value="big">
      <mat-icon aria-hidden="false" style="font-size: 32px">format_size</mat-icon>
    </mat-button-toggle>
  </mat-button-toggle-group>

  <div style="position: absolute; top: 20px; left: 20px;">

    <div style="display: flex; flex-direction: row; justify-content: space-evenly">
      <div *ngIf="showalbum">{{ nbreofalbum }} Album</div>
      <div class="removeOnMobile"  *ngIf="showpics">{{ nbreoffile }} Pics</div>
      <div>
        <button mat-button *ngIf="showpics && !transfert" (click)="transfertToAlbum()" class="removeOnMobile">Transfert Photos</button>
      </div>
      <div>

        <button mat-button *ngIf="showpics && transfert" (click)="chooseAlbumToTransfert()">Choose Album Photos</button>
      </div>
      <div class="removeOnMobile">
        <button mat-button *ngIf="showpics && (tempalbumid !== 0)" (click)="renameAlbum()">Rename Album</button>
      </div>
    </div>

  </div>


  <div style="position: absolute; top: 20px; right: 20px;" >

    <button mat-button *ngIf="showpics && (tempalbumid !== 0)" (click)="deleteAlbum()"  class="removeOnMobile">Delete Album</button>
    <button mat-button *ngIf="showpics" (click)="closeAlbum()">Close Album</button>
    <button mat-button *ngIf="showalbum" (click)="createAlbum()">Create Album</button>
    <button mat-button *ngIf="showalbum" (click)="addto('materials')"  class="removeOnMobile">Add To Divers</button>
    <button mat-button *ngIf="showpics" (click)="addto('materials')"  class="removeOnMobile">Add To this Album</button>
    <input id="loaddata" type="file" name="file" style="display:none;" (change)="fileChangeEvent($event, 'materials')"
      multiple />
  </div>

</div>

<mat-spinner class="centred" *ngIf="spinnerProgress">
</mat-spinner>

<div style="width: 100%; height: auto; overflow-y: none; float: left;">

  <div *ngIf="showalbum">
    <app-albumphotos *ngFor="let y of albums" [desc]="y.desc" [date]="y.date" [back]="y.background" [albumid]="y.id"
      [total]="y.totalpics" [visible]="y.visiblepics" (click)="showAlbum(y.id)"></app-albumphotos>
  </div>


  <div
    style="display: flex; flex-direction: row; justify-content: flex-start;flex-wrap: wrap;height: 100%;overflow-y: auto;"
    #content>



    <div *ngIf="showpics" 
      cdkDropList
      
      #todoList="cdkDropList"
      [cdkDropListData]="gallery"
      [cdkDropListConnectedTo]="doneList"
      
      (cdkDropListDropped)="drop($event)"
      [style.height.px]="contentHeight * 0.8 "
      [style.maxHeight.px]="contentHeight * 0.8"
      style="display: flex; flex-direction: row; justify-content: flex-start;flex-wrap: wrap;">


      <div cdkDrag *ngFor="let x of  gallery;  let i = index " [ngClass]="getClass(size, x.visibility)"
        class="event-card2" [style.backgroundImage]="getImage(x.resize)">

        <div style="position:relative;height:100%;">

          <div class="imageMenu" [attr.id]=" 'menu' + i">

            <div class="menuChoice" (click)="DeletePics(x.id, i, 'gallery')">
              Delete Pics
            </div>

            <div (click)="useAsSpecial(x.id, 'main', i)" class="menuChoice">Use as Main</div>
            <div (click)="useAsSpecial(x.url, 'avatar', i)" class="menuChoice">Make Avatar</div>
          </div>

          <div class="iconCenter">
            <div (click)="open(i)" style="cursor:pointer;width:100%;height:100%; position: relative;">
              <img style="position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);" width="20px"
                src="assets/album.png" />
            </div>
          </div>


          <div
            style="position:absolute;top:0;left:0;width:30px;height:30px;background-color:white;opacity:0.5;z-index: 100">
            <a [href]="x.fullurl" target="_blank" style="cursor:pointer;width:100%;height:100%">
              <img style="position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);" width="20px"
                src="assets/picture.png" />
            </a>


          </div>

          <div *ngIf="!transfert"
            style="position:absolute;top:0;right:0;width:30px;height:30px;background-color:white;opacity:0.5;z-index: 100"
            (click)="showMenu(i)">
            <div style="cursor:pointer;width:100%;height:100%; position: relative;">
              <img style="position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);" width="20px"
                src="assets/settings2.png" />


            </div>
          </div>






          <div
            style="position:absolute;bottom:0;right:0;width:30px;height:30px;background-color:white;opacity:0.5;cursor:pointer;z-index: 100"
            (click)="changeImageVisibility(x.id, x.visibility, i, 'gallery')">
            <img style="position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);" width="20px"
              src="assets/value.png" />
          </div>

          <div
            style="position:absolute;bottom:0;left:0;width:30px;height:30px;background-color:white;opacity:0.5;cursor:pointer;z-index: 100"
            (click)="rotatePics(x.id, i, 'gallery')">
            <img style="position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);" width="20px"
              src="assets/rotate.png" />
          </div>

          <mat-checkbox *ngIf="transfert" style="position:absolute; top: 5px; right: 5px;"
            (click)="addToTransfert(x.id)"></mat-checkbox>

        </div>

      </div>


    </div>

  </div>

  <div style="width: 100%; background-color: white;">


    <div style="width: 100%; border-top: 1px solid #212121;display: flex; flex-direction: row; flex: 1;"
      *ngIf="packageSelection">


      <div *ngIf="!showPackageList" style="display: flex; flex-direction: column; justify-content: center; align-items: center;width: 100%;">
        <button mat-button (click)="_addNewPackage()">Create New Package</button>
      </div>

      <div *ngFor="let x of showPackageList; let i = index;" class="selectedImgPics" [style.backgroundImage]="getImage(x.packageCover)"  (click)="selectThisPackage(x.packageId)">

        <div class="selectedImgPics-title">
          <div class="selectedImgPics-title-inner">
           <div class="centred" style="color: white;"> {{x.packageName}}</div>
          </div>
          </div>

      </div>

    </div>

    <div >
      <div 
      cdkDropList 
      #doneList="cdkDropList" 
    
      [cdkDropListConnectedTo]="todoList"

      
      [ngClass]="toggleVisibility()"
      (cdkDropListDropped)="drop($event)"
      
      >
        <div cdkDrop style="display: flex; flex-direction: column; justify-content: center; align-items: center; height: inherit;">
          <img src="../../../../../assets/upload.png"  *ngIf="!loading" style="height: inherit;" />
          <div>
            <mat-spinner  *ngIf="loading">
            </mat-spinner>
          </div>
     
          
        </div>

      </div>

    </div>


  </div>

</div>