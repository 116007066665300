<div style="display: flex; flex-direction: column; justify-content: flex-start;flex-wrap: wrap;max-height: 80%">

    <div class="message_blurb"  *ngFor="let x of templates; let i = index">
        <div style="position: absolute; top: 5px; right: 5px; cursor: pointer;" (click)="_delMessage(x.id, i)" >X</div>
        <p style="cursor: pointer;" (click)="getMessage(x.message)" class="message_blurb_p">
        {{ x.message }}
        </p>
      </div>







</div>

<button class="fab" (click)="addTemplates()" mat-fab color="primary">+</button>