import { Component, OnInit,Input, Inject } from '@angular/core';
import { HttpService } from '../http.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { UntypedFormBuilder, Validators, UntypedFormGroup} from '@angular/forms';
import { MatSnackBar} from '@angular/material/snack-bar';


export interface DialogData {
  model: string;
 }

@Component({
  selector: 'app-state',
  templateUrl: './state.component.html',
  styleUrls: ['./state.component.css']
})
export class StateComponent implements OnInit {

  @Input() state: number;
  @Input() model: number;
 
  //model: string;

  constructor(
    private _Activatedroute:ActivatedRoute,
    private http: HttpService,
    public dialog: MatDialog
  ) {
 
   }

  ngOnInit() {

    

  }


  stateApproved(elem){
    let dialogRef = this.dialog.open(StateApprovedComponent, {
      height: 'auto',
      width: '600px',
      data: {state: elem, model: this.model }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
  //    console.log('result', result);
    });
  }

  }


  
@Component({
  selector: 'choose-state',
  templateUrl: 'choosestate.html',
})
export class StateApprovedComponent {

  recup: any;
  spinnerProgress : boolean;
  chgStateGroup: UntypedFormGroup;
  preparedMess: any;

  SubjectMessage:any;
  ApprovedMessage: any;
 
  constructor(
    public dialogRef: MatDialogRef<StateApprovedComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    private http: HttpService,
    public snackbar: MatSnackBar,

 
  ) {
      this.recup = data;
      this.createForm();
    }

ngOnInit(){
  this.getCustomMess(this.recup.state, this.recup.model) ;
 // console.log('model recup', this.recup['model']);
}
    
  onNoClick(): void {
    this.dialogRef.close();
  }

  
createForm(){
  this.chgStateGroup = this.fb.group({
    email: ['', Validators.required],
    appmess: ['', Validators.required],
    subject: ['', Validators.required],
    message: ['', Validators.required],
    });
}

prepareForm(preparedSubject: any, preparedMessage: any){
  this.chgStateGroup.reset({
    email: true, 
    appmess: true,
    subject : preparedSubject,
    message:  preparedMessage
  });
}

onOkClick(){

  this.spinnerProgress = true;

  let postData = new FormData();
postData.append('email', this.chgStateGroup.get('email').value);
postData.append('appmess',  this.chgStateGroup.get('appmess').value);
postData.append('subject',  this.chgStateGroup.get('subject').value);
postData.append('message',  this.chgStateGroup.get('message').value);
postData.append('model',  this.recup['model'] );
postData.append('state',  this.recup['state'] );

  this.http.sendCustomMess(postData).subscribe(
    res => {
      if(res){
    //    console.log(res);
        this.openSnackBar('Model have been ',this.recup.state);
  
        this.spinnerProgress = false;
        this.dialogRef.close();   
      }
    }, error =>{
      this.openSnackBar('Error !','');
    }
  );
}

getCustomMess(type: string, model: string){
  this.spinnerProgress = true;
this.http.getCustomMess(type, model).subscribe ( res => {
  this.spinnerProgress = false;
  this.preparedMess = res;
  this.prepareForm(this.preparedMess.subject, this.preparedMess.mess);
 // console.log(res);
})
}

openSnackBar(message: string, action: string) {
  this.snackbar.open(message, action, {
    duration: 2000,
  });
}


}