<mat-spinner class="centred"

*ngIf="spinnerProgress">
</mat-spinner>

<form *ngIf="!spinnerProgress" [formGroup]="chgStateGroup" style="width:80%;margin-top:15px;margin-left:50%; transform: translate(-50%, 0);">

    
      You gonna send an App Message (with Push Notifications) to this model.
      
      
           <mat-form-field style="display:block;margin-top:10px;">
            <textarea  matInput placeholder="Subject" formControlName="subject"></textarea>
          </mat-form-field>
        
        
          <mat-form-field style="display:block;margin-top:10px;">
            <textarea  matInput placeholder="App Message" formControlName="message"></textarea>
          </mat-form-field>
        
          <div mat-dialog-actions >
            <button mat-button (click)="onNoClick()">Cancel</button>
            <button mat-button (click)="onOkClick()" cdkFocusInitial>Ok</button>
          </div>
          
          
        </form>