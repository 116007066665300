import { Component, OnInit } from '@angular/core';
import { HttpService } from '../http.service';

@Component({
  selector: 'app-push-manager',
  templateUrl: './push-manager.component.html',
  styleUrls: ['./push-manager.component.css']
})
export class PushManagerComponent implements OnInit {


  pushmenu: any;

  constructor(
    private http: HttpService,
  ) { }

  ngOnInit() {
    this.getPushCategory();
  }

  getPushCategory(){
    this.http.getMenu('pushmenu').subscribe(res=>{
      console.log(res);
      this.pushmenu = res;
    })
  }

  

}
