<mat-horizontal-stepper [linear]="isLinear" #stepper>
    <mat-step [stepControl]="firstFormGroup">
        <form [formGroup]="firstFormGroup" style="border: 1px solid #000; padding: 10px; margin-top: 10px">
            <ng-template matStepLabel>Choose Model</ng-template>
            <div *ngIf="!model" style="display: flex; flex-direction: column; justify-content: center;">
                <button mat-stroked-button (click)="chooseModel()">Click here to choose Model</button>
                <button mat-stroked-button style="margin-top: 20px" (click)="goForm()">Click here to fill in form
                    Manually</button>
            </div>

            <div style="display: flex; flex-direction: column; justify-content: center;" *ngIf="model">

                <mat-form-field>
                    <mat-label>Sexe</mat-label>
                    <mat-select required formControlName="sexe">
                        <mat-option value="male">Monsieur</mat-option>
                        <mat-option value="female">Madame</mat-option>
                        <mat-option value="Mlle">Mademoiselle</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field>
                    <input matInput placeholder="First Name" formControlName="firstname" required>
                </mat-form-field>

                <mat-form-field>
                    <input matInput placeholder="Last Name" formControlName="lastname" required>
                </mat-form-field>



                <button mat-stroked-button (click)="goEntreprise(stepper)">Next</button>

            </div>

        </form>
    </mat-step>


    <mat-step [stepControl]="secondFormGroup">
        <form [formGroup]="agencyFormGroup" style="border: 1px solid #000; padding: 10px; margin-top: 10px">
            <ng-template matStepLabel>Clients</ng-template>

            <div *ngIf="!agency" style="display: flex; flex-direction: column; justify-content: center;">
                <button mat-stroked-button (click)="chooseAgency()">Click here to choose Clients</button>
                <button mat-stroked-button style="margin-top: 20px" (click)="goFormAgency()">Click here to fill in form
                    Manually</button>
            </div>

            <div style="display: flex; flex-direction: column; justify-content: center;" *ngIf="agency">

                <mat-form-field>
                    <input matInput placeholder="Agency Name" formControlName="agencyname" required>
                </mat-form-field>

                <mat-form-field>
                    <input matInput placeholder="Agency Address" formControlName="agencyaddress" required>
                </mat-form-field>

               <!--
<mat-form-field>
                    <input matInput placeholder="Siret" formControlName="siret" required>
                </mat-form-field>
               --> 

                <mat-form-field>
                    <input matInput placeholder="T.V.A" formControlName="tva" required>
                </mat-form-field>


                <button mat-stroked-button (click)="goEntreprise(stepper)">Next</button>

            </div>


        </form>
    </mat-step>

    <mat-step [stepControl]="projectFormGroup">
        <form [formGroup]="projectFormGroup" style="border: 1px solid #000; padding: 10px; margin-top: 10px">
            <ng-template matStepLabel>Project</ng-template>


            <div style="display: flex; flex-direction: column; justify-content: center;">

                <mat-form-field>
                    <input matInput placeholder="Annonceur" formControlName="annonceur" required>
                </mat-form-field>

                <mat-form-field>
                    <input matInput placeholder="Responsable Executif" formControlName="respexecutif" required>
                </mat-form-field>

                <mat-form-field>
                    <input matInput placeholder="Telephone du Responsable Executif" formControlName="respexecutifphone" required>
                </mat-form-field>

                <mat-form-field>
                    <input matInput placeholder="Photographe" formControlName="photographe" required>
                </mat-form-field>

                <mat-form-field>
                    <input matInput placeholder="Telephone du Photographe" formControlName="photographephone" required>
                </mat-form-field>

                <mat-form-field>
                    <input matInput placeholder="Nature de la Prestation" formControlName="prestation" required>
                </mat-form-field>

                <mat-form-field>
                    <input matInput placeholder="Conditions d'utilisation" formControlName="termsofuse" required>
                </mat-form-field>


                <button mat-stroked-button (click)="goEntreprise(stepper)">Next</button>

            </div>


        </form>
    </mat-step>

    <mat-step [stepControl]="detailsFormGroup">
        <form [formGroup]="detailsFormGroup" style="border: 1px solid #000; padding: 10px; margin-top: 10px">
            <ng-template matStepLabel>Details</ng-template>


            <div style="display: flex; flex-direction: column; justify-content: center;">

                <mat-form-field>
                    <input matInput placeholder="Lieu de la prestation" formControlName="lieu" required>
                </mat-form-field>



                  <mat-form-field class=" example-full-half">
                    <input matInput 
                    [selectMode]="'range'" 
                    [owlDateTime]="dt4" [owlDateTimeTrigger]="dt4" 
                    placeholder="Horraire de la prestation"
                      formControlName="prestationhorraire">
                    <owl-date-time #dt4 [firstDayOfWeek]="1"></owl-date-time>
                  </mat-form-field>


                <mat-form-field>
                    <input matInput placeholder="Montant H.T de la prestation" formControlName="montantht" required>
                </mat-form-field>



                <button mat-stroked-button (click)="goEntreprise(stepper)">Next</button>

            </div>


        </form>
    </mat-step>

    <mat-step [stepControl]="secondFormGroup">
        <form [formGroup]="secondFormGroup" style="border: 1px solid #000; padding: 10px; margin-top: 10px">
            <ng-template matStepLabel>Entreprise</ng-template>

            <div style="display: flex; flex-direction: column; justify-content: center;" *ngIf="model">

                <mat-form-field>
                    <input matInput placeholder="Company" formControlName="company" required>
                </mat-form-field>

                <mat-form-field>
                    <input matInput placeholder="Address 1st Line" formControlName="address1" required>
                </mat-form-field>

                <mat-form-field>
                    <input matInput placeholder="Addresse 2nd Line" formControlName="address2" required>
                </mat-form-field>

                <mat-form-field>
                    <input matInput placeholder="SIRET" formControlName="siret" required>
                </mat-form-field>



                <button mat-stroked-button (click)="goEntreprise(stepper)">Next</button>

            </div>


        </form>
    </mat-step>
    <mat-step>
        <ng-template matStepLabel>Done</ng-template>
        <div style="display: flex; flex-direction: column; justify-content: center; align-items : center; border: 1px solid #000; padding: 10px; margin-top: 10px"
            *ngIf="!download">

            <div>
                What do you want to do with this contract ?
            </div>

            <div style="display: flex; flex-direction: row;" >
                <div class="boxItem" (click)="generateFile()">
                    <div>
                        <img src="../../../../assets/download.png" style="height: 20px" />
                    </div>
                    <div class="boxItemTitle">
                        Download </div>

                </div>

                <div class="boxItem">
                    <div>
                        <img src="../../../../assets/mail.png" style="height: 20px" />
                    </div>
                    <div class="boxItemTitle">
                        Send (not available)
                    </div>
                </div>
            </div>


        



        </div>

        <div *ngIf="download">
            Download...
        </div>

    </mat-step>
</mat-horizontal-stepper>