<div class="seemo">

    <div class="sidebarModel">
       <div class="rowSpeedAction">

        </div>


        <div class="modelAvatar">
            <img src="http://bmyscouter.co/db/mobile/images/user162/user162avatar1532123598.jpg" style="position:relative;top:10px;border-radius:50%;left:50%;transform:translate(-50%,0)"
                width="99px" height="99px" />
            <div class="userState"></div>
            <div class="name">Romain B</div>
            <div class="area">Available in : Paris, Milan, Dunkerque, Roubaix and so on</div>
            <div style="text-align:center;margin-top:10px;" class="contact">
                <i style="font-size:12px !important" class="material-icons">settings_phone</i> Phone : 07 82 44 06 10</div>
            <div style="text-align:center;" class="contact">
                <i style="font-size:12px !important" class="material-icons">mail</i> Mail : test@test.fr</div>
            <div style="text-align:center;" class="origin">Origin : Caucasian</div>
            <div style="text-align:center;" class="origin">Represented by IMG</div>
        </div>

        <div style="position:relative;height:auto;width:100%;border-top : 1px solid #d7d7d7;border-bottom: 1px solid #d7d7d7">
            <div style="align-self: center">
                <span class="gender">MALE</span>
                <span class="age">22 ans</span>
                <span class="level">New Face</span>
            </div>
        </div>

        <div class="modelMeasure">
            <div class="measureCtn">
              <div class="value">Height : blab cm</div>
              <div class="value">Chest : blab cm</div>
              <div class="value">Waist : blab cm</div>
              <div class="value">Hips : blab cm</div>
              <div class="value">Shoes Size : blab cm</div>
              <div class="value">Hair Color : blab cm</div>
              <div class="value">Eyes Color : blab cm</div>
            </div>
          <!--  <mat-slide-toggle> Slide me!  </mat-slide-toggle>-->
        </div>


    </div>

    <div class="fullModel">
 <!--       <mat-tab-group>
            <mat-tab label="First"> Content 1 </mat-tab>
            <mat-tab label="Second"> Content 2 </mat-tab>
            <mat-tab label="Third"> Content 3 </mat-tab>
        </mat-tab-group>-->
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque luctus magna quam, non ullamcorper ipsum accumsan eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent at sapien vel risus posuere gravida. Aenean luctus sodales aliquam. Nunc eros mauris, interdum vitae orci sed, viverra congue lorem. Sed at interdum odio. Curabitur leo erat, elementum ac pharetra in, consectetur vitae ligula. Aenean risus metus, convallis vitae gravida eget, porta eu enim. In eu facilisis sapien, ac semper ligula. Nunc eget placerat purus. Fusce nunc justo, vulputate id nibh et, posuere interdum enim. Fusce rutrum, dui sit amet lobortis lobortis, magna eros maximus ante, et vestibulum neque velit in eros. Sed fringilla lobortis venenatis.

Praesent facilisis, turpis et lacinia commodo, velit sapien lacinia nulla, non vestibulum felis felis eu tellus. Cras nibh augue, fringilla eu quam ut, convallis hendrerit urna. Praesent rhoncus diam in ligula maximus imperdiet. Fusce nunc urna, rutrum sed lacus ac, semper imperdiet velit. In in dolor justo. Vestibulum pretium diam dui. Suspendisse eu neque vitae erat rutrum facilisis in eu dolor. Etiam nisl mauris, consequat ac dui quis, finibus ullamcorper tortor. Maecenas leo erat, mollis quis gravida sed, bibendum in turpis. Praesent nisi lectus, feugiat quis elementum dapibus, luctus vitae massa. Suspendisse et arcu aliquet, tincidunt velit vitae, euismod orci. Pellentesque porttitor sapien vitae dolor laoreet accumsan. Suspendisse quis leo orci. Maecenas eget metus quis arcu scelerisque luctus et quis dui. Nam in venenatis augue.

Maecenas varius at lorem ac eleifend. Suspendisse potenti. Duis ultrices sem a bibendum euismod. Sed eget justo mauris. Etiam sollicitudin, eros eget feugiat egestas, enim est tincidunt sem, non pharetra odio est sed lacus. Curabitur et purus consectetur, iaculis ipsum at, venenatis massa. Proin pulvinar volutpat ligula, at mollis neque finibus at. Quisque vitae arcu fermentum mi viverra tempus id ut risus.

Fusce fringilla felis arcu, aliquet sagittis eros placerat ac. Phasellus cursus porta turpis, quis vestibulum quam egestas eget. Mauris commodo nisi velit, ut malesuada neque vehicula id. Vivamus sollicitudin malesuada magna eu maximus. Nunc vel sapien facilisis, ultrices dui aliquam, fringilla mauris. Fusce sollicitudin dapibus magna, at viverra orci tincidunt sed. Maecenas ornare fermentum lectus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nam suscipit erat lobortis turpis aliquam, ac ullamcorper leo rutrum. Nulla placerat orci et scelerisque iaculis. Sed egestas facilisis hendrerit. Ut finibus blandit ligula ut tristique.

Donec urna nunc, tempor eget sem at, fringilla rutrum orci. Vestibulum tincidunt tincidunt velit, et venenatis turpis euismod a. Nunc commodo, quam vitae eleifend ornare, dolor nibh convallis lorem, vitae aliquet risus ex id purus. Aliquam erat volutpat. Integer fringilla aliquam nisl, nec posuere arcu egestas sit amet. Duis eu nulla at diam eleifend vestibulum eu eget turpis. Praesent eget mauris sed erat viverra tempor vitae sed mi. Ut pharetra nulla nec pharetra pulvinar. Pellentesque sed lectus id metus ornare vulputate. Aenean non nulla posuere, dapibus mauris sed, fringilla leo. Vivamus sit amet bibendum nisl. Pellentesque faucibus arcu ut eros commodo, non lacinia lectus rutrum. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Cras consequat ullamcorper facilisis.

Nam molestie, nisl a rutrum volutpat, tortor enim scelerisque lorem, ac tempus sapien nisl et sem. Suspendisse lacinia sagittis lacus, ut vehicula sapien aliquam ac. Vestibulum nec scelerisque urna, in placerat ligula. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Duis semper mi a nibh viverra malesuada. Phasellus eu imperdiet eros. Sed vel cursus arcu. Sed risus diam, venenatis ut auctor quis, pulvinar et lorem. Vestibulum id bibendum velit. Vestibulum non finibus augue, vel tempus purus. Vestibulum et semper ligula. Duis sit amet malesuada quam, eget scelerisque lacus. Donec sit amet iaculis ex. Nulla maximus ex orci.

Aenean rutrum tortor a quam convallis tempus a nec arcu. Morbi vitae lacus leo. Donec et orci sapien. Vivamus at dui lectus. Integer bibendum nunc ante, non sagittis nisl ullamcorper id. Aliquam malesuada malesuada velit nec rhoncus. Suspendisse nec sapien in erat tincidunt iaculis. Vivamus non lorem vitae nisl sodales vulputate. Duis eget orci metus. Quisque euismod non nulla a pellentesque. Etiam luctus quam velit, eu ultricies ex laoreet eget. Proin enim neque, fermentum vel est ut, feugiat rutrum nisl. Maecenas magna neque, venenatis vitae ornare sit amet, facilisis aliquam risus. Aenean elementum magna ligula, eu posuere orci vulputate et. Etiam sed faucibus dolor.

Proin malesuada ipsum ut nunc blandit molestie. Aliquam commodo, tortor in pharetra dapibus, sapien mauris ornare nunc, eu rutrum lacus metus in ex. Duis et massa eget felis convallis tempus. Nam vestibulum metus nec nunc tempor, vel pellentesque ipsum vestibulum. Nulla consequat tristique est, at fermentum tellus mollis semper. Nunc ac quam diam. Nam mollis ex et sapien vulputate consequat. Pellentesque sagittis, massa ac dignissim sodales, augue ante laoreet leo, id scelerisque est purus tempor tortor. Nunc imperdiet id lorem eu ultrices. Etiam malesuada orci urna, dapibus scelerisque felis pulvinar a. Donec porta leo eget efficitur mattis. Suspendisse mattis sed neque vitae maximus. Morbi sagittis elit eget urna interdum, ornare scelerisque lorem tempus. Aliquam varius pellentesque tincidunt. Praesent et convallis metus, sit amet placerat dui. Sed fermentum mi in sem aliquam malesuada.

Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque luctus magna quam, non ullamcorper ipsum accumsan eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent at sapien vel risus posuere gravida. Aenean luctus sodales aliquam. Nunc eros mauris, interdum vitae orci sed, viverra congue lorem. Sed at interdum odio. Curabitur leo erat, elementum ac pharetra in, consectetur vitae ligula. Aenean risus metus, convallis vitae gravida eget, porta eu enim. In eu facilisis sapien, ac semper ligula. Nunc eget placerat purus. Fusce nunc justo, vulputate id nibh et, posuere interdum enim. Fusce rutrum, dui sit amet lobortis lobortis, magna eros maximus ante, et vestibulum neque velit in eros. Sed fringilla lobortis venenatis.

Praesent facilisis, turpis et lacinia commodo, velit sapien lacinia nulla, non vestibulum felis felis eu tellus. Cras nibh augue, fringilla eu quam ut, convallis hendrerit urna. Praesent rhoncus diam in ligula maximus imperdiet. Fusce nunc urna, rutrum sed lacus ac, semper imperdiet velit. In in dolor justo. Vestibulum pretium diam dui. Suspendisse eu neque vitae erat rutrum facilisis in eu dolor. Etiam nisl mauris, consequat ac dui quis, finibus ullamcorper tortor. Maecenas leo erat, mollis quis gravida sed, bibendum in turpis. Praesent nisi lectus, feugiat quis elementum dapibus, luctus vitae massa. Suspendisse et arcu aliquet, tincidunt velit vitae, euismod orci. Pellentesque porttitor sapien vitae dolor laoreet accumsan. Suspendisse quis leo orci. Maecenas eget metus quis arcu scelerisque luctus et quis dui. Nam in venenatis augue.

Maecenas varius at lorem ac eleifend. Suspendisse potenti. Duis ultrices sem a bibendum euismod. Sed eget justo mauris. Etiam sollicitudin, eros eget feugiat egestas, enim est tincidunt sem, non pharetra odio est sed lacus. Curabitur et purus consectetur, iaculis ipsum at, venenatis massa. Proin pulvinar volutpat ligula, at mollis neque finibus at. Quisque vitae arcu fermentum mi viverra tempus id ut risus.

Fusce fringilla felis arcu, aliquet sagittis eros placerat ac. Phasellus cursus porta turpis, quis vestibulum quam egestas eget. Mauris commodo nisi velit, ut malesuada neque vehicula id. Vivamus sollicitudin malesuada magna eu maximus. Nunc vel sapien facilisis, ultrices dui aliquam, fringilla mauris. Fusce sollicitudin dapibus magna, at viverra orci tincidunt sed. Maecenas ornare fermentum lectus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nam suscipit erat lobortis turpis aliquam, ac ullamcorper leo rutrum. Nulla placerat orci et scelerisque iaculis. Sed egestas facilisis hendrerit. Ut finibus blandit ligula ut tristique.

Donec urna nunc, tempor eget sem at, fringilla rutrum orci. Vestibulum tincidunt tincidunt velit, et venenatis turpis euismod a. Nunc commodo, quam vitae eleifend ornare, dolor nibh convallis lorem, vitae aliquet risus ex id purus. Aliquam erat volutpat. Integer fringilla aliquam nisl, nec posuere arcu egestas sit amet. Duis eu nulla at diam eleifend vestibulum eu eget turpis. Praesent eget mauris sed erat viverra tempor vitae sed mi. Ut pharetra nulla nec pharetra pulvinar. Pellentesque sed lectus id metus ornare vulputate. Aenean non nulla posuere, dapibus mauris sed, fringilla leo. Vivamus sit amet bibendum nisl. Pellentesque faucibus arcu ut eros commodo, non lacinia lectus rutrum. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Cras consequat ullamcorper facilisis.

Nam molestie, nisl a rutrum volutpat, tortor enim scelerisque lorem, ac tempus sapien nisl et sem. Suspendisse lacinia sagittis lacus, ut vehicula sapien aliquam ac. Vestibulum nec scelerisque urna, in placerat ligula. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Duis semper mi a nibh viverra malesuada. Phasellus eu imperdiet eros. Sed vel cursus arcu. Sed risus diam, venenatis ut auctor quis, pulvinar et lorem. Vestibulum id bibendum velit. Vestibulum non finibus augue, vel tempus purus. Vestibulum et semper ligula. Duis sit amet malesuada quam, eget scelerisque lacus. Donec sit amet iaculis ex. Nulla maximus ex orci.

Aenean rutrum tortor a quam convallis tempus a nec arcu. Morbi vitae lacus leo. Donec et orci sapien. Vivamus at dui lectus. Integer bibendum nunc ante, non sagittis nisl ullamcorper id. Aliquam malesuada malesuada velit nec rhoncus. Suspendisse nec sapien in erat tincidunt iaculis. Vivamus non lorem vitae nisl sodales vulputate. Duis eget orci metus. Quisque euismod non nulla a pellentesque. Etiam luctus quam velit, eu ultricies ex laoreet eget. Proin enim neque, fermentum vel est ut, feugiat rutrum nisl. Maecenas magna neque, venenatis vitae ornare sit amet, facilisis aliquam risus. Aenean elementum magna ligula, eu posuere orci vulputate et. Etiam sed faucibus dolor.

Proin malesuada ipsum ut nunc blandit molestie. Aliquam commodo, tortor in pharetra dapibus, sapien mauris ornare nunc, eu rutrum lacus metus in ex. Duis et massa eget felis convallis tempus. Nam vestibulum metus nec nunc tempor, vel pellentesque ipsum vestibulum. Nulla consequat tristique est, at fermentum tellus mollis semper. Nunc ac quam diam. Nam mollis ex et sapien vulputate consequat. Pellentesque sagittis, massa ac dignissim sodales, augue ante laoreet leo, id scelerisque est purus tempor tortor. Nunc imperdiet id lorem eu ultrices. Etiam malesuada orci urna, dapibus scelerisque felis pulvinar a. Donec porta leo eget efficitur mattis. Suspendisse mattis sed neque vitae maximus. Morbi sagittis elit eget urna interdum, ornare scelerisque lorem tempus. Aliquam varius pellentesque tincidunt. Praesent et convallis metus, sit amet placerat dui. Sed fermentum mi in sem aliquam malesuada.
    </div>

   <div class="fastAction">

        <div class="squareBtnCtn">
            <div class="squareBtn">
                test1
            </div>
            <div class="squareBtn">
                test2
            </div>
            <div class="squareBtn">
                test3
            </div>
            <div class="squareBtn">
                test4
            </div>
        </div>
    </div>
</div>