
   <mat-spinner *ngIf="loader"></mat-spinner>
<div *ngIf="!loader">

  <div class="modalCloseBtn" (click)="onOkClick()">
    <div class="centred" style="color:white">X</div>
  </div>
  

  <div  class="paddingOnMobile">

    <div style="display: flex; flex-direction: column; justify-content: center; align-items: center; max-width: 100%;">
    <div>
      <img [src]="details?.agencylogo" style="height: 80px; border-radius: 50%; cursor: pointer;" (click)="goAgency(details?.agencyhash)" />
    </div>
    </div>

    <div style="display: flex; flex-direction: column; justify-content: flex-start; max-width: 100%;">
      <div><span class="gras">Agency/Client :</span> {{ details?.agencyname }}</div>
      <div><span class="gras">Start :</span> {{ start }}</div>
      <div ><span class="gras">End :</span> {{ end }}</div>
      <div ><span class="gras">Person to ask :</span> {{ details?.persontoask }}</div>
      <div ><span class="gras">Contact :</span> {{ details?.personcontact }}</div>
      <div ><span class="gras">Address :</span> {{ details?.eventaddress }}</div>
      <div><span class="gras">More Infos :</span> {{ details?.moredetails }}</div>
    </div>
  

  
    <div *ngIf="details" >
      <span class="gras">Participant :</span> 
      <div *ngFor="let x of details.guest">
        <div style="display: flex; flex-direction: row; justify-content: flex-start; align-items: center;">
          <div style="margin-left: 5px;">
            {{ x.participant }} 
          </div>
          <div  style="margin-left: 5px;">
            ( 
              <i *ngIf="x.state == 'notopen' " style="font-size:18px !important; color: grey;" class="material-icons customIcon">info</i> 
              <i *ngIf="x.state == 'accepted' " style="font-size:18px !important; color: #54e50d" class="material-icons customIcon">check_circle</i> 
              <i *ngIf="x.state == 'refused' " style="font-size:18px !important; color: red" class="material-icons customIcon">highlight_off</i> 
              <i *ngIf="x.state == 'open' " style="font-size:18px !important; color: orange" class="material-icons customIcon">info</i> 
            
            
           )
          </div>
          <div  style="margin-left: 5px;">
            <img (click)="goModel(x.modalhash)" src="../../../assets/eye.png" style = "height: 25px; cursor: pointer;" />
          </div>
        
        </div>

      </div>
    </div>
  
    <div style="margin-top: 20px;">
   
     <div *ngIf="details.files">
      <div>
        Files attached to this event : 
      </div>
      <table  style="max-width: 400px; margin-left: auto; margin-right: auto; margin-top: 10px">
        <tr>
          <td>File</td>
          <td>Public</td>
          <td>Action</td>
        </tr>
        <tr  *ngFor="let y of details.files">
          <td>{{y.file_name}}</td>
  
          <td>
            <mat-slide-toggle  [checked]="y.public == '1' " (change)="toggleFileVisibility(y.id)" ></mat-slide-toggle>
          </td>
      
          <td>
            <div style="display: flex; flex-direction: row; align-items: center;">
            <div style="margin-right: 15px;">
            <img src="../../../assets/eye.png" (click)="goLink(y.file_url)" style="height: 30px; cursor: pointer;" />
            </div>
            <div>
              <img src="../../../assets/trash.png" (click)="deleteFile(y.id)" style="height: 20px; cursor: pointer;" />
            </div>
            </div>
          </td>
        </tr>
      </table>
    
     </div>
  
    </div>
  
  </div>
  
  <div style="margin-top: 10px" >
      <button class="BtnLongOnMobile" mat-stroked-button mat-strokked (click)="changeData()">Change Data</button>
    <button class="BtnLongOnMobile" mat-stroked-button mat-strokked (click)="cancelEvent()">Cancel This booking</button>
    <button class="BtnLongOnMobile" mat-stroked-button mat-strokked (click)="joinDocs()">Join Docs</button>
    <button  class="BtnLongOnMobile" mat-stroked-button mat-strokked (click)="onOkClick()" cdkFocusInitial>Ok</button>
  </div>
  

  
</div>
