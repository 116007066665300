import { Component, OnInit, Inject, Input } from '@angular/core';
import {  Router, ActivatedRoute } from "@angular/router";
import { HttpService } from '../http.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatSnackBar} from '@angular/material/snack-bar';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { UntypedFormBuilder, Validators, UntypedFormGroup} from '@angular/forms';

import { CreateAgency } from '../agency-card/agency-card.component';
import { PushlogComponent } from '../push-manager/push/pushlog/pushlog.component';

import {
  CriteriaService
} from '../criteria.service';

export interface DialogData {
  model: string;
}


@Component({
  selector: 'app-fastaction-agency',
  templateUrl: './fastaction-agency.component.html',
  styleUrls: ['./fastaction-agency.component.css']
})
export class FastactionAgencyComponent implements OnInit {

  hash: string;
  @Input() agency: string;

  constructor(
    private _Activatedroute:ActivatedRoute,
    public dialog: MatDialog,
    public router: Router,
    public snackBar: MatSnackBar,
    private criteriaservice: CriteriaService,
  ) { }

  ngOnInit() {
    /*this._Activatedroute.params.subscribe( params => {
      console.log('params',params.id)  ;
      this.hash = params.id;
    });*/
    console.log('params',this.agency)  ;
    this.hash = this.agency;
  }

  Delete(){
    const dialogRef = this.dialog.open(DeleteAgency, {
      data: {agency: this.hash}
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if(result == 'delete'){
        this.openSnackBar('Agency have been removed', '');
        this.criteriaservice.updateAgencyForm(result);
      }
    
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  SendEmail(){
    const dialogRef = this.dialog.open(SendEmailToAgency, {
      data: {agency: this.hash}
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      switch(result){
        case 'success':
        this.openSnackBar('Mail have been sent', '');
        break;

        case 'echec':
        this.openSnackBar('An error has occured', '');
        break;

        default:
        break;
      }

  })
}

editAgency(){
  const dialogRef = this.dialog.open(CreateAgency, {
    data: {agency: this.hash},
    panelClass: 'nopadding-box'
  });

  dialogRef.afterClosed().subscribe(result => {
    console.log('The dialog was closed', result);
 if(result == 'updated'){
  this.criteriaservice.updateAgencyForm(result);
 }
  });
}

showLog(){
  const dialogRef = this.dialog.open(PushlogComponent, {
    data: {agency: this.hash}
  });

  dialogRef.afterClosed().subscribe(result => {
    console.log('The dialog was closed');
    if(result == 'success'){
      this.openSnackBar('Agency have been updated', '');
     // this.router.navigate(['members/agency']);
    }
  });
}


}


@Component({
  selector: 'DeleteAgency',
  templateUrl: 'delete-agency.html',
})

export class DeleteAgency {

  constructor(
    public dialogRef: MatDialogRef<DeleteAgency>,
    private get: HttpService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  )
   {
  

  }

  ngOnInit(): void {
   console.log(this.data['agency']);
  }

  onNoClick(): void {
    this.dialogRef.close('echec');
  }

  onOkClick() : void {
    console.log('delete', this.data['agency']);
 this.deleteAgency();
    
  }

  deleteAgency(){

    let postData = {
      'table' : 'agency', 'action': 'delete', criteria : [ { 'label': 'agency_hash', 'value' : this.data['agency'] }]
    }

    this.get.goBackOffice(postData).subscribe(res=>{
      if(res == 'delete'){
        this.dialogRef.close(res);
      }
    })
  }


}


@Component({
  selector: 'SendEmail',
  templateUrl: 'sendemail.html',
})

export class SendEmailToAgency {

  chgStateGroup: UntypedFormGroup;
  spinnerProgress: boolean;
  destinataire : any;
  contact: any;
  htmlContent: any;
 

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '300px',
    minHeight: '300px',
    placeholder: 'Enter text here...',
    translate: 'no',
    uploadUrl: 'v1/images', // if needed
    customClasses: [ // optional
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };

  constructor(
    public dialogRef: MatDialogRef<SendEmailToAgency>,
    private get: HttpService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: UntypedFormBuilder,
  )
   {
  this.createForm();

  }

  ngOnInit(): void {
   console.log(this.data['agency']);
   /*this.get.getContactAgency(this.data['agency']).subscribe( res => {
     console.log('contact', res);
     this.contact = res;
   })*/
  }

  onNoClick(): void {
    this.dialogRef.close('nothing');
  }

  onOkClick() : void {

    let postData = new FormData();
  postData.append('to',  this.chgStateGroup.get('to').value);
  postData.append('htmlContent',  this.chgStateGroup.get('htmlContent').value);
  postData.append('subject',  this.chgStateGroup.get('subject').value);
  
    this.get.sendEmail(postData).subscribe(
      res => {
        if(res){
          console.log(res);    
          this.spinnerProgress = false;
          this.dialogRef.close('success');   
        }
      }, error =>{
        console.log('error', error);
     this.spinnerProgress = false;
     this.dialogRef.close('echec');  
      }
    );
    
  }

  createForm(){
    this.chgStateGroup = this.fb.group({
      to: ['', Validators.required],
      htmlContent: ['', Validators.required],
      subject: ['', Validators.required],
      });
  }



}
