<div class="seemo paddingTopOnMobile" *ngIf="modal">



  <div class="sidebarModel ">

    <div class="modalCloseBtn" (click)="close()">
      <div class="centred" style="color:white">X</div>
    </div>
    
    <div class="rowSpeedAction">
<app-state [state]="modal?.user_profile" [model]="data['modal']"></app-state>
    </div>



    <div class="modelAvatar">
      <img [src]="modal?.user_avatar" style="position:relative;top:
      10px;border-radius:50%;left:50%;transform:translate(-50%,0); cursor: pointer;"
        width="80px" height="80px"  (click)="mgmtAlbum()" />
      <img src="../../../assets/ig_star.png" style="position: absolute; top: 10px; left: calc(50% + 20px); height: 20px;" *ngIf="state == 'signed' "/>
        <div *ngIf="!modal.user_avatar">
          <img *ngIf="modal?.user_gender == 'female' "  src="assets/avatar_female.png" style="position:relative;top:10px;border-radius:50%;left:50%;transform:translate(-50%,0)"
          width="80px" height="80px" />
          <img  *ngIf="modal?.user_gender == 'male' " src="assets/avatar_male.png" style="position:relative;top:10px;border-radius:50%;left:50%;transform:translate(-50%,0)"
          width="80px" height="80px" />
        </div>

      
      <div class="name"> 
        <span [ngStyle]="setMyStyles(modal.user_first)">{{modal.user_first}}</span> 
        <span [ngStyle]="setMyStyles(modal.user_last)" style="margin-left: 5px;">{{modal.user_last}}</span></div>
     

        <div style="text-align:center;margin-top:5px;" [ngStyle]="setMyStyles(modal.user_address)">
          <i style="font-size:12px !important" class="material-icons">home</i> 
          Address : {{modal.user_address}}
        </div>
      
      <div (click)="sendFastLinkBySMS(modal.user_phone)" style="text-align:center;margin-top:5px;cursor: pointer;" [ngStyle]="setMyStyles(modal.user_phone)" >
        <i style="font-size:12px !important" class="material-icons">settings_phone</i> 
        Phone : {{modal.user_phone}}
      </div>

      <div  style="text-align:center;" [ngStyle]="setMyStyles(modal.user_email)">
        <div style="display: flex;flex-direction: row; justify-content: center; flex-wrap: wrap; align-items: center;">

          <div>
            Mail :
            <span (click)="copyMessage(modal.user_email)" style="cursor: pointer; text-decoration: underline; color: blue"> {{modal.user_email}}</span>
          </div>
          <div style="margin-left: 10px;">
            <i style="font-size:16px !important; cursor: pointer;" class="material-icons"  (click)="goMail(modal.user_email)">mail</i> 
          </div>

        </div>
     
        
      </div>

      <div style="text-align:center;" class="origin" [ngStyle]="setMyStyles(modal.user_morphotype)">
        Origin : {{modal.user_morphotype}}
      </div>

      <div style="text-align:center;" class="origin"  [ngStyle]="setMyStyles(modal.user_nationality)"  *ngIf="role > 1">
        Nationality : {{modal.user_nationality}}
      </div>

      <div style="text-align:center;" class="origin"  [ngStyle]="setMyStyles(modal.user_igaccount)"  *ngIf="role > 1">
        @Instagram : {{modal.user_igaccount}}
      </div>


    <div class="area" [ngStyle]="setMyStyles(location)">
      Available in : {{ location }}
    </div>
   <!--   <div style="text-align:center;" class="origin" *ngFor="let x of agency">Represented by {{ x.name }} <div *ngIf="x.city !== 'undefined' ">in {{ x.city }}</div></div> -->
    </div>

    <div style="position:relative;height:auto;width:100%;border-top : 1px solid #d7d7d7;border-bottom: 1px solid #d7d7d7">
      <div style="align-self: center">
        <span class="gender">{{modal.user_gender}}</span>
        <span class="age" style="cursor:pointer" [matTooltip]=" modal.user_birthday">{{ getAge( modal.user_birthday) }}</span>
        <span *ngIf="modal.user_experience" class="level">{{modal.user_experience}}</span>
      </div>
    </div>

    <div class="modelMeasure">
      <div class="measureCtn">
        <div [ngStyle]="setMyStyles(modal.user_height)" class="value">Height : {{ checkUnity(modal.user_height) }} {{extension}}</div>
        <div  [ngStyle]="setMyStyles(modal.user_chest)" class="value">Chest : {{ checkUnity(modal.user_chest)}} {{extension}}</div>
        <div  [ngStyle]="setMyStyles(modal.user_waist)" class="value">Waist : {{ checkUnity(modal.user_waist) }} {{extension}}</div>
        <div [ngStyle]="setMyStyles(modal.user_hips)" class="value">Hips : {{ checkUnity(modal.user_hips) }} {{extension}}</div>
        <div [ngStyle]="setMyStyles(modal.user_shoe)"  class="value">Shoes Size : {{ checkUnity(modal.user_shoe) }} {{extension}}</div>
        <div  [ngStyle]="setMyStyles(modal.user_haircolor)" class="value">Hair Color : {{modal.user_haircolor}}</div>
        <div  [ngStyle]="setMyStyles(modal.user_eyecolor)" class="value">Eyes Color : {{modal.user_eyecolor}} </div>
      </div>
  
    </div>

    <app-modelcategory  [hash]="data['modal']" [type]="'modal'"></app-modelcategory>


  </div>

  <div class="fullModel">

        <app-pictures [hash]="data['modal']" [avatar]="modal.user_avatar"></app-pictures>

</div>

<!--
<div class="specificties">
  <app-specificty [hash]="data['modal']"></app-specificty>
</div>
-->


  <div class="fastAction">

<app-fastaction [hash]="data['modal']"></app-fastaction>

</div>
