<div *ngIf="!rappelBool" style="display: flex; flex-direction: column; justify-items: center; align-content: center; width: 100%; height: 100%; margin-top: 20px;">
  <div style="display: flex; flex-direction: row; justify-content: center;">
    <img  style="width: 320px;" src="../../../assets/ok.png" />
  </div>
  <div style="text-align: center; margin-top: 20px; font-size: 32px; font-family: 'Roboto', sans-serif;">
    You are up to date
  </div>
 
</div>


<div *ngIf="rappelBool">

  
<div style="display: flex; flex-direction: row; justify-content: center; margin-top: 20px;">
  <div style="background-color: black; color: white;padding-right: 5px; padding-left: 5px; text-transform: uppercase; font-weight: 800;">Facture To Do</div>
  </div>
  
  
  <table  mat-table [dataSource]="rappel" class="mat-elevation-z1"
  style="width: 90%; margin-left: auto; margin-right: auto; margin-top: 25px;"
  
  >
  
  <ng-container matColumnDef="Client">
    <th mat-header-cell *matHeaderCellDef>Client</th>
    <td mat-cell *matCellDef="let x">
      
        <div *ngIf="!x.client_avatar">{{ x.client_name }} </div>
      <div  style="display: flex; flex-direction: row; justify-content: flex-start; " *ngIf="x.client_avatar">
        <div class="avatar2" [style.backgroundImage]=" 'url('+ x.client_avatar +')'  "></div>
      </div>
    </td>
  </ng-container>
  
  <!-- Name Column -->
  <ng-container matColumnDef="Job Date">
    <th mat-header-cell *matHeaderCellDef>Job Date</th>
    <td mat-cell *matCellDef="let x">
      <div> {{ getDate(x.event_from) }}</div>
  
    </td>
  </ng-container>
  
  <!-- Name Column -->
  <ng-container matColumnDef="Amount">
    <th mat-header-cell *matHeaderCellDef>Amount</th>
    <td mat-cell *matCellDef="let x"> {{ x.montant }}</td>
  </ng-container>
  
  <ng-container matColumnDef="Actions">
    <th mat-header-cell *matHeaderCellDef>Actions</th>
    <td mat-cell *matCellDef="let x; let i = index">
        <div style="display: flex; flex-direction: row; justify-content: flex-start;">
          <button mat-stroked-button (click)="doNotShow(x.event_hash, i)">Do not show again</button>
      <button mat-stroked-button (click)="generateFac(x.event_hash)" style="margin-left: 10px">Generate Invoice</button>
      </div>
  
    </td>
  </ng-container>
  
  
  <tr mat-header-row *matHeaderRowDef="displayedColumns ; sticky : true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  
  

</div>
