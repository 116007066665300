<mat-spinner class="centred"

*ngIf="spinnerProgress">
</mat-spinner>

<form  *ngIf="!spinnerProgress" [formGroup]="chgStateGroup" style="width:90%;margin-top:15px;margin-left:50%; transform: translate(-50%, 0);">

    
      You gonna send an E-Mail.
      
      <mat-form-field style="display:block;margin-top:10px;">
        <mat-select  placeholder="destinataire" multiple formControlName="to">
            <mat-option *ngFor="let x of contact" [value]="x.contactid">
               {{ x.firstname }} <{{x.mail}}>
            </mat-option>
       </mat-select>
       </mat-form-field>

           <mat-form-field style="display:block;margin-top:10px;">
            <textarea matInput placeholder="Subject" formControlName="subject"></textarea>
          </mat-form-field>
        
        

          <angular-editor style="margin-top:10px" formControlName="htmlContent" [config]="editorConfig" [(ngModel)]="htmlContent"></angular-editor>
        
          <div mat-dialog-actions >
            <button mat-button (click)="onNoClick()">Cancel</button>
            <button mat-button (click)="onOkClick()" cdkFocusInitial>Ok</button>
          </div>
          
          
</form>