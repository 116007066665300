import { Component, OnInit, ViewChild, Input } from '@angular/core';

import { ConversComponent } from '../convers/convers.component'


@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css']
})
export class MessageComponent implements OnInit {

  
  @Input() hash: string;
  @ViewChild('convers', { static: true }) child:ConversComponent;
  constructor(
  
  ) { }

  ngOnInit() {
    console.log('chat', this.hash);
    //this.child.getAllConvers('0266e33d3f546cb5436a10798e657d97', '0');
  }

  goBox(uid: string, type: string){
    //this.child.getAllConvers('0266e33d3f546cb5436a10798e657d97', type);
  }



}
