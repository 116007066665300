import { Component, OnInit, Input } from '@angular/core';
import { HttpService } from '../../http.service';

import { ChartType, ChartOptions } from 'chart.js';

@Component({
  selector: 'app-modelstats',
  templateUrl: './modelstats.component.html',
  styleUrls: ['./modelstats.component.scss']
})
export class ModelstatsComponent implements OnInit {

  @Input() hash: string;
  public pieChartOptions: ChartOptions = {
    responsive: true,
  };
  pieChartData: any;
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [];
  public pieChartLabels = [];

  total : any;

  constructor(
    private get: HttpService,
  ) { }

  ngOnInit() {
    this.getModelStats();
  }

  getModelStats(){
    console.log('stats', this.hash);
    this.get.getModelStats(this.hash).subscribe(res=> {
      console.log('stats', res);
      this.pieChartLabels = ['Approved', 'Not Approved', 'Not Finished',  'Waiting'];
    })
  }

}
