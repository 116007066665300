import { Component, OnInit } from '@angular/core';
import { HttpService } from '../http.service';

import { ActivatedRoute, Params  } from '@angular/router'; 

@Component({
  selector: 'app-agency',
  templateUrl: './agency.component.html',
  styleUrls: ['./agency.component.css']
})
export class AgencyComponent implements OnInit {

  agency: any;
  croppedImageNews: any;
  agencytopush : any;
  country: any;
  type: any;

  classic: boolean;

  constructor(
    private get: HttpService,
    private route: ActivatedRoute
  ) {
    this.classic = true;
   }

  ngOnInit() {
    console.log('param');
    this.type = this.route.snapshot.paramMap.get('id');
    this.refresh();

  }


  refresh(){
    if(this.classic){
      this.selectCountry(this.type);
    } else {
      this.selectClientCategory('agency');
    }
  }

  selectCountry(location){
    this.get.selectCountry(location).subscribe( res => {
       console.log('select country', res);
     this.country = res;
  
    })
  }

  selectClientCategory(type){
    this.get.selectClientCategory(type).subscribe( res => {
       console.log('select country', res);
     this.country = res;
    })
  }

  switchDisplay(elem){
    switch(elem){
      case 'category':
      this.classic = false;

      break;
  
      case 'classic':
        this.classic = true;
      
        break;
    }
    this.refresh();
  }

  toggleMenu(){
    console.log('toggle menu');
    document.getElementById('choice').classList.toggle('show');
  }

  switchCategory(e){
    console.log('togllge', e);
    if(e.checked == true){
      this.switchDisplay('category');
    } else {
      this.switchDisplay('classic');
    }
  }


}






