<input type="file" (change)="fileChangeEvent($event)" />

<image-cropper
    [imageChangedEvent]="imageChangedEvent"
    [maintainAspectRatio]="true"
    [aspectRatio]="4/3"
    [resizeToWidth]="800"
    format="jpeg"
    (imageCroppedBase64)="imageCropped($event)"
    (imageLoaded)="imageLoaded()"
    (loadImageFailed)="loadImageFailed()"
></image-cropper>


<div mat-dialog-actions>
        <button mat-button (click)="onNoClick()">Cancel</button>
        <button mat-button (click)=" onOkClick()"  cdkFocusInitial >Save</button>
      </div>
      