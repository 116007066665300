import { Component, OnInit } from '@angular/core';
import {  MatDialog,MatDialogRef } from '@angular/material/dialog';

import { AddtemplatesComponent } from './addtemplates/addtemplates.component';
import { HttpService } from '../../http.service';


@Component({
  selector: 'app-messtemplate',
  templateUrl: './messtemplate.component.html',
  styleUrls: ['./messtemplate.component.scss']
})
export class MesstemplateComponent implements OnInit {

  /* templates = [
     {'message': 'blablabla', 'id' : 1},
     {'message': 'azeaze',  'id' : 2},
     {'message': 'dsqdsq', 'id' : 3},
     {'message': 'hgfhgfhgf', 'id' : 4},
 
   ];*/
  templates: any;


  constructor(
    public dialogRef: MatDialogRef<MesstemplateComponent>,
    public dialog: MatDialog,
    private http: HttpService
  ) { } 0

  ngOnInit() {
    this._getTemplates();
  }


  onNoClick(): void {
    this.dialogRef.close('close');
  }

  _getTemplates() {
    this.http.getTemplates().subscribe(res => {
      console.log(res);
      this.templates = res;
    })
  }

  getMessage(elem) {
    this.dialogRef.close(elem);
  }

  addTemplates() {
    const dialogRef = this.dialog.open(AddtemplatesComponent
      
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result['status'] == 'ok') {
        let temp = { 'message': result['message'], 'id': result['id'] };

        this.templates.push(temp);
      }
      console.log('The dialog was closed', result);

    });
  }

  _delMessage(messid, index) {
    console.log('del mess');
    this.http.delMessage(messid).subscribe(res => {
      console.log(res);
      if (res == 'delete') {
        this.templates.splice(index, 1);
      }
    })
  }

}
