<div style="  background-color: #F0F4F7; min-height: calc(100vh - 50px);">

  <div style="display: flex; flex-direction: column; width: 80%; margin: auto;">

    <mat-card style="position: relative;">
      <div class="title" style="margin-left: 30px; ">
        Model Category Management
      </div>

      <div cdkDropList class="example-list" style="margin-top: 20px;" (cdkDropListDropped)="drop($event, 'modal')">
        <div class="example-box" *ngFor="let x of modelcategory; let i = index;" cdkDrag>
          <div style="display: flex; flex-direction: row; justify-content: space-between; width: 100%">
            <div>
              {{x}}
            </div>


            <div *ngIf="x !== 'Our Models' " (click)="editCategory(x, 'modal')" style="cursor: pointer !important;">
              Edit
            </div>



          </div>

        </div>
      </div>

      <button class="fab" (click)="CreateAgency('modal')" mat-fab color="primary">+</button>

    </mat-card>


    <mat-card style="position: relative;">
      <div class="title" style="margin-left: 30px; ">
        Client Category Management
      </div>

      <div cdkDropList class="example-list" style="margin-top: 20px;" (cdkDropListDropped)="drop($event, 'client')">
        <div class="example-box" *ngFor="let x of clientcategory; let i = index;" cdkDrag>
          <div style="display: flex; flex-direction: row; justify-content: space-between; width: 100%">
            <div>
              {{x}}
            </div>


            <div  (click)="editCategory(x, 'client')" style="cursor: pointer !important;">
              Edit
            </div>



          </div>

        </div>
      </div>

      <button class="fab" (click)="CreateAgency('client')" mat-fab color="primary">+</button>

    </mat-card>


  </div>

</div>
