import { Component, OnInit, Inject } from '@angular/core';
import {  MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { HttpService } from '../../http.service';

@Component({
  selector: 'app-contact-list-mgmt',
  templateUrl: './contact-list-mgmt.component.html',
  styleUrls: ['./contact-list-mgmt.component.scss']
})
export class ContactListMgmtComponent implements OnInit {

  customlist : any;
  working : boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private http: HttpService,
    public dialogRef: MatDialogRef<ContactListMgmtComponent>,
  ) { 
    this.working = false;
  }

  ngOnInit(): void {
    console.log('contact to add' , this.data);
    this.getCustomList();
  }

  async getCustomList(){
    var funcE = { type: 'CONTACT', func: 'getCustomList' };  
  
    var Postdata = { func: funcE };
    console.log('Postdata', Postdata);

    let res = await this.http.goAcess(Postdata);
    console.log('res', res);
    this.customlist = res;
  }

  async addToCustomList(customListId){
    if(!this.working){
      this.working = true;
      var funcE = { type: 'CONTACT', func: 'addToCustomList' };  
      var dataE = { customlistid : customListId, contactid : this.data['contactid'] }
    
      var Postdata = { func: funcE, data : dataE };
      console.log('Postdata', Postdata);
  
      let res = await this.http.goAcess(Postdata);
      this.working = false;
      this.onNoClick();
      console.log('res', res);
    }

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
