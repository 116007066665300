<div style="position:relative;">

  <mat-spinner class="centred" *ngIf="spinnerProgress">
  </mat-spinner>


  <div *ngIf="!spinnerProgress">
    <div class="event-card" *ngFor="let x of models; let i = index" (mouseover)="getDetails($event,i)" (mouseout)="getDetails($event,i)"
      [style.backgroundImage]="x.portrait ? 'url('+ x.portrait +')' : 'url(assets/girl_vide.jpg)' ">



      <div style="position:absolute;bottom:0;height:30px;width:100%;background-color:black;opacity:0.5;">
        <span class="name">{{x.first}}</span>
      </div>

      <div id="{{ 'details' + i }}" class=" modelInfos ">
       <!--   <mat-radio-group class="check_model">
              <mat-radio-button value= "x.hash" (click)="SelectModel(x.hash)"></mat-radio-button>
            </mat-radio-group> -->

        <img *ngIf="x.register == 'Facebook' " class="registration_type" src="assets/facebook.png" />
        <img *ngIf="x.register == 'activated' " class="registration_type" src="assets/email_activated.png" />
        <img *ngIf="x.register == 'notactivated' " class="registration_type" src="assets/email_notactivated.png" />
        <div class="avatar" style="z-index:200;position:relative;width:90px;height:90px;top:10px;left:50%;transform: translate(-50%,0)">

          <img *ngIf="x.avatar" src="{{x.avatar}}" class="avatar" />
          <div *ngIf="!x.avatar">
            <img *ngIf="x.gender == 'female' " src="assets/avatar_female.png" style="position:absolute;top:0;left:0;width:90px;border-radius:50%;" />
            <img *ngIf="x.gender == 'male' " src="assets/avatar_male.png" style="position:absolute;top:0;left:0;width:90px;border-radius:50%;" />
          </div>

          <div class="userState" [ngClass]="{
  'available': x.state === 'AVAILABLE',
  'invisible':x.state === 'INVISIBLE',
  'break': x.state === 'BREAK'
}">
          </div>

        </div>

        <button class="btn" style="position:relative;margin-top:20px;left:50%; transform:translate(-50%,0);"
          mat-raised-button color="primary" (click)="goModel(x.hash)">See Models</button>


        <div *ngIf="x.creation" style="position:absolute;bottom:5px;left:50%; transform: translate(-50%, 0);text-align:center;font-size:0.8rem">
          <div style="text-decoration: underline">Creation Date : </div>{{x.creation}}
        </div>
      </div>


    </div>

  </div>
</div>