<div class="AllMenu" #AllMenu id="AllMenu">

<div class="menuCtn" id="menuCtn" #menuCtn>
  <div class="menuCtnG">
    <img class="menuLogoG" [src]="srcLogo" (click)="goRoot()"/>

    <div class="bTnD" id="bTnD" (click)="getBook()" #bTnD>
      <span *ngIf="gender == 'female' " style="letter-spacing: 2px; white-space: nowrap;position:absolute;transform: translate(-50%, -50%);top:50%;left:50%">Meet her</span>
      <span *ngIf="gender == 'male' " style="letter-spacing: 2px; white-space: nowrap;position:absolute;transform: translate(-50%, -50%);top:50%;left:50%">Meet her</span>
    </div>
  </div>

  <div (click)="toggleMenu()" class="menuCtnD" id="menuCtnD">
    <img class="menuImg" [src]="srcMenu" />
  </div>
  
</div>



<nav class="menu" id="menuID" #menuID>
  <div style="display: flex; padding-left: 6%; padding-right: 6%; margin-top: 10px; flex-wrap: wrap;">
    <div class="flexCtn">
      <div class="menuTitle">Company</div>
      <ul>
        <li class="menuItem" (click)="goPage('ContactusPage')">
          Contact
        </li>
      <!--  <li class="menuItem">
          Jobs
        </li>-->
        <li class="menuItem" (click)="goPage('SecurityPage')">
          Data Security
        </li>
      </ul>

    </div>
    <div class="flexCtn">
      <div class="menuTitle">Community</div>
      <ul>
        <li class="menuItem" (click)="goPage('PressPage')">
          Presse
        </li>
        <li class="menuItem" (click)="goPage('FaqPage')">
          F.A.Q
        </li>
      </ul>
    </div>
    <div class="flexCtn">
      <div class="menuTitle">Partners</div>
      <ul>
       <a href="https://models.com/" target="_blank"> <li class="menuItem">
          Models.com
        </li></a>
      <!--  <li class="menuItem">
          Blabla
        </li> -->
      </ul>
    </div>
    <div class="flexCtn">
      <div class="menuTitle">Legal</div>
      <ul>
        <li class="menuItem" (click)="goPage('RgpdPage')">
          RGPD
        </li>
        <li class="menuItem" (click)="goPage('ConfidentialityPage')">
          Confidentiality
        </li>
        <li class="menuItem" (click)="goPage('CommunityPage')">
          Community Rules
        </li>
      </ul>

    </div>
  </div>

  <div style="display: flex; padding-left: 6%; padding-right: 6%; margin-top: 10px; flex-wrap: wrap;border-top: 1px solid #e8e8e8; margin-bottom: 10px;">

    <div class="flexCtn50">
      <div class="menuTitle">Follow Us</div>
      <li>
          <a style="cursor:pointer" href="https://www.facebook.com/See-Models-454105331779862/" target="_blank">
        <img style="height: 46px;margin:5px;" src="assets/menu/facebook.png" />
      </a>

      <a style="cursor:pointer" href="https://www.instagram.com/seemodels/" target="_blank">
        <img style="height: 46px;margin:5px;" src="assets/menu/instagram.png" />
      </a>
      </li>

    </div>

    <div class="flexCtn50">
      <div class="menuTitle">Download the app</div>

        <div style="display:flex">
         
          <a style="cursor:pointer" href=" https://itunes.apple.com/fr/app/see-models/id1437709823" target="_blank">
        <div class="appBtn">
         <img class="appBtnLogo" src="assets/menu/apple.svg"/> 
        </div>
      </a>

        <a style="cursor:pointer" href="https://play.google.com/store/apps/details?id=co.ionic.seemodels&hl=fr" target="_blank">  
          <div class="appBtn">
           <img class="appBtnLogo" src="assets/menu/android.svg" />
        </div> 
      </a>

      </div>

    </div>

  </div>

</nav>

</div>