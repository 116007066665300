
<div [formGroup]="chgStateGroup">
    <mat-form-field>
        <input matInput placeholder="Category Name" formControlName="catname">
      </mat-form-field>
</div>


<div style="display: flex; flex-direction: row; justify-content: flex-end;">
    <button mat-stroked-button (click)="createCatName()">ok</button>
</div>
