import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { HttpService } from '../../http.service';
import { CriteriaService } from '../../criteria.service';
import {  MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DialogData } from '../../approved/approved.component';

import { MgmtAlbumComponent } from './mgmt-album/mgmt-album.component';

import { MatSnackBar} from '@angular/material/snack-bar';
import { EmailcompComponent } from '../../emailcomp/emailcomp.component';

import { SendsmsComponent } from '../../sendsms/sendsms.component';

export interface DialogData2 {
  modal: string;
  first: string;
}

@Component({
  selector: 'app-model-overview',
  templateUrl: './model-overview.component.html',
  styleUrls: ['./model-overview.component.scss']
})
export class ModelOverviewComponent implements OnInit {

    id: any;
    spinner : boolean;
    modal : any;
    units: string;
    extension: string;
    Boolunits: boolean;
    result : object;
    location: object;
    agency: object;
    State: number;
    hash: any;
    state : any;

    public innerWidth: any;
    role: number;


  constructor
  (
    private get: HttpService,
    private criteriaservice : CriteriaService,
    public dialogRef: MatDialogRef<ModelOverviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {
    this.State = 0;
    this.role = parseInt(localStorage.getItem('role'));
   }

   ngOnInit() {
    this.innerWidth = window.innerWidth;
    this.getModel(this.data['modal']);

    this.criteriaservice.getProfileUpdate().subscribe( res => {
      console.log('res updated', res);
      if(res['text'] == 'updated'){
        this.getModel(this.data['modal']);
      }
      if(res['text'] == 'delete'){
        this.close();
      }
    });
    
  }

  close(){
    this.dialogRef.close();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  mgmtAlbum(){
    let dialogRef = this.dialog.open(MgmtAlbumComponent, {
      height: '100%',
      width: '80%',
      data: { modal : this.data['modal']  },
      panelClass: 'nopadding-box'
    });
    dialogRef.afterClosed().subscribe(result => {
    //  console.log('The dialog was closed');
     // console.log('result', result);
    });
  }

  
  getModel(elem){
    this.spinner = true;
    this.get.seeModel(elem).subscribe( res => {
      console.log('res',res);
      this.spinner = false;
      this.modal = res['personal'];
      this.location = res['location'];
      this.agency = res['agency'];
      this.units = this.modal.user_unity;
      this.state = res['state'];
     // console.log('units',this.units)
      if (this.units == 'cm'){
        this.Boolunits = true;
      } else {
        this.Boolunits = false;
      }
     // console.log('modal',this.modal);
    })
  }

  copyMessage(val: string){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.openSnackBar('Link have been copy','');
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
  

  checkUnity(n) {
    if (this.units == 'cm'){
      this.extension = 'cm';
      return n;
    } else {
      this.extension = '';
      var realFeet =  n / 30.48;
      var feet = Math.floor(realFeet);
      var inches = Math.round((realFeet - feet) * 12);
      return feet + " ' " + inches + ' " ';
    }
  
  }

  changeUnity(elem : boolean){
  //  console.log(elem);
    if (elem == true){
      this.units = 'cm';
    } else {
      this.units = 'uk';
    }
  //  console.log(this.units);
  }


  getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age + ' y.o';
}

setMyStyles(element) {
  let styles = {
    'border-left' : '6px solid red',
    'background-color' : 'lightgrey',
  };
  let transparent = {
    'background-color': 'transparent',
  }
  if(element != null && element.length >0){
    return transparent;
  } else {
    return styles
  }

}

goMail(elem){
  const dialogRef = this.dialog.open(EmailcompComponent, {  
    data: {email : elem },
    panelClass: 'nopadding-box'
   });

   dialogRef.afterClosed().subscribe(result => {
    if(result == 'OK'){
      this.openSnackBar('Email Sent', 'Good');
    }
  }) 
}

sendFastLinkBySMS(phoneE){
  let dialogRef = this.dialog.open(SendsmsComponent, {
    data: {  phone : phoneE },
    panelClass: 'nopadding-box',

  });

  dialogRef.afterClosed().subscribe(result => {
      console.log('tosend', result);    
    });
}



}
