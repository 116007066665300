<div style="height: 100% ">

    <div style="min-height: 50%; max-height: 50%; ">
        
        <app-statslog 
        [agencystats]="getAgencyOrClientLog.client " 
        [type]=" 'client' " >
    </app-statslog>
    </div>
    <div style="min-height: calc(50% - 10px); max-height: calc(50% - 10px); margin-top: 30px; ">
        
    <app-statslog 
    [agencystats]="getAgencyOrClientLog.agency" 
    [type]=" 'agency' ">
</app-statslog>
    </div>

    </div>  