<div style="position: relative;">

  <app-date-filter (newItemEvent)='dateHaveBeenSet($event)' ></app-date-filter>

  <div class="downloadBtn" (click)="DownloadAsZip()">
    <i style="font-size:30px; color: white" class="material-icons centred">download</i> 
  </div> 

  <mat-spinner class="centred"  *ngIf="spinnerProgress"> </mat-spinner>


<div  style="margin-top: 30px; display: flex; flex-direction: row; justify-content: space-evenly; width: 80%; margin-left: auto; margin-right: auto;">
  
  <div  [ngClass]=" selecteddate == 'all' ? 'active': 'noactive'" (click)="SelecteDate('all')">All</div>
  
  <div *ngFor="let x of yearArray.reverse()" [ngClass]=" selecteddate == x ? 'active': 'noactive'" (click)="SelecteDate(x)">{{x}}</div>

  <!--
  <div  [ngClass]=" selecteddate == 'all' ? 'active': 'noactive'" (click)="SelecteDate('all')">All</div>
  <div  [ngClass]=" selecteddate == '2022' ? 'active': 'noactive'"(click)="SelecteDate('2022')">2022</div>
  <div  [ngClass]=" selecteddate == '2021' ? 'active': 'noactive'"(click)="SelecteDate('2021')">2021</div>
  <div  [ngClass]=" selecteddate == '2020' ? 'active': 'noactive'"(click)="SelecteDate('2020')">2020</div>
  <div  [ngClass]=" selecteddate == '2019' ? 'active': 'noactive'" (click)="SelecteDate('2019')">2019</div>
  -->

</div>

<div style="margin-top: 10px; display: flex; flex-direction: row; justify-content: space-evenly; width: 80%; margin-left: auto; margin-right: auto;">
  <div  [ngClass]=" societe == 'all' ? 'active': 'noactive'" (click)="SelecteEntreprise('all')">All</div>
  <div  [ngClass]=" societe == 'See Models SARL' ? 'active': 'noactive'"(click)="SelecteEntreprise('See Models SARL')">See Models SARL</div>
  <div  [ngClass]=" societe == 'SEEMO MANAGEMENT LTD' ? 'active': 'noactive'" (click)="SelecteEntreprise('SEEMO MANAGEMENT LTD')">SEEMO MANAGEMENT LTD</div>
</div>

<table *ngIf="allfactures && !spinnerProgress" mat-table [dataSource]="allfactures" class="mat-elevation-z1 customRespTable"
 
  
  >

  <ng-container matColumnDef="Model">
    <th mat-header-cell *matHeaderCellDef>Model</th>
    <td mat-cell *matCellDef="let x">
     
        <div class="avatar2" *ngFor="let y of x.model" [style.backgroundImage]=" 'url('+ y.avatar +')'  "></div>

     
    </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="Clients">
    <th mat-header-cell *matHeaderCellDef>Clients</th>
    <td mat-cell *matCellDef="let x">
      <div *ngIf="x.agence" class="avatar2" [style.backgroundImage]=" 'url('+ x.agence +')'  "></div>
      <div *ngIf="!x.agence" class="avatar3">
        <div class="centred">
        {{ x.clientsname }}
      </div>
    </div>

    </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="Montant" *ngIf="state == 'clients' ">
    <th mat-header-cell *matHeaderCellDef>Montant H.T (Benef / Cost)</th>
    <td mat-cell *matCellDef="let x"> {{ x.montant }} ({{ x.benef }} / {{ x.cost }})</td>
  </ng-container>

  <ng-container matColumnDef="Montant" *ngIf="state !== 'clients' ">
    <th mat-header-cell *matHeaderCellDef>Montant H.T</th>
    <td mat-cell *matCellDef="let x"> {{ x.montant }}</td>
  </ng-container>




  <ng-container matColumnDef="dateemission">
    <th mat-header-cell *matHeaderCellDef>Date d'Emission</th>
    <td mat-cell *matCellDef="let x"> {{ x.dateemission }}</td>
  </ng-container>

  <ng-container matColumnDef="datepaiement">
    <th mat-header-cell *matHeaderCellDef>Date de Paiement</th>
    <td mat-cell *matCellDef="let x">
      <div *ngIf="x.datepaiement == null" class="setend" (click)="setDate(x.numerofacture)">Set Date</div>
      <div *ngIf="x.datepaiement !== null">
        {{ x.datepaiement }}
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="Facture">
    <th mat-header-cell *matHeaderCellDef>Facture</th>
    <td mat-cell *matCellDef="let x">

      <div (click)="goFacturation(x.factureurl)" style="text-decoration: underline; color: blue; cursor: pointer;">
        {{ x.numerofacture }}
      </div>



      <div *ngFor="let y of x.statementorjobdate">
       <div *ngIf="y.statement_start">
        Statement : {{ y.statement_start }} -> {{ y.statement_end }}
       </div> 
       <div *ngIf="y.job_date">
        Job Date : {{ y.job_date }}
      </div>
      </div>

    </td>
  </ng-container>

  <ng-container matColumnDef="Actions">
    <th mat-header-cell *matHeaderCellDef>Actions</th>
    <td mat-cell *matCellDef="let x">

  <div>
    <i (click)="deleteFac(x.numerofacture)" style="cursor: pointer" class="material-icons">
      delete_outline
    </i>
    <i *ngIf="!x.datepaiement" (click)="goMail(x)" style="cursor: pointer; margin-left: 10px;" class="material-icons">
      alternate_email
    </i>
    <div *ngIf="!x.datepaiement">
      <div *ngFor="let y of x.relance">
        <div style="cursor: pointer;"
        #tooltip="matTooltip"
        [matTooltip]="y.relanceMail"
        matTooltipHideDelay="100000"
        >
          Relancé : {{ getRelativeTime(y.relanceDate) }}
      </div>
  </div>
    </div>
  </div>
    </td>
  </ng-container>


  <tr mat-header-row *matHeaderRowDef="displayedColumns ; sticky : true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="getCssClass(row)"></tr>
</table>







<table style="margin-top: 20px;width: 90%; margin-left: auto; margin-right: auto;">

  <tr>
    <td>
      Somme a encaissé (Total): {{ getSum('total') }}
    </td>

  </tr>
  <tr>
    <td>
      Somme déja encaisse : {{ getSum('already') }}
    </td>

    <td>
      Tva Collecté : {{ getTva('collected') }}
    </td>

  </tr>

  <tr>
    <td>
      En attente : {{ getSum('waiting') }}
    </td>

    <td>
      Tva En attente :  {{ getTva('waiting') }}
    </td>

  </tr>

</table>

<table *ngIf="state == 'clients' " style="margin-top: 20px;width: 90%; margin-left: auto; margin-right: auto;">
  <tr>
    <td>
      Total Cost : {{ getBenefOrCost('cost') }}
    </td>


  </tr>

  <tr>

    <td>
      Total Benef : {{ getBenefOrCost('benef') }}
     </td>
  </tr>
</table>

</div>
 
 