import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef
} from '@angular/core';
import {
  Router,
  ActivatedRoute
} from '@angular/router';
import {
MatDialog,
 MatDialogRef,
 MAT_DIALOG_DATA
} from '@angular/material/dialog';
import {
  HttpService
} from '../http.service';
//import { ConfirmDelete } from './confirmdelete.component';
import { MatSnackBar} from '@angular/material/snack-bar';
import {
  async
} from '@angular/core/testing';
import {
  CdkDragDrop,
  moveItemInArray
} from '@angular/cdk/drag-drop';
import {
  DomSanitizer,
  SafeResourceUrl,
  SafeUrl
} from '@angular/platform-browser';

export interface DialogData {
  pics: string;
}


@Component({
  selector: 'app-pictures',
  templateUrl: './pictures.component.html',
  styleUrls: ['./pictures.component.scss']
})
export class PicturesComponent implements OnInit {

  polaroid: any;

  gallery: any;
  stats: any;

  spinnerProgress: boolean;
  tabLoadTimes: Date[] = [];

  base64Img: any;
  newImg: any;

  imagetoadd: number;
  model: number;
  return: any;
  logs: any;
  materials: any;
  actions: any;
  res: any;

  @Input() hash: string;
  @Input() avatar: string;

  constructor(
    private _Activatedroute: ActivatedRoute,
    private http: HttpService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private sanitization: DomSanitizer
  ) {}

  ngOnInit() {}

  uploadPola(desc) {
    // console.log(desc);
    this.imagetoadd = desc;
    document.getElementById('inputID').click();
  }






  changeImageVisibility(elemid, visibility) {
    this.http.changeImageVisibility(elemid, visibility).subscribe(res => {
      //console.log(res);
    })
  }



  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }


  async drop2(event: CdkDragDrop < string[] > ) {

    moveItemInArray(this.materials, event.previousIndex, event.currentIndex);
    // console.log(event.previousIndex,  event.currentIndex);
    var i = 0;
    for (i = 0; i < this.materials.length; i++) {
      //  console.log(this.materials[i].id, i, this.hash);
      //   const temp = await this.saveOrder(this.materials[i].id, i, this.hash);

    }

  }



  getImage(data) {
    return this.sanitization.bypassSecurityTrustStyle(`url(${data})`);
  }



}
