import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router  } from '@angular/router';

import {  MatDialog } from '@angular/material/dialog';

import { ClienttovalidateComponent } from './clienttovalidate/clienttovalidate.component';

@Component({
  selector: 'app-contactclient',
  templateUrl: './contactclient.component.html',
  styleUrls: ['./contactclient.component.scss']
})
export class ContactclientComponent implements OnInit {

  role : any;

  constructor(
    private router : Router,
    private r: ActivatedRoute,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.role = localStorage.getItem("role")
  }

  goContact(type){
    this.router.navigate(['../agency', type ], { relativeTo: this.r})
  }

  validateClient(){
    const dialogRef = this.dialog.open(ClienttovalidateComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }


}
