<div [formGroup]="myGroup"  style="width:100%; margin-left:50%; transform: translate(-50%, 0);padding:5px;">
   
   
    <mat-form-field >
       <input matInput placeholder="New Album's Name" formControlName="albumname">
     </mat-form-field>
  
  
    <div mat-dialog-actions>
       <button mat-button (click)="onNoClick()">Cancel</button>
       <button mat-button (click)="onOkClick()" cdkFocusInitial>Ok</button>
     </div>
  
   </div>