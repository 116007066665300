<div #scrollMe style=" overflow-y: auto; height: calc(100% - 60px); ">
  <div style="text-align:center;background-color:#f1f1f1">{{subject}}</div>

  <div class="container" style="position:relative;" *ngFor="let x of chat; let i = index" [attr.data-index]="i">
    <div *ngIf="x.sender == from" style="position:relative; top: 5px; left: 5px; ">
      <img class="delIndicator" src="./assets/settings2.png" [matMenuTriggerFor]="menu" />

      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="remMess(x.mailid, i)">Delete</button>
      </mat-menu>

    </div>

    <img *ngIf="x.avatar" [src]="x.avatar" alt="Avatar" [ngClass]="getCSSClasses(x.sender, 'avatar')" class="switchElem"
      (click)="goModel(x.sender)">
    <p [ngClass]="getCSSClasses(x.sender, 'message')">{{x.message}}</p>


    <div style="position:absolute; bottom: 5px; right: 5px; display: block; ">
      <span *ngIf="x.mailreadtime == null">
        <img class="messIndicator" src="assets/email_notactivated.png" />

      </span>
      <span class="tooltip" *ngIf="x.mailreadtime != null">
        <img style="cursor: pointer" class="messIndicator" src="assets/email_activated.png" />
        <span class="tooltiptext">{{x.mailreadtime}}</span>
      </span>
    </div>

    <span style="position:absolute; top: 5px; right: 5px; display: block;" class="switchElem" [ngClass]="getCSSClasses(x.sender, 'date')">
      {{getRelativeTime(x.date)}}
    </span>
  </div>



</div>

<div [formGroup]="myGroup" style="
border-top: 1px solid rgba(128,128,128,0.3);
display: flex; flex-direction: row;
 justify-content: space-between;
 align-items: center;
 height: 60px;
 ">


  <div>
    <!-- <input type="text" placeholder="Write a message" [(ngModel)]="mess" style="height: 60px; padding-left: 10px ; flex-grow: 5; flex-wrap: wrap;background-color:transparent" formControlName="mess">-->
    <textarea placeholder="Write a message..." rows="5" cols="50" [(ngModel)]="mess" style="border: none;
      height: 60px;
    padding-left: 10px ; flex-grow: 5; flex-wrap: wrap;
    background-color:transparent" formControlName="mess"></textarea>
  </div>


  <div style="display: flex; justify-content: space-between;flex-direction: row;">

    <div style="cursor:pointer" (click)="goTemplates()">
      <img src="../../assets/book.svg" style="height: 20px;">
    </div>

    <div id="messBtn" style="text-align: center;
    color: #329BF1; 
    padding-right: 10px; 
    padding-left: 10px; 
    font-weight: 400; 
    opacity: 1; 
    font-size: 18px;
    cursor: pointer;" (click)="sendMess(mess)">{{chatmess}}</div>


  </div>



</div>