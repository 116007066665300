
<div  style="width: 95%; margin-left: auto; margin-right: auto; margin-top: 20px; height: calc(100vh - 100px)" #container>
  <full-calendar 
  #calendar
  [plugins]="options.plugins"
  defaultView="dayGridMonth"

  [header]="{
      center: 'dayGridMonth,timeGridWeek,listWeek'
    }"
  ></full-calendar>

  <div *ngIf="spinner"  style="display: flex; flex-direction: column; justify-content: center; align-items: center; height: calc(100vh - 100px)">
    <mat-spinner ></mat-spinner>
  </div>
  


</div>


<button class="fab" (click)="createEvents()" mat-fab color="primary">+</button>