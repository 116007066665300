

<div class="grid-stats-ctn" >
   <div class="grid-stats-ctn-title">Registration</div>
    <div class="grid-stats-flex-row" *ngIf="!spinnerProgress2">
        <div class="figureCtn"><span class="figureStat">{{stats?.totalRegistration}}</span><span class="figureLabel">in Total</span></div>
        <div class="figureCtn"><span class="figureStat">{{stats?.totalRegistrationYear}}</span><span class="figureLabel">this Year</span></div>
        <div class="figureCtn"><span class="figureStat">{{stats?.totalRegistrationMonth}}</span><span class="figureLabel">this Month</span></div>
    </div>

    <div  class="grid-stats-flex-row"  *ngIf="spinnerProgress2">
        <div>Loading...</div>
    </div>
</div>

<div class="grid-stats-ctn" *ngIf="!spinnerProgress2">
    <div class="grid-stats-ctn-title">Models</div>
    <div class="grid-stats-flex-row" *ngIf="!spinnerProgress2">
        <div class="figureCtn"><span class="figureStat">{{stats?.placedModels}}</span><span class="figureLabel">signed</span></div>
        <div class="figureCtn"><span class="figureStat">{{stats?.totalRegistrationYear}}</span><span class="figureLabel">to be placed</span></div>
        <div class="figureCtn"><span class="figureStat">{{stats?.toSignedModels}}</span><span class="figureLabel">into signing</span></div>
        <div class="figureCtn"><span class="figureStat">{{stats?.readyToPushModels}}</span><span class="figureLabel">Ready to P.</span></div>
    </div>

    <div  class="grid-stats-flex-row"  *ngIf="spinnerProgress2">
        <div>Loading...</div>
    </div>

</div>