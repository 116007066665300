import { Component, OnInit } from '@angular/core';

import { HttpService } from '../http.service';

export interface DialogData {
  type: string;
  data: any;
}


@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.css']
})
export class StatsComponent implements OnInit {

  windowInnerWidth: any;

  getAgencyOrClientLog : any;
  getBookingStats : any;
  getLastAction : any;
  getLastSubscribe : any;
  getPlacementStats : any;
  getRegistrationStats : any;

  spinnerProgress: boolean;
  
  constructor(
    private get: HttpService
  ) {
    this.spinnerProgress = true;
    this.windowInnerWidth = window.innerWidth;
  
   }

   ngOnInit() {
    this.getStatsFromDb();
  }

  
  
 

  async getStatsFromDb() {
    var funcE = { type: 'PRELOAD', func: 'getFullStats' };
    var Postdata = { func: funcE };

    console.log('on click', Postdata);
    try{
      let res = await this.get.goAcess(Postdata);
      this.getAgencyOrClientLog = res.getAgencyOrClientLog;

      this.getBookingStats = res.getBookingStats;
      this.getPlacementStats = res.getPlacementStats;
      this.getRegistrationStats = res.getRegistrationStats;


      this.getLastAction = res.getLastAction;
      this.getLastSubscribe = res.getLastSubscribe;
      this.spinnerProgress = false;
      console.log('on getStatsFromDb', res);
     
    } catch (error) {
      console.log('error', error);
    }
    console.log('try');
  }

}

