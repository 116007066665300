import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { DialogData2 } from '../editcategory.component';
import { HttpService } from '../../../http.service';

@Component({
  selector: 'app-categorytransfert',
  templateUrl: './categorytransfert.component.html',
  styleUrls: ['./categorytransfert.component.scss']
})
export class CategorytransfertComponent implements OnInit {

  category: string;
  nombre : number;
 
  _category : any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData2,
    private http : HttpService,
    public dialogRef: MatDialogRef<CategorytransfertComponent>,
  ) {
    this.category = this.data['category'];
    this.nombre = this.data['nombre'];
   }

  ngOnInit() {
    console.log('transfert', this.data);
    this.getModeltype();
  }

  getModeltype(){
    this.http.getModeltype('listcategory').subscribe(res=>{
      console.log('list cagegory', res);
      this._category = res;
  })
}

selectCategoryToTransfert(newcategory){
  this.http.transfertToCategory(this.category, newcategory).subscribe(res=>{
    if(res == 'ok'){
      this.dialogRef.close('OK');
    }
  })
}

}
