<table class="w3-table w3-striped w3-border" style="width:90%;margin-left: auto; margin-right: auto; margin-bottom: 50px; margin-top: 20px;" >
    <tr>
  
      <th>Numero BOA</th>
      <th>Model</th>
      <th>Client Name</th>
      <th>Annonceur</th>
      <th>Montant H.T</th>
      <th>Action</th>
    </tr>
    <tr *ngFor="let x of boa; let i = index;">

      <td>  {{ x.boanumber }} </td>
      <td>   {{x.modelname}} </td>
      <td> {{x.clientname}} </td>
      <td> {{x.annonceur}} </td>
      <td> {{x.montantht}} </td>
      <td  style="position:relative;">
        <button *ngIf="x.boaurl == null " mat-stroked-button (click)="uploadBoa(x.boanumber)">Upload Signed Boa</button>
        <button *ngIf="x.boaurl !== null " mat-stroked-button>Generate Invoice</button>
        <span (click)="delBoa(x.boanumber)" style="margin-left: 15px">
          <img src="assets/trash.png" height="20px" style="cursor:pointer;margin: 5px"/>
          </span>
      </td>

    </tr>

  </table>

  <input type="file" id="file" style="display:none" (change)="upload($event.target.files)">