import { Component, OnInit, Inject } from '@angular/core';
import { Data } from '../client-page.component';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-detailsmodal',
  templateUrl: './detailsmodal.component.html',
  styleUrls: ['./detailsmodal.component.css']
})
export class DetailsmodalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DetailsmodalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Data,
  ) { }

  ngOnInit() {
    console.log(this.data)
  }

}
