<div  style="overflow: none; float: right; min-width: 339px; max-width: 339px;background-color: #f4f5f7;box-shadow: 0 12px 24px -6px rgba(9,30,66,.25), 0 0 0 1px rgba(9,30,66,.08);">
    <div class="title">Done</div>
    <div class="border"></div>
    <div style="display: flex; flex-direction: column; justify-content: flex-start;overflow-y: scroll;" id="donelist" #donelist [style.height.px]="heightdone" *ngIf="showBool">
    
      <div class="notedone" *ngFor="let x of done">
        <div style="position: relative;min-width: 32px;min-height: 32px;  height: 100%;">

       <div class="avatarL">
        <div class="initials">{{x.initials}}</div>
       </div>
      

        </div>
        <div class="notedetails">
          <div style="word-break: break-all;color: #172b4d;">
            {{x.note}}
          </div>
          <div style="font-size: 12px;color: #5e6c84;">
            {{getRelativeTime(x.doneat)}}
          </div>
    
        </div>

      </div>
    
    </div>

  </div>