<div class="ctn">

  <div class="gauche">

    <div [formGroup]="chgStateGroup" style="display: flex; flex-direction: row; justify-content: space-around; align-items: center; width: 100%">


      <div style="display: flex; flex-direction: row; justify-content: center; align-items: center;  border: 2px solid black; border-radius: 5px; background-color: rgba(255, 255, 255, .2);" >
        <div>
          <input type="text" (keyup.enter)="goSearch()"
          [placeholder]="placeholder" class="search" formControlName="searchValue" />
        </div>

        <div (click)="goSearch()">
          <img src="../../assets/enter.png" style="height: 25px; margin-right: 5Px" />
        </div>

      </div>

      <div  #tooltip="matTooltip"
      matTooltip="Switch between Model/Client Search"
      [matTooltipPosition]="'above'"
      matTooltipHideDelay="2000">
        <mat-slide-toggle (change)="changeSearchType($event)" formControlName="searchType"  ></mat-slide-toggle>
      </div>

  

    </div>

  </div>

</div>