import { Component, OnInit, Inject } from '@angular/core';
import {
  AngularEditorConfig
} from '@kolkov/angular-editor';

import {
  UntypedFormBuilder,
  Validators,
  UntypedFormGroup
} from '@angular/forms';

import {
 MatDialog,
MatDialogRef,
   MAT_DIALOG_DATA
} from '@angular/material/dialog';

import {
  HttpService
} from '../../../http.service';


@Component({
  selector: 'app-relance',
  templateUrl: './relance.component.html',
  styleUrls: ['./relance.component.scss']
})
export class RelanceComponent implements OnInit {

  chgStateGroup: UntypedFormGroup;
  spinnerProgress: boolean;
  htmlContent: any;
  contacts: any;


  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '300px',
    minHeight: '300px',
    placeholder: 'Enter text here...',
    translate: 'no',
    uploadUrl: 'v1/images', // if needed
    customClasses: [ // optional
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<RelanceComponent>,
    private http: HttpService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.createForm();
   }

  ngOnInit(): void {
    console.log('data', this.data);
    this.getInfos(this.data.collabid);
  }

  createForm() {
    this.chgStateGroup = this.fb.group({
      to: ['', Validators.required],
      htmlContent: ['', Validators.required],
      subject: ['', Validators.required],
    });
  }

  async onOkClick(){

    var funcE = { type: 'RELANCE', func: 'sendRelanceEmail' };    


    var values = 
      { 'to' : this.chgStateGroup.get('to').value,
      'subject' : this.chgStateGroup.get('subject').value ,
      'htmlContent' : this.chgStateGroup.get('htmlContent').value
     };


    var Postdata = { func: funcE, data: values };
    console.log('values', Postdata);

    let res = await this.http.goAcess(Postdata);
    console.log('res', res);
    if( res == 'OK'){
      this.dialogRef.close();
    }

  }

  onNoClick(){
      this.dialogRef.close();
  }
  
  async getInfos(animid){

    var funcE = { type: 'RELANCE', func: 'prepareRelance' };    

      var dataE = { 'criteria' : animid };
      var Postdata = { func: funcE, data: dataE };



    
    let res = await this.http.goAcess(Postdata);
    console.log('res', res);
    if(res){
      this.htmlContent = res.template;
      this.chgStateGroup.get('subject').setValue(res.subject);
      this.contacts = res.contacts;
    }
  }

}
