import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup} from '@angular/forms';
import { HttpService } from '../../../http.service';
import { MatDialog, MatDialogRef,  MAT_DIALOG_DATA} from '@angular/material/dialog';

import { DialogData } from '../bookingdetails.component';

@Component({
  selector: 'app-bookingchange',
  templateUrl: './bookingchange.component.html',
  styleUrls: ['./bookingchange.component.css']
})
export class BookingchangeComponent implements OnInit {

  myGroup: UntypedFormGroup;
  details : any;

  fromDate: any;
  toDate: any;

  bookstate: string;
  working : boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private http: HttpService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<BookingchangeComponent>,
  ) {
    this.createForm();
    this.bookstate = "Update Event";
    this.working = false;
   }

  ngOnInit() {
    console.log(this.data['eventdetails']);
    this.getEventDetails();
  }


  async lastBook2(){
    this.bookstate = "Please Wait";
   
    console.log('form', this.myGroup);

    if(!this.working){
      this.working = true;
      
      let dataE = {
        'who' : this.myGroup.get('who').value, 
        'phone' : this.myGroup.get('phone').value, 
        'where' : this.myGroup.get('where').value, 
        'from' :  this.returnDate(this.myGroup.get('from').value[0]), 
        'to' :   this.returnDate(this.myGroup.get('to').value[1]),
        'more' : this.myGroup.get('more').value,
        'bookingid' : this.data['eventdetails']
      }

      var funcE = { type: 'BOOKING', func: 'updateBooking' };    
      var Postdata = { func: funcE, data : dataE };
      let res = await this.http.goAcess(Postdata);
      console.log('res', Postdata, 'res', res);
      if(res == 'ok'){
        this.dialogRef.close('update');
      }
      this.working = false;
    }

  }

  returnDate(elem){
    if((typeof elem == "string") && elem.includes('000Z')){
      return elem;
    } else {
      return elem.toISOString();
    }
  }

  createForm(){
    this.myGroup = this.fb.group({
      who: ['', Validators.required],
      phone: ['', Validators.required],
      where: ['', Validators.required],
      more: ['', Validators.required],
      from: ['', Validators.required],
      to: ['', Validators.required],
    });
  }

  getEventDetails(){
    this.http.getEventDetails(this.data['eventdetails']).subscribe(res=>{
      console.log(res);
      this.details = res;
      this.myGroup.get('who').setValue(this.details.persontoask);
      this.myGroup.get('phone').setValue(this.details.personcontact);
      this.myGroup.get('where').setValue(this.details.eventaddress);
      this.myGroup.get('more').setValue(this.details.moredetails);
      this.myGroup.get('from').setValue([this.details.from, '']);
      this.myGroup.get('to').setValue(['', this.details.to]);

    })
  }

}

