import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private authenticatedUser : any;
  token: string;

  constructor(
    private http: HttpClient,

  ) {
    
   }

  public isAuthenticated(): boolean {
  
    const token = localStorage.getItem('token');

    if (token){
      return true;
    } else {
      return false;
    }
    // Check whether the token is expired and return
    // true or false
  //  return !this.jwtHelper.isTokenExpired(token);
  }

  public loginUser(input){
      let url = 'https://bmyscouter.co/db/clients/login.php';
      return this.http.post(url, input);
    }


  }

  
   

