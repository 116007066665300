import { Component, OnInit } from '@angular/core';
import {  ViewChildren, QueryList, ElementRef, ViewChild } from '@angular/core';
import { HttpService } from '../http.service';

import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import * as moment from 'moment';

import { DonelistComponent } from './donelist/donelist.component';

@Component({
  selector: 'app-todo',
  templateUrl: './todo.component.html',
  styleUrls: ['./todo.component.scss']
})
export class TodoComponent implements OnInit {

  @ViewChildren("txtArea") textAreas:QueryList<ElementRef>;
  @ViewChildren("txtAreanote") txtAreanote:QueryList<ElementRef>;

  @ViewChild('donelist', {static : false}) donelist: DonelistComponent;


trello  : any;
done : any;

currentTime: string;
deadline: string;
noteid: string;
treloindex: string;
refreshBool : boolean;

top : any;
innerHeight: any;
heightdone: any;

  constructor(
    private http: HttpService,
  ) {
    this.currentTime = moment().format('dddd, MMMM, Do YYYY');
    this.refreshBool = true;

   
    
   }

   ngAfterViewInit(){
/*    this.top = this.topflex.nativeElement.offsetHeight;
    this.innerHeight = window.innerHeight;
    console.log('top', this.top, 'innerHeight', this.innerHeight);
  
    this.heightdone = this.innerHeight - 50;*/
   }

  ngOnInit() {
  this.getAllTrello();



  setInterval( () => {
    if(this.refreshBool){
      this.getAllTrello();
    }
   
  }, 60000)
  }

  toggleInput(elemid, index = -1){
    console.log('elemid', elemid, 'index', index);
    var addtodoid = 'addto' + elemid;
    var keyboardtodoid = 'keyboard' + elemid;
   
    if(document.getElementById(addtodoid).style.display == 'block'){
      console.log('show', keyboardtodoid);
      document.getElementById(addtodoid).style.display = 'none';
      document.getElementById(keyboardtodoid).style.display = 'flex';
      this.refreshBool = false;
      console.log('show1', document.getElementById(keyboardtodoid).style.display);
      setTimeout(() => {
        this.textAreas.toArray()[index].nativeElement.focus();
        this.textAreas.toArray()[index].nativeElement.value = '';
      }, 10);

    } else {
      document.getElementById(addtodoid).style.display = 'block';
      document.getElementById(keyboardtodoid).style.display = 'none';
    }



    
  }

  addNote(noteid){
    console.log('add note', noteid);
    let index;
    for(var i = 0; i < this.trello.length; i++){
      if(this.trello[i].id == noteid){
      index = i;
      }
    }

    var temp = {'id': 1000, 'note' :  this.textAreas.toArray()[index].nativeElement.value };
    if(this.trello[index].data){
      this.trello[index].data.push(temp);
    } else {
      this.trello[index].data = temp;
    }
   
    this.toggleInput(noteid);
    let postData = { 'action' : 'add', 'trelo' : noteid, 'note' : this.textAreas.toArray()[index].nativeElement.value }
    this.mgmtNote(postData, noteid);
  }

  toggleNote(noteid, noteindex, dataindex){
    console.log('noteid', noteid, 'index', dataindex,);
    var notetomodify = 'note' + noteid;
    var keyboardnote = 'keyboardnote' + noteid;
    
      if( document.getElementById(notetomodify).style.display == 'block'){
        document.getElementById(notetomodify).style.display = 'none';
        document.getElementById(keyboardnote).style.display = 'block';  
        this.refreshBool = false; 
      } else {
        document.getElementById(notetomodify).style.display = 'block';
        document.getElementById(keyboardnote).style.display = 'none';
      }

  }

  focusOut(noteid, noteindex, dataindex, data, trelloid){
    console.log('noteid', noteid, 'noteindex', noteindex, 'dataindex' , dataindex, 'data', data);
    var notetomodify = 'note' + noteid;
    var keyboardnote = 'keyboardnote' + noteid;

    //var temp = {'id': noteid, 'note' :  this.txtAreanote.toArray()[noteindex].nativeElement.value };
    var temp1 = 'txt_' + noteindex + dataindex;
    var temp = {'id': noteid, 'note' :  data };

    this.trello[noteindex].data[dataindex] = temp;
    console.log('notes' ,this.trello[noteindex], noteindex);

    document.getElementById(notetomodify).style.display = 'block';
    document.getElementById(keyboardnote).style.display = 'none';

    let postData = { 'action' : 'modify', 'noteid' : noteid, 'value' : data };
    this.mgmtNote(postData, trelloid);
  }

  async mgmtNote(data, treloid){

   // let postData = {'action' : 'add', 'notes' : notesid, 'data' : data}
   let url = 'trelo/mgmt_trelo.php';
   this.http.goBackOffice(data, url).subscribe(res=>{
     console.log('trello', res);
     if(treloid !== 'init'){
      this.switchNote(treloid, res);
     }

     if(treloid == 'init'){
       this.trello = res['trello'];
      // this.done = res['done'];
      this.donelist.getDoneList();
     }
  
   }, error => {
     console.log(error);
   })
  }

  getAllTrello(){
    let postData = { 'action' : 'getall' };
    this.mgmtNote(postData, 'init');
  }

  switchNote(treloid, data){
  
    if(data['status'] == 'ok'){
      let index = 0;
    for(var i = 0; i < this.trello.length; i++){
      var temp1 = this.trello[i];
      if(temp1['id'] == treloid){
      index = i;
      }
    }
    console.log('treloid', treloid, 'data', data, 'index', index);
      var temp = {'id' : treloid, 'titre': data['titre'] , 'data' : data['data']}
      this.trello.slice(index, 1);
      this.trello[index] = temp;
      this.donelist.getDoneList();
        }
  }

  drop(event: CdkDragDrop<string[]>, index) {
    console.log('drop',event);
    if (event.previousContainer === event.container) {
     // moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
     var switch1 = this.trello[index].data[event.previousIndex];
     var switch2 =this.trello[index].data[event.currentIndex];

     this.trello[index].data[event.previousIndex] = switch2;
     this.trello[index].data[event.currentIndex] = switch1;
     this.saveTrelloOrder(index);

    } else {
    /*  transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
    */ }
  }

  saveTrelloOrder(index){
    let order = [];
    let trelloid = this.trello[index].id;
    

    for(var i = 0; i<this.trello[index].data.length;i++){
      order[i] = this.trello[index].data[i].id;
    }

    let postData = { 'action' : 'modifynoteorder', 'trelloid' : trelloid, 'value' : order };
    this.mgmtNote(postData, trelloid);
  }

  deleteNote(noteid, indextrelo, indexnote){
    console.log('delete note', indextrelo, indexnote);
    let trelloid = this.trello[indextrelo].id;

    this.trello[indextrelo].data.splice(indexnote, 1);

    let postData = { 'action' : 'deletenote', 'noteid' : noteid };
    this.mgmtNote(postData, trelloid);
  }

  doneNote(noteid, indextrelo, indexnote){
    console.log('delete note', indextrelo, indexnote);
    let trelloid = this.trello[indextrelo].id;

    this.trello[indextrelo].data.splice(indexnote, 1);

    let postData = { 'action' : 'donenote', 'noteid' : noteid };
    this.mgmtNote(postData, trelloid);
  }

  getRelativeTime(date){
    if(date){
      return moment(date).fromNow();
    }
 
  }

  setDeadline(noteid, treloindex){
    document.getElementById('datepicker').click();
    console.log('close picker', this.deadline);
    this.noteid = noteid;

    this.treloindex = treloindex;
  }

  log(deadline){
    let trelloid = this.trello[this.treloindex].id;

    let postData = { 'action' : 'setdeadline', 'noteid' : this.noteid, 'deadline': deadline };
    this.mgmtNote(postData, trelloid);

  }

  getSomeClass(time){
    let Classes = {'list-card': true, 'average': time == 'average', 'hot':time=='hot', 'overtime':time=='overtime'};
    return Classes;
  }

  getSomeClass2(time){
    let Classes = {'list-card-title': true,  'list-card-title-average':time=='hot' || time=='overtime' };
    return Classes;
  }


}
