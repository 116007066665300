

<form [formGroup]="chgStateGroup"
    class="formGroupSMS"
    >

    <div style="font-style: italic;">
        <div>cela ne fonctionne uniquement avec les numéros FR, le format peut être : </div>
        <div>06 23 45 67 89</div>
        <div>+33 6 23 45 67 89</div>
    </div>

    <mat-form-field style="display:block;margin-top:10px;">
        <textarea matInput placeholder="Select Destinataire"
            formControlName="destinataire"></textarea>
    </mat-form-field>
    <button mat-stroked-button mat-strokked (click)="selectCountry1()">Select Destinataire</button>


    <mat-form-field style="display:block;margin-top:10px;">
        <textarea cols="30" rows="10" matInput placeholder="SMS Content"
            formControlName="smscontent"></textarea>
    </mat-form-field>


    <div mat-dialog-actions>
        <div style="display: flex; flex-direction: row; justify-content: space-between; width: 100%; min-width: 100%;">
            <div style="display: flex; flex-direction: flex-start;" *ngIf="!showcongrats">
                <button mat-button (click)="onNoClick()">Cancel</button>
                <button mat-button (click)="onOkClick()" cdkFocusInitial>{{btnTitle}}</button>
            </div>
            <div style="display: flex; flex-direction: row; justify-content: space-between; width: 100%; min-width: 100%; align-items: center;" *ngIf="showcongrats">
                <div>
                    <button mat-button (click)="onNoClick()">Close</button>
                </div>
                <div>
                    <img src="../../assets/checked.svg" style = "height: 20px;" >
                </div>
             
            </div>
        </div>
    </div>



</form>