import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup} from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-filtermodal',
  templateUrl: './filtermodal.component.html',
  styleUrls: ['./filtermodal.component.scss']
})
export class FiltermodalComponent implements OnInit {

  myGroup: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<FiltermodalComponent>,
  ) { 
    this.createForm();
  }

  ngOnInit(): void {
  }

  createForm(){
    this.myGroup = this.fb.group({
      startdate: ['', Validators.required],
      enddate: ['', Validators.required]
    });
  }

  close(){
    this.dialogRef.close();
  }

  okClick(){
    var res = { 'status' : 'set', 'startdate' : this.myGroup.get('startdate').value.toISOString(), 'enddate' :  this.myGroup.get('enddate').value.toISOString() };
    this.dialogRef.close(res);
  }

}
