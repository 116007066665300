import { Component, OnInit, Inject } from '@angular/core';
import { HttpService } from '../../../../http.service';

import {  MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment'


@Component({
  selector: 'app-pushprogramdetails',
  templateUrl: './pushprogramdetails.component.html',
  styleUrls: ['./pushprogramdetails.component.scss']
})
export class PushprogramdetailsComponent implements OnInit {

  push: any;
  scheduleTime: any;
  modelSend: any;

  spinnerProgress2: boolean;
  update : boolean

  constructor(
    private get: HttpService,
    public dialogRef: MatDialogRef<PushprogramdetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
    this.spinnerProgress2 = false;
    this.update = false;
   }

  ngOnInit(): void {
    this.seePushDetails(this.data['scheduleTime'], this.data['modelSend']);
  }

  getRelativeTime(date){
    return moment(date).format('MM-DD-YYYY hh:mm a');
  }

  async seePushDetails(scheduleTime, modelSend){
    this.spinnerProgress2 = true;
    var funcE = { type: 'PUSH', func: 'seePushDetails' };  
    var dataE = { 'scheduleTime' : scheduleTime, 'modelSend' : modelSend };
    var Postdata = { func: funcE, data : dataE };

    let res = await this.get.goAcess(Postdata);
    console.log('on click', res);
    this.push = res.pushItem;
    this.scheduleTime = res.scheduleTime;
    this.modelSend = res.modelSend;
    this.spinnerProgress2 = false;
  }

  onNoClick(): void {
    this.dialogRef.close(this.update);
  }

  async delSchedule(scheduleId){
    var funcE = { type: 'PUSH', func: 'delComingPush' };  
    var dataE = { scheduleid : scheduleId };
    var Postdata = { func: funcE, data : dataE };

    let res = await this.get.goAcess(Postdata);
    console.log('on click', res);
    if(res == 'ok'){
      this.seePushDetails(this.data['scheduleTime'], this.data['modelSend']);
      this.update = true;
    }
  }

  goLink(link){
    window.open(link);
  }


}
