<form [formGroup]="chgStateGroup">

<div style="text-align:center;font-size: 24px; font-weight: 500">Attention : Ne pas retirer les balises </div>

<div style="display: flex; flex-direction: row; justify-content: center;">

  <div>
      <div style="text-align:center;font-size: 18px;">Text FR</div>

      <angular-editor style="margin-top:10px" id="editor2" formControlName="htmlContentFR" [config]="editorConfig" [(ngModel)]="htmlContentFR"></angular-editor>
      
      <div style="text-align:center;font-size: 18px;margin-top: 50px;">Text ENG</div>
      <angular-editor style="margin-top:10px" id="editor3" formControlName="htmlContentENG" [config]="editorConfig" [(ngModel)]="htmlContentENG"></angular-editor>
      
      
      <div mat-dialog-actions >
        <button mat-button (click)="onNoClick()">Cancel</button>
        <button mat-button (click)="onOkClick('base')" cdkFocusInitial>Ok</button>
      </div>
  </div>

  <div>
      <div style="text-align:center;font-size: 18px;">Text FR</div>

      <angular-editor style="margin-top:10px" id="editor4" formControlName="introFR" [config]="editorConfig" [(ngModel)]="introFR"></angular-editor>
      
      <div style="text-align:center;font-size: 18px;margin-top: 50px;">Text ENG</div>
      <angular-editor style="margin-top:10px" id="editor5" formControlName="introENG" [config]="editorConfig" [(ngModel)]="introENG"></angular-editor>
      
      
      <div mat-dialog-actions >
        <button mat-button (click)="onNoClick()">Cancel</button>
        <button mat-button (click)="onOkClick('intro')" cdkFocusInitial>Ok</button>
      </div>
  </div>

  <div>
      <div style="text-align:center;font-size: 18px;">Text FR</div>

      <angular-editor style="margin-top:10px" id="editor6" formControlName="signatureFR" [config]="editorConfig" [(ngModel)]="signatureFR"></angular-editor>
      
      <div style="text-align:center;font-size: 18px;margin-top: 50px;">Text ENG</div>
      <angular-editor style="margin-top:10px" id="editor7" formControlName="signatureENG" [config]="editorConfig" [(ngModel)]="signatureENG"></angular-editor>
      
      
      <div mat-dialog-actions >
        <button mat-button (click)="onNoClick()">Cancel</button>
        <button mat-button (click)="onOkClick('signature')" cdkFocusInitial>Ok</button>
      </div>
  </div>

</div>




</form>