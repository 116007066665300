import { Component, OnInit, Input } from '@angular/core';
import { HttpService } from '../../http.service';

import * as moment from 'moment';

@Component({
  selector: 'app-modelfac',
  templateUrl: './modelfac.component.html',
  styleUrls: ['./modelfac.component.scss']
})
export class ModelfacComponent implements OnInit {

  @Input() hash: string;
  
  agencyfac : any;
  clientsfac : any;

  pricetype : string;
  pricelabel: string;

  displayedColumns: string[] = [ 'agency', 'emission', 'paiement', 'numerofac', 'montant' ];
  displayedColumns2: string[] = [ 'agency', 'emission', 'paiement', 'numerofac' ];

  constructor(
    private http :HttpService,
  ) {
    this.pricetype = 'H.T';
    this.pricelabel = 'Montant H.T';
   }

  ngOnInit() {
    this.getMyFactures();
  }

  getMyFactures(){
    this.http.getMyFactures(this.hash).subscribe(res=>{
      console.log('documents', res);
      this.agencyfac = res['agency'];
      this.clientsfac = res['clients'];
    })
  }

  getTotal(type){
    let res = 0;
    let temp;

    if(type == 'directclient'){
      temp = this.clientsfac;
    }

    if(type == 'comm'){
      temp = this.agencyfac;
    }
  

    for(var i = 0; i < temp.length; i++){
     res = res + parseInt(temp[i].montant);
   } 
   return res + ' €';
  }

  seeFacture(facurl){
    window.open(facurl);
  }

  toggleMontant(){
    
    if(this.pricetype == 'H.T'){
      console.log('toggle montant1');
      this.pricetype = 'T.T.C';
      this.pricelabel = 'Montant T.T.C';
    } else if (this.pricetype == 'T.T.C'){
      console.log('toggle montant2');
      this.pricetype = 'H.T';
      this.pricelabel = 'Montant H.T';
    }


  }

  dispalyMontant(montant){
    var res;
    if(this.pricetype == 'H.T'){
      res = montant + ' H.T';
    }

    if(this.pricetype == 'T.T.C'){
      res = montant*1.2 + ' T.T.C';
    }

    return res;
  }

  getCssClass(elem){
    if(elem.date_paiement){
      return 'paid';
    } else {
      var now = moment(new Date()); //todays date
      var end = moment(elem.date_emission, 'DD-MM-YYYY'); // another date
      var duration = moment.duration(now.diff(end));
      //console.log('ecart',duration.asDays());
      if(duration.asDays()>30 && duration.asDays() < 40){
        return 'retard';
      }
      if(duration.asDays() > 40){
        return 'verylate';
      }
    }
    }
}
