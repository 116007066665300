import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { GenmacComponent } from './genmac/genmac.component';
import { GenboaComponent } from './genboa/genboa.component'; 
import { GenfacComponent } from './genfac/genfac.component';

import { ActivatedRoute, Router  } from '@angular/router';

export interface DialogData {
  type: string;
}

@Component({
  selector: 'app-gencontract',
  templateUrl: './gencontract.component.html',
  styleUrls: ['./gencontract.component.scss']
})
export class GencontractComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    private router : Router,
    private r: ActivatedRoute,
  ) { }

  ngOnInit() {
  }

  genContract(elem){
    switch(elem) {
      case 'mac':
      this.genMac();
      break;

      case 'mca':
      this.genMca();
      break;

      case 'boa':
        this.genBoa();
        break;

      default:
      

    }
  }

  genMac(){
    const dialogRef = this.dialog.open(GenmacComponent, {
      data: {type: 'mac' }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);

    });

  }

  genMca(){
   /* const dialogRef = this.dialog.open(GenmacComponent, {
      data: {type: 'mca' }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);

    });*/
    this.router.navigate(['members/facturesystem/generatemac' ] );
  }

  genBoa(){
    const dialogRef = this.dialog.open(GenboaComponent, {
    
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);

    });
  }

  genFac(){
  /*  const dialogRef = this.dialog.open(GenfacComponent, {
    
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);

    });*/
    this.router.navigate(['members/facturesystem/generatefac', 'null' ] );
  }



}
