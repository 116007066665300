import { Component, Input, ViewChild, ElementRef } from '@angular/core';


@Component({
  selector: 'getbooking',
  templateUrl: 'getbooking.html',
  styleUrls: ['custommenu.css']
})


export class GetBooking {
  
    constructor(
    
    ) {
     
  
    }
  
    ngOnInit(){

    }
    

  
  

    
  
  }
  