<div style="display: flex; flex-direction: row; justify-content: center; flex-wrap: wrap;"
  *ngIf="_type == 'modelhash' ">
  <div *ngFor="let x of _data  | filterUnique; let i = index;" style="margin: 10px">
    <img [src]="x" height="50px" style="cursor: pointer; display: inline-block;" (click)="selectElem(x)" />
  </div>

</div>

<div style="display: flex; flex-direction: row; justify-content: center; flex-wrap: wrap;" *ngIf="_type == 'type' ">
  <div *ngFor="let x of _data  | filterUnique; let i = index;" style="margin: 10px">
    <div (click)="selectElem(x)" class="wordSelect">{{x}}</div>
  </div>

</div>

<div style="display: flex; flex-direction: row; justify-content: center; flex-wrap: wrap;" *ngIf="_type == 'where' ">
  <div *ngFor="let x of _data  | filterUnique; let i = index;" style="margin: 10px">
    <div (click)="selectElem(x)" class="wordSelect">{{x}}</div>
  </div>

</div>

<div style="display: flex; flex-direction: row; justify-content: center; flex-wrap: wrap;" *ngIf="_type == 'location' ">
  <div *ngFor="let x of _data  | filterUnique; let i = index;" style="margin: 10px">
    <div (click)="selectElem(x)" class="wordSelect">{{x}}</div>
  </div>

</div>

<div style="display: flex; flex-direction: row; justify-content: center; flex-wrap: wrap;"
  *ngIf="_type == 'clienttype' ">
  <div *ngFor="let x of _data  | filterUnique; let i = index;" style="margin: 10px">
    <div (click)="selectElem(x)" class="wordSelect">{{x}}</div>
  </div>
</div>

<div style="display: flex; flex-direction: row; justify-content: center; flex-wrap: wrap;"
  *ngIf="_type == 'agencyhash' ">
  <div *ngFor="let x of _data  | filterUnique; let i = index;" style="margin: 10px">
    <img [src]="x" height="50px" style="cursor: pointer; display: inline-block;" (click)="selectElem(x)" />
  </div>
</div>


<div style="display: flex; flex-direction: row; justify-content: center; flex-wrap: wrap;" *ngIf="_type == 'what' ">
  <div *ngFor="let x of _data  | filterUnique; let i = index;" style="margin: 10px">
    <div (click)="selectElem(x)" class="wordSelect">{{x}}</div>
  </div>

</div>