<div class="modalCloseBtn" (click)="closeModal()">
  <div class="centred" style="color:white">X</div>
</div>

<form
class="chgStateGroup paddingTopOnMobile"
  [formGroup]="chgStateGroup"

>
  <mat-form-field style="display: block">
    <input matInput placeholder="Client E-Mail" formControlName="clientmail" (focusout)="searchForNumber()" />
  </mat-form-field>

  <mat-form-field style="display: block">
    <input matInput placeholder="Phone Number" formControlName="phonenumber" />
  </mat-form-field>

  <div style="display: flex; flex-direction: row; justify-content: center">
    <div>
      <button mat-stroked-button (click)="getClient()">
        Get Client From DB
      </button>
    </div>
  </div>



  <div
  style="display: flex; flex-direction: row; justify-content: space-between"
>
  User can see Polaroids
  <mat-slide-toggle

    formControlName="polaroids"
  ></mat-slide-toggle>
</div>


<div
style="display: flex; flex-direction: row; justify-content: space-between"
>
User can see Videos
<mat-slide-toggle
  formControlName="videos"
></mat-slide-toggle>
</div>


<div
style="display: flex; flex-direction: row; justify-content: space-between"
>
User can see/dl all album
<mat-slide-toggle
  [checked]="!checked"
  formControlName="accesstoalbum"
></mat-slide-toggle>
</div>

<div
style="display: flex; flex-direction: row; justify-content: space-between"
>
User can setup Meeting
<mat-slide-toggle
  [checked]="!checked"
  formControlName="setmeeting"
></mat-slide-toggle>
</div>

  <div
    style="display: flex; flex-direction: row; justify-content: space-between"
  >
    User can access ADMIN docs
    <mat-slide-toggle
      [checked]="!checked"
      formControlName="accesstodocs"
    ></mat-slide-toggle>
  </div>

  <div
  style="display: flex; flex-direction: row; justify-content: space-between"
>
  Log User
  <mat-slide-toggle
    [checked]="checked"
    formControlName="log"
  ></mat-slide-toggle>
</div>

  <mat-form-field style="display: block">
    <input matInput placeholder="Gallery Name (optional)" formControlName="galleryname" />
  </mat-form-field>

  <mat-form-field style="display: block">
    <mat-select placeholder="MenuType" formControlName="menuType" >
      <mat-option value="0">No Menu</mat-option>
      <mat-option value="1">Client Menu (1st Choice, 2nd Choice, No interest)</mat-option>
      <mat-option value="2">Agency Menu (Want to see her, No Interest)</mat-option>
    </mat-select>
  </mat-form-field>


  <div
    style="
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 20px;
    "
  >
    <div>
      <button mat-stroked-button (click)="chooseModel()">
        Select Model(s)
      </button>
      <div *ngIf="selectedmodels" style="text-align: center">
        {{ selectedmodels.length }} Models
      </div>
    </div>
  </div>

  <div
    style="
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
    "
    *ngIf="selectedmodels"
  >
    <div>
      <button mat-stroked-button (click)="getFastLink()">Get Fast Link</button>
    </div>

    <div
      *ngIf="shortlink"
      style="
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 20px;
      "
    >
      <div>
        <div style="display: flex; flex-direction: row">
          <div
            style="
              color: blue;
              text-decoration: underline;
              cursor: pointer;
              margin-right: 10px;
            "
            (click)="goLink(shortlink)"
          >
            {{ shortlink }}
          </div>
          <div (click)="copyMessage(shortlink)">
            <img
              src="../../assets/copy.png"
              style="cursor: pointer; height: 20px"
            />
          </div>
        </div>
        <div style="text-align: center">(click to open)</div>

      </div>

    </div>
    <div  *ngIf="shortlink" style = "display: flex; flex-direction: row; justify-items: center;">
        <div (click)="updateSMSandEmailContent()" class="sendBtn">
            <div>
                <img src="../../assets/message.png" style="height: 50px" />
            </div>
            <div style = "text-align: center; font-size: 12px;">Set SMS / Email Content</div>
          
        </div>

    </div>


  </div>
  </form>


    <div style="padding-bottom: 50px; margin-bottom: 50px;">

      <div *ngIf="shortlink">
        <div  *ngIf="!spinner" style="display: flex; flex-direction: flex-start; margin-top: 10px;flex-wrap: wrap;">
          <button mat-button (click)="closeModal()" >CLOSE</button>
          <button mat-button cdkFocusInitial (click)="sendSMS()">Send SMS</button>
          <button mat-button  (click)="sendEMAIL()">Send E-Mail</button>
          <button mat-button  cdkFocusInitial (click)="sendSMSandEMAIL()">Send SMS + E-Mail</button>
        </div>
      </div>
  
      <div *ngIf="spinner">Loading...</div>

      <div *ngIf="logs.length > 0" style="width: 100%; margin-top: 20px;">
        <div style="display: flex; flex-direction: row; justify-content: space-between;" *ngFor="let x of logs">
          <div>
            <img src="../../assets/email.png" class="icon" *ngIf="x.type == 'email' " />
            <img src="../../assets/phone.png" class="icon" *ngIf="x.type == 'sms' " />
          </div>
          <div>{{ x.destinataire }}</div>
          <div>
            <img src="../../assets/checked.svg" class="icon" *ngIf="x.status == 'OK' " />
            <img src="../../assets/nochecked.svg" class="icon" *ngIf="x.status !== 'OK' " />
          </div>
        </div>
      </div>

    </div>








