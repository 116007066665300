
<div style="width:80%;margin-top:15px;margin-left:50%; transform: translate(-50%, 0);">

    
    Your gonna delete this faq, are you sure ?

      <div mat-dialog-actions >
        <button mat-button (click)="onNoClick()">Cancel</button>
        <button mat-button (click)="onOkClick(data)" cdkFocusInitial>Ok</button>
      </div>
      
      
    </div>