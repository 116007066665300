<div style="width: 100%; height: auto; position: relative;">

  <input matInput id="datepicker" 
  [selectMode]="'single'" [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1"
    placeholder="FROM ?" [(ngModel)]="deadline"  (ngModelChange)="log($event)"
    style="opacity: 0; top: 50%; left: 50%; position: absolute; transform: translate(-50%, -50%);">
  <owl-date-time #dt1 [firstDayOfWeek]="1"></owl-date-time>


  <div style="display: flex; flex-direction: row; 
  justify-content: flex-start; margin-top: 10px; 
  flex-wrap: wrap; width: calc(100% - 339px); float: left;">

    <div  
    class="todo-list"
     *ngFor="let x of trello; let i = index" cdkDropList [cdkDropListData]="x.titre"
      (cdkDropListDropped)="drop($event, i)">
      <div class="list">

        <div class="list-header">
          {{ x.titre }}
        </div>
        <div 
        [ngClass]="getSomeClass(y.time)" *ngFor="let y of x.data; let z = index" cdkDrag>
          <div class="done">
            <div class="flexItem">
              <i style="cursor: pointer; font-size: 15px !important" class="material-icons"
                (click)="doneNote(y.id, i, z)">done</i>
            </div>
          </div>
          <div class="edit">
            <div style="display: flex; flex-direction: row; justify-content: flex-end; width: 100%">

              <div class="flexItem">
                <i style="cursor: pointer; font-size: 15px !important" class="material-icons"
                  (click)="setDeadline(y.id, i)">alarm</i>
              </div>


              <div class="flexItem">
                <i style="cursor: pointer; font-size: 15px !important" class="material-icons"
                  (click)="deleteNote(y.id, i, z)">delete</i>
              </div>


              <div class="flexItem">
                <i style="cursor: pointer; font-size: 15px !important" class="material-icons"
                  (click)="toggleNote(y.id, i,z)">edit</i>
              </div>


            </div>

          </div>
          <div [id]="'note' + y.id" class="list-card-details">
            <div [ngClass]="getSomeClass2(y.time)">
              {{y.note}}
            </div>
            <div *ngIf="y.deadline" class="deadline">
              <div>
                <i style="cursor: pointer; font-size: 15px !important" class="material-icons" [ngClass]="getSomeClass2(y.time)">alarm</i>
              </div>
              <div style="margin-left: 5px;" [ngClass]="getSomeClass2(y.time)">
                {{getRelativeTime(y.deadline)}}
              </div>

            </div>

          </div>
          <div [id]="'keyboardnote' + y.id" style="display: none;">
            <textarea class="card-input" [(ngModel)]="y.note" (focusout)="focusOut(y.id, i,z, y.note, x.id)"></textarea>
          </div>


        </div>



        <div [id]="'addto' + x.id" class="card-composer" (click)="toggleInput(x.id, i)"
          style="display: block;cursor: pointer;">
          <div class="ctn">
            + Ajouter une todo
          </div>

        </div>

        <div [id]=" 'keyboard' + x.id "
          style=" flex-direction: column; margin-right:4px; margin-left: 4px; margin-bottom: 8px; display: none;">
          <textarea #txtArea class="card-input border" style="overflow:auto" placeholder="Add a Todo"
            (keyup.enter)="addNote(x.id)"></textarea>
          <div style="display: flex; flex-direction: row; justify-content: space-between;margin-top: 4px">
            <div>
              <button mat-stroked-button (click)="addNote(x.id)">Save</button>
            </div>

            <div style="cursor: pointer;">
              <button mat-stroked-button (click)="toggleInput(x.id)">Cancel</button>
            </div>
          </div>

        </div>


      </div>


    </div>

  </div>

  <app-donelist #donelist></app-donelist>



</div>
