import { Component, OnInit, Inject } from '@angular/core';
//import { Router, ActivatedRoute } from "@angular/router";
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { UntypedFormBuilder, Validators, FormGroup} from '@angular/forms';
import { HttpService } from '../http.service';

import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

import {  MatDialog } from '@angular/material/dialog';
import { EditcategoryComponent } from './editcategory/editcategory.component';
import { AddcategoryComponent } from './editcategory/addcategory/addcategory.component';

export interface DialogData {
  category: string;
}

@Component({
  selector: 'show-personal',
  templateUrl: 'show-personal.html',
  styleUrls: ['./show-personal.css']
})
export class ShowPersonal implements OnInit {

  modelcategory: any;
  clientcategory: any;

  constructor(
    private fb: UntypedFormBuilder,
    private http: HttpService,
    public dialog: MatDialog,
  ) {
   
   }

  ngOnInit() 
  {
    this.getModeltype();
  }

  drop(event: CdkDragDrop<string[]>, type) {
    if(type == 'modal'){
      moveItemInArray(this.modelcategory, event.previousIndex, event.currentIndex);
      console.log(event, this.modelcategory);
      this.http.saveCategoryOrder(this.modelcategory, 'modal').subscribe(res=>{
        console.log(res);
      })
    }

    if(type == 'client'){
      moveItemInArray(this.clientcategory, event.previousIndex, event.currentIndex);
      this.http.saveCategoryOrder(this.clientcategory, 'client').subscribe(res=>{
        console.log(res);
      })
    }
    
    

  }


  getModeltype(){
    this.http.getModeltype('listcategory').subscribe(res=>{
      console.log('list cagegory', res);
      this.modelcategory = res['modelcategory'];
      this.clientcategory = res['clientcategory'];
  })
}

editCategory(elem, categorytypeD){
  console.log('edit category', );
  const dialogRef = this.dialog.open(EditcategoryComponent, {
    width: '250px',
    data: { category : elem, categorytype :  categorytypeD }
  });

  dialogRef.afterClosed().subscribe(result => {
    console.log(result);

      if(result == 'OK'){
        this.getModeltype();
      }else {
 
      }
    

  });

}

deleteCategory(x){
  console.log('del category', x);
}

CreateAgency(type){
  console.log('create category', );
  const dialogRef = this.dialog.open(AddcategoryComponent, {
    width: '250px',
    data : type
  });

  dialogRef.afterClosed().subscribe(result => {
    console.log(result);
      if(result == 'OK'){
        this.getModeltype();
      }
  });

}

}

