import { Component, OnInit } from '@angular/core';
import {  MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpService } from '../../../http.service';

import { sendEmailComponent } from '../push.component';
import { PushprogramdetailsComponent } from './pushprogramdetails/pushprogramdetails.component';

import * as moment from 'moment'

@Component({
  selector: 'app-pushprogram',
  templateUrl: './pushprogram.component.html',
  styleUrls: ['./pushprogram.component.scss']
})
export class PushprogramComponent implements OnInit {

  schedule: any;
  spinnerProgress2: boolean;

  constructor(
    private get: HttpService,
    public dialog: MatDialog
  ) {
    this.spinnerProgress2 = true;
   }

  async ngOnInit(){
    this.getStatsFromDb();
  }

  async getStatsFromDb(){
    var funcE = { type: 'PUSH', func: 'getComingPush' };  
        
    var Postdata = { func: funcE };
    this.spinnerProgress2 = true;
    let res = await this.get.goAcess(Postdata);
    this.spinnerProgress2 = false;
    console.log('on click', res);
    this.schedule = res;
  }

  sendModel(){
    let dialogRef = this.dialog.open(sendEmailComponent, {
      height: 'auto',
      width: '80%',
      minWidth: '80%',
      data: { 'type': 'schedulepush' },
      panelClass: 'nopadding-box'

    });

    dialogRef.afterClosed().subscribe(result => {
   //   console.log('The dialog was closed');
      if(result == 'success'){
        this.getStatsFromDb();
      }
      console.log('result', result);
    });
  }

  getRelativeTime(date){
    return moment(date).format('MM-DD-YYYY hh:mm a');
  }



  goLink(link){
    window.open(link);
  }



  async goPushDetailsModal(scheduleTime, modelSend){
    let dialogRef = this.dialog.open(PushprogramdetailsComponent, {
      height: 'auto',
      width: '80%',
      minWidth: '80%',
      data: { 'scheduleTime': scheduleTime, 'modelSend' : modelSend }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result == true){
        this.getStatsFromDb();
      }
     
    });
  }



}
