import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-seemodels',
  templateUrl: './seemodels.component.html',
  styleUrls: ['./seemodels.component.scss']
})
export class SeemodelsComponent implements OnInit {

  naked: boolean;
  topless: boolean;
  color: string;
  step : number;

  constructor() {
    this.naked = false;
    this.topless = true;
    this.color= "#F0F4F7";
    this.step = 0;
   }

  ngOnInit() {
  }



  setStep(index: number) {
    this.step = index;
  }

}
