import { Component, OnInit, HostListener } from '@angular/core';
import { HttpService } from '../http.service';
import { Router, ActivatedRoute } from "@angular/router";

import { FastpushComponent } from '../fastpush/fastpush.component';
import { FastmeetComponent } from './fastmeet/fastmeet.component';
import {MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit {

  sidenav: object;
  role: any;
  menu: any;

  user_avatar: string;

  titleMenuWidth: number;
  banmenu : any;
  initials: any;

  agendaBool : boolean;

  constructor(
    private http: HttpService,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
  ) {
    this.agendaBool = false;
   }

  @HostListener('window:resize', ['$event'])
onResize(event) {
  console.log("resize menu");
  this.getWidth();

}


  ngOnInit() {
    this.user_avatar = localStorage.getItem('avatar');

   this.getWidth();
    this.getMenu('mainmenu');
  
    this.getBanniereMenu();
    this.checkForRouteChange();
  }

  checkForRouteChange(){
    this.router.events.subscribe((val) => {
      // see also 
      if(window.innerWidth < 650){
        this.closeMobileMenuIfOpen();
      }
      //console.log("route changed",  val) ;
  })
}

  getWidth(){
    this.titleMenuWidth = window.innerWidth / 12;
  }


  getBanniereMenu(){
    this.http.getMenu('banniere').subscribe(res=>{
      this.banmenu = res;
  //    console.log('banniere',res);
    })
  }

  changeStyle($event){
    //console.log($event);
//    if ($event.type == 'mouseover'){
 //     document.getElementById("sidenav").style.width = "250px";
  //  } else {
   //   document.getElementById("sidenav").style.width = "4.6875rem";
   // }
  }

  getMenu(elem){
    this.http.getMenu(elem).subscribe( res => {
      this.menu = res;
      console.log('menu', this.menu); 
       })
  }

  
  async logout(){
    var Postdata = { 'func' : 'endSession', 'sessionToken' :  localStorage.getItem('sessionToken') };
    console.log('Postdata', Postdata);
    let res = await this.http.goAcess(Postdata, 'newlogin.php');
    console.log('Postdata res', res);
    if(res == 'ok'){
      localStorage.clear();
      this.router.navigate(['login']);
    }
  }
  
  goAdmin(){
    this.router.navigate(['admin'], { relativeTo: this.route });
  }

  toDo(){
    this.router.navigate(['todo'], { relativeTo: this.route });
  }

  goPersonal(){
    this.router.navigate(['personal'], { relativeTo: this.route });
  }

  goFacturation(){
    this.router.navigate(['facturation'], { relativeTo: this.route });
  }

  goDocuments(){
    this.router.navigate(['documents'], { relativeTo: this.route });
  }

  goContract(){
    this.router.navigate(['goContract'], { relativeTo: this.route });
  }

  goFastpush(){
    this.router.navigate(['fastpush'], { relativeTo: this.route });
  }

  goFastLink(){
    this.closeMobileMenuIfOpen();
    const dialogRef = this.dialog.open(FastpushComponent, {
      panelClass: 'nopadding-box'
    });
  
    dialogRef.afterClosed().subscribe(index => {
      console.log('The dialog was closed', index);
      }
    );
  }

  goFastMeet(){
    this.closeMobileMenuIfOpen();
    const dialogRef = this.dialog.open(FastmeetComponent, {

    });
  
    dialogRef.afterClosed().subscribe(index => {
      console.log('The dialog was closed', index);
      }
    );
  }

  MakeLogoAnim(){
    let navburger = document.getElementById('navTrigger');

    if(navburger.classList.contains('active')) {
      navburger.classList.remove('active');
    } else {
      navburger.classList.add('active');
    }
  }


  toggleMobileMenu(){
    
    let menu = document.getElementById('sidenav');
    
    if(menu.classList.contains('mobileMenuOpen') ) {
      menu.classList.remove('mobileMenuOpen');
      this.MakeLogoAnim();

    } else {
      menu.classList.add('mobileMenuOpen');
      this.MakeLogoAnim();
    }
  
  }

  closeMobileMenuIfOpen(){
    let menu = document.getElementById('sidenav');
    

    if(menu.classList.contains('mobileMenuOpen')){
        menu.classList.remove('mobileMenuOpen');
        this.MakeLogoAnim();
    }
  }
  

}
