<div>

  <div style="display: flex; flex-direction: column; justify-content: center; align-items : center; 
  border: 1px solid #000; padding: 10px; margin-top: 10px" *ngIf="choose">

    <div>
      Which contract do you want to upload ?
    </div>

    <div style="display: flex; flex-direction: row;">
      <div class="boxItem" (click)="downloadSwitch('modelagreement')">
        <div>
          <img src="../../../../assets/download.png" style="height: 20px" />
        </div>
        <div class="boxItemTitle">
          Model Agreement </div>

      </div>

      <div class="boxItem" (click)="downloadSwitch('mac')">
        <div>
          <img src="../../../../assets/mail.png" style="height: 20px" />
        </div>
        <div class="boxItemTitle">
          M.A.C
        </div>
      </div>
    </div>

  </div>

  <div *ngIf="!choose && mac">

    <div [formGroup]="myGroup" style="width:80%; margin-left:50%; transform: translate(-50%, 0);padding:5px;">

      <input id="agency_contract" type="file" style="display:none;"
        (change)="fileChangeEvent($event.target.files, 'agency_contract')" />

        <div style="display: flex; flex-direction: column;">
        <button *ngIf="!agency" style="margin:10px" mat-strokked-button color="primary" (click)="chooseAgency()">Choose Agency</button>
      </div>
    
          <mat-form-field  *ngIf="agency" style="display:block">
            <input matInput placeholder="Choose Agency" formControlName="agence">
          </mat-form-field>
      



      <mat-form-field style="display:block">
        <input matInput [matDatepicker]="picker" placeholder="Début de Representation" formControlName="date">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <div style="display: flex; flex-direction: column;">
        <button style="margin:10px" mat-strokked-button color="primary" (click)="addcontract('agency_contract')">Upload Contrat
          Agence</button>
        <button style="margin:10px" mat-strokked-button color="primary" (click)="addCollab()">Add
          Collaboration</button>
      </div>

    </div>
  </div>

  <div *ngIf="!choose && modelagreement">

    <div [formGroup]="myGroup2" style="width:80%; margin-left:50%; transform: translate(-50%, 0);padding:5px;">

      <input id="model_agreement" type="file" style="display:none;"
        (change)="fileChangeEvent($event.target.files, 'model_agreement')" />

      <mat-form-field style="display:block">
        <input matInput [matDatepicker]="picker" placeholder="Début de Representation" formControlName="date">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <div style="display: flex; flex-direction: column;">
        <button style="margin:10px" mat-strokked-button color="primary" (click)="addcontract('model_agreement')">Upload
          File</button>
        <button style="margin:10px" mat-strokked-button color="primary" (click)="addMac()">Add
          M.A.C</button>
      </div>



    </div>
  </div>
</div>


