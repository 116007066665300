import { Component, OnInit, Inject, HostListener } from '@angular/core';
import {  MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogData2 } from '../model-overview.component';
import { DomSanitizer} from '@angular/platform-browser';
import { HttpService } from '../../../http.service';
import {MatDialog } from '@angular/material/dialog';
import { ConfirmmodalComponent } from "../../../common/confirmmodal/confirmmodal.component"

@Component({
  selector: 'app-mgmt-album',
  templateUrl: './mgmt-album.component.html',
  styleUrls: ['./mgmt-album.component.scss']
})
export class MgmtAlbumComponent implements OnInit {

  publiclink: any;
  downloadlink : any;

  innerWidth: number;
  spinner : boolean;

//  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  constructor(

    @Inject(MAT_DIALOG_DATA) public data: DialogData2,
    public dialogRef: MatDialogRef<MgmtAlbumComponent>,
    private sanitizer: DomSanitizer,
    private http: HttpService,
    public dialog: MatDialog,
  ) {
    this.spinner = false;
   }

   closeModal(){
    this.dialogRef.close();
   }

   ngOnInit() {
    //console.log(this.data);
    this.innerWidth = window.innerWidth;
    this.getPublicLink();
  }

  goLink(url){
    window.open(url);
  }

  transform() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.publiclink);
  }

  copyMessage(elem){
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = elem;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }



async getPublicLink(){
  var funcE = { type: 'PACKAGE', func: 'getPublicSiteLink' };  
  let dataE = { 'modalHash' : this.data.modal};

  var Postdata = { func: funcE, data : dataE };
    let res = await this.http.goAcess(Postdata);

    if(res.status == 'inmainboard') {
      this.publiclink = res.link;
    }

  console.log('on click Postdata', Postdata);
}

async createZIP(){
  var funcE = { type: 'PACKAGE', func: 'createZip' };  
  let dataE = { 'modalHash' : this.data.modal};

  var Postdata = { func: funcE, data : dataE };
   let res = this.http.goAcess(Postdata);
  console.log("res", res)
   

   let dialogRef = this.dialog.open(ConfirmmodalComponent, {

    data: { text : "An email with all material will be send to pa@seemodels.co. PLEASE WAIT 5 MINUTES !"  },
  });
  dialogRef.afterClosed().subscribe(result => {
  //  console.log('The dialog was closed');
   // console.log('result', result);
  });

}


}
