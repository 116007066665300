
     <div *ngIf="type == 'agency' " class="grid-stats-ctn-title">Agency Feedback Tracking</div>
     <div *ngIf="type == 'clients' "  class="grid-stats-ctn-title">Client Feedback Tracking</div>
  <div style="max-height:  calc((100vh - 80px)/2); overflow-y: scroll;">
 

    <table class="w3-table w3-striped w3-bordered">
      <tr class="w3-bar" *ngFor="let x of agencystats; let i = index" >
        <td >
          <div *ngIf="x.agency_logo !== '' " (click)="goAgency(x.agency_hash)">
            <img *ngIf="x.agency_logo" [src]="x.agency_logo" height="50px" style="cursor: pointer;"
            [matTooltip]="x.agency_contact" />

          </div>

          <div *ngIf="x.agency_logo == '' "
            style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
            <div>

              {{ x.agency_contact }}

            </div>
          </div>

        </td>
        <td>
          <img [src]="getIcon(x.log_type)" height="50px" [matTooltip]="x.log_type" />
       
        </td>

        <td>
          {{x.date}}
        </td>
        <td>
          <img [src]="x.modalAvatar" height="50px" style="border-radius: 50%" (click)="goModel(x.model_hash)" />
        </td>
        <td>
          {{x.agency_location_city}}
        </td>
        <td>
          <i (click)="delLog(x.log_id, i)" style="cursor: pointer" class="material-icons">
            delete_outline
          </i>
        </td>
      </tr>
    </table>



  
  </div>
    