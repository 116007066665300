
<form [formGroup]="myGroup"  style="width:100%;">
 
 
    <mat-form-field style="display:inline-block;width:100%;">
       <mat-select placeholder="category" formControlName="category" >
         <mat-option *ngFor="let x of category" [value]="x.view">
           {{x.value}}
         </mat-option>
       </mat-select>
     </mat-form-field>
   
     <mat-form-field style="display:inline-block;width:100%;">
       <textarea cdkTextareaAutosize matInput  placeholder="question" formControlName="question" ></textarea>
     </mat-form-field>
   
   
     <mat-form-field style="display:inline-block;width:100%;">
        <textarea cdkTextareaAutosize matInput  placeholder="answer" formControlName="answer" ></textarea>
       </mat-form-field>
   
       <button style="margin:10px" mat-raised-button color="primary" (click)="onNoClick()">Cancel</button>
     <button  style="margin:10px" mat-raised-button color="primary" (click)="onOkClick()">Save FAQ</button>

   
 </form>