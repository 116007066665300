import { Component, OnInit, Inject } from '@angular/core';

import { UntypedFormBuilder, Validators, UntypedFormGroup} from '@angular/forms';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { HttpService } from '../../../http.service';

import { DialogData } from '../modeldocs.component';
import { ChooseagencyComponent } from '../../../agenda/gobook/chooseagency/chooseagency.component';

@Component({
  selector: 'app-adddocs',
  templateUrl: './adddocs.component.html',
  styleUrls: ['./adddocs.component.scss']
})
export class AdddocsComponent implements OnInit {

  chgStateGroup : UntypedFormGroup;
  spinnerProgress:boolean;
  file : any;
  choice : any;
  doctypes: any;
  agency: any;


  constructor(
    private fb: UntypedFormBuilder,
    private http: HttpService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<AdddocsComponent>,
    public dialog: MatDialog,

  ) {
    this.agency = null;
    this.file = null;
    this.prepareForm();
   }

   async getDocTypes(){
    var funcE = { type: 'DOCS', func: 'getDocsCategory' };    
    var Postdata = { func: funcE };
    console.log('on click', Postdata);
    let res = await this.http.goAcess(Postdata);
    console.log('on click', res);
    this.doctypes = res;
   }

  ngOnInit() {
    console.log('add docs to ', this.data);
  }

  onNoClick(): void {
    this.dialogRef.close('close');
  }

  uploadFile(){
    document.getElementById('file1').click();
  }


  prepareForm(){
    this.chgStateGroup = this.fb.group({
      type: ['', Validators.required],
      name: ['', Validators.required],
    });
    this.getDocTypes();
  }

  sendFile(){
    if(this.chgStateGroup.get('type').value == 'M.A.C'){
      let dialogRef = this.dialog.open(ChooseagencyComponent, {
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        console.log('result', result);
        this.agency = result;
        this.handleFileInput();
      });
    } else {
      this.handleFileInput();
    }
  }

  handleFileInput(){
    this.spinnerProgress = true;

    this.http.handleFileInput2(
      this.chgStateGroup.get('type').value,
      this.chgStateGroup.get('name').value,
      this.data['modal'],
      this.file,
      this.agency
      ).subscribe( res => {
        console.log(res);
        this.spinnerProgress = false;
        if(res == 'OK'){
          this.dialogRef.close('success');
        }
     
    },
    error => {
      console.log(error);
      this.spinnerProgress = false;
      this.dialogRef.close('error');
    }
  )
  }

  upload(elem){
    this.file = elem[0];
  }



}
