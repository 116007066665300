import { Component, OnInit, Input } from '@angular/core';

import {MatDialog } from '@angular/material/dialog';
import { HttpService } from '../../http.service';
import { AdddocsComponent } from './adddocs/adddocs.component';

import { PdfviewerComponent } from '../../common/pdfviewer/pdfviewer.component'

export interface DialogData {
  modal: string;
}

@Component({
  selector: 'app-modeldocs',
  templateUrl: './modeldocs.component.html',
  styleUrls: ['./modeldocs.component.scss']
})
export class ModeldocsComponent implements OnInit {

  @Input() hash: string;
  docs : any;
  displayedColumns: any; 
  checked: boolean;

  windowWidth : any;

  constructor(
    public dialog: MatDialog,
    private http :HttpService

  ) {
    this.windowWidth = window.innerWidth;

    if(this.windowWidth > 650) {
      this.displayedColumns = ['Name', 'Type', 'Date', 'Delete', 'Download', 'Public'];
    } else {
      this.displayedColumns =  ['Name', 'Type',  'Download', 'Public'];
    }
   }

  ngOnInit() {
    console.log('model docs', this.hash);
    this.getDocs();
  }

  addDocs(){
    let dialogRef = this.dialog.open(AdddocsComponent, {
      //height: '100%',
      width: '240px',
      data: { modal : this.hash  }
    });
    dialogRef.afterClosed().subscribe(result => {
    //  console.log('The dialog was closed');
      console.log('result', result);
     if(result == 'success'){
      this.getDocs();
     }
    });
  }

  async getDocs(){
    var funcE = { type: 'DOCS', func: 'getModelDocs' };   
    let dataE = { 'modal' : this.hash };   
    var Postdata = { func: funcE, data : dataE };

    console.log('on click', Postdata);
    let res = await this.http.goAcess(Postdata);
    console.log('on click', res);
    this.docs = res;
  }


  downloadfile(url){
    window.open(url);
  }



  async delFile(fileid){

    var funcE = { type: 'DOCS', func: 'deleteDoc' };   
    let dataE = { 'modal' : this.hash, 'fileid' : fileid, 'type' : 'modeldocs' };   
    var Postdata = { func: funcE, data : dataE };
    let res = await this.http.goAcess(Postdata);
    console.log('res',res)
    if(res == 'delete'){
      this.getDocs();
    }

  }

  async changeDocPublic(docid, docpublic){
    console.log('change pub', docid, 'docpublic', docpublic);

    var funcE = { type: 'DOCS', func: 'getModelDocs' };   
    let dataE = { 'modal' : this.hash };   
    var Postdata = { func: funcE, data : dataE };

    console.log('on click', Postdata);
 //   let res = await this.http.goAcess(Postdata);
  //  console.log('on click', res);
  //  this.docs = res;
  }

  viewFile(url, ext){
    let dialogRef = this.dialog.open(PdfviewerComponent, {
      height: '100%',
      width: '100%',
      data: { fileUrl : url, fileType : ext  }
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  

}
