<div class="macSettingsCtn" [style.height.px]="innerHeight">

      <div style = "display: flex; flex-direction: row; width: 100%; justify-content: space-between;">
      
        <div >
          <div  style="border: 1px solid #000; padding: 10px; margin-top: 10px; position : relative">
    
              <div *ngIf="!model" style="display: flex; flex-direction: column; justify-content: center;">
                  <button mat-stroked-button (click)="chooseModel()">Click here to choose Model</button>
    
              </div>
  
              <div style="display: flex; flex-direction: column; justify-content: center;" *ngIf="model">
  
                  <div [style.backgroundImage]="'url(' + modelavatar + ')'" class="avatar2"
                  style="margin-left: 50%; transform: translate(-50%, 0)"></div>
        
  
              </div>

              <div *ngIf="model"  (click)="model = !model" style="position: absolute; bottom: 10px; right: 10px; cursor: pointer; color: blue; font-style: italic; font-size: 12px;">
                  Change Model
                </div>
          
  
          </div>
      </div>

          
      <div>
        <div style="border: 1px solid #000; padding: 10px; margin-top: 10px">
          

            <div *ngIf="!agency" style="display: flex; flex-direction: column; justify-content: center;">
                <button mat-stroked-button (click)="chooseAgency()">Click here to choose Agency</button>

            </div>

            <div style="display: flex; flex-direction: column; justify-content: center; position: relative;" >

                <div style="display: flex; flex-direction: column; justify-content: center;" *ngIf="agency">

                    <div [style.backgroundImage]="'url(' + agencyavatar + ')'" class="avatar2"
                    style="margin-left: 50%; transform: translate(-50%, 0)"></div>
          
    
                </div>

                <div *ngIf="agency"  (click)="agency = !agency" style="position: absolute; bottom: 10px; right: 10px; cursor: pointer; color: blue; font-style: italic; font-size: 12px;">
                    Change Agency
                  </div>
            
        
            </div>


          </div>
       </div>
      
      </div>


    


        <div style= " display: flex; flex-direction: column; justify-content: center; border: 1px solid black;margin-top: 10px ">
          <mat-form-field>
            <mat-label>Language</mat-label>
            <mat-select [(ngModel)]="language" (ngModelChange)="getContent()">
              <mat-option  [value]="'fr'">Français</mat-option>
              <mat-option  [value]="'uk'">English</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Compagny</mat-label>
            <mat-select [(ngModel)]="seemodels" (ngModelChange)="getPreviewUrl()">
              <mat-option  [value]="'fr'">See Models SARL</mat-option>
              <mat-option  [value]="'uk'">SEEMO MANAGEMENT LTD</mat-option>
            </mat-select>
          </mat-form-field>


        </div>


        <div>
      
            <div style="display: flex; flex-direction: column; justify-content: center; align-items : center; border: 1px solid #000; padding: 10px; margin-top: 10px"
                 [formGroup]="contentFormGroup" >
    
                    <angular-editor 
                    style="margin-top:10px; min-width: 320px; min-height: 300px;" 
                    id="editor1" 
                    [config]="editorConfig" 
                    formControlName="htmlContent"
                    [(ngModel)]="htmlContent"
                    (ngModelChange)="getPreviewUrl()"
                    ></angular-editor>
    
            </div>
    
    
        </div>


        <div style = "display: flex; flex-direction: column; ">
          <button mat-stroked-button (click)="contractToPdf()" [disabled]="btnBool" style="margin-top: 15px">
            {{ btnStatus }}
          </button>
        </div>

</div>

<div
class="iFrameCtn"
[style.height.px]="innerHeight"
>

<iframe width="100%" [height]="innerHeight" [src]=" url " (onload)="resizeIframe(this)"></iframe>

</div>
