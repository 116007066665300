import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpService } from '../http.service';
import { MapsAPILoader } from '@agm/core';
import {  MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../agency-card/agency-card.component';

import { LogoCrop } from '../agency-card/agency-card.component';

import {
  CriteriaService
} from '../criteria.service';

@Component({
  selector: 'app-agency-details',
  templateUrl: './agency-details.component.html',
  styleUrls: ['./agency-details.component.css']
})



export class AgencyDetailsComponent implements OnInit {
  
  details: any;
  lat:  51.678418;
  lng: 7.809007;
  croppedImageNews: any;
  type : string;

  constructor(
    private _Activatedroute:ActivatedRoute,
    private get: HttpService,
    public mapsApiLoader: MapsAPILoader,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialog: MatDialog,
    private criteriaservice: CriteriaService,
    private dialogRef : MatDialogRef<AgencyDetailsComponent>
  ) {


   }

  ngOnInit() {
    console.log('transfered', this.data);
  this.getAgencyDetails2(this.data['agency']);
  this.type = this.data['type'];

  this.criteriaservice.getAgencyUpdate().subscribe( res => {
    console.log('res updated', res);
    if(res['text'] == 'updated'){
      this.getAgencyDetails2(this.data['agency']);
    }

    if(res['text'] == 'delete'){
     this.close('delete');
    }

  });

}

close(elem){
  this.dialogRef.close(elem);
}

getAgencyDetails2(hash){

  var criteria = [
    {'label': 'agency_hash', 'value': hash}
  ]

  var postData = {  'table' : 'agency','action' : 'get', 'criteria' : criteria };

  this.get.goBackOffice(postData).subscribe(res=>{
  
    this.details = res;
    console.log('details', this.details);
  })
}

/*
getAgencyDetails(hash){
  console.log('getagencydetails',);
    this.get.getAgencyDetails(hash).subscribe(res => {
      console.log(res);
      this.details = res;
    })
  }*/

  uploadLogo(){
    const dialogRef = this.dialog.open(LogoCrop, {
      width: '500px',
      height: '500px',
    //  data: {img: this.croppedImage}
    });
  
    dialogRef.afterClosed().subscribe(result => {
      
      this.croppedImageNews = sessionStorage.getItem("imgtemp");
      console.log('The dialog was closed', this.croppedImageNews);
      if( result !== null){
       this.updateLogo(this.croppedImageNews);
      }
      sessionStorage.removeItem("imgtemp");
    });
  }

   updateLogo(logo){
     console.log('update logo', logo);
    this.get.updateLogo(this.data['agency'],logo).subscribe(res=>{
      console.log(res);
      if(res['status'] == 'update'){
        this.details['agency_logo'] = res['logo'];
        this.close('update');
      }
    })
  }

  goInsta(account){
    let url = 'https://instagram.com/' + account;
    window.open(url);
  }

  goPage(link){
    window.open(link);
  }


}
