import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup} from '@angular/forms';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { HttpService } from '../../../http.service';

import { EditFactureComponent } from '../../../facturesystem/allfactures/edit-facture/edit-facture.component';

@Component({
  selector: 'app-addfichedepaie',
  templateUrl: './addfichedepaie.component.html',
  styleUrls: ['./addfichedepaie.component.scss']
})
export class AddfichedepaieComponent implements OnInit {

  chgStateGroup : UntypedFormGroup;
  spinnerProgress:boolean;
  libelle: any;
  file: any;
  relativeJobs: any;
  relativeJobsSelected : any;

  constructor(
    private fb: UntypedFormBuilder,
    private http: HttpService,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<AddfichedepaieComponent>,
    public dialog: MatDialog,

  ) {
    this.file = null;
   }

  ngOnInit(): void {
    
    this.prepareForm();
    this.getLibele();
    this.getRepart();
  }

  async getRepart(){
    var funcE = { type: 'FDP', func: 'getRepartFDP' };  
    var dataE = { "modal" : this.data['modal'] };
    var Postdata = { func: funcE, data : dataE };
    
    var Postdata = { func: funcE, data : dataE };

    console.log('on click', Postdata);
    let res = await this.http.goAcess(Postdata);
    console.log('on click', res);
    this.relativeJobs = res;
  }

  prepareForm(){
    this.chgStateGroup = this.fb.group({
      libelle: ['', Validators.required],
      brutEmployeur: ['', Validators.required],
      netMannequin: ['', Validators.required],
      relativeJobs: ['', Validators.required],
    });
  }

  async save(){
    var funcE = { type: 'FDP', func: 'addFicheDePaie' };  

    var dataE = { 
      'libelle': this.chgStateGroup.get('libelle').value,
      'brutEmployeur': this.chgStateGroup.get('brutEmployeur').value, 
      'netMannequin' : this.chgStateGroup.get('netMannequin').value ,
      'modal' : this.data['modal'],
      'fileAsString' : this.file,
      'relativeJobsSelected' : this.relativeJobsSelected
    };    

    var Postdata = { func: funcE, data : dataE };

    console.log('on click', Postdata);
    let res = await this.http.goAcess(Postdata);
    console.log('on click', res);
   this.dialogRef.close('close');
  }

  goCancel(){
    this.dialogRef.close('close');
  }

  uploadFile(){
    document.getElementById('file1').click();
  }

  upload(elem){

    this.handleUpload(elem[0]);
  }

  getLibele(){

    let actualDate = new Date();
    let monthLibelle = [];

    for(var i = 0; i < 12; i++){

      let date = new Date( actualDate.setMonth(i));
     
      monthLibelle[i] = this.capitalizeFirstLetter(date.toLocaleString('default', { month: 'long' } ) ) + "-" + actualDate.getFullYear();
    }

    this.libelle = monthLibelle;
    console.log(monthLibelle);
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  handleUpload(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.file = reader.result;
        console.log(reader.result);
    };
  }

  changeTO(jobs){
  
    this.relativeJobsSelected = jobs.value;
  }

  setNewMontant(repartId, type){

    let dataE;
    if (type == "montant") {
      dataE = { text : "Montant Brut Mannequin", "type" : "number" };
    }

    if (type == "libelle") {
      dataE = { text : "Nouveau Libelle", "type" : "number" };
    }

    const dialogRef = this.dialog.open(EditFactureComponent, {  
      width: '350px', 
      data: dataE
     });

     dialogRef.afterClosed().subscribe(result => {
      console.log("setnew", result);

      if(result){
        if(result.status == 'set'){
          for(var i = 0; i < this.relativeJobsSelected.length; i++){
            if(this.relativeJobsSelected[i].id == repartId) {

              if(type == "montant"){
                this.relativeJobsSelected[i].in_pocket = result.date;
              }

              if(type == "libelle"){
                this.relativeJobsSelected[i].note = result.date;
              }
             
            }
          }
     
        }
      }

    }) 
  }



}
