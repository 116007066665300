<div class="gridCtn">

<div class="gridElemOne">
<app-registration-stat [stats]="getRegistrationStats"></app-registration-stat>
</div>
<div class="gridElemTwo ">
<app-placement-stats [stats]="getPlacementStats"></app-placement-stats>
</div>
<div class="gridElemThree removeOnMobile">
<app-booking-stats [statsB]="getBookingStats"></app-booking-stats>
</div>


  </div>
