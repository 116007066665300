<div>

    <div (click)="closeWindow()" style="cursor: pointer; margin-bottom: 20px;">
        <img src="assets/choice/close.png" style="height: 50px;" />
    </div>

        <div *ngIf="fileExt == 'jpg' || fileExt == 'jpeg' || fileExt == 'png' " style="max-height: 80vh; width: auto;">
            <img [src]="pdfSrc" style="max-height: 80vh;" />
        </div>



        <div *ngIf="fileExt == 'doc' || fileExt == 'docx' || fileExt == 'xls' || fileExt == 'xlsx' || fileExt == 'pptx' || fileExt == 'pdf' || fileExt == 'txt'  ">
            <ngx-doc-viewer
            [url]="pdfSrc"
            viewer="google"
            style="width:100%;height: calc(100vh - 100px);"
          ></ngx-doc-viewer>

        </div>

    
            
</div>



