import { Component, OnInit, EventEmitter, Output } from '@angular/core';
//import { Options } from 'ng5-slider';
import {UntypedFormControl} from '@angular/forms';
import { HttpService } from '../http.service';
import { ActivatedRoute } from '@angular/router';
import { CriteriaService } from '../criteria.service';

@Component({
  selector: 'app-full-agency-details',
  templateUrl: './full-agency-details.component.html',
  styleUrls: ['./full-agency-details.component.css']
})
export class FullAgencyDetailsComponent implements OnInit {


 // select: any;
  female: any;
  male: any;
  hash: any;

  toppings = new UntypedFormControl();

  constructor(
    private http: HttpService,
    private _Activatedroute:ActivatedRoute,
    private criteriaservice : CriteriaService
  ) {
   // this.select = [];

   }

   ngOnInit(){
    this._Activatedroute.params.subscribe( params => {
      console.log('params',params.id)  ;
      this.hash = params.id;
  })
   }

   ngAfterViewInit () {
  //  this.getCriteria(this.hash);

}

  /*getCriteria(hash){
    this.http.getCriteria(hash).subscribe ( res => {

      this.female = res['female'];
      this.male = res['male'];
      console.log('male',  res['male']);
      console.log('female',  res['female']);    
    })
  }*/

  changeSliderValue(slider, user_part, gender){

    let input = new FormData();    
    input.append('user_part', user_part);
    input.append('value_min', slider.value);
    input.append('value_max',    slider.highValue);
    input.append('gender',    gender);
    input.append('hash',    this.hash);
    console.log(gender);
    
    // UPDATE CRITERIA
 

    this.http.changeSliderValue(input).subscribe( res => {
     //  this.message = res;
    // console.log(res);
    if(res){
      this.criteriaservice.updateCriteria('updated');
    }
     },
     error => {
     }
   )
 
   }


}
