

import {Component, OnInit, Input } from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import { HttpService } from '../../http.service';

@Component({
  selector: 'app-mgmtcategory',
  templateUrl: './mgmtcategory.component.html',
  styleUrls: ['./mgmtcategory.component.css']
})
export class MgmtcategoryComponent implements OnInit {

  category = new UntypedFormControl();
  categoryList: string[] = ['London', 'Turkie', 'Booking' ];
  selected: any;

  @Input() model: string;

  constructor(
    private http: HttpService,
  ) {
  
   }

  ngOnInit() {
    console.log('category', this.model);
    this.initCategory();
 
  }

  changeCategory(e){
    console.log(e.value);
    this.http.changeCategory(this.model, this.selected).subscribe(res=>{
      console.log(res);
    })
  }

  initCategory(){
    this.http.initCategory(this.model).subscribe(res=>{
      console.log('cateogryu',res);
      this.selected = res;
    })
  }







}
