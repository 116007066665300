import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../http.service';

import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';

@Component({
  selector: 'app-statsrepart',
  templateUrl: './statsrepart.component.html',
  styleUrls: ['./statsrepart.component.scss']
})
export class StatsrepartComponent implements OnInit {

  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}], yAxes: [{}] },
  };

  public barChartType: ChartType = 'bar';
  public barChartLegend = true;



  barChartLabels: any;
  barChartData: any;

  stats  : any;
  selecteddate: string;
  societe: string;

  constructor(
    private http: HttpService
  ) {
    this.selecteddate = '2021';
    this.societe = 'SEEMO MANAGEMENT LTD';
   }

  ngOnInit(): void {
    this.getStats();
  }

  SelecteDate(date){
    this.selecteddate = date;
    this.getStats();
  }

  SelecteEntreprise(societe){
    this.societe = societe;
    this.getStats();
  }

  

  async getStats() {
    var dataE = { 'year' : this.selecteddate, 'societe' : this.societe };
    var funcE = { type: 'STATS', func: 'getFacturationStatsMonthByMonth' };
    var Postdata = { func: funcE, data : dataE };
    let res = await this.http.goAcess(Postdata);
    console.log('res', res);
    this.stats = res;
    this.barChartLabels = res.barChartLabels;
    this.barChartData = res.barChartData;
  }
}
