import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { UntypedFormBuilder, Validators, UntypedFormGroup} from '@angular/forms';

import { HttpService } from '../../../http.service';

import { DialogData } from '../documents.component';

@Component({
  selector: 'app-add-documents',
  templateUrl: './add-documents.component.html',
  styleUrls: ['./add-documents.component.scss']
})
export class AddDocumentsComponent implements OnInit  {


  chgStateGroup : UntypedFormGroup;
  spinnerProgress:boolean;
  file : any;
  choice : any;

 // contactoption = [ {'value': 'test1'}, {'value': 'test2'}, {'value': 'test3'} ];


  constructor(
    public dialogRef: MatDialogRef<AddDocumentsComponent>,
    private fb: UntypedFormBuilder,
    private http: HttpService,
    @Inject(MAT_DIALOG_DATA) public contactoption: DialogData,
  ) {
    this.prepareForm();
   }

   ngOnInit() {
    this.choice = this.contactoption['category'];
  }


   onNoClick(): void {
    this.dialogRef.close('close');
  }

  uploadFile(){
    document.getElementById('file').click();
  }


  prepareForm(){
    this.chgStateGroup = this.fb.group({
      type: ['', Validators.required],
      name: ['', Validators.required],
      desc: ['', Validators.required],
    });
  }

  handleFileInput(){
    this.spinnerProgress = true;

    this.http.handleFileInput(
      this.chgStateGroup.get('type').value,
      this.chgStateGroup.get('name').value,
      this.chgStateGroup.get('desc').value,
      this.file
      ).subscribe( res => {
        console.log(res);
        this.spinnerProgress = false;
      this.dialogRef.close(res);
    },
    error => {
      console.log(error);
      this.spinnerProgress = false;
      this.dialogRef.close('error');
    }
  )
  }

  upload(elem){
    this.file = elem[0];
  }

}
