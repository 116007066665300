<h1 mat-dialog-title>Hi {{data.name}}</h1>
<div mat-dialog-content>
  <p>What's your  mail?, we will send you a link to reset your password</p>
  <mat-form-field>
    <input matInput>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button (click)="onNoClick()" cdkFocusInitial>Ok</button>
</div>
