import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-search-pannel',
  templateUrl: './search-pannel.component.html',
  styleUrls: ['./search-pannel.component.scss']
})
export class SearchPannelComponent implements OnInit {

  searchvalue: string;
  searchType: string;



  constructor(
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit(): void {
    console.log('inject', this.data);
    this.searchvalue = this.data['searchValue'];
    this.searchType = this.data['searchType'];
  }

}
