
<form [formGroup]="myGroup"  style="width:80%; margin-left:50%; transform: translate(-50%, 0);padding:5px;">


  
  <mat-form-field style="display:block" *ngIf="type !=='timepicker' && type !== 'number'  ">
    <input matInput [matDatepicker]="picker" [placeholder]="text" formControlName="datepaiement">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>

  <mat-form-field *ngIf=" type =='timepicker' ">
    <input matInput type="datetime-local" [placeholder]="text" formControlName="datepaiement">
  </mat-form-field>

  <mat-form-field *ngIf=" type == 'number' ">
    <input matInput [placeholder]="text" formControlName="datepaiement">
  </mat-form-field>




  <div mat-dialog-actions align="end">
     <button mat-button (click)="close()">Cancel</button>
     <button mat-button cdkFocusInitial (click)="editFacture()">Ok</button>
   </div>

</form>