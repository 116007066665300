<div   style="position:absolute !important;left:50%; transform: translate(-50%, 0);min-width: 800px;">
    <h2 style="text-align: center;">Gestion des utilisateurs</h2>
  <!--  <p style="text-align: center;">Ajouter vos frais avec Justificatif (TVA).</p>-->
  
    <table class="w3-table w3-striped w3-border" style="width:100%;margin-left:50%; transform: translate(-50%, 0); margin-bottom: 50px;" >
      <tr>
    
        <th>Login</th>
        <th>Last Connection</th>
        <th>Status</th>
        <th>Actions</th>
      </tr>
      <tr *ngFor="let x of users; let i = index;">

        <td>
          <div style="display: flex; flex-direction: row; justify-content: start; align-items: center;">
            <div style="width: 50px; height: 50px; border-radius: 50%; background-position: center center; background-size: cover; " [style.backgroundImage]="getAvatarBackground(x.user_avatar)" ></div>
            <div style="margin-left: 10px;">
              {{x.user_login}}
            </div>
          </div>
         
      
        </td>
        <td>{{ getRelativeTime(x.user_logdate) }}</td>
        <td>
          <mat-slide-toggle *ngIf="x.user_role < 2" [checked]="x.account_state == '1' " (change)="ToggleStatus($event,  x.user_login)"></mat-slide-toggle>
        </td>
        <td style="width:120px;" >

          <div *ngIf="x.user_role < 2">
            <i (click)="updateUsers('edit', x.user_login)" style="cursor: pointer;margin:5px;" class="material-icons">
              edit
              </i>
      
           <i (click)="delUsers(x.user_login)" style="cursor: pointer;margin:5px;" class="material-icons">
            delete_outline
            </i> 
          </div>


        </td>

      </tr>
  

  
    </table>
  
  
  
  </div>  
  <button class="fab" (click)="addUsers('add')" mat-fab color="primary">+</button>