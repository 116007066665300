<div style="position:relative;" >



  <mat-spinner class="centred"  *ngIf="spinnerProgress">
  </mat-spinner>


  <div *ngIf="!spinnerProgress">

    <button class="fab" (click)="sendModel()" mat-fab color="primary">+</button>


        <div class="event-card" *ngFor="let x of models; let i = index"
          [style.backgroundImage]="x.portrait ? 'url('+ x.portrait +')' : 'url(assets/girl_vide.jpg)' ">


          <mat-checkbox style="position:relative; top: 5px; left: 5px" (click)=addToSend(x.hash)></mat-checkbox>

          <div
          style="position:absolute;top:0;right:0;width:30px;height:30px;background-color:white;opacity:0.5;cursor:pointer"
          (click)="goLog(x.hash)">
          <img style="position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);" width="20px"
            src="assets/stats.png" />
        </div>


          <div style="position:absolute;bottom:0;height:30px;width:100%;background-color:black;opacity:0.5;">
            <span class="name">{{x.first}}</span>
          </div>



        </div>


  </div>
</div>
