<div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">

  <p style="text-align: center;">{{ nbrecollab }} Representations </p>

  <table *ngIf="dataSource" mat-table [dataSource]="dataSource" class="mat-elevation-z3"
    style=" width: 90%; margin: 5px;  margin-top: 20px; margin-bottom: 20px;;">

    <ng-container matColumnDef="Mannequin">
      <th mat-header-cell *matHeaderCellDef>
        <div style="display: flex; flex-direction: column; justify-content: space-evenly;">
          <div>
            Mannequin
          </div>
          <div (click)="useFilter('modelhash')" style="color: blue; text-decoration: underline; cursor: pointer;">
            Select Model
          </div>
        </div>
      </th>
      <td mat-cell *matCellDef="let x">
    

        <div class="avatar5" [style.backgroundImage]=" 'url('+ x.model +')'  "></div>    
      </td>


    </ng-container>

    <ng-container matColumnDef="Agence">
      <th mat-header-cell *matHeaderCellDef>
        <div style="display: flex; flex-direction: column; justify-content: space-evenly;">
          <div>
            Agence
          </div>
          <div (click)="useFilter('agencyhash')" style="color: blue; text-decoration: underline; cursor: pointer;">
            Select Model
          </div>
        </div>
      </th>
      <td mat-cell *matCellDef="let x">
    
          <div class="avatar5" [style.backgroundImage]=" 'url('+ x.agency +')'  "></div>
       
      </td>

    </ng-container>

    <ng-container matColumnDef="Début">
      <th mat-header-cell *matHeaderCellDef>Début</th>
      <td mat-cell *matCellDef="let x">{{x.debut}}</td>

    </ng-container>

    <ng-container matColumnDef="Fin">
      <th mat-header-cell *matHeaderCellDef>Fin</th>
      <td mat-cell *matCellDef="let x">
        <div *ngIf="x.end == null" class="setend" (click)="setEnd(x.id)">Set End</div>
        <div *ngIf="x.end !== null">
          {{ x.end }}
        </div>
      </td>

    </ng-container>

    <ng-container matColumnDef="Location">
      <th mat-header-cell *matHeaderCellDef>
        <div style="display: flex; flex-direction: column; justify-content: space-evenly;">
          <div>
            Location
          </div>
          <div (click)="useFilter('location')" style="color: blue; text-decoration: underline; cursor: pointer;">
            Select Location
          </div>
        </div>
      </th>
      <td mat-cell *matCellDef="let x">{{x.location}}</td>

    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>
        <div style="display: flex; flex-direction: column; justify-content: space-evenly;">
          <div>
            Send Email
          </div>
        </div>
      </th>
      <td mat-cell *matCellDef="let x" (click)="goRelance(x.id)" 
      style="text-decoration: blue; text-decoration: underline;cursor: pointer;color:blue"
      >Send Email</td>

    </ng-container>

    <ng-container matColumnDef="statement">
      <th mat-header-cell *matHeaderCellDef>
        <div style="display: flex; flex-direction: column; justify-content: space-evenly;">
          <div>
         Details
          </div>
        </div>
      </th>
      <td mat-cell *matCellDef="let x">
        <div *ngFor="let y of x.details">
          <div>
           Statement : {{ y.statement.start}} =>  {{ y.statement.end}}   Montant : {{ y.montant }} 
           <span (click)="seeFacture(y.facture_url)" >
            Facture : <span style="cursor: pointer; color: blue; text-decoration: underline;">{{ y.numfacture }}</span>
           </span>
          </div> 
 
         </div>
      </td>



    </ng-container>



    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>





</div>

