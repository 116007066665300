<div
  style="display:flex; flex-direction: column; height: 100%; width: 100%; justify-content: center;align-items: center;">


  <table *ngIf="representation" mat-table [dataSource]="representation" class="mat-elevation-z1"
    style=" width: 90%; margin: 5px;  margin-top: 20px;">

    <ng-container matColumnDef="agency">
      <th mat-header-cell *matHeaderCellDef>Agency</th>
      <td mat-cell *matCellDef="let x">
        <img [src]="x.agency" height="40px" />
      </td>
    </ng-container>

    <ng-container matColumnDef="start">
      <th mat-header-cell *matHeaderCellDef>Début de Rep.</th>
      <td mat-cell *matCellDef="let x">
        {{ x.start }}
      </td>
    </ng-container>

    <ng-container matColumnDef="end">
      <th mat-header-cell *matHeaderCellDef>Fin de Rep.</th>
      <td mat-cell *matCellDef="let x">
        <div *ngIf="x.end == null" class="setend" (click)="setEnd(x.collabid)">Set End</div>
        <div *ngIf="x.end !== null">
          {{ x.end }}
        </div>
    </ng-container>

    <ng-container matColumnDef="mac">
      <th mat-header-cell *matHeaderCellDef>M.C.A</th>
      <td mat-cell *matCellDef="let x">
        <i  (click)="downloadfile(x.agencycontract)" style="cursor: pointer" class="material-icons">
          get_app
          </i>
    </ng-container>

    <ng-container matColumnDef="location">
      <th mat-header-cell *matHeaderCellDef>Location</th>
      <td mat-cell *matCellDef="let x">
        {{ x.location }}
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef>Action</th>
      <td mat-cell *matCellDef="let x">
        <i (click)="delFile(x.collabid, 'representation')" style="cursor: pointer" class="material-icons">
          delete_outline
          </i>
    </ng-container>




    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  
  <table *ngIf="mac" mat-table [dataSource]="mac" class="mat-elevation-z1"
  style=" width: 70%; margin: 5px;  margin-top: 20px;">



  <ng-container matColumnDef="start">
    <th mat-header-cell *matHeaderCellDef>Début de Rep.</th>
    <td mat-cell *matCellDef="let y">
      {{ y.start }}
    </td>
  </ng-container>

  <ng-container matColumnDef="end">
    <th mat-header-cell *matHeaderCellDef>Fin de Rep.</th>
    <td mat-cell *matCellDef="let y">
      <div *ngIf="y.end == null" class="setend" (click)="setEnd(y.collabid)">Set End</div>
      <div *ngIf="y.end !== null">
        {{ y.end }}
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="modelagreement">
    <th mat-header-cell *matHeaderCellDef>Model Agree.</th>
    <td mat-cell *matCellDef="let y">
      <i  (click)="downloadfile(y.modelcontract)" style="cursor: pointer" class="material-icons">
        get_app
        </i>
  </ng-container>

  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef>Action</th>
    <td mat-cell *matCellDef="let y">
        <i (click)="delFile(y.collabid, 'mac')" style="cursor: pointer" class="material-icons">
          delete_outline
          </i>
  </ng-container>



  <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
</table>



  <div style="margin-top: 20px" *ngIf="!representation">
    <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;" >
      <div>
        <img src="../../../assets/client/agency.png" style="height: 50px" />
      </div>
      <div style="text-align: center;">
        No Representation
      </div>
    </div>

  </div>

  <div>
    <button class="fab" (click)="addReprensetation('add')" mat-fab color="primary" style="margin-top: 50px">+</button>
  </div>

</div>
