import { Component, OnInit,Input } from '@angular/core';
import { HttpService } from '../../../http.service';

@Component({
  selector: 'app-registration-stat',
  templateUrl: './registration-stat.component.html',
  styleUrls: ['./registration-stat.component.scss']
})
export class RegistrationStatComponent implements OnInit {

 
  spinnerProgress2: boolean;

  @Input() stats: any;
  
  constructor(
    private get: HttpService,
  ) { }

  async ngOnInit() {
 
    }



}
