
<div style=" height: 75vh; " *ngIf="loader">
  <mat-spinner class="centred" >
  </mat-spinner>
</div>



<div 
*ngIf="!loader"
style="display: flex; flex-direction: column; 
justify-items: center; align-items: center; width: 90%; margin-left: 50%; 
transform: translate(-50%, 0);
overflow: auto; height: 75vh; 
margin-bottom: 20px
"

>
    <div *ngIf="payments" style="width: 100%">
      <table  mat-table [dataSource]="payments" class="mat-elevation-z1"
      style=" width: 100%; margin: 5px;  margin-top: 20px;">

      <ng-container matColumnDef="client">
        <th mat-header-cell *matHeaderCellDef>Client</th>
        <td mat-cell *matCellDef="let x">
          <img [src]="x.agency_logo" height="40px" *ngIf="x.agency_logo"/>
          <div *ngIf="!x.agency_logo">{{x.agency_name}}</div>
        </td>
    </ng-container>

      <ng-container matColumnDef="numfac">
        <th mat-header-cell *matHeaderCellDef>Num Fac.</th>
        <td mat-cell *matCellDef="let x">
     {{x.numero_facture}}
        </td>
    </ng-container>

      <ng-container matColumnDef="note">
          <th mat-header-cell *matHeaderCellDef>Note</th>
          <td mat-cell *matCellDef="let x">
       {{x.note}}
          </td>
      </ng-container>

      <ng-container matColumnDef="montantht">
        <th mat-header-cell *matHeaderCellDef>Montant H.T</th>
        <td mat-cell *matCellDef="let x">
     {{x.montant_ht}}
        </td>
    </ng-container>

    <ng-container matColumnDef="inpocket">
      <th mat-header-cell *matHeaderCellDef>In Pocket</th>
      <td mat-cell *matCellDef="let x">
   {{x.in_pocket}}
      </td>
  </ng-container>

  <ng-container matColumnDef="marge">
    <th mat-header-cell *matHeaderCellDef>Marge</th>
    <td mat-cell *matCellDef="let x">
 {{ getMarge(x.montant_ht, x.in_pocket) }}
    </td>
</ng-container>


<ng-container matColumnDef="Paid On">
  <th mat-header-cell *matHeaderCellDef>Paid On</th>
  <td mat-cell *matCellDef="let x">
    <div *ngIf="x.paid_on">
      {{ getEasyDate(x.paid_on) }}
    </div>
   <div>
    <button *ngIf="!x.paid_on" mat-stroked-button style="margin-left: 10px">Set Paid Date</button>
   </div>

  </td>
</ng-container>




      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
  </table>

 
  <div>
    <button  mat-stroked-button (click)="downloadStatement('fr')" >Download Statement FR</button>
    <button  mat-stroked-button (click)="downloadStatement('uk')" >Download Statement UK</button>
    <button  mat-stroked-button (click)="downloadStatement('all')" >Download Statement ALL</button>
    <button  mat-stroked-button (click)="sendStatement()" >Send Statement</button>
  </div>


  <div style="display: flex;flex-direction: column;">
    <div>CA : <span style = "font-weight: 700;">{{ getFigures('ca', true) }} </span> </div>
    <div>In Pocket : <span style = "font-weight: 700;">{{ getFigures('pocket', true) }} </span> </div>
    <div>Marge : <span style = "font-weight: 700;">{{ getFigures('marge', true) }} </span> </div>
    </div>
  
  
    <div class="chart" *ngIf="getFigures('ca', false) > 0">
      <canvas baseChart
        [data]="pieChartData"
        [labels]="pieChartLabels"
        [chartType]="pieChartType"
        [legend]="pieChartLegend">
      </canvas>
    </div>
    
    </div>


    <div *ngIf="!payments" style="margin-top: 20px">
                <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                                <div>
                                    <img src="../../../assets/money.png"  style="height: 50px" />
                                </div>
                                <div style="text-align: center;">
                                  No Payment
                                </div>
                              </div>
    </div>


 

</div>

