import { Component, OnInit } from '@angular/core';
import {MatDialog } from '@angular/material/dialog';

import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ChoosemodelComponent } from '../facturesystem/gencontract/genmac/choosemodel/choosemodel.component';

import { HttpService } from '../http.service';
import { CountrylogpushComponent } from '../push-manager/push/countrylogpush/countrylogpush.component';

import { SmsandemailcompComponent } from './smsandemailcomp/smsandemailcomp.component';

import {  MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {  MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-fastpush',
  templateUrl: './fastpush.component.html',
  styleUrls: ['./fastpush.component.scss']
})
export class FastpushComponent implements OnInit {

  tosend : any;

  pictureChoice: string[] = ['Gallery', 'Polaroids', 'Videos'];

  checked: boolean;
  chgStateGroup: UntypedFormGroup;

  selectedmodels: any;
  longlink: any;
  shortlink : any;
  pictureChoiceSelected: any;

  smsContent: any;
  emailContent: any;
  emailSender : string = 'contact@seemodels.co';
  emailSubject : string = 'SEE MODELS : Showpackage';

  logs: any;
  spinner: boolean;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private http : HttpService,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<FastpushComponent>,
  ) {
    this.logs = [];
    this.spinner = false;
   }

  ngOnInit() {
    this.checked = true;
    this.createForm();
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  async searchForNumber(){
    if ( (this.chgStateGroup.get('clientmail').value == null) || (this.chgStateGroup.get('clientmail').value == '') ) {
      return;
    }
    
    console.log('search number from email');
    var funcE = { type: 'RAPPEL', func: 'getPhoneNumberFromEmail' };  
    var dataE = { 'email' : this.chgStateGroup.get('clientmail').value };

    var Postdata = { func: funcE, data : dataE };
 
    let res = await this.http.goAcess(Postdata);
    console.log('on click Postdata', Postdata, 'res', res);
    if(res.status == 'exist'){
      this.chgStateGroup.get('phonenumber').setValue(res.phone);
    }
  }


  createForm(){
    this.chgStateGroup = this._formBuilder.group({
      clientmail: ['', Validators.required],
      accesstoalbum : [false, Validators.required],
      accesstodocs : [false, Validators.required],
      polaroids : [true, Validators.required],
      videos : [true, Validators.required],
      log : [true, Validators.required],
      phonenumber: ['', Validators.required],
      menuType : ['1', Validators.required],
      setmeeting: [false, Validators.required],
   //   picturetype: [ ['Polaroids', 'Gallery, Videos'], Validators.required],
     galleryname: ['', Validators.required],
  //    share: [true, Validators.required],
    //  compcard: [true, Validators.required],
   //   download: [true, Validators.required],
   //   admin: [false, Validators.required],

    });

    this.pictureChoiceSelected = [this.pictureChoice[0], this.pictureChoice[1], this.pictureChoice[2]]
  }

  chooseModel(){
    const dialogRef = this.dialog.open(ChoosemodelComponent, {
      data: {
        selecttype: 'multiple', showpackage : true
      },
      panelClass: 'nopadding-box'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('choose model', result);
      if(result[0]['status'] == 'ok'){
        console.log('status ok');
       // this.prepareModelForm(result[0]['hash']);
       this.selectedmodels = result[0]['hash'];
      }
    });
  }

  async getFastLink(){
  
    let dataE = {
      'clientmail' : this.chgStateGroup.get('clientmail').value, 
      'albums' : this.chgStateGroup.get('accesstoalbum').value, 
      'docs' : this.chgStateGroup.get('accesstodocs').value, 
      'polaroids' : this.chgStateGroup.get('polaroids').value, 
      'videos' : this.chgStateGroup.get('videos').value,
      'galleryname' : this.chgStateGroup.get('galleryname').value,
      'log' : this.chgStateGroup.get('log').value,
     'menuType' : this.chgStateGroup.get('menuType').value,
     'setmeeting' : this.chgStateGroup.get('setmeeting').value,
      'shortlink' : 1,
      'selectedmodels' : this.selectedmodels,
    };

   
    var funcE = { type: 'MGMTTOKEN', func: 'generateToken' };  
    var Postdata = { func: funcE, data : dataE };
    console.log('on click Postdata', Postdata);
    let res = await this.http.goAcess(Postdata);
    console.log('on click', res);

    this.longlink = res['longlink'];
    this.shortlink = res['shortlink'];

    this.smsContent = await this.getFastLinkContent(this.shortlink,this.selectedmodels.length, 'sms' );
    this.emailContent = await  this.getFastLinkContent(this.shortlink,this.selectedmodels.length, 'email' );
  }

  goLink(link){
    window.open(link);
  }

  copyMessage(val: string){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.openSnackBar('Link have been copy','');
  }

  getClient(){
    this.tosend = [];
    let dialogRef = this.dialog.open(CountrylogpushComponent, {
      data: {country: 'France', model: 'aze'},
      panelClass: 'nopadding-box'
    });

    dialogRef.afterClosed().subscribe(result => {
        console.log('tosend', result);    
        this.chgStateGroup.get('clientmail').setValue(result[0]);
      });
   
  }

  updateSMSandEmailContent(){

    let dialogRef = this.dialog.open(SmsandemailcompComponent, {
      data: { smscontent: this.smsContent, htmlcontent: this.emailContent, emailsender : this.emailSender , emailsubject : this.emailSubject },
      height: 'auto',
      width: 'auto',
      panelClass: 'nopadding-box'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result.status == 'change'){
        this.smsContent = result.data.smcontent;
        this.emailContent = result.data.emailcontent;

        this.emailSender = result.data.emailsender;
        this.emailSubject = result.data.emailsubject;
      }
      });
  }
  


  async sendEmailGeneric(destinataireE, htmlContentE){



    var funcE = { type: 'RELANCE', func: 'sendRelanceEmail' };  
    var destinataires = [];
      destinataires[0] = destinataireE;

    var dataE = { 
      'to': destinataires,
      'subject': this.emailSubject, 
      'htmlContent' : await this.prepareContent(htmlContentE, 'email') ,
      'sender' : this.emailSender,
      'type': 'sendfastlink'
    };    


    var Postdata = { func: funcE, data : dataE };

    console.log('on click', Postdata);
    let res = await this.http.goAcess(Postdata);
    console.log('on click', res);
    
    let prepareLog = {'type' : 'email', 'destinataire' : destinataireE, 'status' : res.toUpperCase() };
    this.logs.push(prepareLog);
  }

  async sendSmsGeneric(DestinataireNumber, SmsContent){
    var funcE = { type: 'RELANCE', func: 'testSMS' };  
    var dataE = { 
      'destinataire': DestinataireNumber, 
      'content' :  await this.prepareContent(SmsContent, 'sms'),
    };    

    var Postdata = { func: funcE, data : dataE };
    let res = await this.http.goAcess(Postdata);
    console.log('on click', res);

    let prepareLog = {'type' : 'sms', 'destinataire' : DestinataireNumber, 'status' : res.toUpperCase() };
    this.logs.push(prepareLog);
  }

  async sendSMS(){
    this.spinner = true;
    await this.sendSmsGeneric( this.chgStateGroup.get('phonenumber').value, this.smsContent );
    this.spinner = false;
  }

  async sendEMAIL(){
    this.spinner = true;
    await this.sendEmailGeneric( this.chgStateGroup.get('clientmail').value, this.emailContent );
    this.spinner = false;
  }

  async sendSMSandEMAIL(){
    this.spinner = true;
    await this.sendSmsGeneric( this.chgStateGroup.get('phonenumber').value, this.smsContent );
    await  this.sendEmailGeneric( this.chgStateGroup.get('clientmail').value,  this.emailContent );
    this.spinner = false;
  }


  async getFastLinkContent(fastlink, nbreofmodel, type){
    var funcE;
    if(type == 'sms'){
      funcE = { type: 'RELANCE', func: 'getFastlinkSmsMessage' };  
    }

    if(type == 'email'){
      funcE = { type: 'RELANCE', func: 'getFastLinkEmailMessage' };  
    }
    
    
    var dataE = { 
      'fastlink': fastlink, 'nbreofmodel' : nbreofmodel
    };    
    var Postdata = { func: funcE, data : dataE };

   
    let res = await <unknown> this.http.goAcess(Postdata);
    console.log('on click', Postdata, 'smscontent', res);
    return res;
  }

  closeModal(){
    this.dialogRef.close();
  }

  async prepareContent(contentE, typeE){
    let email = await this.getNameFromEmail(this.chgStateGroup.get('clientmail').value);
    contentE = contentE.replaceAll('[Contact Name]', email.firstname);
    contentE = contentE.replaceAll('[FAST LINK]', this.shortlink);
    return contentE;
  }

  async getNameFromEmail(mail){
    var funcE = { type: 'MAIL', func: 'getNameFromEmail' };  
    var dataE = {  'mail' : mail };    
  
    var Postdata = { func: funcE, data : dataE };
  
    console.log('on click', Postdata);
    let res = await this.http.goAcess(Postdata);
    return res;
  }
  

  
}
