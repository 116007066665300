import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { UntypedFormBuilder, Validators, UntypedFormGroup} from '@angular/forms';
import { Data } from '../admin.component';
import { HttpService } from '../../http.service';
import { Md5 } from 'ts-md5/dist/md5';

@Component({
  selector: 'app-addusers',
  templateUrl: './addusers.component.html',
  styleUrls: ['./addusers.component.css']
})
export class AddusersComponent implements OnInit {

  myGroup: UntypedFormGroup;
  category: any;
  selectedvalue = [];
  action: string;
  pwd: any;

  constructor(
    public dialogRef: MatDialogRef<AddusersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Data,
   private http: HttpService,
   private fb: UntypedFormBuilder,

  ) {
    this.getCategory();
   }

  ngOnInit() {
    this.createForm();
    if(this.data['action'] == 'edit'){
      this.prepareForm(this.data['login']);
    }
  }


  close() : void {
      this.dialogRef.close();      
  }

  createForm(){
    this.myGroup = this.fb.group({
        first: ['', Validators.required],
        last: ['', Validators.required],
        login: ['', Validators.required],
        email: ['', Validators.required],
        password: ['', Validators.required],
        category: ['', Validators.required],

    });
  }

  async prepareForm(login){
   /* this.http.getOneUsers(this.data['id']).subscribe( res => {
    //  console.log(res);
    this.myGroup.get('first').setValue(res['data'].first);
    this.myGroup.get('last').setValue(res['data'].last);
    this.myGroup.get('email').setValue(res['data'].email);

    if(res['category']){
      for(var i=0; i<res['category'].length; i++)
      {
       this.selectedvalue.push(res['category'][i].value);
      } 
      this.myGroup.get('category').setValue(this.selectedvalue);
    }

    })*/

    var funcE = { 'type' : 'BDD', 'func' : 'getoneLineFromTable' };  
    var valuesE = [{ 'label': 'user_login',  'value' : login }];
    var values = { 'table' : 'adminusers', 'values' : valuesE };

    var Postdata = { func: funcE, data: values };
    console.log('values', Postdata);
    let res = await this.http.goAcess(Postdata);
    console.log('res', res);
    if(res){
      this.myGroup.get('first').setValue(res.user_first);
      this.myGroup.get('last').setValue(res.user_last);
      this.myGroup.get('email').setValue(res.user_email);
      this.myGroup.get('login').setValue(res.user_login);
    }
  }

  getCategory(){
    this.http.getCategory().subscribe(res=>{
  //    console.log(res);
      this.category = res;
    })
  }

  changeTO($event){
  //  console.log($event.value);
    this.selectedvalue = $event.value;
  }

  addUsers(){

    if(this.data['action'] == 'edit'){
      this.action = 'update';
    } else {
      this.action = 'adduser';
    }
    this.pwd = Md5.hashStr(this.myGroup.get('password').value);


    console.log('password', this.pwd);
    this.http.addUsers(
        this.myGroup.get('first').value, 
        this.myGroup.get('last').value, 
        this.myGroup.get('email').value,
        this.pwd,
        this.selectedvalue,
        this.action,
        this.data['id']
       ).subscribe( res => {
        console.log(res);
        if(res['status'] == 'ok' || res['status'] == 'updated'){
          this.dialogRef.close(res); 
        }
      })
}

}
