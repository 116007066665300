import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { HttpService } from '../http.service';
import { UntypedFormBuilder, Validators, UntypedFormGroup} from '@angular/forms';

import { CountrylogpushComponent } from '../push-manager/push/countrylogpush/countrylogpush.component';

@Component({
  selector: 'app-sendsms',
  templateUrl: './sendsms.component.html',
  styleUrls: ['./sendsms.component.scss']
})
export class SendsmsComponent implements OnInit {


  chgStateGroup: UntypedFormGroup;
  loading : boolean;
  showcongrats: boolean;
  btnTitle: string;

  constructor(
    public dialogRef: MatDialogRef<SendsmsComponent>,
    public dialog: MatDialog,
    private http : HttpService,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {

    this.showcongrats = false;
    this.loading = false;
    this.btnTitle = "Send SMS";
   }

   createForm(){
    this.chgStateGroup = this.fb.group({
      destinataire: ['', Validators.required],
      smscontent: ['', Validators.required],
      });
  }

  
  ngOnInit(): void {
    console.log('data', this.data);
    this.createForm();
    if(this.data['fastlink']){
      this.getFastLinkMessage(this.data['fastlink'], this.data['nbreofmodel']);
    }

    if(this.data['phone']){
      this.chgStateGroup.get('destinataire').setValue(this.data['phone']);
    }
 
  }

  async getFastLinkMessage(fastlink, nbreofmodel){
    var funcE = { type: 'RELANCE', func: 'getFastlinkSmsMessage' };  
    
    var dataE = { 
      'fastlink': fastlink, 'nbreofmodel' : nbreofmodel
    };    
    var Postdata = { func: funcE, data : dataE };

    console.log('on click', Postdata);
    let res = await this.http.goAcess(Postdata);
    console.log('on click', res);
    this.chgStateGroup.get('smscontent').setValue(res);
  }


  
  onNoClick(){
    this.dialogRef.close();
  }

  async onOkClick(){

    if(!this.loading){

      this.loading = true;
      this.btnTitle = "Please Wait";
      console.log('sms destinataire',  this.chgStateGroup.get('destinataire').value)
  
      var funcE = { type: 'RELANCE', func: 'testSMS' };  
      var dataE = { 
        'destinataire': this.chgStateGroup.get('destinataire').value, 
        'content' :  this.chgStateGroup.get('smscontent').value ,
      };    
  
      var Postdata = { func: funcE, data : dataE };
      let res = await this.http.goAcess(Postdata);
      console.log('on click', res);
      if(res == 'ok'){
        this.loading = false;
        this.btnTitle = "Send SMS";
        this.showcongrats = true;
      } else {
        this.btnTitle = "ERROR";
      }
    }


/*
    var funcE = { type: 'RELANCE', func: 'sendRelanceEmail' };  

    var destinataires = [];
    if( Array.isArray(this.chgStateGroup.get('destinataire').value) ){
      destinataires = this.chgStateGroup.get('destinataire').value;
    } else {
      destinataires[0] = this.chgStateGroup.get('destinataire').value;
    }
   
    var dataE = { 
      'to': destinataires,
      'subject': this.chgStateGroup.get('subject').value, 
      'htmlContent' : this.chgStateGroup.get('htmlContent').value ,
      'sender' : this.chgStateGroup.get('sender').value,
      'type': this.data['type'], 'typedata' : this.data
    };    
    var Postdata = { func: funcE, data : dataE };

    console.log('on click', Postdata);
    let res = await this.http.goAcess(Postdata);
    console.log('on click', res);
    if(res){
      this.dialogRef.close(res);
    }
*/
  }

  selectCountry1(){
    let dialogRef = this.dialog.open(CountrylogpushComponent, {
      data: {country: "France", model: 'aze', type : 'sms'},
      height: 'auto',
      width: 'auto',
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log('tosend', result);   
      this.chgStateGroup.get('destinataire').setValue(result);
    });
  }


}
