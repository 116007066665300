import { Component, OnInit, Input } from '@angular/core';
import {MatDialog } from '@angular/material/dialog';

import { HttpService } from '../../http.service';
import { CollaborationComponent } from '../../facturesystem/contract/addcollaboration.component';
import { SetcollabendComponent } from './setcollabend/setcollabend.component';

export interface collabData {
  action: string;
  model: string;
}

export interface collabData2 {
  collabid: string;
}




@Component({
  selector: 'app-modelrepresentation',
  templateUrl: './modelrepresentation.component.html',
  styleUrls: ['./modelrepresentation.component.scss']
})
export class ModelrepresentationComponent implements OnInit {

  @Input() hash: string;
  displayedColumns: string[] = [ 'agency', 'start', 'end', 'mac', 'location', 'action' ];
  displayedColumns2: string[] = ['start', 'end', 'modelagreement', 'action'];
  representation: any;
  mac : any;

  constructor(
    public dialog: MatDialog,
    private http: HttpService
  ) { }

  ngOnInit() {
    this.getRepresentation();
  }

  getRepresentation(){
    this.http.getMyRepresentation(this.hash).subscribe(res=>{
      console.log('get my rep', res);
      this.representation = res['representation'];
      this.mac = res['mac'];

    })
  }

  addReprensetation(todo): void {
   
    const dialogRef = this.dialog.open(CollaborationComponent, {  
       width: '550px',
      //  height: '500px',
      data: { action : todo, model : this.hash}
      });

  dialogRef.afterClosed().subscribe(result => {
    console.log('The dialog was closed', result);
    if(result == 'ok'){
      this.getRepresentation();
    }
  })
}

downloadfile(url){
  window.open(url);
}

setEnd(collabid){
  const dialogRef = this.dialog.open(SetcollabendComponent, {  
   data: { collabid : collabid }
   });

dialogRef.afterClosed().subscribe(result => {
 console.log('The dialog was closed', result);
 if(result == 'ok'){
   this.getRepresentation();
 }
})
}


async delFile(fileid, typeE){

  var funcE = { type: 'DOCS', func: 'deleteDoc' };   
  let dataE = { 'modal' : this.hash, 'fileid' : fileid, 'type' : typeE };   
  var Postdata = { func: funcE, data : dataE };
  let res = await this.http.goAcess(Postdata);
  console.log('res',res)
  if(res == 'delete'){
    this.getRepresentation();
  }


}





}
