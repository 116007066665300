import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup} from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { HttpService } from '../../../http.service';

//import { factureEdit } from '../../facturation/facturation.component';

@Component({
  selector: 'app-edit-facture',
  templateUrl: './edit-facture.component.html',
  styleUrls: ['./edit-facture.component.css']
})
export class EditFactureComponent implements OnInit {

  myGroup: UntypedFormGroup;
  text : string;
  type: string

  constructor(
    public dialogRef: MatDialogRef<EditFactureComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
   private http: HttpService,
   private fb: UntypedFormBuilder,
  ) {
    this.createForm();
   }

  ngOnInit() {
    console.log(this.data);
    this.text = this.data['text'];
    this.type = this.data['type'];
  }

  editFacture(){
    var res = { 'status' : 'set', 'date' : this.type == 'timepicker' ?  this.myGroup.get('datepaiement').value :  this.type == 'number' ? this.myGroup.get('datepaiement').value : this.myGroup.get('datepaiement').value.toISOString() };
    this.dialogRef.close(res);
  }

  close(){
    this.dialogRef.close();
  }

  createForm(){
    this.myGroup = this.fb.group({
      datepaiement: ['', Validators.required],
    });
  }


}
