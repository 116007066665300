import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup} from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { HttpService } from '../../../http.service';


@Component({
  selector: 'app-addcategory',
  templateUrl: './addcategory.component.html',
  styleUrls: ['./addcategory.component.scss']
})
export class AddcategoryComponent implements OnInit {


  chgStateGroup : UntypedFormGroup;


  constructor(
    public dialogRef: MatDialogRef<AddcategoryComponent>,
    private http : HttpService,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    console.log('transfert data', this.data);
    this.prepareForm();
  }
  

  
  prepareForm(){
    this.chgStateGroup = this.fb.group({
      catname: ['', Validators.required],
    });
  }

  createCatName(){
    this.http.createCatName(this.chgStateGroup.get('catname').value, this.data).subscribe(res=>{
      console.log(res);
      if(res == 'ok'){
        this.dialogRef.close('OK');
      }
    })
  }

}
