<div [formGroup]="chgStateGroup" style="width:90%;margin-top:15px;margin-left:50%; transform: translate(-50%, 0);">



    <mat-form-field class="example-full-width">
        <input type="text" placeholder="Type de Fichier" aria-label="Number" matInput formControlName="type"
            [matAutocomplete]="auto" required>
        <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let x of choice" [value]="x.doctype">
                {{x.doctype}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>





    <mat-form-field class="input">
        <input matInput placeholder="Nom du fichier" formControlName="name">
    </mat-form-field>

    <mat-form-field class="input">
        <input matInput placeholder="Description" formControlName="desc">
    </mat-form-field>

    <div style="margin-top:15px;margin-bottom: 15Px;">
        <button mat-raised-button color="primary" (click)="uploadFile()">Upload</button>
        <input type="file" id="file" style="display:none" (change)="upload($event.target.files)">
    </div>

    <button mat-raised-button color="primary" (click)="handleFileInput()">Ajouter</button>

</div>