import { Component, OnInit, Inject } from '@angular/core';

import {  MatDialogRef,  MAT_DIALOG_DATA} from '@angular/material/dialog';

import { DialogData } from '../../stats.component'



@Component({
  selector: 'app-usefilter',
  templateUrl: './usefilter.component.html',
  styleUrls: ['./usefilter.component.scss']
})
export class UsefilterComponent implements OnInit {

  _data : any;
  _type: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<UsefilterComponent>,
  ) { }

  ngOnInit() {
    console.log('data transfered', this.data);
    this._data = this.data['data'];
    this._type = this.data['type'];
    console.log('data transfered', this._data);
  }

  selectElem(elem){
    this.dialogRef.close(elem);
  }



}
