<form
  [formGroup]="chgStateGroup"
  style="
    width: 90%;
    margin-top: 15px;
    margin-left: 50%;
    transform: translate(-50%, 0);
  "
>
  <mat-form-field style="display: block">
    <input matInput placeholder="Client E-Mail" formControlName="clientmail" (focusout)="searchForNumber()" />
  </mat-form-field>

  <mat-form-field style="display: block">
    <input matInput placeholder="Phone Number" formControlName="phonenumber" />
  </mat-form-field>

  <div style="display: flex; flex-direction: row; justify-content: center">
    <div>
      <button mat-stroked-button (click)="getClient()">
        Get Client From DB
      </button>
    </div>
  </div>

  <div
    style="
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 20px;
    "
  >
    <div>
      <button mat-stroked-button (click)="chooseModel()">
        Select 1 Model
      </button>

    </div>
  </div>

  <div
    style="
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
    "
    *ngIf="selectedmodels"
  >
    <div>
      <button mat-stroked-button (click)="getFastLink()">Get Fast Meet Link</button>
    </div>

    <div
      *ngIf="shortlink"
      style="
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 20px;
      "
    >
      <div>
        <div style="display: flex; flex-direction: row">
          <div
            style="
              color: blue;
              text-decoration: underline;
              cursor: pointer;
              margin-right: 10px;
            "
            (click)="goLink(shortlink)"
          >
            {{ shortlink }}
          </div>
          <div (click)="copyMessage(shortlink)">
            <img
              src="../../assets/copy.png"
              style="cursor: pointer; height: 20px"
            />
          </div>
        </div>
        <div style="text-align: center">(click to open)</div>

      </div>

    </div>
    <div   style = "display: flex; flex-direction: row; justify-items: center;">
        <div (click)="updateSMSandEmailContent()" class="sendBtn">
            <div>
                <img src="../../assets/message.png" style="height: 50px" />
            </div>
            <div style = "text-align: center; font-size: 12px;">Set SMS / Email Content</div>
          
        </div>

    </div>

    <div >
      <div  *ngIf="!spinner" style="display: flex; flex-direction: flex-start; margin-top: 10px;">
        <button mat-button (click)="closeModal()" >CLOSE</button>
        <button mat-button cdkFocusInitial (click)="sendSMS()">Send SMS</button>
        <button mat-button  (click)="sendEMAIL()">Send E-Mail</button>
        <button mat-button  cdkFocusInitial (click)="sendSMSandEMAIL()">Send SMS + E-Mail</button>
      </div>
    </div>


    <div *ngIf="spinner">Loading...</div>

    <div *ngIf="logs.length > 0" style="width: 100%; margin-top: 20px;">
      <div style="display: flex; flex-direction: row; justify-content: space-between;" *ngFor="let x of logs">
        <div>
          <img src="../../assets/email.png" class="icon" *ngIf="x.type == 'email' " />
          <img src="../../assets/phone.png" class="icon" *ngIf="x.type == 'sms' " />
        </div>
        <div>{{ x.destinataire }}</div>
        <div>
          <img src="../../assets/checked.svg" class="icon" *ngIf="x.status == 'OK' " />
          <img src="../../assets/nochecked.svg" class="icon" *ngIf="x.status !== 'OK' " />
        </div>
      </div>
    </div>

  </div>
</form>


