<div *ngIf="boa" style="display: flex; flex-direction: column; justify-content: flex-start;">

    <div *ngFor="let x of boa" style="display: flex; flex-direction: row;" (click)="goSelect(x.model_hash, x.client_hash, x.montantht, x.annonceur)">

        <div class="avatarFace" [style.backgroundImage]="getImage(x.model_avatar)"
            [id]="x.hash">
            <div class="avatarName">{{x.first}}</div>
        </div>

        <div>
        -->
        </div>

        <div class="avatarFaceBorder avatarFace" [style.backgroundImage]="getImage(x.client_avatar)"
            [id]="x.hash">
            <div class="avatarName">{{x.first}}</div>
        </div>

    </div>
</div>