<mat-form-field style="display:block;margin-top:10px;">
    <mat-select placeholder="Destinataire" multiple (selectionChange)="changeTO($event)">

        <div *ngIf="this.data['type'] !== 'sms' ">
            <mat-option *ngFor="let y of contact" [value]="y.contact_mail">
                {{y.contact_firstname }} {{y.contact_lastname }} --  {{y.contact_mail}}
               </mat-option>
        </div>

        <div *ngIf="this.data['type'] == 'sms' ">
            <mat-option *ngFor="let y of contact" [value]="y.contact_phone">
                {{y.contact_firstname }} {{y.contact_lastname }} -- {{y.contact_phone}}
               </mat-option>
        </div>



    </mat-select>
  </mat-form-field> 

  <div mat-dialog-actions>
    <button mat-button (click)="addAllContact()">Add All Contact</button>
      <button mat-button (click)="addFeedback()">Add Feedback</button>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button (click)="onOkClick()" cdkFocusInitial>Ok</button>
</div>