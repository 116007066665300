import { Component, OnInit, Inject } from '@angular/core';
import {  MatDialogRef, MAT_DIALOG_DATA,  MatDialog } from '@angular/material/dialog';

//import { DialogData } from '../countrylogpush.component';
import { HttpService } from '../../../../http.service';

import { FeedbackComponent } from './feedback/feedback.component';


export interface DialogData {
  agency: string;
  model: string;
 }


@Component({
  selector: 'app-contacttosend',
  templateUrl: './contacttosend.component.html',
  styleUrls: ['./contacttosend.component.scss']
})
export class ContacttosendComponent implements OnInit {

  contact : any;
  tosend: any;

  constructor(
    public dialogRef: MatDialogRef<ContacttosendComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private http : HttpService,
    public dialog: MatDialog
  ) {

   }

  ngOnInit() {
    console.log('contact', this.data);
    this.getContact2(this.data['agency']);
  }

  getContact2(elem){

    var criteria = [
      { 'label' : 'agency_hash' , 'value' : elem },
    ];

    var postData = {  'table' : 'agency_contact','action' : 'getall', 'criteria' : criteria };

    this.http.goBackOffice(postData).subscribe(res=>{
      console.log('cotnact', res);
      this.contact = res;
    })
  }

  changeTO(agency){
     console.log('to changed', agency.value);
     this.tosend = agency.value;
   }

   onNoClick(){
     this.dialogRef.close('empty');
   }

   onOkClick(){
    this.dialogRef.close(this.tosend);
   }

   addFeedback(){
     console.log('add feedback');

     let dialogRef = this.dialog.open(FeedbackComponent, {
      height: 'auto',
      width: 'auto',
      data: { agency : this.data['agency'], model : this.data['model'], agencyavatar : this.data['avatar'] }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      console.log('result', result);
      if(result == 'log'){
        this.dialogRef.close(result);
      }
    });

   }

   addAllContact(){
     console.log('contact', this.contact);
     let newcontact = [];
     for(var i = 0; i < this.contact.length; i++){
      newcontact[i] = this.contact[i]['contact_mail'];
     }
     this.dialogRef.close(newcontact);
   }

}
