import { Component, OnInit, Input } from '@angular/core';
import { HttpService } from '../../../http.service';

import { PdfviewerComponent } from '../../../common/pdfviewer/pdfviewer.component'
import {  MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-doccategory',
  templateUrl: './doccategory.component.html',
  styleUrls: ['./doccategory.component.scss']
})
export class DoccategoryComponent implements OnInit {

  @Input() type: string;
  docs: any;
 

  constructor(
    private http : HttpService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this._getdocuments(this.type);
  }

  _getdocuments(elem){
    this.http.getdocuments(elem).subscribe(res=>{
      console.log('docs', res);
      this.docs = res;
    })
  }

  goUrl(url){
    window.open(url);
  }

  _deleteFile(fileid, index){
    console.log('id index', fileid, index);
    this.http.deleteFile(fileid).subscribe(res=>{
      console.log(res);
      if(res == 'delete'){
          this.docs.splice(index, 1);
      }
    })
  }

  viewFile(url, ext){
    let dialogRef = this.dialog.open(PdfviewerComponent, {
      height: '100%',
      width: '100%',
      data: { fileUrl : url, fileType : ext  }
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }


}
