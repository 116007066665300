import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpService } from '../http.service';

import {  MatDialog } from '@angular/material/dialog';

import { BookingdetailsComponent } from './bookingdetails/bookingdetails.component';

import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';

import { FullCalendarComponent } from '@fullcalendar/angular';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';

import { GobookComponent } from './gobook/gobook.component';


export interface DialogData {
  eventdetails: string;
}


@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.css'],

})
export class AgendaComponent implements OnInit {


  
events : any;
eventsBool: boolean;
modalOpen : boolean;

containerWidth  : any;
containerHeight: any;

  options: any;
  eventsData: any;
  calendarPlugins = [dayGridPlugin]; // important!
  @ViewChild('calendar', {static : true}) calendarComponent: FullCalendarComponent;
  @ViewChild('container', {static : true}) container: ElementRef;


  spinner : boolean = false;


  constructor(
    private http : HttpService,
    public dialog: MatDialog,
  ) {

    this.eventsBool = false;
    this.modalOpen= false;
   

   }

  ngOnInit() {
    this.getEvents();
    this.containerWidth = this.container.nativeElement.offsetWidth;
    this.containerHeight = this.container.nativeElement.offsetHeight;
    console.log('window width', this.containerHeight);
  }



  async getEvents(){
   
    this.spinner = true;
    console.log('events ask');
    await this.http.getEvents().subscribe(res=>{
      this.spinner = false;
      console.log('events',res);
      this.eventsBool = true;

      this.initiateCalendar(res);
      let calendarApi = this.calendarComponent.getApi();
      calendarApi.addEventSource(res);
  
    })
  }

  initiateCalendar(res){
    console.log('initiate',);
    this.calendarComponent.firstDay = 1;
    this.calendarComponent.businessHours = true;
    this.calendarComponent.locale = 'fr';
    this.calendarComponent.selectable = true;
 //   this.calendarComponent.editable = true;
    this.calendarComponent.nowIndicator = true;
    this.calendarComponent.events = res;

    if(this.containerWidth < 410){
      this.calendarComponent.contentHeight = this.containerHeight - 150;
    } else {
      this.calendarComponent.contentHeight = 750;
    }


    this.calendarComponent.minTime = '8:00:00';
    this.calendarComponent.maxTime = '22:30:00';
    
    this.calendarComponent.eventClick.subscribe(res => {
      console.log('event',res);
      if(!this.modalOpen){
        this.handleEvent(res.event.id);
      }
     
    })

    this.calendarComponent.select.subscribe(res => {
    //  this.createEvents(res);
    })


    this.options = {
      editable: true,
      firstHour : 8,
      plugins: [dayGridPlugin,  timeGridPlugin, interactionPlugin, listPlugin]
    };
 
  }

  createEvents(res = new Date() ){
    const dialogRef = this.dialog.open(GobookComponent, {
      data: {
        modal: 'none', event : res
      },
      panelClass: 'nopadding-box'
    });
  
    dialogRef.afterClosed().subscribe(result => {
           console.log('The dialog was closed', result);
           if(result == 'inserted'){
            this.refreshAgenda();

           }
    });
  }



  handleEvent(elem){
    console.log('eventid', elem);
    this.modalOpen = true;
    const dialogRef = this.dialog.open(BookingdetailsComponent, 
      {
        height: 'auto',
        width: 'auto',
        data: { eventdetails : elem },
        panelClass: 'nopadding-box'
      });
   
 dialogRef.afterClosed().subscribe(result => {
  this.modalOpen = false;
   if(result == 'update'){
     this.refreshAgenda();
   }
  })


  }

  refreshAgenda(){
    let calendarApi = this.calendarComponent.getApi();
    calendarApi.removeAllEventSources();
    this.getEvents();
  }
  



}
