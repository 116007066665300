<div style="position:relative; height:100%">


  <div class="fastActionCtn" >
   
 <!--   <div class="actions">
      <img src="assets/email.png" height="30px" (click)="SendEmail()" style="position:absolute; left:50%; top:50%; transform: translate(-50%, -50%)" />
    </div>-->

    <div class="actions removeOnMobile">
      <img src="assets/stats.png" height="30px" (click)="showLog()" style="position:absolute; left:50%; top:50%; transform: translate(-50%, -50%)" />
    </div>
    
    <div class="actions">
      <img src="assets/edit.png" height="30px" (click)="editAgency()" style="position:absolute; left:50%; top:50%; transform: translate(-50%, -50%)" />
    </div>
    
   <div class="actions removeOnMobile">
     <img src="assets/trash.png" height="30px" (click)="Delete()" style="position:absolute; left:50%; top:50%; transform: translate(-50%, -50%)" />
   </div>
 </div>


</div>