import { Component, Input, OnInit } from '@angular/core';

import { HttpService } from '../../../http.service';

import {MatDialog } from '@angular/material/dialog';
import { ModelOverviewComponent } from '../../../pictures/model-overview/model-overview.component';


@Component({
  selector: 'app-lastactions',
  templateUrl: './lastactions.component.html',
  styleUrls: ['./lastactions.component.scss']
})
export class LastactionsComponent implements OnInit {

  @Input() last : any;
  spinnerProgress : boolean;

  constructor(
    private get: HttpService,
    public dialog: MatDialog,
  ) {
    this.spinnerProgress = false;
   }

  ngOnInit(): void {
  
  }



goModel(elem: string): void {
  const dialogRef = this.dialog.open(ModelOverviewComponent, {
    data: {modal: elem}
  });

  dialogRef.afterClosed().subscribe(result => {
//     console.log('The dialog was closed');
  });
}



}
