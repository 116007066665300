import { Injectable } from '@angular/core';
import * as JSZip from 'jszip';
import * as moment from 'moment';
import JSZipUtils from "jszip-utils";

import { saveAs } from "file-saver";



@Injectable({
  providedIn: 'root'
})
export default class CommonFunc { 


  GetFilename(url)
{
  const pathname = new URL(url).pathname;
  const index = pathname.lastIndexOf('/');
  return (-1 !== index) ? pathname.substring(index + 1) : pathname;
}


async createZip(gallery, zipname) {
console.log("create ZIP", gallery, "albumname", zipname);
  var zip = new JSZip();

  var zip = zip.folder(zipname);
  for (var i = 0; i <  gallery.length; i++) {
    let url = gallery[i].url;
    let ext = gallery[i].ext;
    let name = gallery[i].name;

    let b64 = await this.urlToB64(url, ext);
    console.log('b64', b64);

    if(b64 !== null){
      zip.file( name + '.' + ext, b64, { base64: true } );
    }
      

    
  }

  zip.generateAsync({ type: "blob" }).then(function callback(blob) {
    saveAs(blob, zipname);

  });

}

_arrayBufferToBase64( buffer ) {
  var binary = '';
  var bytes = new Uint8Array( buffer );
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
      binary += String.fromCharCode( bytes[ i ] );
  }
  return window.btoa( binary );
}


async urlToB64(url, ext) {

  console.log('url', url, 'ext', ext);

  let result = await this.urlToPromiseAsync(url).then( res => {
    return res;
  })
  console.log('urlToB64', result);
  
  let res =  this._arrayBufferToBase64(result);
  /*
  let res;
  switch(ext){
    case "pdf":
     
    break;
  }
  */
  return res;
  
}



urlToPromiseAsync(url) {
  console.log("url", url);
 

    return new Promise( (resolve, reject) => {

      JSZipUtils.getBinaryContent(url, function (err, data) {
      
        if (err) {
         reject('null');
        } else {
       
        resolve(data)
        }
      });
  
    })
  

}

}
