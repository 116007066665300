import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router  } from '@angular/router';

import { HttpService } from '../http.service';

@Component({
  selector: 'app-facturesystem',
  templateUrl: './facturesystem.component.html',
  styleUrls: ['./facturesystem.component.scss']
})
export class FacturesystemComponent implements OnInit {

  paymenttodo: any;
  facturetodo: any;

  constructor(
    private router : Router,
    private r: ActivatedRoute,
    private http : HttpService
  ) { }

  ngOnInit() {
    this.getRappel();
    this.checkForRouteChange();
  }

  async getRappel(){
    var funcE = { type: 'RAPPEL', func: 'getPaymentsAndFactureRappel' };    
    var Postdata = { func: funcE };

    let res = await this.http.goAcess(Postdata);
    console.log('res', res);
    if(res){
     this.facturetodo = res.facturetodo;
      this.paymenttodo = res.paymenttodo;
    }


  }

  goFactures(elem){
    this.router.navigate(['facturation', elem ], { relativeTo: this.r})
  }

  goBoa(){
    this.router.navigate(['boa' ], { relativeTo: this.r})
  }

  goRepresentation(elem){
    this.router.navigate(['representation', elem ], { relativeTo: this.r})
  }

  goFrais(){
    this.router.navigate(['frais' ], { relativeTo: this.r})
  }

  goStats(){
    this.router.navigate(['statistiques' ], { relativeTo: this.r})
  }

  goRepartition(){
    this.router.navigate(['repartition' ], { relativeTo: this.r})
  }

  goContrats(){
    this.router.navigate(['gencontract' ], { relativeTo: this.r})
  }
  goPayments(elem){
    this.router.navigate(['payments', elem ], { relativeTo: this.r})
  }

  goFacTodo(){
    this.router.navigate(['rappel' ], { relativeTo: this.r})
  }
  
  goFDP(){
    this.router.navigate(['fdp' ], { relativeTo: this.r})
  }

  checkForRouteChange(){
    this.router.events.subscribe((val) => {
      // see also 
      if(window.innerWidth < 650){
        this.closeMobileMenuIfOpen();
      }
      //console.log("route changed",  val) ;
  })
}

MakeLogoAnim(){
  let navburger = document.getElementById('navTriggerFacture');

  if(navburger.classList.contains('active')) {
    navburger.classList.remove('active');
  } else {
    navburger.classList.add('active');
  }
}

  toggleMobileMenu(){
    
    let menu = document.getElementById('submenu');
   

    if(menu.classList.contains('subMenuMobileOpen') ) {
      menu.classList.remove('subMenuMobileOpen');
      this.MakeLogoAnim();
    } else {
      menu.classList.add('subMenuMobileOpen');
      this.MakeLogoAnim();
    }
  
  }

  closeMobileMenuIfOpen(){
    let menu = document.getElementById('submenu');
    if(menu.classList.contains('subMenuMobileOpen') ) {
      menu.classList.remove('subMenuMobileOpen');
      this.MakeLogoAnim();
    } 
  }


}
