
<div  class="allChatCtn">

<div class="messageCtn">


    <div style="min-width:100%;max-width: 100%;height:100%; overflow-y: auto;">
            <app-convers #convers [hash]="hash"></app-convers>
         
    </div>
    

</div>


</div>
