<div  *ngIf="payments" style ="display: flex; flex-direction: column; align-items: center; margin-top: 20px;">
  
  <div> Paid By Client</div>
  <table mat-table [dataSource]="payments" class="mat-elevation-z1"
  style="width: 90%; margin-left: auto; margin-right: auto; margin-top: 25px;"
  
  >

  <ng-container matColumnDef="model">
    <th mat-header-cell *matHeaderCellDef>Model</th>
    <td mat-cell *matCellDef="let x">
      <div  style="display: flex; flex-direction: row; justify-content: flex-start; ">
          <img [src]="x.user_avatar" height="40px" *ngIf="x.user_avatar"/>
      </div>
    </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="clients">
    <th mat-header-cell *matHeaderCellDef>Clients</th>
    <td mat-cell *matCellDef="let x">
        <div  style="display: flex; flex-direction: row; justify-content: flex-start; ">
            <img [src]="x.agency_logo" height="40px" *ngIf="x.agency_logo"/>
            <div *ngIf="!x.agency_logo">{{x.agency_name}}</div>
        </div>
    </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="montantht">
    <th mat-header-cell *matHeaderCellDef>Montant H.T</th>
    <td mat-cell *matCellDef="let x"> {{ x.montant_ht }}</td>
  </ng-container>

  <ng-container matColumnDef="topay">
    <th mat-header-cell *matHeaderCellDef>In Pocket</th>
    <td mat-cell *matCellDef="let x">
      <div *ngIf="x.in_pocket">{{ x.in_pocket }} </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="datepaiement">
    <th mat-header-cell *matHeaderCellDef>Date de Paiement Client</th>
    <td mat-cell *matCellDef="let x">
      <div class="setend" >{{ x.date_paiement }}</div>
    </td>
  </ng-container>

  <ng-container matColumnDef="Facture">
    <th mat-header-cell *matHeaderCellDef>Facture</th>
    <td mat-cell *matCellDef="let x">

      <div  style="text-decoration: underline; color: blue; cursor: pointer;" (click)="goFacture(x.facture_url)">
        {{ x.numero_facture }}
      </div>


    </td>
  </ng-container>

  <ng-container matColumnDef="Actions">
    <th mat-header-cell *matHeaderCellDef>Actions</th>
    <td mat-cell *matCellDef="let x">

      <div *ngIf="!x.paid_on">
        <button mat-stroked-button (click)="setNotConcerned(x.repartid, 'donotshow')">Not Concerned</button>
        <button mat-stroked-button style="margin-left: 10px" (click)="setDate(x.repartid)">Set Paid Date</button>
      </div>
   

    </td>
  </ng-container>


  <tr mat-header-row *matHeaderRowDef="displayedColumns ; sticky : true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

</div>


<div *ngIf="paymentsNotPaidByClient"  style ="display: flex; flex-direction: column; align-items: center; margin-top: 40px; margin-bottom: 20px;">

  <div>Not Paid By Client</div>

  <table  mat-table [dataSource]="paymentsNotPaidByClient" class="mat-elevation-z1"
  style="width: 90%; margin-left: auto; margin-right: auto; margin-top: 25px;"
  
  >

  <ng-container matColumnDef="model">
    <th mat-header-cell *matHeaderCellDef>Model</th>
    <td mat-cell *matCellDef="let x">
      <div  style="display: flex; flex-direction: row; justify-content: flex-start; ">
          <img [src]="x.user_avatar" height="40px" *ngIf="x.user_avatar"/>
      </div>
    </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="clients">
    <th mat-header-cell *matHeaderCellDef>Clients</th>
    <td mat-cell *matCellDef="let x">
        <div  style="display: flex; flex-direction: row; justify-content: flex-start; ">
            <img [src]="x.agency_logo" height="40px" *ngIf="x.agency_logo"/>
            <div *ngIf="!x.agency_logo">{{x.agency_name}}</div>
        </div>
    </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="montantht">
    <th mat-header-cell *matHeaderCellDef>Montant H.T</th>
    <td mat-cell *matCellDef="let x"> {{ x.montant_ht }}</td>
  </ng-container>

  <ng-container matColumnDef="topay">
    <th mat-header-cell *matHeaderCellDef>In Pocket</th>
    <td mat-cell *matCellDef="let x">
      <div *ngIf="x.in_pocket">{{ x.in_pocket }} </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="datepaiement">
    <th mat-header-cell *matHeaderCellDef>Date de Paiement Client</th>
    <td mat-cell *matCellDef="let x">
      <div class="setend" >{{ x.date_paiement }}</div>
    </td>
  </ng-container>

  <ng-container matColumnDef="Facture">
    <th mat-header-cell *matHeaderCellDef>Facture</th>
    <td mat-cell *matCellDef="let x">

      <div  style="text-decoration: underline; color: blue; cursor: pointer;" (click)="goFacture(x.facture_url)">
        {{ x.numero_facture }}
      </div>


    </td>
  </ng-container>

  <ng-container matColumnDef="Actions">
    <th mat-header-cell *matHeaderCellDef>Actions</th>
    <td mat-cell *matCellDef="let x">

      <div *ngIf="!x.paid_on">
        <button mat-stroked-button (click)="setNotConcerned(x.repartid, 'donotshow')">Not Concerned</button>
        <button mat-stroked-button style="margin-left: 10px" (click)="setDate(x.repartid)">Set Paid Date</button>
      </div>
   

    </td>
  </ng-container>


  <tr mat-header-row *matHeaderRowDef="displayedColumns ; sticky : true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

</div>



<div *ngIf="!payments">
  <div style="display: flex; flex-direction: column; justify-items: center; align-content: center; width: 100%; height: 100%; margin-top: 20px;">
    <div style="display: flex; flex-direction: row; justify-content: center;">
      <img  style="width: 320px;" src="../../../assets/ok.png" />
    </div>
    <div style="text-align: center; margin-top: 20px; font-size: 32px; font-family: 'Roboto', sans-serif;">
      You are up to date
    </div>
  
  </div>

</div>