

 

      
    
     <div>
      <input id="inputID" type="file" style="display:none;" (change)="readThis($event)" />
      <div style="margin-top: 20px; display: flex; flex-direction: column; align-items: center;" [formGroup]="myGroup">

        <div>
          <mat-form-field class="example-full-width" >
            <input matInput placeholder="File Name" formControlName="filename" required >
          </mat-form-field>
        </div>

        <div>
          <button class="fab" (click)="addDocs()" mat-fab color="primary">+</button>
        </div>

     
      </div>
    

     
    </div>
    
    