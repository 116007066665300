import { Component, OnInit, ViewChild,ElementRef } from '@angular/core';



@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent  {


  constructor(

    ) { }

  ngOnInit() {
    console.log('notif subscribe');
  }

 public  launch_toast(message) {
   console.log('lanch toast', message);
   document.getElementById('desc').innerHTML = message;
   document.getElementById('toast').classList.add('show');
    setTimeout(function(){
      document.getElementById('toast').classList.remove('show'); 
      }, 5000);
}

}
