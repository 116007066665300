import { Component, OnInit, EventEmitter } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';

import { UntypedFormBuilder, Validators, UntypedFormGroup} from '@angular/forms';
import { HttpService } from '../http.service';
import { MatSnackBar} from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';


export interface Feed {
  value: string;
  viewValue: string;
}



@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})


export class EventsComponent implements OnInit {


  myGroup: UntypedFormGroup;
  imageE: any;
  filetoUpload: any;
  spinnerProgress: boolean;
  htmlContent: any;
  date: any;
  dateIso: any;
  title: string;
  cate: string;
  bgImage: string;
  newsid: string;
  message: any;
  croppedImageFeed: any;
  croppedImageNews: any;


  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '50vh',
    minHeight: '50vh',
    placeholder: 'Enter text here...',
    translate: 'no',
    uploadUrl: 'v1/images', // if needed
    customClasses: [ // optional
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };

  constructor(
    private put: HttpService,
    private fb: UntypedFormBuilder,
    public snackBar: MatSnackBar,
    private _Activatedroute:ActivatedRoute,
    private get: HttpService,
    public dialog: MatDialog,
  // private imageCrop : ImageCrop
  ) {
this.createForm();
  this.bgImage = null;
  this.newsid = null;
   }

   feed: Feed[] = [
    {value: 'News', viewValue: 'News'},
    {value: 'Tips & Advice', viewValue: 'Tips & Advice'},
    {value: 'Model of the week', viewValue: 'Model of the week'},
    {value: 'Special Event', viewValue: 'Special Event'}
  ];

  ngOnInit() {
    this._Activatedroute.params.subscribe( params => {
      console.log('params',params.id);
      if(params){
        this.readNews(params.id)
      }
    })
  }

  createForm(){
    this.myGroup = this.fb.group({
      category: ['', Validators.required],
      title: ['', Validators.required],
      cover: ['', Validators.required],
      imgNews: ['', Validators.required],
      htmlContent: ['', Validators.required],
      date: ['', Validators.required],
    });
  }


  sendNews(){
    console.log('newsid',this.newsid);
    console.log('group value',this.myGroup.value);
    console.log(this.myGroup.get('date').value.toISOString());

    let input = new FormData();
    if(this.newsid == null){
      input.append('type','add');
      console.log('add');
    
    } else {
      input.append('type','update');
      input.append('eventid',this.newsid);
      console.log('update');
    }
   
    // This can be done a lot prettier; for example automatically assigning values by looping through `this.form.controls`, but we'll keep it as simple as possible here
    input.append('category', this.myGroup.get('category').value);
    input.append('title', this.myGroup.get('title').value);
    input.append('cover',   this.myGroup.get('cover').value);
    input.append('imgNews',   this.myGroup.get('imgNews').value);
    input.append('htmlContent', this.myGroup.get('htmlContent').value);
    input.append('date', this.myGroup.get('date').value.toISOString());

    this.spinnerProgress = true;
    this.put.putNews(input).subscribe( res => {
     this.spinnerProgress = false;
      this.message = res;
      this.openSnackBar('Congrats !', this.message)


    },
    error => {
      this.spinnerProgress = false;
      this.openSnackBar('Error!','')
    }
  )

  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }


  upImage(){
    document.getElementById("cover").click();
  }


    readNews(newsId){
    let postData = new FormData();
    postData.append('newsid', newsId);

    this.get.readNews(postData).subscribe ( res => {
      console.log(res);
      this.cate = res['category'];
      this.title = res['title'];
      this.htmlContent = res['contenu'];    
      this.bgImage = res['image'];
      this.newsid = res['id'];    
      this.date = res['date'];
    // this.dateIso = this.date.toISOString();   
    },
    error => {

    }
  )
  }


cropFeed(): void {
  const dialogRef = this.dialog.open(ImageCrop, {
    width: '500px',
    height: '500px',
    //data: {img: this.croppedImage}
  });

  dialogRef.afterClosed().subscribe(result => {
    console.log('The dialog was closed');
    this.croppedImageFeed = sessionStorage.getItem("imgtemp");
    this.myGroup.get('cover').setValue(this.croppedImageFeed);
    sessionStorage.removeItem("imgtemp");
  });
}

cropNews(): void {
  const dialogRef = this.dialog.open(ImageCrop2, {
    width: '500px',
    height: '500px',
  //  data: {img: this.croppedImage}
  });

  dialogRef.afterClosed().subscribe(result => {
    console.log('The dialog was closed');
    this.croppedImageNews = sessionStorage.getItem("imgtemp");
    this.myGroup.get('imgNews').setValue(this.croppedImageNews);
    sessionStorage.removeItem("imgtemp");
  });
}

}

@Component({
  selector: 'image-crop',
  templateUrl: 'image-crop.html',
})


export class ImageCrop {
  imageChangedEvent: any = '';
  croppedImage: any = '';
  getImage: any;

  constructor(
    public dialogRef: MatDialogRef<ImageCrop>,
   // @Inject(MAT_DIALOG_DATA) public data: DialogData,
    
  )
   {
 
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onOkClick() : void {
    console.log(this.croppedImage);
    sessionStorage.setItem("imgtemp", this.croppedImage);
    this.dialogRef.close();
  }

  
  fileChangeEvent(event: any): void {
      this.imageChangedEvent = event;
  }

  imageCropped(image: string) {
      this.croppedImage = image;
  }

  imageLoaded() {
      // show cropper
  }
  loadImageFailed() {
      // show message
  }
  

}


@Component({
  selector: 'image-crop2',
  templateUrl: 'image-crop2.html',
})


export class ImageCrop2 {
  imageChangedEvent: any = '';
  croppedImage: any = '';
  getImage: any;

  constructor(
    public dialogRef: MatDialogRef<ImageCrop>,
   // @Inject(MAT_DIALOG_DATA) public data: DialogData,
    
  )
   {
 
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onOkClick() : void {
    console.log(this.croppedImage);
    sessionStorage.setItem("imgtemp", this.croppedImage);
    this.dialogRef.close();
  }

  
  fileChangeEvent(event: any): void {
      this.imageChangedEvent = event;
  }

  imageCropped(image: string) {
      this.croppedImage = image;
  }

  imageLoaded() {
      // show cropper
  }
  loadImageFailed() {
      // show message
  }
  

}