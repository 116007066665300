import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { HttpService } from '../../../http.service';

import { DialogData } from '../push.component';

@Component({
  selector: 'app-pushlog',
  templateUrl: './pushlog.component.html',
  styleUrls: ['./pushlog.component.css']
})
export class PushlogComponent implements OnInit {

  in: any;
  notin: any;

  constructor(
    public dialogRef: MatDialogRef<PushlogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
   private http: HttpService,
  ) { }

  ngOnInit() {
    console.log(this.data);
    if(this.data['model']){
      this.getPushLog();
    }

    if(this.data['agency']){
      this.getPushLog2();
    }


  }

  getPushLog(){
    this.http.getPushLog(this.data['model'], 'getmodellog', 'France','agency').subscribe(res => {
      console.log(res);
      this.in = res['in'];
      this.notin = res['notin'];
    })
  }

  getPushLog2(){
    this.http.getPushLog(this.data['agency'], 'getagencylog', 'France', 'agency').subscribe(res => {
      console.log(res);
      this.in = res['in'];
      this.notin = res['notin'];
    })
  }

  getState(elem){

    if(elem['interest'] == 1 && elem['notinterest'] == 1){
      return 'dontknow';
    }

    if(elem['interest'] == 1){
      return 'interest';
    }

    if(elem['notinterest'] == 1){
      return 'notinterest';
    }

    if(elem['see'] == 1){
      return 'see';
    }

  }

}
