import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { HttpService } from '../../http.service';

import { DialogData } from '../showpersonal.component';
import { UntypedFormBuilder, Validators, UntypedFormGroup} from '@angular/forms';

import {  CategorytransfertComponent } from './categorytransfert/categorytransfert.component';

export interface DialogData2 {
  category: string;
  number : number;
}

@Component({
  selector: 'app-editcategory',
  templateUrl: './editcategory.component.html',
  styleUrls: ['./editcategory.component.scss']
})
export class EditcategoryComponent implements OnInit {

  category: string;
  categorytype: string;

  number : any;
  change : boolean;
  chgStateGroup : UntypedFormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<EditcategoryComponent>,
    private http : HttpService,
    private fb: UntypedFormBuilder,
    public dialog: MatDialog,
  ) {
    this.prepareForm();
   }

  ngOnInit() {
    console.log('inject category', this.data);
    this.category = this.data['category'];
    this.categorytype = this.data['categorytype'];
    this.getNumber();
  }

  prepareForm(){
    this.chgStateGroup = this.fb.group({
      newname: ['', Validators.required],
    });
  }

  getNumber(){
    this.http.getCategoryNumber(this.category,  this.categorytype).subscribe(res=>{
      console.log(res);
      if(res['status'] == 'ok'){
        this.number = res['nombre'];
      }
    })
  }

  changeName(){
    console.log('change name');
    this.change = true;
  }

  deleteCategory(){
    console.log('delete category');
    if(this.number > 0){
      this.openCategoryTransfert();
    } else {
      // delete Category
      this.goDelete();
    }
  }

  goDelete(){
    this.http.deleteModelCategory(this.category).subscribe(res=>{
      console.log(res);
      if(res['status'] == 'ok'){
        this.dialogRef.close('OK');
      } else {
        // Error
      }
    })
  }

  validNewName(){
    console.log('new name', this.chgStateGroup.get('newname').value, this.categorytype);
    this.http.changeCategoryName(this.category, this.chgStateGroup.get('newname').value,  this.categorytype).subscribe(res=>{
      console.log(res);
      if(res == 'ok'){
        this.dialogRef.close('OK');
      }
    })
  }

  openCategoryTransfert(){
    console.log('edit category', );
    const dialogRef = this.dialog.open(CategorytransfertComponent, {
      data: { category : this.category, nombre : this.number  }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if(result == 'OK'){
        this.goDelete();
      }
    });
  }

}
