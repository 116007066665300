

  <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;"> 
    <img [src]="details?.agencylogo" style="height: 80px; border-radius: 50%;" />
  </div>

<div [formGroup]="myGroup">


  <mat-form-field class="example-full-width">
    <input type="text" placeholder="Ask for" aria-label="Number" matInput formControlName="who"
       required>
  </mat-form-field>

  <mat-form-field class="example-full-width">
    <input matInput placeholder="Phone Number" value="" formControlName="phone" >
  </mat-form-field>



  <mat-form-field class="example-full-width">
    <input matInput placeholder="Adress" formControlName="where" required>
  </mat-form-field>

  <mat-form-field class="example-full-width">
    <input type="text" placeholder="More Details" aria-label="Number" matInput formControlName="more">
  </mat-form-field>

  <mat-form-field class=" example-full-half" style="margin-right: 10px;">
    <input matInput [selectMode]="'rangeFrom'" [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" placeholder="FROM ?"
    formControlName="from"   required>
    <owl-date-time #dt1 [firstDayOfWeek]="1"></owl-date-time>
  </mat-form-field>



  <mat-form-field class=" example-full-half">
    <input matInput [selectMode]="'rangeTo'" [owlDateTime]="dt2" [owlDateTimeTrigger]="dt2" placeholder="TO ?"
      formControlName="to" >
    <owl-date-time #dt2 [firstDayOfWeek]="1"></owl-date-time>
  </mat-form-field>

  <div>
<div style="text-align: center; font-weight: 700;">Participant will be informed by SMS & E-Mail.</div>
    <div style="position: relative;margin-top: 50px;
    margin-bottom: 20px;
    padding-bottom: 20px;">
      <div class="bTnStandard  centred" (click)="lastBook2()">{{bookstate}} </div>
    </div>


  </div>

</div>