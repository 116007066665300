import { Component, OnInit, Inject } from '@angular/core';
//import { DialogData } from '../push.component';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { HttpService } from '../../../http.service';

import { ContacttosendComponent } from './contacttosend/contacttosend.component';

import { DomSanitizer  } from '@angular/platform-browser';

import { ChoosemodelComponent } from '../../../facturesystem/gencontract/genmac/choosemodel/choosemodel.component';

export interface DialogData {
  contact: string;
 }


@Component({
  selector: 'app-countrylogpush',
  templateUrl: './countrylogpush.component.html',
  styleUrls: ['./countrylogpush.component.scss']
})
export class CountrylogpushComponent implements OnInit {

  in: any;
  notin: any;
  tosend = [];

  type: any;
countrylist:any;
  selectedtype: string;

  clienttype : boolean;
  country: boolean;
  contacts : boolean;
  loading : boolean;

  agency: any;

  constructor(
    public dialogRef: MatDialogRef<CountrylogpushComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private http : HttpService,
    public dialog: MatDialog,
    private sanitization: DomSanitizer
  ) {
    this.clienttype = true;
    this.country = false;
    this.contacts = false;
    this.loading = false;
   }

  ngOnInit() {
    console.log('transfer', this.data['country']);
   // this.getPushLog(this.data['model']);
   this.getClientType();
  }



  getClientType(){
    this.http.getClientType().subscribe(res=>{
      this.type = res;
    })
  }

  selectClientType(type){
    console.log(type);
    this.selectedtype = type;
    if(type == 'models'){
this.selectModel();
    } else {
      this.selectCountry(type);
    }
  
    
  }

  selectModel(){
    let dialogRef = this.dialog.open(ChoosemodelComponent, {
      height: 'auto',
      width: 'auto',
      data: {
        selecttype: 'multiple'
      }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log('result', result);
      if(result[0]['status'] == 'ok'){
        this.getEmailFromHash(result[0]['hash'])
      }
        });
  }


  getEmailFromHash(hash){
    this.http.getEmailFromHash(hash).subscribe(res=>{
      console.log('email from hash', res);
      this.dialogRef.close(res);
    })
  }

  selectCountry(type){
  
    this.clienttype = false; 
    this.http.selectCountry(type).subscribe( res => {
     this.countrylist = res;
     this.country = true;
     console.log('country', this.countrylist);
    })
  }

  showContacts(country){
    this.country = false;
    this.contacts = true;
    this.getPushLog(this.data['model'], [country] );
  }

  getPushLog(model, country){
    console.log('model country seletec', model , country, this.selectedtype  );
    this.http.getPushLog(model, 'getmodellog',  country, this.selectedtype ).subscribe(res => {
      console.log(res);
      this.in = res['in'];
      this.notin = this.sortByLetter(res['notin']);
      console.log("res", this.notin);
    })
  }

  addToSelection(hash, agencyavatar){
    console.log('add to selection', hash);
    let dialogRef = this.dialog.open(ContacttosendComponent, {
      height: 'auto',
      width: 'auto',
      data: { agency : hash, model : this.data['model'], avatar : agencyavatar, 'type' : this.data['type'] }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      console.log('result', result);
      if(result !== 'empty'){
    
          for(var i = 0; i < result.length; i++){
            this.tosend.push(result[i]);
          }
       
 
        document.getElementById(hash).classList.add('selected');
      }

    if(result == 'log'){
      this.dialogRef.close(result);
    }
    });


  }

  getState(elem){

    if(elem['interest'] == 1 && elem['notinterest'] == 1){
      return 'dontknow';
    }

    if(elem['interest'] == 1){
      return 'interest';
    }

    if(elem['notinterest'] == 1){
      return 'notinterest';
    }

    if(elem['see'] == 1){
      return 'see';
    }

  }

  onNoClick(){
    this.dialogRef.close('empty');
  }

  
  onOkClick(){
    this.dialogRef.close(this.tosend);
  }

  getUrl(data){
    return this.sanitization.bypassSecurityTrustStyle(`url(${data})`);
  }

  async goSearch(valueE){
    this.clienttype = false; 
    this.country = false;
    this.contacts = true;
    this.loading = true;
    this.notin = [];
    var funcE = { type: 'SEARCH', func: 'searchClient' };  
    var dataE = { 'firstorlast' : valueE, 'type' : this.selectedtype };
    var Postdata = { func: funcE, data : dataE };
    console.log('Postdata', Postdata);

    let res = await this.http.goAcess(Postdata);
    console.log('res', res);
    this.loading = false;
   
   // this.agency = this.sortByLetter(res);
   this.notin = this.sortByLetter(this.reOrder(res));
   console.log('notin',  this.notin );
  }

  reOrder(_data){
    let temp = [];
    for(var i = 0; i < _data.length; i++){
      var temporary;
      temporary = {'name' : _data[i].agency_name, 'agencyhash' : _data[i].agency_logo, 'hash' : _data[i].agency_hash };


      temp[i] = temporary;
    }
    return temp;
  }

  sortByLetter(dataE){
    var letters = 'abcdefghijklmnopqrstuvwxyz';

    var temp = [];

    for(var i = 0; i < letters.length; i++){
      var letter = letters[i];
 

      var tmp = [];
     
      for(var j = 0; j < dataE.length; j++){
    //    console.log('letter', letter, 'data', dataE[j].first.toLowerCase().charAt(0))
        if(dataE[j].name.toLowerCase().charAt(0) == letter){

            tmp.push(dataE[j]);
          
        }
        dataE.slice(j, 1);
      }
      if(tmp.length > 0){
        temp[i] = {'letter': letter, 'data' : tmp };
      }
      
    }

    var filtered = temp.filter(function (el) {
      return el != null;
    });
   // console.log('filtered', filtered);
    return filtered;
  }


}
