
<form [formGroup]="myGroup"  style="width:80%; margin-left:50%; transform: translate(-50%, 0);padding:5px;">


    <mat-form-field style="display:block">
      <input matInput [matDatepicker]="picker" placeholder="End Date" formControlName="enddate">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  
  
    <div mat-dialog-actions align="end">
       <button mat-button (click)="close()">Cancel</button>
       <button mat-button cdkFocusInitial (click)="editEnd()">Ok</button>
     </div>
  
  </form>