import { Component, OnInit, Input } from '@angular/core';
import { HttpService } from '../http.service';

import { Router, ActivatedRoute } from "@angular/router";
import { MatDialog  } from '@angular/material/dialog';
import { ModelOverviewComponent } from '../pictures/model-overview/model-overview.component';



export interface DialogData {
  model: string;
}


@Component({
  selector: 'app-approved',
  templateUrl: './approved.component.html',
  styleUrls: ['./approved.component.css']
})
export class ApprovedComponent implements OnInit {

  @Input() type: string;
  @Input() gender: string;
  @Input() searchValue: any;
  

  models: any;
  modelsToShow: any;

  title: string;
  modelInfos: string;
  spinnerProgress: boolean;
  tabLoadTimes: Date[] = [];
  measures: any;
  nombre: any;
  //gender: string;

    constructor(
    private get: HttpService,
    private router: Router,
    public route: ActivatedRoute,
    public dialog: MatDialog,
   // public dialogRef: MatDialogRef<ApprovedComponent>,

      )
   {
    this.title = 'Model';
0

  }

  ngOnInit() {


    if( (this.gender == null) && (this.type !== 'search') ){
      this.gender = 'female';
      this.getmodel(this.type, this.gender);
    }

    if(this.type == 'search'){
      this.goSearch(this.searchValue);
    }

    
  }

  closeModal(){
   // this.dialogRef.close();
   }


  getmodel(type, gender){
    this.models = [];
    this.spinnerProgress = true;
 //   console.log('tpye, gender', type , gender);
    this.get.getModel(type, gender).subscribe( res => {
      console.log('res', res);
      this.spinnerProgress = false;
      if (this.models != 'null'){
        this.models = this.sortByLetter(res['modal']);
        this.modelsToShow = this.models;
      //  console.log( 'resultat', this.models);
      this.nombre = res['modal'].length;
      } else {
     //   console.log('null');
      }

    })
  } 

  getDetails($event,index, index2) {
    let detail = 'details'.concat(index).concat(index2);
   // console.log(detail);
    if ($event.type == 'mouseover'){
      document.getElementById(detail).classList.add('reveal');
    } else {
      document.getElementById(detail).classList.remove('reveal');
    }
   
  }
  


  UpdateGender(elem){
   // console.log(elem);
    this.gender = elem;
    this.getmodel(this.type, this.gender);
  }

  goModel(elem: string): void {
    console.log("do model");
    const dialogRef = this.dialog.open(ModelOverviewComponent, {
      data: {modal: elem},
      panelClass: 'nopadding-box'
    });
  
    dialogRef.afterClosed().subscribe(result => {
   //   console.log('The dialog was closed');
    });
  }

 

  sortByLetter(dataE){
    var letters = 'abcdefghijklmnopqrstuvwxyz';

    var temp = [];

    for(var i = 0; i < letters.length; i++){
      var letter = letters[i];
 

      var tmp = [];
      for(var j = 0; j < dataE.length; j++){
    //    console.log('letter', letter, 'data', dataE[j].first.toLowerCase().charAt(0))
        if(dataE[j].first.toLowerCase().charAt(0) == letter){
            tmp.push(dataE[j]);
          
        }
        dataE.slice(j, 1);
      }
      if(tmp.length > 0){
        temp[i] = {'letter': letter, 'data' : tmp };
      }
      

    }
  
    

    var filtered = temp.filter(function (el) {
      return el != null;
    });
   // console.log('filtered', filtered);
    return filtered;
  }

  async goSearch(valueE){
    this.spinnerProgress = true;
    console.log(valueE);
       var  funcE = { type: 'SEARCH', func: 'searchModels' };  

    var dataE = { 'firstorlast' : valueE };
    var Postdata = { func: funcE, data : dataE };
    console.log('Postdata', Postdata);

    let res = await this.get.goAcess(Postdata);
    this.spinnerProgress = false;
    this.models = this.sortByLetter(res['modal']);
    this.modelsToShow = this.models;
  //  console.log( 'resultat', this.models);
   this.nombre = res['modal'].length;
  
  }


}
