import { Component, OnInit, Input } from '@angular/core';
import { HttpService } from '../../http.service';


@Component({
  selector: 'app-agencylog',
  templateUrl: './agencylog.component.html',
  styleUrls: ['./agencylog.component.scss']
})
export class AgencylogComponent implements OnInit {

  @Input() agency: string;
  displayedColumns: string[] = [ 'contact', 'who', 'what', 'date' ];
  logs : any;

  constructor(
    private get: HttpService,
  ) { }

  ngOnInit() {
    this.getData();
  }

  getData(){
    var criteria = [
      { 'label' : 'agency_hash' , 'value' : this.agency },
    ];

    var switchvalue = [
      { 'label' : 'model_hash' , 'type' : 'model' }
    ];

    var postData = {  'table' : 'agency_send_log', 'action' : 'getall', 'criteria' : criteria, 'switchvalue' : switchvalue} ;

    this.get.goBackOffice(postData).subscribe(res => {
      console.log('res', res);
      this.logs = res;
    })
  }

  filterType(elem){
    console.log('filter');
  }


}
