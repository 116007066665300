
<form [formGroup]="myGroup"  style="width:80%; margin-left:50%; transform: translate(-50%, 0);padding:5px;">

    <mat-form-field>
        <input matInput [matDatepicker]="picker" placeholder="date" formControlName="date">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>


    <input id="inputID" type="file" style="display:none;" (change)="readThis($event.target.files)" />
    <mat-form-field style="display:inline-block;width:100%;">
       <mat-select placeholder="Type de Frais" formControlName="typefrais" >
         <mat-option *ngFor="let x of category" [value]="x.view">
           {{x.value}}
         </mat-option>
       </mat-select>
     </mat-form-field>
   
     <mat-form-field style="display:inline-block;width:100%;">
       <textarea cdkTextareaAutosize matInput  placeholder="observations" formControlName="observations" ></textarea>
     </mat-form-field>
   
   
     <mat-form-field style="display:inline-block;width:100%;">
        <textarea cdkTextareaAutosize matInput  placeholder="Montant H.T" formControlName="montantht" ></textarea>
       </mat-form-field>

       <mat-form-field style="display:inline-block;width:100%;">
        <textarea cdkTextareaAutosize matInput  placeholder="Montant T.T.C" formControlName="montantttc" ></textarea>
       </mat-form-field>

       <mat-form-field style="display:inline-block;width:100%;">
        <mat-select placeholder="Societe" formControlName="societe" >
          <mat-option *ngFor="let x of societe" [value]="x.id">
            {{x.nom}}
          </mat-option>
        </mat-select>
      </mat-form-field>
   
      <button *ngIf="action == 'add'" style="margin:10px" mat-raised-button color="primary" (click)="addJustif()">Justificatif</button>
     <!--  <button style="margin:10px" mat-raised-button color="primary" (click)="onNoClick()">Cancel</button>-->
     <button *ngIf="action == 'add'" style="margin:10px" mat-raised-button color="primary" (click)="addFrais()">Save Frais</button>
     <button *ngIf="action == 'edit'" style="margin:10px" mat-raised-button color="primary" (click)="editFrais()">Edit Frais</button>

   
 </form>