<div style="height: calc(100vh - 100px);width:100%;">


    <div style="display:flex; flex-wrap: wrap;">

        <div *ngFor="let x of docs; let i = index;" class="item" [id]="x.id" [attr.data-index]="i">
            <div (click)="_deleteFile(x.id, i)"
                style="cursor:pointer;position:absolute;top:0;left:0;width: 30px; height: 30px;background-color: black;"
               >
                <div style="position: relative; width: 100%; height: 100%">
                    <img src="assets/delete.png" height="20px"
                        style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)" />
                </div>
            </div>

            <div (click)="viewFile(x.url, x.ext)"
            style="cursor:pointer;position:absolute;top:0;right:0;width: 30px; height: 30px;background-color: black;"
           >
            <div style="position: relative; width: 100%; height: 100%">
                <img src="assets/eye_white.png" height="20px"
                    style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)" />
            </div>
        </div>

            <div
                style="display:flex; flex-direction:row;  align-items: center;  justify-content: space-between; width: 100%">

                <div style="margin:5px;">
                    <img *ngIf="x.ext == 'pdf' " src="assets/pdf.svg" height="50px" />
                    <img *ngIf="x.ext == 'docx' || x.ext == 'doc' " src="assets/word.svg" height="50px" />
                    <img *ngIf="x.ext == 'txt' " src="assets/word.svg" height="50px" />
                    <img *ngIf="x.ext == 'pptx' || x.ext == 'ppt' " src="assets/powerpoint.svg" height="50px" />
                    <img *ngIf="x.ext == 'xlsx' || x.ext == 'xls'  " src="assets/excel.svg" height="50px" />
                </div>

                <div style="margin:5px;">
                    <span style="display: block">{{x.title}}</span>
                    <span style="display: block">{{x.subtitle}}</span>
                </div>

                <div style="cursor:pointer" (click)="goUrl(x.url)">
                    <img src="assets/download.svg" height="50px" />
                </div>


            </div>

        </div>

    </div>





</div>
