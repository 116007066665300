

 

   
      <div style="display:flex; flex-direction: column; height: 100%; width: 100%; justify-content: center;align-items: center;">

        
    <table *ngIf="docs"  mat-table [dataSource]="docs" class="mat-elevation-z1" style=" width: 90%; margin: 5px;  margin-top: 20px;">
      <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->
      
        <!-- Position Column -->
        <ng-container matColumnDef="Name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let x">{{x.name}} </td>
        </ng-container>
      
        <!-- Name Column -->
        <ng-container matColumnDef="Type">
          <th mat-header-cell *matHeaderCellDef>Type</th>
          <td mat-cell *matCellDef="let x">{{x.docname}} </td>
        </ng-container>
      
        <!-- Weight Column -->
        <ng-container matColumnDef="Date">
          <th mat-header-cell *matHeaderCellDef>Date</th>
          <td mat-cell *matCellDef="let x">{{x.date}} </td>
        </ng-container>
      
        <!-- Symbol Column -->
        <ng-container matColumnDef="Delete">
          <th mat-header-cell *matHeaderCellDef>Delete</th>
          <td mat-cell *matCellDef="let x">  
             <i (click)="delFile(x.fileid)" style="cursor: pointer" class="material-icons">
            delete_outline
            </i> </td>
        </ng-container>
      
        <ng-container matColumnDef="Download">
          <th mat-header-cell *matHeaderCellDef>Action</th>
          <td mat-cell *matCellDef="let x">
              <i  (click)="downloadfile(x.url)" style="cursor: pointer" class="material-icons">
                  get_app
                  </i>

                  <i  (click)="viewFile(x.url, x.ext)" style="cursor: pointer" class="material-icons">
                    remove_red_eye
                    </i>
           </td>
 
        
        </ng-container>

        <ng-container matColumnDef="Public">
          <th mat-header-cell *matHeaderCellDef>Public</th>
          <td mat-cell *matCellDef="let x">
            <mat-slide-toggle  [checked]="x.public == 1" (change)="changeDocPublic(x.fileid, checked)"></mat-slide-toggle>
           </td>
        
        
        </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
  


  <div style="margin-top: 20px"  *ngIf="!docs">
    <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
      <div>
          <img src="../../../assets/box.png"  style="height: 50px" />
      </div>
      <div style="text-align: center;">
        No Files
      </div>
    </div>
 
  </div>

  <div>
    <button class="fab" (click)="addDocs()" mat-fab color="primary">+</button>
  </div>
 
</div>

