import { Component, OnInit, ViewChild, Input } from '@angular/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';

import { FullCalendarComponent } from '@fullcalendar/angular';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';

import { HttpService } from '../../http.service';
import { GobookComponent } from '../../agenda/gobook/gobook.component';
import {MatDialog } from '@angular/material/dialog';

import { BookingdetailsComponent } from '../../agenda/bookingdetails/bookingdetails.component';


@Component({
  selector: 'app-modelagenda',
  templateUrl: './modelagenda.component.html',
  styleUrls: ['./modelagenda.component.scss']
})
export class ModelagendaComponent implements OnInit {

  @Input() hash: string;
  @Input() avatar: string;

  options: any;
  eventsData: any;
  calendarPlugins = [dayGridPlugin]; // important!
  @ViewChild('calendar', {static : true}) calendarComponent: FullCalendarComponent;


  constructor(
    private http : HttpService,
    public dialog: MatDialog,
  ) {
   
   }

  async ngOnInit() {
    await this.getMyEvents();
  
  }



  initiateCalendar(){
    console.log('initiate', this.hash, this.avatar);

    this.calendarComponent.firstDay = 1;
    this.calendarComponent.businessHours = true;
    this.calendarComponent.locale = 'fr';
    this.calendarComponent.selectable = true;
    this.calendarComponent.editable = true;
    this.calendarComponent.nowIndicator = true;
    //this.calendarComponent.eventSources = this.eventsData;
    this.calendarComponent.minTime = '8:00:00';
    this.calendarComponent.maxTime = '22:30:00';
    this.calendarComponent.eventClick.subscribe(res => {
      this.getEventDetails(res);
    });


    this.options = {
      editable: true,
      plugins: [dayGridPlugin,  timeGridPlugin, interactionPlugin, listPlugin]
    };

    this.calendarComponent.select.subscribe(res => {
      this.createEvents(res);
    })

 
  }

  async getMyEvents(){
    console.log('events ask');
    await this.http.getMyEvents(this.hash).subscribe(res=>{
      console.log('events',res);

      this.calendarComponent.events = res;
      this.initiateCalendar();
      let calendarApi = this.calendarComponent.getApi();
      calendarApi.addEventSource(res);
      console.log('events received');
    })
  }

  getEventDetails(res){
    const dialogRef = this.dialog.open(BookingdetailsComponent, 
      {
        height: 'auto',
        width: 'auto',
        data: { eventdetails : res.event.id }
      });;
  
    dialogRef.afterClosed().subscribe(result => {
           console.log('The dialog was closed', result);
           if(result == 'ok'){
            this.refreshAgenda();
           }
    });
  }

  createEvents(res){
    console.log('create event',    'modal', this.hash, 'event' , res, 'avatar:', this.avatar);
    const dialogRef = this.dialog.open(GobookComponent, {
      data: {
        modal: { 'hash' : this.hash } , event : res, avatar: this.avatar
      }
    });
  
    dialogRef.afterClosed().subscribe(result => {
           console.log('The dialog was closed', result);
           if(result == 'inserted'){
            this.refreshAgenda();

           }
    });
  }

  refreshAgenda(){
    let calendarApi = this.calendarComponent.getApi();
    calendarApi.removeAllEventSources();
    this.getMyEvents();
  }



  
}
