import { Component, OnInit } from '@angular/core';
import { MessagingService } from "../messaging.service";



@Component({
  selector: 'app-loged',
  templateUrl: './loged.component.html',
  styleUrls: ['./loged.component.css']
})
export class LogedComponent implements OnInit {


  constructor(
    private messagingService: MessagingService,
  ) { }

  ngOnInit() {
   // this.messagingService.requestPermission();
  //  this.messagingService.receiveMessage();
  }





}
