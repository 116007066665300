import { Component, OnInit, Input } from '@angular/core';
import { HttpService } from '../http.service';
import { Router, ActivatedRoute } from "@angular/router";
import {MatDialog } from '@angular/material/dialog';

import { ModelOverviewComponent } from '../pictures/model-overview/model-overview.component';
import { GobookComponent } from '../agenda/gobook/gobook.component';

@Component({
  selector: 'app-messprofile',
  templateUrl: './messprofile.component.html',
  styleUrls: ['./messprofile.component.css']
})
export class MessprofileComponent implements OnInit {

  model: number;
  profile : any;


  constructor(
    private http: HttpService,
    private router: Router,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
     
  }

 getProfile(elem, elem1){
    this.model = elem;
    this.http.getProfile(elem, elem1).subscribe( res => {
   //   console.log('getProfile' , res);
      this.profile = res;
      this.model = this.profile['hash'];
    })
  }



  goModel(): void {
    const dialogRef = this.dialog.open(ModelOverviewComponent, {
      data: {modal: this.model}
    });
  
    dialogRef.afterClosed().subscribe(result => {
 //     console.log('The dialog was closed');
    });
  }

  setMeeting(){
    const dialogRef = this.dialog.open(GobookComponent, {
      data: {modal: this.model}
    });
  
    dialogRef.afterClosed().subscribe(result => {
 //     console.log('The dialog was closed');
    });
  }

}
