<div   style="position: absolute !important;left:50%; transform: translate(-50%, 0);min-width: 800px;">
  <h2 style="text-align: center;">Gestion des Factures</h2>
 <p *ngIf="colab" style="text-align: center;">Mannequin : {{ colab.model }} </p>
 <p *ngIf="colab" style="text-align: center;">Agence :  {{ colab.agency }}</p>

  <table class="w3-table w3-striped w3-border" style="width:100%;margin-left:50%; transform: translate(-50%, 0); margin-bottom: 50px;" >
    <tr>
  
      <th>Numero Facture</th>
      <th>Intitulé</th>
      <th>Emise le</th>
      <th>Payé le</th>
      <th>Montant</th>
      <th>Facture</th>
      <th>Actions</th>
    </tr>
    <tr *ngFor="let x of factures; let i = index;">

      <td>  {{ x.numfacture }} </td>
      <td>   {{x.intitule}} </td>
      <td> {{x.dateemission}} </td>
      <td> {{x.datepaiement}} </td>
      <td> {{x.montant}} </td>
      <td  style="position:relative;">
        <span (click)="goFacture(x.url)">
          <img src="assets/search.png" height="20px" style="cursor:pointer;margin: 5px"/>
          </span>

        <span (click)="editFacture(x.numfacture)">
          <img src="assets/edit.png" height="20px" style="cursor:pointer;margin: 5px"/>
          </span>
      </td>
      <td style="position: relative;">
        <span (click)="delFacture(x.facture)">
          <img src="assets/trash.png" height="20px" style="cursor:pointer;margin: 5px"/>
          </span>
      </td>
    </tr>

  </table>



</div>  
<button class="fab2" (click)="addFacture()" mat-fab color="primary">+</button>