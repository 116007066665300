import {
  Component,
  OnInit,
  Inject
} from '@angular/core';
import {
  HttpService
} from '../../../../http.service';
import {
  DomSanitizer,
} from '@angular/platform-browser';


import {
MatDialogRef,
MAT_DIALOG_DATA
} from '@angular/material/dialog';

import { SelectshowpackageComponent } from './selectshowpackage/selectshowpackage.component';
import {MatDialog } from '@angular/material/dialog';
//import { DialogData2 } from '../../../../push-manager/push/push.component';

@Component({
  selector: 'app-choosemodel',
  templateUrl: './choosemodel.component.html',
  styleUrls: ['./choosemodel.component.scss']
})
export class ChoosemodelComponent implements OnInit {

  model: any;
  type: any;
  category: any;

  modelBool: boolean;
  hashtotransfert: any;
  avatartotransfert: any;
  selecttype: any;
  chooseShowpackage : boolean;

  loading : boolean;

  windowHeight : any;


  constructor(
    private http: HttpService,
    private sanitization: DomSanitizer,
    public dialogRef: MatDialogRef < ChoosemodelComponent > ,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    
  ) {
    this.modelBool = false;
    this.hashtotransfert = [];
    this.avatartotransfert = [];
    
  }

  ngOnInit() {
    console.log('transfert', this.data);
    this.windowHeight = window.innerHeight;
    if (this.data) {
      this.selecttype = this.data['selecttype'];
      this.chooseShowpackage = this.data['showpackage'];
    }

    this.getModeltype();
  }

  getModel(elem) {
    this.modelBool = true;
    this.loading = true;
    this.http.getModel(elem, 'female').subscribe(res => {
      this.loading = false;
      console.log('mannequin', res);

      this.model = this.sortByLetter(res['modal']);
      console.log('mannequin',  this.model );
    })
  }


  getModeltype() {
    this.http.getModeltype('allcategory').subscribe(res => {
      console.log(res);
     // this.type = this.filterCategory(res['classic']);
      this.category = this.filterCategory(res['category']);
    })
  }


  filterCategory(data){
    var newCat = [];
    for(var i = 0; i < data.length; i++){
      if( (data[i].label == 'Our Models') || (data[i].label == 'TO FOLLOW') ||  (data[i].label == 'READY TO PUSH') || (data[i].label == 'INTO SIGNING PROCESS') || 
      (data[i].label == 'FOR TEST USING')
      ){
        newCat.push(data[i]);
      }
    }
    return newCat;
  }


  getImage(data) {
    return this.sanitization.bypassSecurityTrustStyle(`url(${data})`);
  }

  selectOther(){
    this.modelBool = false;
  }

  selectShowPackage(modelhash, avatar){
    console.log('show pack', avatar);
    if( this.chooseShowpackage == true) {
      const dialogRef = this.dialog.open(SelectshowpackageComponent, {
        data: {
          'modelhash' : modelhash
        },
        width: 'auto'
      });
  
      dialogRef.afterClosed().subscribe(result => {
        console.log('choose model', result);
        if(result[0]['status'] == 'ok'){
            this.selectAvatar(result[0]['hash'], result[0]['showpackid'], avatar);
        }
      });
    } else {

      this.selectAvatar(modelhash, null, avatar);
    }


  }

  selectAvatar(hash, showpackid, avatarUrl = null) {
    if (this.selecttype == 'single') {
      console.log('single type');
      var transfert = [{
        'status': 'ok',
        'hash': hash,
    //    'avatar' : avatar
      }];
      this.dialogRef.close(transfert);
    }

    if (this.selecttype == 'multiple') {
      var index2 = this.hashtotransfert.indexOf(hash);
      if (this.hashtotransfert.indexOf(hash) !== -1) {
        var index = this.hashtotransfert.indexOf(hash);
        this.hashtotransfert.splice(index, 1);
        //this.avatartotransfert.splice(index, 1);
        document.getElementById(hash).classList.remove('selectedModel');

      } else {
        document.getElementById(hash).classList.add('selectedModel');
        let res = {'hash': hash, 'packageid' : showpackid, 'showpackage' :  this.chooseShowpackage  };
        this.hashtotransfert.push(res);
        this.avatartotransfert.push(avatarUrl);
      }

      console.log('selected', this.hashtotransfert, 'index', index2, 'avatar', this.avatartotransfert);
    }

  }

  onOkClick() {
    var transfert = [{
      'status': 'ok',
      'hash': this.hashtotransfert,
      'avatars' : this.avatartotransfert
    }];
    this.dialogRef.close(transfert);
  }

  onNoClick(){
    this.dialogRef.close('close');
  }

  sortByLetter(dataE){
    var letters = 'abcdefghijklmnopqrstuvwxyz';

    var temp = [];

    for(var i = 0; i < letters.length; i++){
      var letter = letters[i];
 

      var tmp = [];
      for(var j = 0; j < dataE.length; j++){
    //    console.log('letter', letter, 'data', dataE[j].first.toLowerCase().charAt(0))
        if(dataE[j].first.toLowerCase().charAt(0) == letter){
            tmp.push(dataE[j]);
          
        }
        dataE.slice(j, 1);
      }
      if(tmp.length > 0){
        temp[i] = {'letter': letter, 'data' : tmp };
      }
      

    }
  
    

    var filtered = temp.filter(function (el) {
      return el != null;
    });
   // console.log('filtered', filtered);
    return filtered;
  }

  async goSearch(valueE){
    console.log(valueE);
    this.loading = true;
 

    var funcE = { type: 'SEARCH', func: 'searchModels' };  
    var dataE = { 'firstorlast' : valueE };
    var Postdata = { func: funcE, data : dataE };
    console.log('Postdata', Postdata);

    let res = await this.http.goAcess(Postdata);
    this.loading = false;
    this.model = this.sortByLetter(res['modal']);
  //  console.log( 'resultat', this.models);
  
    console.log('res', res);

  }

  deleteThisModel(modelHash){
    console.log('deleteThisModel', modelHash);
    for(var i = 0; i < this.hashtotransfert.length; i++){
      if(this.hashtotransfert[i].hash == modelHash){
        this.hashtotransfert.splice(i, 1);
      }
    }
    document.getElementById(modelHash).classList.remove('selectedModel');
    console.log('selected', this.hashtotransfert, );
  }

  getClass(label){
    let classtoreturn;
    switch(label){
      case 'Our Models':
        classtoreturn = "Bigagency-card";
      break;
      
      default:
        classtoreturn = 'agency-card';
    }
    return classtoreturn;
  }

}
