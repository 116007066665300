import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import { HttpService } from '../../http.service';


@Component({
  selector: 'app-statssms',
  templateUrl: './statssms.component.html',
  styleUrls: ['./statssms.component.scss']
})
export class StatssmsComponent implements OnInit {

  spinnerProgress: boolean;
  dataSource2 = new  MatTableDataSource();

  displayedColumns2: string[] = [ 'phone', 'status', 'date', 'message' ];

  constructor(
    private get: HttpService,
  ) {
    this.spinnerProgress = true;
   }

  ngOnInit(): void {
    this.getSMSStats();
  
  }

  async getSMSStats(){
    this.spinnerProgress = true;
    var funcE = { type: 'STATS', func: 'getSMSStats' };   
    var Postdata = { func: funcE };
    let res = await this.get.goAcess(Postdata);
    console.log('res',res)
    this.dataSource2.data = res;
    this.spinnerProgress = false;
  }

}
