<div class="principal" >
 
  <div class="main">
    <app-notifications></app-notifications>
    <router-outlet></router-outlet>
  </div>

  <div class="test" >
    <app-main-menu></app-main-menu>
  </div>

  <div class="banniere removeOnMobile">
    <app-banniere></app-banniere>
      </div>
     

 
</div>
