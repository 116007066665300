import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import 'hammerjs';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { Page1Component } from './page1/page1.component';
import { Page2Component } from './page2/page2.component';
import { HttpClientModule } from '@angular/common/http';


import { SeemodelsComponent } from './seemodels/seemodels.component';
import { SeeeventComponent } from './seeevent/seeevent.component';

import { MainMenuComponent } from './main-menu/main-menu.component';
import { ModelOverviewComponent } from './pictures/model-overview/model-overview.component';
import { PicturesComponent } from './pictures/pictures.component';

import { SpecifictyComponent } from './specificty/specificty.component';
import { BanniereComponent } from './banniere/banniere.component';
import { ShowPersonal } from './banniere/showpersonal.component';

import { FastactionComponent } from './fastaction/fastaction.component';
import { EventsComponent } from './events/events.component';


import { AngularEditorModule } from '@kolkov/angular-editor';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import {  MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import {MatTabsModule} from '@angular/material/tabs';
import { MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { TextFieldModule } from '@angular/cdk/text-field';
import {MatIconModule} from '@angular/material/icon';
import { NoSanitizePipePipe } from './no-sanitize-pipe.pipe';
import { SearchComponent } from './search/search.component';
import {MatRadioModule} from '@angular/material/radio';
import {MatGridListModule} from '@angular/material/grid-list';
import { StateComponent } from './state/state.component';
import { ChatComponent } from './chat/chat.component';
import { LoginComponent } from './login/login.component';
import { LogedComponent } from './loged/loged.component';
import { MatMenuModule } from '@angular/material/menu';
import {MatCardModule } from '@angular/material/card';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { MessageComponent } from './message/message.component';
import { ApprovedComponent } from './approved/approved.component';
import {  MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import {  DialogOverviewExampleDialog, WrongPassword} from './login/login.component';
import { ImageCrop } from './events/events.component';
import { ImageCrop2 } from './events/events.component';

import { SendMess } from './fastaction/fastaction.component';
import { DeleteModal } from './fastaction/fastaction.component';
import { SendEmail } from './fastaction/fastaction.component';
import { EditProfile } from './fastaction/editprofile.component';

import { ImageCropperModule } from 'ngx-image-cropper';
import  {  MatSlideToggleModule } from '@angular/material/slide-toggle';
import { StatsComponent } from './stats/stats.component';
import { StateApprovedComponent } from './state/state.component'
import {  MatCheckboxModule} from '@angular/material/checkbox';
import { ConversComponent } from './convers/convers.component';
import { MessPipePipe } from './mess-pipe.pipe';
import {  MatProgressBarModule} from '@angular/material/progress-bar';

import {MatDatepickerModule} from '@angular/material/datepicker';

import { AgmCoreModule  } from '@agm/core';
import {
  MatNativeDateModule
} from '@angular/material/core';
import { AgencyComponent } from './agency/agency.component';
import { AgencyDetailsComponent } from './agency-details/agency-details.component';
import { FullAgencyComponent } from './full-agency/full-agency.component';
//import { Ng5SliderModule } from 'ng5-slider';
import { FullAgencyDetailsComponent } from './full-agency-details/full-agency-details.component';
import { CreateAgency, LogoCrop } from './agency-card/agency-card.component';
import { FastactionAgencyComponent } from './fastaction-agency/fastaction-agency.component';
import { SuggestedComponent } from './suggested/suggested.component';
import { DeleteAgency, SendEmailToAgency } from './fastaction-agency/fastaction-agency.component';

import { AgencyContactComponent, AddContact, DelContact, EditContact } from './agency-contact/agency-contact.component';
//import { showProfile } from './banniere/banniere.component';
import { FaqComponent } from './faq/faq.component';
import { addFaq, delFaq, editFaq } from './faq/faq.component';
import { MessprofileComponent } from './messprofile/messprofile.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PushComponent } from './push-manager/push/push.component';
import { sendEmailComponent } from './push-manager/push/push.component';
import { AgencyCardComponent } from './agency-card/agency-card.component';

import { CustommenuComponent } from './custommenu/custommenu';
import { GetBooking } from './custommenu/getbooking';
import { FraisComponent } from './frais/frais.component';
import { addFrais } from './frais/addfrais.component';
import { ContractComponent } from './facturesystem/contract/contract.component';
import { CollaborationComponent } from './facturesystem/contract/addcollaboration.component';
import { MatListModule} from '@angular/material/list';
import { ChartsModule } from 'ng2-charts';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {   MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatStepperModule } from '@angular/material/stepper';

import { PushManagerComponent } from './push-manager/push-manager.component';
import { AdminComponent } from './admin/admin.component';
import { AddusersComponent } from './admin/addusers/addusers.component';
import { ClientPageComponent } from './client-page/client-page.component';
import { DetailsmodalComponent } from './client-page/detailsmodal/detailsmodal.component';
import { ClientMenuComponent } from './client-page/client-menu/client-menu.component';
import { FacturationComponent } from './facturesystem/facturation/facturation.component';
import { AddfactureComponent } from './facturesystem/facturation/addfacture/addfacture.component';
import { MgmtcategoryComponent } from './specificty/mgmtcategory/mgmtcategory.component';
import { EditFactureComponent } from './facturesystem/allfactures/edit-facture/edit-facture.component';
import { PushlogComponent } from './push-manager/push/pushlog/pushlog.component';

import { AgendaComponent } from './agenda/agenda.component';
import { GobookComponent } from './agenda/gobook/gobook.component';

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { BookingdetailsComponent } from './agenda/bookingdetails/bookingdetails.component';
import { ChooseagencyComponent } from './agenda/gobook/chooseagency/chooseagency.component';
import {  MatAutocompleteModule } from '@angular/material/autocomplete';
import { PushcustomizerComponent } from './push-manager/push/pushcustomizer/pushcustomizer.component';
import { BookingchangeComponent } from './agenda/bookingdetails/bookingchange/bookingchange.component';

//Video
//import { MatVideoModule } from 'mat-video';
// Notif
import { AsyncPipe } from '../../node_modules/@angular/common';
import { MessagingService } from './messaging.service';
//import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { environement2 } from './environement';
import { environment } from '../environments/environment';
//import { AngularFireModule } from '@angular/fire';
//import { AngularFireDatabaseModule } from '@angular/fire/database';
//import { AngularFireAuthModule } from '@angular/fire/auth';

import { NotificationsComponent } from './common/notifications/notifications.component';
import { AllfacturesComponent } from './facturesystem/allfactures/allfactures.component';


import { CountrylogpushComponent } from './push-manager/push/countrylogpush/countrylogpush.component';
import { ContacttosendComponent } from './push-manager/push/countrylogpush/contacttosend/contacttosend.component';
import { SeedestinataireComponent } from './push-manager/push/seedestinataire/seedestinataire.component';
import { VideomodelComponent } from './pictures/videomodel/videomodel.component';
import { FeedbackComponent } from './push-manager/push/countrylogpush/contacttosend/feedback/feedback.component';
import { MgmtAlbumComponent } from './pictures/model-overview/mgmt-album/mgmt-album.component';
import { MgmtGalleryComponent } from './pictures/model-overview/mgmt-album/mgmt-gallery/mgmt-gallery.component';
import { SelectedGalleryComponent } from './pictures/model-overview/mgmt-album/selected-gallery/selected-gallery.component';
import { AlbumphotosComponent } from './pictures/model-overview/mgmt-album/mgmt-gallery/albumphotos/albumphotos.component';
import { CreateAlbumComponent } from './pictures/model-overview/mgmt-album/mgmt-gallery/create-album/create-album.component';
import { ChoosealbumfortransfertComponent } from './pictures/model-overview/mgmt-album/mgmt-gallery/choosealbumfortransfert/choosealbumfortransfert.component';
import { RenameAlbumComponent } from './pictures/model-overview/mgmt-album/mgmt-gallery/rename-album/rename-album.component';
import { DocumentsComponent } from './banniere/documents/documents.component';
import { AddDocumentsComponent } from './banniere/documents/add-documents/add-documents.component';
import { DoccategoryComponent } from './banniere/documents/doccategory/doccategory.component';
import { MesstemplateComponent } from './chat/messtemplate/messtemplate.component';
import { AddtemplatesComponent } from './chat/messtemplate/addtemplates/addtemplates.component';
import { ModeldocsComponent } from './pictures/modeldocs/modeldocs.component';
import { AdddocsComponent } from './pictures/modeldocs/adddocs/adddocs.component';
import { ModelnotesComponent } from './pictures/modelnotes/modelnotes.component';
import { AddnotesComponent } from './pictures/modelnotes/addnotes/addnotes.component';
import { ModellogComponent } from './pictures/modellog/modellog.component';
import { ModelmeetingComponent } from './pictures/modelmeeting/modelmeeting.component';
import { ModelstatsComponent } from './pictures/modelstats/modelstats.component';
import { GencontractComponent } from './facturesystem/gencontract/gencontract.component';
import { GenmacComponent } from './facturesystem/gencontract/genmac/genmac.component';
import { ChoosemodelComponent } from './facturesystem/gencontract/genmac/choosemodel/choosemodel.component';
import { AvatarcropComponent } from './pictures/model-overview/mgmt-album/mgmt-gallery/avatarcrop/avatarcrop.component';
import { ModelsumaryComponent } from './pictures/modelsumary/modelsumary.component';
import { ModelrepresentationComponent } from './pictures/modelrepresentation/modelrepresentation.component';


import {  MatTableModule } from '@angular/material/table';
import { ContactComponent } from './contact/contact.component';
import { ContactclientComponent } from './contact/contactclient/contactclient.component';
import { UsefilterComponent } from './stats/statsone/usefilter/usefilter.component';
import { FilterUniquePipe } from './filter-unique.pipe';
import { ModelcategoryComponent } from './pictures/modelcategory/modelcategory.component';
import { EditcategoryComponent } from './banniere/editcategory/editcategory.component';
import { CategorytransfertComponent } from './banniere/editcategory/categorytransfert/categorytransfert.component';
import { AddcategoryComponent } from './banniere/editcategory/addcategory/addcategory.component';
import { SetcollabendComponent } from './pictures/modelrepresentation/setcollabend/setcollabend.component';
import { ModelfacComponent } from './pictures/modelfac/modelfac.component';
import { FacturesystemComponent } from './facturesystem/facturesystem.component';
import { AgencylogComponent } from './full-agency/agencylog/agencylog.component';
import { GenboaComponent } from './facturesystem/gencontract/genboa/genboa.component';

import { FullCalendarModule } from '@fullcalendar/angular';
import { ModelagendaComponent } from './pictures/modelagenda/modelagenda.component';
import { ModelmessageComponent } from './pictures/modelmessage/modelmessage.component';
import { GenfacComponent } from './facturesystem/gencontract/genfac/genfac.component';
import { FromboaComponent } from './facturesystem/gencontract/genfac/fromboa/fromboa.component';
import { BoaComponent } from './facturesystem/facturation/boa/boa.component';
import { TodoComponent } from './todo/todo.component';
import { DonelistComponent } from './todo/donelist/donelist.component';
import { FastpushComponent } from './fastpush/fastpush.component';
import { ClienttovalidateComponent } from './contact/contactclient/clienttovalidate/clienttovalidate.component';
import { RelanceComponent } from './facturesystem/contract/relance/relance.component';
import { ModelpaymentsComponent } from './pictures/modelpayments/modelpayments.component';
import { FacturerappelComponent } from './facturesystem/facturerappel/facturerappel.component';
import { PaymentsComponent } from './facturesystem/payments/payments.component'; // for FullCalendar!
import { StatistiquesComponent } from './facturesystem/statistiques/statistiques.component';
import { StatsrepartComponent } from './facturesystem/statsrepart/statsrepart.component';

import { GalleryComponent } from './pictures/model-overview/mgmt-album/gallery/gallery.component';
import { EmailcompComponent } from './emailcomp/emailcomp.component';

import { LightboxModule } from 'ngx-lightbox';
import { SendsmsComponent } from './sendsms/sendsms.component';
import { JoindocsComponent } from './agenda/gobook/joindocs/joindocs.component';
import { SmsandemailcompComponent } from './fastpush/smsandemailcomp/smsandemailcomp.component';
import { StatslogComponent } from './stats/statstwo/statslog/statslog.component';
import { LastsubscribeComponent } from './stats/statsthree/lastsubscribe/lastsubscribe.component';
import { LastactionsComponent } from './stats/statsthree/lastactions/lastactions.component';
import { StatssmsComponent } from './stats/statssms/statssms.component';
import { StatsoneComponent } from './stats/statsone/statsone.component';
import { StatstwoComponent } from './stats/statstwo/statstwo.component';
import { StatsthreeComponent } from './stats/statsthree/statsthree.component';
import { RegistrationStatComponent } from './stats/statsone/registration-stat/registration-stat.component';
import { PlacementStatsComponent } from './stats/statsone/placement-stats/placement-stats.component';
import { BookingStatsComponent } from './stats/statsone/booking-stats/booking-stats.component';
import { PushprogramComponent } from './push-manager/push/pushprogram/pushprogram.component';
import { ContactListMgmtComponent } from './agency-contact/contact-list-mgmt/contact-list-mgmt.component';
import { PushprogramdetailsComponent } from './push-manager/push/pushprogram/pushprogramdetails/pushprogramdetails.component';
import { SearchPannelComponent } from './banniere/search-pannel/search-pannel.component';
import { FastmeetComponent } from './main-menu/fastmeet/fastmeet.component';
import { CompcardComponent } from './pictures/model-overview/mgmt-album/compcard/compcard.component';

import {VgCoreModule} from '@videogular/ngx-videogular/core';
import {VgControlsModule} from '@videogular/ngx-videogular/controls';
import {VgOverlayPlayModule} from '@videogular/ngx-videogular/overlay-play';
import {VgBufferingModule} from '@videogular/ngx-videogular/buffering';

import {
  LyTheme2,
  StyleRenderer,
  LY_THEME,
  LY_THEME_NAME,
} from '@alyle/ui';
import { MinimaLight, MinimaDark } from '@alyle/ui/themes/minima';


import { LyImageCropperModule } from '@alyle/ui/image-cropper';
import { LyButtonModule } from '@alyle/ui/button';
import { LyIconModule } from '@alyle/ui/icon';
import { LySliderModule } from '@alyle/ui/slider';
import { FichedepaieComponent } from './pictures/fichedepaie/fichedepaie.component';
import { AddfichedepaieComponent } from './pictures/fichedepaie/addfichedepaie/addfichedepaie.component';
import { FdpComponent } from './facturesystem/fdp/fdp.component';
import { SelectshowpackageComponent } from './facturesystem/gencontract/genmac/choosemodel/selectshowpackage/selectshowpackage.component';


import { PdfviewerComponent } from './common/pdfviewer/pdfviewer.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { DateFilterComponent } from './common/date-filter/date-filter.component';
import { FiltermodalComponent } from './common/date-filter/filtermodal/filtermodal.component';
import { ConfirmmodalComponent } from './common/confirmmodal/confirmmodal.component';

registerLocaleData(localeFr);

@NgModule({
    declarations: [
        AppComponent,
        Page1Component,
        Page2Component,
        SeemodelsComponent,
        SeeeventComponent,
        MainMenuComponent,
        ModelOverviewComponent,
        PicturesComponent,
        SpecifictyComponent,
        BanniereComponent,
        FastactionComponent,
        EventsComponent,
        NoSanitizePipePipe,
        SearchComponent,
        StateComponent,
        ChatComponent,
        LoginComponent,
        LogedComponent,
        MessageComponent,
        ApprovedComponent,
        DialogOverviewExampleDialog,
        WrongPassword,
        ImageCrop,
        ImageCrop2,
        StatsComponent,
        StateApprovedComponent,
        ConversComponent,
        MessPipePipe,
        SendMess,
        DeleteModal,
        SendEmail,
        AgencyComponent,
        AgencyDetailsComponent,
        FullAgencyComponent,
        FullAgencyDetailsComponent,
        CreateAgency,
        LogoCrop,
        FastactionAgencyComponent,
        SuggestedComponent,
        DeleteAgency,
        AgencyContactComponent, AddContact, DelContact, ShowPersonal, SendEmailToAgency, EditContact, FaqComponent, addFaq, delFaq, editFaq, MessprofileComponent, PushComponent, sendEmailComponent, EditProfile, AgencyCardComponent,
        CustommenuComponent, GetBooking, FraisComponent,
        addFrais, ContractComponent, CollaborationComponent, PushManagerComponent,
        AdminComponent, AddusersComponent, ClientPageComponent, DetailsmodalComponent, ClientMenuComponent,
        FacturationComponent, AddfactureComponent, MgmtcategoryComponent, EditFactureComponent, PushlogComponent, AgendaComponent,
        GobookComponent,
        BookingdetailsComponent,
        ChooseagencyComponent,
        PushcustomizerComponent,
        BookingchangeComponent,
        NotificationsComponent,
        AllfacturesComponent,
        CountrylogpushComponent,
        ContacttosendComponent,
        SeedestinataireComponent,
        VideomodelComponent,
        FeedbackComponent,
        MgmtAlbumComponent,
        MgmtGalleryComponent,
        SelectedGalleryComponent,
        AlbumphotosComponent,
        CreateAlbumComponent,
        ChoosealbumfortransfertComponent,
        RenameAlbumComponent,
        DocumentsComponent,
        AddDocumentsComponent,
        DoccategoryComponent,
        MesstemplateComponent,
        AddtemplatesComponent,
        ModeldocsComponent,
        AdddocsComponent,
        ModelnotesComponent,
        AddnotesComponent,
        ModellogComponent,
        ModelmeetingComponent,
        ModelstatsComponent,
        GencontractComponent,
        GenmacComponent,
        ChoosemodelComponent,
        AvatarcropComponent,
        ModelsumaryComponent,
        ContactComponent,
        ContactclientComponent,
        UsefilterComponent,
        FilterUniquePipe,
        ModelcategoryComponent,
        EditcategoryComponent,
        CategorytransfertComponent,
        AddcategoryComponent,
        ModelrepresentationComponent,
        SetcollabendComponent,
        ModelfacComponent,
        FacturesystemComponent,
        AgencylogComponent,
        GenboaComponent,
        ModelagendaComponent,
        ModelmessageComponent,
        GenfacComponent,
        FromboaComponent,
        BoaComponent,
        TodoComponent,
        DonelistComponent,
        FastpushComponent,
        ClienttovalidateComponent,
        RelanceComponent,
        ModelpaymentsComponent,
        FacturerappelComponent,
        PaymentsComponent,
        GalleryComponent,
        EmailcompComponent,
        SendsmsComponent,
        StatistiquesComponent,
        StatsrepartComponent,
        JoindocsComponent,
        SmsandemailcompComponent,
        StatslogComponent,
        LastsubscribeComponent,
        LastactionsComponent,
        StatssmsComponent,
        StatsoneComponent,
        StatstwoComponent,
        StatsthreeComponent,
        RegistrationStatComponent,
        PlacementStatsComponent,
        BookingStatsComponent,
        PushprogramComponent,
        ContactListMgmtComponent,
        PushprogramdetailsComponent,
        SearchPannelComponent,
        FastmeetComponent,
        CompcardComponent,
        FichedepaieComponent,
        AddfichedepaieComponent,
        FdpComponent,
        SelectshowpackageComponent,
        PdfviewerComponent,
        DateFilterComponent,
        FiltermodalComponent,
        ConfirmmodalComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AngularEditorModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatButtonModule,
        MatInputModule,
        MatTabsModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatIconModule,
        MatRadioModule,
        MatGridListModule,
        MatMenuModule,
        MatCardModule,
        MatButtonToggleModule,
        MatDialogModule,
        ImageCropperModule,
        MatSlideToggleModule,
        MatCheckboxModule,
        MatProgressBarModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatListModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyBOEXaY17ioOIvzcQJJJADq8APgaieHvk4'
            // apiKey: 'qsd'
        }),
        //Ng5SliderModule,
        MatTooltipModule,
        ChartsModule,
        DragDropModule,
        MatChipsModule,
        /*CalendarModule.forRoot({
          provide: DateAdapter,
          useFactory: adapterFactory
        }),*/
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        MatAutocompleteModule,
        /*
        AngularFireMessagingModule,
        AngularFireModule.initializeApp(environement2.firebase),
        AngularFireDatabaseModule,
        AngularFireAuthModule,
        */
      //  MatVideoModule,
        TextFieldModule,
        MatStepperModule,
        MatTableModule,
        FullCalendarModule,
        FormsModule,
        LightboxModule,
        LyImageCropperModule,
        LySliderModule,
        LyButtonModule,
        LyIconModule,
        NgxDocViewerModule,
        VgCoreModule,
        VgControlsModule,
        VgOverlayPlayModule,
        VgBufferingModule
    ],
    exports: [],
    providers: [
      {
        provide: MatDialogRef,
        useValue: {}
      },
        MatDatepickerModule,
        { provide: OWL_DATE_TIME_LOCALE, useValue: 'fr' },
        MessagingService, AsyncPipe, NotificationsComponent,
        [LyTheme2],
        [StyleRenderer],
        { provide: LY_THEME_NAME, useValue: 'minima-light' },
        { provide: LY_THEME, useClass: MinimaLight, multi: true },
        { provide: LY_THEME, useClass: MinimaDark, multi: true }, // name: `minima-dark`
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
