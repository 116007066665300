import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../http.service';
import * as moment from 'moment';

import { PdfviewerComponent } from '../../common/pdfviewer/pdfviewer.component'
import { MatDialog } from '@angular/material/dialog';

import CommonFunc  from '../../commonfunc';

@Component({
  selector: 'app-fdp',
  templateUrl: './fdp.component.html',
  styleUrls: ['./fdp.component.scss']
})
export class FdpComponent implements OnInit {


  fdp : any;
  details:any;
  spinnerProgress: boolean;

  constructor(
    private http :HttpService,
    public dialog: MatDialog,
    private commonFunc : CommonFunc
  ) {
   this.spinnerProgress = false;
   }

  ngOnInit(): void {
    this.getFdp();
  }

  async getFdp(){
    this.spinnerProgress = true;
    var funcE = { type: 'FDP', func: 'getAllFDP' };  
    var Postdata = { func: funcE };

    let res = await this.http.goAcess(Postdata);
    console.log('res', res);
    this.spinnerProgress = false;
    this.fdp = res;
  }

  async showDetails(fdpid, indexE){

    let windowWidth = window.innerWidth;

    if(windowWidth <  650){
      return;
    }

    this.details = null;
    for(var i = 0; i < this.fdp.length; i++){

      var elemA = document.getElementById("hidden_" + i);
      if (elemA.classList.contains('show') == true) {
        elemA.classList.remove('show');
        elemA.classList.add("hidden");
      }

    }

    let elem = document.getElementById("hidden_" + indexE);
    
    var classList = elem.classList;

    if(classList.contains('show') == true) {
      classList.remove('show');
      classList.add("hidden");
    } else {
      classList.remove('hidden');
      this.details = await this.getFDPDetails(fdpid);
      classList.add("show");
    }
    
  }

  async getFDPDetails(fdpid){

    var funcE = { type: 'FDP', func: 'getOneFDPdetails' };  
    var dataE = { fdpid : fdpid };
    var Postdata = { func: funcE, data : dataE };

    let res = await this.http.goAcess(Postdata);
    console.log('res', res);
    return res;

  }

  downloadfile(url){
    window.open(url);
  }

  getDate(date){
    if( (date !== null) && (date!== '') ) {
      let newdate = moment(date).format('DD/MM/YYYY');
      return newdate;
    } else {
      return 'not paid yet'
    }
   
   
   }

   viewFile(url, ext){
    let dialogRef = this.dialog.open(PdfviewerComponent, {
      height: '100%',
      width: '100%',
      data: { fileUrl : url, fileType : ext  }
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  async DownloadAsZip(){
    this.spinnerProgress = true;
    var pdfUrlList = [];
    for(var i = 0; i < this.fdp.length; i++)
    {
      pdfUrlList[i] =  
      { url: this.fdp[i].url, 
        name : this.fdp[i].user_first + '_' + this.fdp[i].user_last  + '_' + this.fdp[i].libelle,
        ext : "pdf"
      };
    }

    await this.commonFunc.createZip(pdfUrlList, "fdp.zip");
    this.spinnerProgress = false;

  }


}
