<div style="overflow-y: auto">



    <mat-tab-group >

        
        <mat-tab label="Contact"> 
          <app-agency-contact [agency]="agency"></app-agency-contact>

        </mat-tab>

        <mat-tab label="Push" *ngIf=" type !== 'customlist' "> 
          <app-agencylog [agency]="agency"></app-agencylog>

        </mat-tab>

        <mat-tab label="Notes"> 
          <app-modelnotes [hash]="agency" [type]=" 'client' "></app-modelnotes>

        </mat-tab>

      </mat-tab-group>



</div>