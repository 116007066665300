<div class="paddingTopOnMobile" style="max-width: 100vw; max-height: 100vh; overflow-y: scroll;">
  <div *ngIf="clienttype" style="display: flex; flex-direction: row; justify-content: center; flex-wrap: wrap; align-items: center; height: 100%;">



    <div *ngFor="let x of type" (click)="selectClientType(x)" class="agency-card">
      <div class="flexItem">
        <div>
          <img *ngIf="x == 'agency' " src="../../../../assets/client/agency.png" class="medIcon" />
          <img *ngIf="x == 'photograph' " src="../../../../assets/client/photographer.png" class="medIcon" />
          <img *ngIf="x == 'clients' " src="../../../../assets/client/client.png" class="medIcon" />
          <img *ngIf="x == 'advisor' " src="../../../../assets/client/advisor.png" class="medIcon" />
          <img *ngIf="x == 'models' " src="../../../../assets/client/models.png" class="medIcon" />
          <img *ngIf="x == 'customlist' " src="../../../../assets/client/list.png" class="medIcon" />
        </div>
        <div  style="max-width: 50px;text-align: center; white-space: nowrap; font-size: 10px;">
          {{x}}
        </div>
      </div>


    </div>
  </div>




  <div *ngIf="country" style="display: flex; flex-direction: row;  flex-wrap: wrap; overflow-y: scroll; max-width: 100vw; ">
   


    <div *ngFor="let y of countrylist" class="agency-card" (click)="showContacts(y.name)">
      <div
        style="display: flex; flex-direction: column; justify-content: space-evenly; align-items: center; min-width: 100%;top: 50%;
        position: absolute;
        transform: translate(0, -50%);">
        <div>
          <img [src]="y.flag" class="medIcon"  />
        </div>
        <div>
          <span style="max-width: 50px;text-align: center; white-space: nowrap; font-size: 10px;">{{ y.name }}</span>
        </div>

      </div>
    </div>
  </div>



  <div style="display: flex; flex-direction: row; flex-wrap: wrap; overflow: none;" *ngIf="contacts" style = "height: 90vh; max-height: 90vh; overflow-y: scroll;">
 
    <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
      <div class="removeOnMobile">
        <input placeholder="Find" (keyup.enter)="goSearch($event.target.value)" />
      </div>
     
    </div>

    <mat-spinner *ngIf="loading">
    </mat-spinner>


    <div style="display: flex; flex-direction: row;flex-wrap: wrap;" *ngIf="!loading">



      <div  style="display: flex; flex-direction: row;flex-wrap: wrap;"  *ngFor="let x of notin" 

      >
 
     <div class="agency-card cardLetter"  >
       <div class="event-card-round-letter">
         <div class="bigLetter">
           {{ x.letter }}
         </div>
       </div>
     </div>
 
     <div
     [id]="y.hash"
     class="agency-card"
     *ngFor="let y of x.data; let i = index"
    
   (click)="addToSelection(y.hash, y.agencyhash)"
     [style.backgroundImage]="'url(' + y.agencyhash + ')'"
   >
     <div
       id="{{ y.hash }}"
       [ngClass]="{
         agencyInfos: y.agencyhash !== '',
         agencyInfos2: y.agencyhash == ''
       }"
     >
       <div
         *ngIf="y.name"
         style="
           font-size: 14px;
           text-align: center;
           color: white;
           white-space: wrap;
           padding: 5px;
         "
       >
         {{ y.name }}
       </div>
     </div>
    </div>

 
  </div>

  <!--
      <div *ngIf="x.agencyhash == '' " style="display: flex; flex-direction: column; justify-content: center; align-items: center; height: 100%; width: 100%;border-radius: 50%;">
        <div  style="text-align: center;margin: 5px;">{{x.name}}</div>
      </div>

  -->



    </div>



  </div>


  <div class="bTnPannelForMobile" >
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button (click)="onOkClick()" cdkFocusInitial>Ok</button>
  </div>

</div>
