<div  class="main">

    <div class="box removeOnMobile" (click)="genContract('mac')">
        <div class="flexTitle">
            <div>
                <img src="../../../assets/contract.png" style="height: 50px;" />
            </div>
            <div class="flexSubtitle">
                Mother Agency Contract
            </div>
        </div>
    </div>

    <div class="box" (click)="genContract('mca')">
        <div class="flexTitle">
            <div>
                <img src="../../../assets/contract.png" style="height: 50px;" />
            </div>
            <div class="flexSubtitle">
                    Model Commission Agreement
            </div>
        </div>
    </div>

    <div class="box removeOnMobile" (click)="genBoa()">
        <div class="flexTitle">
            <div>
                <img src="../../../assets/contract.png" style="height: 50px;" />
            </div>
            <div class="flexSubtitle">
                Booking Order Agreement
        </div>
        </div>
    </div>

    <div class="box" (click)="genFac()">
        <div class="flexTitle">
            <div>
                <img src="../../../assets/contract.png" style="height: 50px;" />
            </div>
            <div class="flexSubtitle">
               Invoice
        </div>
        </div>
    </div>




</div>
