<div style="margin-top: 30px; display: flex; flex-direction: row; justify-content: space-evenly; width: 80%; margin-left: auto; margin-right: auto;">
  <div  [ngClass]=" selecteddate == '2021' ? 'active': 'noactive'"(click)="SelecteDate('2021')">2021</div>
    <div  [ngClass]=" selecteddate == '2020' ? 'active': 'noactive'"(click)="SelecteDate('2020')">2020</div>
    <div  [ngClass]=" selecteddate == '2019' ? 'active': 'noactive'" (click)="SelecteDate('2019')">2019</div>
  </div>
  
  <div style="margin-top: 10px; display: flex; flex-direction: row; justify-content: space-evenly; width: 80%; margin-left: auto; margin-right: auto;">
    <div  [ngClass]=" societe == 'all' ? 'active': 'noactive'"(click)="SelecteEntreprise('all')">All</div>
    <div  [ngClass]=" societe == 'See Models SARL' ? 'active': 'noactive'"(click)="SelecteEntreprise('See Models SARL')">See Models SARL</div>
    <div  [ngClass]=" societe == 'SEEMO MANAGEMENT LTD' ? 'active': 'noactive'" (click)="SelecteEntreprise('SEEMO MANAGEMENT LTD')">SEEMO MANAGEMENT LTD</div>
  </div>

<div *ngIf="stats" >
    <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions"
      [legend]="barChartLegend" [chartType]="barChartType" style="background-color: rgba(0, 0, 0, 0.2)" >
    </canvas>
  </div>