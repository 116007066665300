import { Component, OnInit, Input } from '@angular/core';
import { HttpService } from '../../../http.service';

import { MatTableDataSource } from '@angular/material/table';
import {MatDialog } from '@angular/material/dialog';

import { UsefilterComponent } from '../../statsone/usefilter/usefilter.component';

import { ModelOverviewComponent } from '../../../pictures/model-overview/model-overview.component';
import { AgencyDetailsComponent } from '../../../agency-details/agency-details.component';

import * as moment from 'moment';



@Component({
  selector: 'app-statslog',
  templateUrl: './statslog.component.html',
  styleUrls: ['./statslog.component.scss'],
})
export class StatslogComponent implements OnInit {
  @Input() agencystats: any;
  @Input() type: string;

  spinnerProgress: boolean;
  stats: any;
  catstats: any;



  constructor(private get: HttpService, public dialog: MatDialog) {
    this.spinnerProgress = true;

  }

  async ngOnInit() {
   
  }



  async delLog(logid, index) {
    //  console.log('del log', logid, index);
    await this.get.delLog(logid).subscribe((res) => {
      console.log(res);
      if (res == 'delete') {
        //   this.fullStats();
        this.agencystats.splice(index, 1);
     
      }
    });
  }

  goModel(elem: string): void {
    const dialogRef = this.dialog.open(ModelOverviewComponent, {
      data: { modal: elem },
    });

    dialogRef.afterClosed().subscribe((result) => {
      //     console.log('The dialog was closed');
    });
  }

  getDate(start, end) {
    return moment(start).format('MMMM Do YYYY, h:mm');
  }

  goAgency(elem: string) {
    console.log('router', elem);
    // this.router.navigate(['members/agency',elem] );

    const dialogRef = this.dialog.open(AgencyDetailsComponent, {
      data: { agency: elem },
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed', result);
    });
  }

  getIcon(type) {
    let icon = '';
    switch (type) {
      case 'mail':
        icon = 'assets/mail.png';
        break;

      case 'see':
        icon = 'assets/eye.png';
        break;

      case 'nointerest':
        icon = 'assets/choice/refuse.png';
        break;

      case 'close':
        icon = 'assets/choice/close.png';
        break;
      case 'interest':
        icon = 'assets/checked.png';
        break;

      case 'gopola':
        icon = 'assets/gopola.png';
        break;

      case 'govideo':
        icon = 'assets/govideo.png';
        break;

      case 'zip':
        icon = 'assets/choice/zip.png';
        break;

      case 'pdf':
        icon = 'assets/choice/pdf.png';
        break;

      case 'share':
        icon = 'assets/choice/share.png';
        break;

      case 'download':
        icon = 'assets/choice/download.png';
        break;

      case 'DATA | getOneModelData | albumdetails':
        icon = 'assets/album.png';
        break;

      case 'DATA | getOneModelData | getallalbums':
        icon = 'assets/gopola.png';
        break;

      case 'DATA | getOneModelData | full':
        icon = 'assets/eye.png';
        break;

      case 'I want to represent here':
        icon = 'assets/contract.png';
        break;

      case 'I Pass':
        icon = 'assets/choice/refuse.png';
        break;

        case 'Request an appointment':
          icon = 'assets/choice/star.png';
          break;

          case 'DATA | getMySelection':
            icon = 'assets/choice/selection.png';
            break;

            case 'DATA | getModelSample':
              icon = 'assets/choice/sample.png';
              break;

        

      case '1st Choice':
        icon = 'assets/choice/star.png';
        break;

      case '2nd Choice':
        icon = 'assets/choice/star2.png';
        break;
    }

    return icon;
  }
}
