import { Component, OnInit, Input } from '@angular/core';
import {MatDialog } from '@angular/material/dialog';
import { HttpService } from '../../http.service';


import { ChartType, ChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';
import * as moment from 'moment';
import { EmailcompComponent } from '../../emailcomp/emailcomp.component';

@Component({
  selector: 'app-modelpayments',
  templateUrl: './modelpayments.component.html',
  styleUrls: ['./modelpayments.component.scss']
})
export class ModelpaymentsComponent implements OnInit {

  @Input() hash: string;
  payments : any;

  public pieChartLabels: Label[] = ['CA', 'In Pocket', 'Marge'];
  public pieChartData : any;
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;


  loader: boolean;

  displayedColumns: string[] = [ 'client', 'note', 'numfac', 'montantht', 'inpocket', 'marge', "Paid On" ];

  constructor(
    public dialog: MatDialog,
    private http :HttpService
  ) {
   this.loader = true;
   }

  async ngOnInit() {
    await this.getPayments();
    this.pieChartData = [ this.getFigures('ca'), this.getFigures('pocket'), this.getFigures('marge')];
  }

  async getPayments(){
    this.loader = true;
    var funcE = { type: 'PAYMENTS', func: 'getOneModelPayments' };  
    
    var dataE = { model: this.hash  };    

    var Postdata = { func: funcE, data : dataE };

    let res = await this.http.goAcess(Postdata);
    console.log('res', res);
    this.payments = res;
    this.loader = false;
  }

  getMarge(ca, payment){
    var marge = ca-payment;
    var percent = marge/ca * 100;
    var temp = Math.round(marge) + ' (' + Math.round(percent) + '%)';
    return temp;
  }

  getFigures(elem, unity = false){
    let somme = 0;
    switch(elem){
      case 'ca':
        for(var i = 0; i < this.payments.length; i++){
          if(this.payments[i].montant_ht > 0){
            somme = somme + parseInt(this.payments[i].montant_ht);
          }
         
        }
        break;
      case 'pocket':
        for(var i = 0; i < this.payments.length; i++){
          if(this.payments[i].in_pocket > 0){
          somme = somme + parseInt(this.payments[i].in_pocket);
          }
        }
        break;

        case 'marge':
          let ca = 0;
          let pocket = 0;
          for(var i = 0; i < this.payments.length; i++){
            if(this.payments[i].in_pocket > 0 || this.payments[i].montant_ht > 0){
            ca = ca + parseInt(this.payments[i].montant_ht);
            pocket = pocket + parseInt(this.payments[i].in_pocket);
            }
          }
          somme = ca - pocket;
          break;
    }
    if(unity){
      return somme + ' €';
    } else {
      return somme;
    }
   
  }


  getEasyDate(baddate){
    let newdate = moment(baddate).format('DD/MM/YYYY');
    return newdate;
  }

  async downloadStatement(societe){
    var funcE = { type: 'STATEMENT', func: 'getOneModelStatement' };  
    
    var dataE = { model: this.hash, societe : societe  };    

    var Postdata = { func: funcE, data : dataE };

    let res = await this.http.goAcess(Postdata);
    console.log('res', res);

   /* let b64data = res.data.replace("data:application/vnd.ms-excel;base64,", '');
    let B64Decode =  btoa(b64data);
    let temp = "data:application/vnd.ms-excel;base64," + B64Decode;
*/
    this.downloadFile(res.name, this.converBase64toBlob(res.data, 'application/vnd.ms-excel'));
  }

  converBase64toBlob(content, contentType) {
    contentType = contentType || '';
    var sliceSize = 512;
    var byteCharacters = window.atob(
      content); 
    var byteArrays = [];
    for (var offset = 0; offset < byteCharacters.length; offset +=
      sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, {
      type: contentType
    }); 
    return blob;
  }

  sendStatement(){
    const dialogRef = this.dialog.open(EmailcompComponent, {  
      data: { "type" : "statement", "modal" : this.hash  }
     });

     dialogRef.afterClosed().subscribe(result => {


  })
  }

  downloadFile(filename, blob) {
    var link = document.createElement("a");
    var url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

}
