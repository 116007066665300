import { Component, OnInit, Input } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { HttpService } from '../http.service';


@Component({
  selector: 'app-specificty',
  templateUrl: './specificty.component.html',
  styleUrls: ['./specificty.component.scss']
})
export class SpecifictyComponent implements OnInit {

  specs : any;
  stats: any;
  lat: number;
  long: number;
  notes: any;

  @Input() hash: string;

  constructor(
    private _Activatedroute:ActivatedRoute,
    private get: HttpService,

  ) 
  {

   }

  ngOnInit() {

   // this.getSpecs(this.hash);
    this.getStats(this.hash);
    
  }

 /* getSpecs(elem){
   // this.spinner = true;
    this.get.getSpecs(elem).subscribe( res => {
    console.log('spec', res);
      this.specs = res;
     
    
    })
  }*/

  getStats(elem){
    this.get.getStats(elem).subscribe( res => {
      this.stats = res;
      console.log('stats', this.stats);
    })
  }


}
