<div >


  <div >

    <mat-tab-group *ngIf="classic">

      <mat-tab *ngFor="let x of type" [label]="x.label">
        <ng-template matTabContent>
          <app-approved [type]="x.label"></app-approved>
        </ng-template>
      </mat-tab>


    </mat-tab-group>

    <mat-tab-group *ngIf="!classic">

      <mat-tab *ngFor="let x of category" [label]="x.label">
        <ng-template matTabContent>
          <app-approved [type]="x.label"></app-approved>
        </ng-template>
      </mat-tab>


    </mat-tab-group>

    <div style="position:absolute; top: 10px;right:50px;" class="removeOnMobile" >
      <mat-slide-toggle (change)="switchCategory($event)">Switch Category</mat-slide-toggle>
    </div>
  


  </div>


</div>
