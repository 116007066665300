import { Component, OnInit, Input } from '@angular/core';
import { HttpService } from '../../http.service';


@Component({
  selector: 'app-videomodel',
  templateUrl: './videomodel.component.html',
  styleUrls: ['./videomodel.component.scss']
})
export class VideomodelComponent implements OnInit {

  spinnerProgress: boolean;
  @Input() hash: string;
  return : any;
  video: any;

  constructor(
    private http: HttpService
  ) {
    this.video = [];
   }

  ngOnInit() {
  //  console.log('hash', this.hash);
    this.getVideo();
  }

  uploadVideo(){
   // console.log('upload video');
    document.getElementById('inputID').click();
  }

  getVideo(){
    this.http.getVideo(this.hash).subscribe(res=>{
     // console.log('res',res);
      this.video = res;
    })
  }

 async fileChangeEvent(e){
   // console.log('video', e);
    const files = e.target.files;
    this.spinnerProgress = true;
      const temp = await this.saveImagePola(this.hash, files[0]);
    //  console.log('temp', temp);
      if(temp == 'ok'){
        this.getVideo();
      }
  
    this.spinnerProgress = false;
  }

  async saveImagePola(modal, video){
   // console.log('modal', modal, 'video', video, 'desc');
  
    return new Promise(resolve => 
    this.http.saveVideo(modal, video).subscribe( res => {
      this.return = res;
  
  //    console.log('return',res);
      resolve(this.return.status);
    }, error =>{
   //   console.log(error);
    })
  );
   // console.log('modal', modal, 'image', image, 'desc', desc)
  }

  seeVideo(url){
    window.open(url);
  }

  delVideo(imageid){
    this.http.deleteImageGallery(imageid).subscribe(res=>{
  //    console.log('res',res);
      if(res == 'delete'){
        this.getVideo();
      }
    })
  }

  changeVideoVisibility(elem, visibility){
   // console.log(elem, visibility);

    this.http.changeImageVisibility(elem, visibility).subscribe(res=>{
    //  console.log(res);
    }, error => {
   //   console.log(error);
    })
  }

}
