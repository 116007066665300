import { Component, OnInit, Inject } from '@angular/core';
import { HttpService } from '../../../../../http.service';
import { DialogData } from '../mgmt-gallery.component';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-choosealbumfortransfert',
  templateUrl: './choosealbumfortransfert.component.html',
  styleUrls: ['./choosealbumfortransfert.component.scss']
})
export class ChoosealbumfortransfertComponent implements OnInit {

  albumsList : any;

  constructor(
    public dialogRef: MatDialogRef<ChoosealbumfortransfertComponent>,
    private http: HttpService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit() {
    console.log(this.data);
    this.getAlbumList(this.data['modal']);
  }

  async getAlbumList(elem){
    this.http.getAlbumList(elem).subscribe(res => {
      console.log('album list', res);
      this.albumsList = res;
     
    })
  }

  selectAlbum(id){
    this.dialogRef.close(id);
  }

}
