import { Component, OnInit, Inject, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpService } from '../http.service';
import {MatDialog,  MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { UntypedFormBuilder, Validators, UntypedFormGroup} from '@angular/forms';
import { MatSnackBar} from '@angular/material/snack-bar';

import { EmailcompComponent } from '../emailcomp/emailcomp.component';
import { SendsmsComponent } from '../sendsms/sendsms.component';

import { ContactListMgmtComponent } from './contact-list-mgmt/contact-list-mgmt.component';

export interface DialogData {
  agency: string;
}

export interface contactData {
  contact: string;
}



@Component({
  selector: 'app-agency-contact',
  templateUrl: './agency-contact.component.html',
  styleUrls: ['./agency-contact.component.css']
})
export class AgencyContactComponent implements OnInit {

  @Input() agency: string;
  hash: any;
  contact: any;
  contacttoadd: any;
  public lottieConfig: Object;

  windowWidth : any;

  //dataSource: any;

  displayedColumns:any;

  constructor(
    private _Activatedroute:ActivatedRoute,
    private http: HttpService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
  ) {
    this.windowWidth = window.innerWidth;
    if(this.windowWidth > 650) {
      this.displayedColumns = ['position', 'name', 'weight', 'Fonction','symbol',  'actions'];
    } else {
      this.displayedColumns = ['position', 'name',  'symbol',  'actions'];
    }
   }


  ngOnInit(){
  this.getContact2(this.agency);
   }



  getContact2(elem){
    var criteria = [
      { 'label' : 'agency_hash' , 'value' : elem},
    ];

    var postData = {  'table' : 'agency_contact','action' : 'getall', 'criteria' : criteria };

    this.http.goBackOffice(postData).subscribe(res=>{
      console.log('cotnact', res);
      this.contact = res;
    })
  }


  delContact(contactid, index){
    console.log('delcontact', contactid, index);
    const dialogRef = this.dialog.open(DelContact, {
    
      data: {contacttotransfer: contactid}
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log('deleted contact', result);
        if(result == 'delete'){
         this.getContact2(this.agency);
        } else {
          this.openSnackBar('An Error occured', '');
        }


    });
  }

  addContact(){
    const dialogRef = this.dialog.open(AddContact, {
      width: '400px',
      data: {agency: this.agency},
      panelClass: 'nopadding-box',
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log('add contact', result);
        if(result = 'inserted'){
          this.getContact2(this.agency);
          this.openSnackBar('Contat have been added', '');
        }


    });
  }

  editContact(contactid){
    const dialogRef = this.dialog.open(EditContact, {
      width: '400px',
      data: {contacttotransfer: contactid},
      panelClass: 'nopadding-box',
    });
  
    dialogRef.afterClosed().subscribe(result => {
        if(result == 'updated'){
          this.getContact2(this.agency);
            this.openSnackBar('Contat have been updated', '');
        }


    });
  }


  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  sendEmail(emailE){
    console.log('email');
    const dialogRef = this.dialog.open(EmailcompComponent, {  
      panelClass: 'nopadding-box',
      data: {email : emailE }
     });

     dialogRef.afterClosed().subscribe(result => {

    }) 
  }


  sendSMS(phoneE){
    let dialogRef = this.dialog.open(SendsmsComponent, {
      panelClass: 'nopadding-box',
      data: {  phone : phoneE },
      height: 'auto',
      width: 'auto',
    });
  
    dialogRef.afterClosed().subscribe(result => {
        console.log('tosend', result);    
      });
  }

  addToList(contactId){
    let dialogRef = this.dialog.open(ContactListMgmtComponent, {
      data: {  contactid : contactId },
      height: 'auto',
      width: 'auto',
    });
  
    dialogRef.afterClosed().subscribe(result => {
        console.log('tosend', result);    
      });
  }
  

}


@Component({
  selector: 'add-contact',
  templateUrl: 'add-contact.html',
})

export class AddContact {
  spinnerProgress : boolean;
  croppedImageNews: any;
  contact: any;
  chgStateGroup: UntypedFormGroup;
  gender: string;

  constructor(
    public dialogRef: MatDialogRef<AddContact>,
    private get: HttpService,    
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  )
   {
    this.createForm();
  //  this.spinnerProgress = false;
  }

  ngOnInit(): void {
    console.log('add contact', this.data['agency']);
   }

  onNoClick(): void {
    this.dialogRef.close('echec');
  }



  insertContact(){
    var data = [
      { 'label': 'contact_gender', 'value': this.chgStateGroup.get('gender').value },
      { 'label': 'contact_firstname', 'value': this.chgStateGroup.get('firstname').value },
      { 'label': 'contact_lastname', 'value': this.chgStateGroup.get('lastname').value },
      { 'label': 'contact_phone', 'value': this.chgStateGroup.get('phone').value },
      { 'label': 'contact_mail', 'value': this.chgStateGroup.get('email').value },
      { 'label': 'contact_fonction', 'value': this.chgStateGroup.get('fonction').value },
      { 'label': 'agency_hash', 'value': this.data['agency'] }
    ];

    var postData = { 'table': 'agency_contact', 'action': 'insertcontact', 'data': data };

    this.get.goBackOffice(postData).subscribe(res=>{
      if(res == 'inserted'){
        this.dialogRef.close(res);
      }
    })
  }


  onOkClick(){
    console.log( 'agency', this.data['agency']);
    this.insertContact();
  }

  createForm(){
    this.chgStateGroup = this.fb.group({
      gender: ['', Validators.required],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', Validators.required],
      fonction: ['', Validators.required],
      });
  }




}



@Component({
  selector: 'del-contact',
  templateUrl: 'del-contact.html',
})

export class DelContact {
  spinnerProgress : boolean;
  croppedImageNews: any;
  contact: any;
  chgStateGroup: UntypedFormGroup;
  gender: string;


  constructor(
    public dialogRef: MatDialogRef<AddContact>,
    private get: HttpService,    
    @Inject(MAT_DIALOG_DATA) public data: contactData,
  )
   {
  //  this.spinnerProgress = false;
  }

  ngOnInit(): void {
    console.log('contactid', this.data['contacttotransfer']);
   }

  onNoClick(): void {
    this.dialogRef.close('echec');
  }



 /* onOkClick(){
    this.get.delContact(this.data['contacttotransfer']).subscribe( res => {
        if(res){
          this.dialogRef.close(res);
        } else {
          this.dialogRef.close('echec');
        }
    
    })
  }*/

  onOkClick(){
    var criteria = [
      { 'label' : 'contact_id' , 'value' : this.data['contacttotransfer']},
    ];

    var postData = {  'table' : 'agency_contact','action' : 'delete', 'criteria' : criteria };

    this.get.goBackOffice(postData).subscribe(res=>{
      console.log('cotnact', res);
      if(res == 'delete'){
        this.dialogRef.close(res);
       }
    })
  }
}



@Component({
  selector: 'edit-contact',
  templateUrl: 'edit-contact.html',
})

export class EditContact {

  chgStateGroup: UntypedFormGroup;
  result: any;

  constructor(
    public dialogRef: MatDialogRef<AddContact>,
    private get: HttpService,    
    @Inject(MAT_DIALOG_DATA) public data: contactData,
    private fb: UntypedFormBuilder,
  )
   {
  //  this.spinnerProgress = false;
  this.createForm();
  }

  ngOnInit(): void {
 this.prepareForm();

   }

  onNoClick(): void {
    this.dialogRef.close('echec');
  }

  onOkClick(){
    var criteria = [
      { 'label' : 'contact_id' , 'value' : this.data['contacttotransfer']},
    ];

    var values = [
      { 'label' : 'contact_gender' , 'value' : this.chgStateGroup.get('gender').value},
      { 'label' : 'contact_firstname' , 'value' : this.chgStateGroup.get('firstname').value},
      { 'label' : 'contact_lastname' , 'value' : this.chgStateGroup.get('lastname').value},
      { 'label' : 'contact_phone' , 'value' : this.chgStateGroup.get('phone').value},
      { 'label' : 'contact_mail' , 'value' : this.chgStateGroup.get('email').value},
      { 'label' : 'contact_fonction' , 'value' : this.chgStateGroup.get('fonction').value},
    ];

    var postData = {  'table' : 'agency_contact', 'action' : 'update', 'criteria' : criteria, 'values' : values };

    this.get.goBackOffice(postData).subscribe(res=>{
      console.log('cotnact', res);
      if (res == 'updated'){
        this.dialogRef.close(res);
      }
    })
  }


prepareForm(){
  var criteria = [
    { 'label' : 'contact_id' , 'value' : this.data['contacttotransfer']},
  ];


  var postData = {  'table' : 'agency_contact', 'action' : 'get', 'criteria' : criteria };

  this.get.goBackOffice(postData).subscribe(res=>{
    console.log('cotnact', res);
    this.chgStateGroup.get('gender').setValue(res['contact_gender']);
    this.chgStateGroup.get('firstname').setValue(res['contact_firstname']);
    this.chgStateGroup.get('lastname').setValue(res['contact_lastname']);
    this.chgStateGroup.get('phone').setValue(res['contact_phone']);
    this.chgStateGroup.get('email').setValue(res['contact_mail']);
    this.chgStateGroup.get('fonction').setValue(res['contact_fonction']);
  })

}


  createForm(){
    this.chgStateGroup = this.fb.group({
      gender: ['', Validators.required],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', Validators.required],
      fonction: ['', Validators.required],
      });
  }

 /* prepareForm(){
    this.get.editContactAgency(this.data['contacttotransfer']).subscribe( res => {
      this.result = res;
      console.log(this.result);
      this.chgStateGroup.get('gender').setValue(this.result.gender);
   this.chgStateGroup.get('firstname').setValue(this.result.firstname);
   this.chgStateGroup.get('lastname').setValue(this.result.lastname);
   this.chgStateGroup.get('phone').setValue(this.result.phone);
   this.chgStateGroup.get('email').setValue(this.result.email);
    })
  }*/

}
