
	
        <div class="card">
            <div class="card-image"  [style.backgroundImage]="back ? 'url('+ back +')' : 'url(assets/girl_vide.jpg)' ">
              
            </div>
            <div class="card-description ">
                <div *ngIf="desc">{{desc}}</div>
                <div *ngIf="!desc">Divers</div>
                <div *ngIf="date">  ({{date}})</div>
            </div>
            <div style="display: flex; flex-direction: row; justify-content: space-between; margin: 5px;" class="removeOnMobile">
            <div>Total Pics : {{total}}</div>
            <div>Public Pics : {{visible}}</div>
            </div>
        </div>
        
        
