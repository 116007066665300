
import { Component, OnInit, Inject } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatDialog } from '@angular/material/dialog';

import { ChoosemodelComponent } from '../genmac/choosemodel/choosemodel.component';
import { ChooseagencyComponent } from '../../../agenda/gobook/chooseagency/chooseagency.component';

import { HttpService } from '../../../http.service';
import { MatStepper } from '@angular/material/stepper';

import {  MatDialogRef } from '@angular/material/dialog';
import {   MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-genboa',
  templateUrl: './genboa.component.html',
  styleUrls: ['./genboa.component.scss']
})
export class GenboaComponent implements OnInit {

  firstFormGroup: UntypedFormGroup;
  secondFormGroup: UntypedFormGroup;
  agencyFormGroup: UntypedFormGroup;
  projectFormGroup: UntypedFormGroup;
  detailsFormGroup: UntypedFormGroup;

  isLinear = false;
  model : any;
  modal: any;
  agency : any;

  download : boolean;
  type : string;
  agencycountry: string;
  agencycity: string;

  modelhash: any;
  agencyhash: any;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private http : HttpService,
    public dialogRef: MatDialogRef<GenboaComponent>,
  ) {
    this.download = false;
   }

  ngOnInit() {
    this.firstFormGroup = this._formBuilder.group({
      sexe: ['', Validators.required],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
  
    });
    this.secondFormGroup = this._formBuilder.group({
      company: ['', Validators.required],
      address1: ['', Validators.required],
      address2: ['', Validators.required],
      siret: ['', Validators.required],
    });

    this.agencyFormGroup = this._formBuilder.group({
      agencyname: ['', Validators.required],
      agencyaddress: ['', Validators.required],
      tva: ['', Validators.required],
    });

    this.projectFormGroup = this._formBuilder.group({
      annonceur: ['', Validators.required],
      respexecutif: ['', Validators.required],
      respexecutifphone: ['', Validators.required],
      photographe: ['', Validators.required],
      photographephone: ['', Validators.required],
      prestation: ['', Validators.required],
      termsofuse: ['', Validators.required],
    })

    this.detailsFormGroup = this._formBuilder.group({
      lieu: ['', Validators.required],
      prestationhorraire: ['', Validators.required],
      montantht: ['', Validators.required],

    })
  }


  chooseModel(){
    const dialogRef = this.dialog.open(ChoosemodelComponent, {
      data: {
        selecttype: 'single'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('choose model', result);
      if(result[0]['status'] == 'ok'){
        console.log('status ok');
        this.prepareModelForm(result[0]['hash']);
      }
    });
  }

  chooseAgency(){
    const dialogRef = this.dialog.open(ChooseagencyComponent, 
      {
      data : { type : 'clients'  }
      });

    dialogRef.afterClosed().subscribe(result => {
      console.log('choose model', result);
     this.prepareFormAgencyHash(result);
    });
  }

  
  prepareFormAgencyHash(agencyhash){
    this.http.prepareFormAgency(agencyhash).subscribe(res=>{
      console.log('res agency', res);
      if(res){
        this.agencyFormGroup.get('agencyname').setValue(res['name']);
        this.agencyFormGroup.get('agencyaddress').setValue(res['address']);
       // this.agencyFormGroup.get('siret').setValue(res['siret']);
        this.agencyFormGroup.get('tva').setValue(res['tva']);
        this.agencyhash = res['agencyhash'];
        this.goFormAgency();

      }
    })
  }



  goForm(){
    this.model = 'aze';
  }

  goFormAgency(){
    this.agency = 'aze';
  }

  prepareModelForm(hash){
    console.log('prepare form', hash);
    this.http.seeModel(hash).subscribe(res=>{
      console.log('one model', res);
      this.modal = res['personal'];
      console.log('one model', this.model);
      this.firstFormGroup.get('sexe').setValue(this.modal.user_gender);
      this.firstFormGroup.get('firstname').setValue(this.modal.user_first);
      this.firstFormGroup.get('lastname').setValue(this.modal.user_last);
      this.modelhash = this.modal.user_hash;
     this.goForm();
    })
  }


  async prepareCompanyForm(){
    await this.http.getCompagnyInfo().subscribe(res=>{
       console.log('entreprise', res);
       this.secondFormGroup.get('company').setValue(res['name']);
       this.secondFormGroup.get('address1').setValue(res['address1']);
       this.secondFormGroup.get('address2').setValue(res['address2']);
       this.secondFormGroup.get('siret').setValue(res['siret']);
 
     })
   }
 
  async goEntreprise(stepper: MatStepper){
     await this.prepareCompanyForm();
     stepper.next();
   }
 
   downloadSwitch(elem = 'fr'){
     switch (this.type){
       case 'mac':
       this.downloadMAC();
       break;
 
       case 'mca':
      // this.downloadMCA(elem);
       break;
 
     }
   }

   validateDate(date){
     if(date == 'Inconnue'){
       return date;
     }
    if(date && (date !== null) ){
      return date;
    } else {
      return 'Inconnue';
    }
   }

   getDate(value, time){
    if( time == 'start'){
     return this.validateDate(value[0].toISOString());
    }

    if( time == 'end'){
     return this.validateDate(value[1].toISOString());
    }
    
  }

 
   generateFile(){

    var data = [
      { 'label': 'model_hash', 'value': this.modelhash },
      { 'label': 'client_hash', 'value': this.agencyhash },

      { 'label': 'annonceur', 'value': this.projectFormGroup.get('annonceur').value },
      { 'label': 'executif', 'value': this.projectFormGroup.get('respexecutif').value },
      { 'label': 'executif_phone', 'value': this.projectFormGroup.get('respexecutifphone').value },
      { 'label': 'photographer', 'value': this.projectFormGroup.get('photographe').value },
      { 'label': 'photographer_phone', 'value': this.projectFormGroup.get('photographephone').value },
      { 'label': 'prestation', 'value': this.projectFormGroup.get('prestation').value },
      { 'label': 'conditions_utilisation', 'value': this.projectFormGroup.get('termsofuse').value },


      { 'label': 'lieu_prestation', 'value': this.detailsFormGroup.get('lieu').value },
      
      { 'label': 'horraire_prestation_start', 'value': this.getDate(this.detailsFormGroup.get('prestationhorraire').value, 'start') },
      { 'label': 'horraire_prestation_end', 'value': this.getDate(this.detailsFormGroup.get('prestationhorraire').value, 'end') },
      { 'label': 'montant_ht', 'value':  this.detailsFormGroup.get('montantht').value },

    ];

    var postData = { 'table': 'booking_order_agreement', 'action': 'insert', 'data': data };
    this.download = true;
    this.http.goBackOffice(postData, 'contract/mgmt_boa.php').subscribe(res=>{
      console.log('res',res);
      if(res['status'] == 'ok'){
        this.dlFile(res['file'], res['name'], res['type']);
        this.dialogRef.close('nothing');
      } else {
        this.download = false;
      }
    }, error =>{
      console.log('error',error);
      this.download = false;
    })

   }


   downloadMAC(){
     console.log('download mac');
     this.download = true;
     
     this.http.generateContractMAC(
       this.firstFormGroup.get('sexe').value,
       this.firstFormGroup.get('firstname').value,
       this.firstFormGroup.get('lastname').value,
       this.firstFormGroup.get('birthdate').value,
       this.firstFormGroup.get('nationality').value,
       this.firstFormGroup.get('modeladdress').value,
       this.secondFormGroup.get('company').value,
       this.secondFormGroup.get('address1').value,
       this.secondFormGroup.get('address2').value,
       this.secondFormGroup.get('siret').value,
 
     ).subscribe(res=>{
       console.log('gen contract', res);
       if(res['status'] == 'ok'){
         this.dlFile(res['file'], res['name'], res['type']);
         this.dialogRef.close('nothing');
       }
     })
   }
 
   dlFile(data: Response, name, type) {
 
     switch(type){
       case 'pdf' :
       type = 'application/pdf' 
       break;
     }
   
     var blob = this.b64toBlob(data, type, '512');
     var blobUrl = URL.createObjectURL(blob);
   
     var a = document.createElement("a");
     document.body.appendChild(a);
     a.href = blobUrl;
     a.download = name;
   //  this.spinnerProgress = false;
     a.click();
     window.URL.revokeObjectURL(blobUrl);
     a.remove();
   }
 
   b64toBlob(b64Data, contentType, sliceSize) {
     contentType = contentType || '';
     sliceSize = sliceSize || 512;
   
     var byteCharacters = atob(b64Data);
     var byteArrays = [];
   
     for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
       var slice = byteCharacters.slice(offset, offset + sliceSize);
   
       var byteNumbers = new Array(slice.length);
       for (var i = 0; i < slice.length; i++) {
         byteNumbers[i] = slice.charCodeAt(i);
       }
   
       var byteArray = new Uint8Array(byteNumbers);
   
       byteArrays.push(byteArray);
     }
   
     var blob = new Blob(byteArrays, {type: contentType});
     return blob;
   }
 



}
